import { Grid } from "@mui/material";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import axios from "axios";
import dayjs from 'dayjs';
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SingleValue } from "react-select";
import { HookConfig } from "../../config/HookConfig";
import { pontCurrencies } from "../../data/en/pontCurrencies";
import { ApplicationConfig } from "../../config/ApplicationConfig";
import { operatorDefaultHeaders } from "../../functions/api/api";
import { GenerateExternalReference } from "../../functions/utils/helper";
import { useDirtyStateV2 } from "../../hooks/Common/dirtyV2";
import { useFetchInventoryTypes } from "../../hooks/Inventory/inventory";
import { useCreateMembership, useFetchMembershipById, useFetchMembershipSummaryById, useFetchMembershipUnavailabilities, useUpdateMembership } from "../../hooks/Memberships/membership";
import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { SummaryItemDto } from "../../types/Common/SummaryItemDto";
import { InventoryTypeSelectOption } from "../../types/Inventory/InventoryTypeDto";
import { InventoryUnavailabilityDto } from "../../types/Inventory/InventoryUnavailabilityDto";
import { InventoryVariantDto, InventoryVariantSelectOption } from "../../types/Inventory/InventoryVariantDto";
import { LocationResponseDto } from "../../types/Location/LocationResponseDto";
import { MembershipPriceDto } from "../../types/Membership/MembershipPriceDto";
import { MembershipResponseDto } from "../../types/Membership/MembershipResponseDto";
import { PriceDto } from "../../types/Price/PriceDto";
import { ConfirmationChangesDialog } from '../Common/ConfirmationChangesDialog';
import { CustomDatePicker } from "../Common/formComponents/CustomDatepicker";
import { CustomSelect } from "../Common/formComponents/CustomSelect";
import { Input } from "../Common/formComponents/Input";
import { Label } from "../Common/formComponents/Label";
import { LR } from "../Common/Help/LR";
import { SectionHead } from "../Common/Help/SectionHead";
import FormattedNumberInput from "../Common/Prices/FormatNumberInput";
import { SummaryItemCollection } from "../Common/SummaryItems/SummaryItemCollection";
import { showSnackbar } from "../Common/UI/CustomSnackbar";
import { InventoryUnavailabilities } from "../Inventory/InventoryUnavailabilities";
import { LocationSelector } from "../Location/LocationSelector";

type Args = {
    userLogged: ApplicationUser,
    isEdit: boolean
}

const MembershipSummary = ({ userLogged, isEdit }: Args) => {
    const nav = useNavigate();
    let summaryName: string = '';
    const queryStringParams = useParams();

    if (isEdit && !queryStringParams.id) throw Error("Membership id needed");

    const { cancelDirtyAction, confirmDirtyAction, handleDirtyAction, isDirty, setDirty, showConfirmation, unsetDirty } = useDirtyStateV2();

    if (queryStringParams.summaryName)
        summaryName = queryStringParams.summaryName;

    const linkCallBackCollection = (item: SummaryItemDto) => {
        const path: Record<string, string> = {
            "MembershipFeaturesSummary": `/memberships/${queryStringParams.id}/features`,
            "MembershipDescriptionSummary": `/memberships/${queryStringParams.id}/description`,
            "FeatureSummary": `/memberships/${queryStringParams.id}/features`,
            "MembershipImagesSummary": `/memberships/${queryStringParams.id}/digital-assets/${item.uri.substring(item.uri.indexOf('group=') + 6)}`,
            "MembershipDocsSummary": `/memberships/${queryStringParams.id}/digital-assets/${item.uri.substring(item.uri.indexOf('group=') + 6)}`
        }

        let targetUrl = path[item.name]

        if (!targetUrl) {
            alert(`Item ${item.name} does not have a link yet`);
            return;
        }

        handleDirtyAction(() => nav(targetUrl));
    };

    const homeCallBack = () => {
        const homeUrl = `/inventory`;
        handleDirtyAction(() => nav(homeUrl));
    };

    const createPriceItem = (membershipPriceDto: MembershipPriceDto, membershipId: string) => {
        let priceDto: PriceDto = {
            id: membershipPriceDto?.id ?? 0,
            entityName: 'Membership',
            entityKey: membershipId ?? '',
            unitName: 'Ticket',
            fromDate: membershipPriceDto?.fromDate ?? Date,
            toDate: membershipPriceDto?.toDate ?? Date,
            currencyCode: membershipPriceDto?.currencyCode ?? 'GBP',
            price: membershipPriceDto?.price ?? 0
        }
        return priceDto;
    }

    const defaultMembership: MembershipResponseDto = {
        id: queryStringParams.id!,
        name: '',
        inventoryTypeName: '',
        locationId: ApplicationConfig.emptyGuid,
        inventoryVariantId: ApplicationConfig.emptyGuid,
        quantity: 0,
        quantityLimitPerOrder: 0,
        externalReference: '',
        isDisabled: false,
        internalIdentifier: '',
        fromDate: new Date(),
        toDate: new Date(),
        prices: [],
        soldQuantity: 0,
        locationName: '',
        shortDescription: '',
        description: '',
    };

    const defaultSummaries: SummaryItemDto[] = [];
    const [membershipState, setMembershipState] = useState(defaultMembership);
    const [summariesState, setMembershipSummaries] = useState(defaultSummaries);
    const [selectedPrice, setSelectedPrice] = useState(0);
    const [selectedCurrency, setSelectedCurrency] = useState<{ value: string, label: string, countryCode: string }>({ value: 'GBP', label: 'British Pound Sterling - £', countryCode: 'GBR' });
    const [priceItemState, setPriceItemState] = useState<PriceDto>(createPriceItem(null!, null!));
    const [unavailabilityState, setUnavailability] = useState<InventoryUnavailabilityDto[]>([])

    const { data: membership, status: membershipStatus, isSuccess: membershipIsSuccess } = useFetchMembershipById(queryStringParams.id!, isEdit);
    const { data, status } = useFetchMembershipSummaryById(queryStringParams.id!, summaryName!, isEdit);
    const { data: unavailabilityData } = useFetchMembershipUnavailabilities(queryStringParams.id!, isEdit);
    const { mutate } = useUpdateMembership();
    const create = useCreateMembership();


    useEffect(() => {
        if (isEdit) {
            if (membershipIsSuccess && membership) {
                setMembershipState(membership);
                setSelectedPrice(membership?.prices[0]?.price ?? 0);
                const _priceCurrency = membership?.prices[0]?.currencyCode ? membership?.prices[0]?.currencyCode : 'GBP'
                var priceCurrency = pontCurrencies.find(x => x.value === _priceCurrency)
                setSelectedCurrency(priceCurrency!);
                setPriceItemState(createPriceItem(membership?.prices[0], membership.id));
                if (data) {
                    setMembershipSummaries(data)
                }
            }
        }
    }, [status, membershipStatus]);

    useEffect(() => {
        if (unavailabilityData) {
            setUnavailability(unavailabilityData);
        }
    }, [unavailabilityData])

    useEffect(() => {
        if (showConfirmation) {
            console.log("🚀 Confirmation dialog triggered by state change.");
        }
    }, [showConfirmation]);


    const handleCurrencyChange = (selectedOption: any) => {
        setSelectedCurrency(selectedOption);
        setPriceItemState({ ...priceItemState!, currencyCode: selectedOption });
    }

    const generateUniqueCode = () => {
        if (membershipState.externalReference === '' || membershipState.externalReference === null) {
            const formattedCode = GenerateExternalReference(membershipState.name ?? '');
            setMembershipState({ ...membershipState, externalReference: formattedCode });
        }
    };

    const confirmLocation = (location: LocationResponseDto) => {
        setMembershipState({ ...membershipState, locationId: location.id });
        fetchVariantData(location.id);
    }

    const onPriceItemChange = (value: string) => {
        setPriceItemState({ ...priceItemState!, price: Number(value) });
    }

    const addMembershipPrice = () => {
        let price: MembershipPriceDto = {
            id: 0,
            unitName: 'Day',
            fromDate: membershipState.fromDate,
            toDate: membershipState.toDate,
            currencyCode: priceItemState.currencyCode,
            price: priceItemState.price
        }
        membershipState.prices.push(price);
    }

    const requiredPopulated = () => {
        return !!membershipState.name &&
            !!membershipState.shortDescription &&
            !!membershipState.locationId && membershipState.toDate > membershipState.fromDate;
    }

    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            membershipState.inventoryTypeName = selectedInventoryType?.value!;
            membershipState.inventoryVariantId = selectedVariant?.value!;
            let ticketPrice = membershipState.prices?.find(x => x.unitName === 'Ticket');
            if (ticketPrice) {
                ticketPrice.price = priceItemState.price;
                ticketPrice.currencyCode = priceItemState.currencyCode;
                ticketPrice.fromDate = membershipState.fromDate;
                ticketPrice.toDate = membershipState.toDate;
            }
            else {
                addMembershipPrice();
            }

            if (isEdit) {
                mutate(membershipState, {
                    onSuccess: (data) => {
                        nav(`/memberships/${queryStringParams.id}/summary`);
                    },
                    onError: (error) => {
                        showSnackbar(error.response?.data, {
                            persist: true,
                            variant: 'error'
                        });
                    },
                });
            }
            else {
                create.mutate(membershipState, {
                    onSuccess: ({ id }) => {
                        nav(`/memberships/${id}/summary`)
                    },
                    onError: (error) => {
                        showSnackbar(error.response?.data, {
                            persist: true,
                            variant: 'error'
                        });
                    },
                });
            }
            unsetDirty();
        } catch (err: any) {
            console.error(err);
        }
    }


    const [selectedInventoryType, setSelectedInventoryType] = useState<InventoryTypeSelectOption>();
    const [inventoryTypes, setInventoryTypes] = useState<InventoryTypeSelectOption[]>([]);
    const { data: retrievedTypes } = useFetchInventoryTypes();

    const fetchVariantData = async (locationId: string) => {
        const response = await axios.get(`${HookConfig.inventoryUrl}/inventory-variants/${locationId}/${selectedInventoryType?.value ?? 'DayPass'}`,
            {
                withCredentials: true,
                headers: operatorDefaultHeaders()
            }
        );
        const variants: InventoryVariantDto[] = response.data;
        const variantOptions = variants.filter(x => x.id !== ApplicationConfig.emptyGuid).map((variant) => ({
            label: variant.value,
            value: variant.id
        }));
        setInventoryVariants(variantOptions);
    }

    useEffect(() => {
        if (retrievedTypes) {
            const selectOptions = retrievedTypes.map((inventoryType) => ({
                label: i18n.t(inventoryType.label),
                value: inventoryType.name,
                quantityVisible: inventoryType.quantityVisible,
                unitName: inventoryType.unitName,
            }));

            setInventoryTypes(selectOptions);
            let dayPassOption = selectOptions.find(x => x.value === 'DayPass');
            setSelectedInventoryType(dayPassOption);
            if (membership) {
                let type = selectOptions.find(x => x.value === membership.inventoryTypeName) ?? dayPassOption;
                setSelectedInventoryType(type);
            }
        }
    }, [retrievedTypes, membership]);

    const handleDropdownChange = (value: SingleValue<InventoryTypeSelectOption>) => {
        var selectedType = inventoryTypes.find(x => x.value === value?.value);
        setSelectedInventoryType(selectedType);
    };

    const handleDropdownChangeVariant = (value: SingleValue<{ value: string, label: string | undefined }>) => {
        const selectedVariant = inventoryVariants.find(x => x.value === value?.value);

        if (selectedVariant) {
            setSelectedVariant(selectedVariant);
        }
    };
    const [inventoryVariants, setInventoryVariants] = useState<InventoryVariantSelectOption[]>([]);
    const [selectedVariant, setSelectedVariant] = useState<InventoryVariantSelectOption>();

    const handleChange = (name: string, value: any) => {
        setMembershipState({ ...membershipState, [name]: value });
        setDirty();
    }

    useEffect(() => {
        let selectedVariant = inventoryVariants.find(x => x.value === membership?.inventoryVariantId);
        setSelectedVariant(selectedVariant ?? inventoryVariants[0]);
    }, [inventoryVariants]);

    return (

        <>
            <form onSubmit={onFormSubmit}>
                {!queryStringParams.summaryName && (
                    <SectionHead
                        linkCallBack={homeCallBack}
                        ctaText={i18n.t('COMMON_LABEL_INVENTORY')!}
                        name={isEdit ? `${membershipState.name} (${membershipState.externalReference})` : ''}
                        title={i18n.t(!isEdit ? 'MEMBERSHIP_SUMMARY_MEMBERSHIPMAINPAGEADD' : 'MEMBERSHIP_SUMMARY_MEMBERSHIPMAINPAGEEDIT'!)}
                        description={i18n.t('COMMON_SUMMARY_MEMBERSHIPMANAGEMENT')}
                    />
                )}



                <div className="row d-flex justify-content-center">
                    <div className="col-md-7 col-11 justify-content-center">
                        <Grid container spacing={2} mb={2}>
                            <Grid item xs={12}>
                                <Input
                                    placeholder={i18n.t("MEMBERSHIP_LABEL_NAME").toString()}
                                    value={membershipState.name}
                                    onChange={e => handleChange('name', e.target.value)}
                                    onBlur={() => generateUniqueCode()}
                                    required
                                    labelProps={{
                                        message: 'MEMBERSHIP_LABEL_NAME',
                                        mandatory: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Input
                                    placeholder={i18n.t("MEMBERSHIP_LABEL_DESCRIPTION").toString()}
                                    value={membershipState.shortDescription}
                                    onChange={e => handleChange('shortDescription', e.target.value)}
                                    required
                                    labelProps={{
                                        message: 'MEMBERSHIP_LABEL_DESCRIPTION',
                                        mandatory: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LocationSelector
                                    locationId={membershipState.locationId!}
                                    placeholder="MEMBERSHIP_LABEL_LOCATION"
                                    operatorId={userLogged.operator?.id!}
                                    handleConfirm={confirmLocation}
                                    labelProps={{
                                        mandatory: true,
                                        message: 'MEMBERSHIP_LABEL_LOCATION'
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomSelect
                                    value={selectedInventoryType}
                                    options={inventoryTypes}
                                    onChange={(e) => handleDropdownChange(e)}
                                    placeholder={i18n.t('INVENTORY_LABEL_TYPE')}
                                    labelProps={{
                                        mandatory: true,
                                        message: 'INVENTORY_LABEL_TYPE'
                                    }}
                                    isDisabled
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomSelect
                                    value={selectedVariant}
                                    options={inventoryVariants}
                                    onChange={(e) => handleDropdownChangeVariant(e)}
                                    placeholder={i18n.t('INVENTORY_LABEL_VARIANT')}
                                    labelProps={{
                                        mandatory: false,
                                        message: 'INVENTORY_LABEL_VARIANT'
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Input
                                    placeholder={i18n.t("MEMBERSHIP_LABEL_IDENTIFIER").toString()}
                                    value={membershipState.externalReference}
                                    onChange={e => handleChange('externalReference', e.target.value)}
                                    labelProps={{
                                        message: 'MEMBERSHIP_LABEL_IDENTIFIER',
                                        mandatory: true
                                    }}
                                    required
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Input
                                    placeholder={i18n.t("MEMBERSHIP_LABEL_QUANTITY").toString()}
                                    value={membershipState.quantity}
                                    onChange={e => handleChange('quantity', Number(e.target.value))}
                                    labelProps={{
                                        message: 'MEMBERSHIP_LABEL_QUANTITY',
                                        mandatory: true
                                    }}
                                    type="number"
                                    inputProps={{
                                        min: 0
                                    }}
                                    required
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Input
                                    placeholder={i18n.t("MEMBERSHIP_LABEL_LIMIT").toString()}
                                    value={membershipState.quantityLimitPerOrder}
                                    onChange={e => handleChange('quantityLimitPerOrder', Number(e.target.value))}
                                    labelProps={{
                                        message: 'MEMBERSHIP_LABEL_LIMIT',
                                        mandatory: true
                                    }}
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        max: membershipState.quantity
                                    }}
                                    id="limit"
                                    required
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Input
                                    placeholder={i18n.t("MEMBERSHIP_LABEL_SOLD").toString()}
                                    value={membershipState.soldQuantity}
                                    labelProps={{
                                        message: 'MEMBERSHIP_LABEL_SOLD',
                                        mandatory: true
                                    }}
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                    }}
                                    id="sold"
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormattedNumberInput
                                    allowEmpty={false}
                                    value={selectedPrice}
                                    onChange={(item, value) => {
                                        onPriceItemChange(value);
                                        setDirty();
                                    }}
                                    currencyValue={selectedCurrency!.value}
                                    item={priceItemState!}
                                    labelProps={{
                                        message: 'COMMON_LABEL_DAILYPRICE',
                                        mandatory: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CustomSelect
                                    value={selectedCurrency}
                                    options={pontCurrencies}
                                    onChange={handleCurrencyChange}
                                    placeholder={i18n.t('COMMON_LABEL_SELECTCURRENCY')}
                                    labelProps={{
                                        mandatory: true,
                                        message: 'COMMON_LABEL_CURRENCY'
                                    }}
                                    isDisabled
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CustomDatePicker
                                    value={dayjs(membershipState.fromDate)}
                                    onChange={e => handleChange('fromDate', e?.toDate()!)}
                                    labelProps={{
                                        message: 'MEMBERSHIP_LABEL_FROMDATE'
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CustomDatePicker
                                    value={dayjs(membershipState.toDate)}
                                    onChange={e => handleChange('toDate', e?.toDate()!)}
                                    labelProps={{
                                        message: 'MEMBERSHIP_LABEL_TODATE'
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </div>

                <div className={`row d-flex justify-content-center`}>
                    <div className="col-md-7 col-11 justify-content-center">
                        {isEdit &&
                            <InventoryUnavailabilities
                                items={unavailabilityState}
                                locationId={membershipState.locationId}
                                handleDirtyAction={handleDirtyAction}
                            />}
                        <SummaryItemCollection linkCallBack={linkCallBackCollection} items={summariesState} />
                    </div>
                </div>

                <div className="row d-flex justify-content-center mt-3">
                    <div className="col-md-2 col-6 justify-content-center">
                        <Tooltip title={!requiredPopulated() ? i18n.t('MEMBERSHIP_REQUIRED_TOOLTIP') : ''}>
                            <span>
                                <button
                                    type="submit"
                                    disabled={!requiredPopulated() || !isDirty}
                                    style={!requiredPopulated() ? { pointerEvents: 'none' } : {}}
                                    className="btn btn-green btn-primary btn-block"
                                >
                                    <LR localResource="COMMON_LABEL_SAVE"></LR>
                                </button>
                            </span>
                        </Tooltip>
                    </div>
                </div>
            </form>
            <ConfirmationChangesDialog
                handleConfirm={confirmDirtyAction}
                handleCancel={cancelDirtyAction}
                showConfirmation={showConfirmation}
            />
        </>
    );

};

export { MembershipSummary };

