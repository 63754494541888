import { useEffect, useState } from "react";
import { SingleValue } from "react-select";
import { pontCountries } from "../../data/en/pontCountries";
import { pontCountriesLabels } from "../../data/en/pontCountriesLabels";
import useFetchVisitor from "../../hooks/Common/visitor";
import i18n from "../../i18n";
import { CustomSelect } from "./formComponents/CustomSelect";
import { LabelType } from "./formComponents/Label";

type Args = {
    inputCountry?: string | null,
    valueChanged: (value: string) => void;
    labelProps?: LabelType;
};

const CountryDropDown = ({ inputCountry, valueChanged, labelProps }: Args) => {
    const { data: visitor, status, isSuccess } = useFetchVisitor();
    const [selectedCountry, setSelectedCountry] = useState<any>(null);

    useEffect(() => {
        if (!inputCountry || inputCountry === '') {
            const defaultCountry = pontCountriesLabels.find(i => i.value === visitor?.countryCode);
            setSelectedCountry(defaultCountry);
            if (visitor?.countryCode !== undefined) {
                const selectedValue = pontCountries.find(i => i.Iso2Code === visitor?.countryCode)?.Iso3Code!
                valueChanged(selectedValue);
            }
        }
    }, [visitor?.countryCode]);

    useEffect(() => {
        if (inputCountry && inputCountry !== '') {
            const iso2Value = pontCountries.find(i => i.Iso3Code === inputCountry)?.Iso2Code!
            if (iso2Value) {
                const selectedCountry = pontCountriesLabels.find(i => i.value === iso2Value);
                setSelectedCountry(selectedCountry);
                valueChanged(inputCountry!);
            }
        }
    }, [inputCountry]);

    const ChangeCountry = (value: SingleValue<{ value: string, label: string | undefined }>) => {
        setSelectedCountry({
            ...selectedCountry,
            value: (value?.value !== undefined ? value.value : ''),
            label: (value?.label !== undefined ? value.label : '')
        });

        const selectedValue = pontCountries.find(i => i.Iso2Code === value?.value!)?.Iso3Code!
        valueChanged(selectedValue);
    }

    if (!isSuccess) return <><span>{status}...</span></>;

    return (
        <CustomSelect
            options={pontCountriesLabels}
            value={selectedCountry}
            onChange={(e) => ChangeCountry(e)}
            placeholder={i18n.t('COMMON_LABEL_COUNTRY')}
            labelProps={labelProps}
        />
    );
}

export default CountryDropDown;