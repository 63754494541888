import { isNil } from "lodash";
import { useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useAddMember } from "../../hooks/Member/member";
import i18n from "../../i18n";
import { useSource } from "../../providers/SourceProvider";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { SourceEnum } from "../../types/Common/CommonSummaryHeaderProps";
import { MemberRequestDto } from "../../types/Member/MemberRequestDto";
import { LR } from "../Common/Help/LR";
import { SectionHead } from "../Common/Help/SectionHead";
import { showErrorMessage } from "../Common/UI/CustomSnackbar";
import { MemberForm } from "./MemberForm";

type Args = {
    userLogged: ApplicationUser
}

const MemberAdd = ({ userLogged }: Args) => {
    const params = useParams();
    const nav = useNavigate();
    const parentId = params.parentId;

    const { source } = useSource()

    const memberType: Partial<Record<SourceEnum, string>> = {
        [SourceEnum.operators]: 'Operator',
        [SourceEnum.landlords]: 'Landlord'
    }

    const currentMemberType = memberType[source]

    if (isNil(currentMemberType)) {
        throw Error("Member type not found")
    }

    //we are adding a company
    const [submitting, setSubmitting] = useState(false);
    const { mutate: addMemberMutation } = useAddMember(currentMemberType, parentId);
    const AddMember = (member: MemberRequestDto) => {
        setSubmitting(true);
        addMemberMutation(member, {
            onError: (error) => {
                showErrorMessage(error.response?.data)
            }
        })
        setSubmitting(false);
    }

    const member: MemberRequestDto = {
        countryIso3Code: '',
        enrolmentCode: '',
        fullName: '',
        languageCode: i18n.language,
        memberTypeName: currentMemberType
    };

    const callBackUrl: any = {
        [SourceEnum.operators]: "/operators",
        [SourceEnum.landlords]: "/landlords"
    }
    const ctaText: any = {
        [SourceEnum.operators]: "COMMON_LABEL_OPERATORS",
        [SourceEnum.landlords]: "COMMON_LABEL_LANDLORDS"
    }
    const title: any = {
        [SourceEnum.operators]: "MEMBER_TITLE_NEWOPERATOR",
        [SourceEnum.landlords]: "MEMBER_TITLE_NEWLANDLORD"
    }
    const description: any = {
        [SourceEnum.operators]: "COMMON_SUMMARY_OPERATORMANAGEMENT",
        [SourceEnum.landlords]: "COMMON_SUMMARY_LANDLORDMANAGEMENT"
    }
    const message: any = {
        [SourceEnum.operators]: "MEMBER_MESSAGE_NEWOPERATOR",
        [SourceEnum.landlords]: "MEMBER_MESSAGE_NEWLANDLORD"
    }

    return (
        <>
            <SectionHead linkCallBack={() => nav(callBackUrl[source]!)}
                ctaText={i18n.t(ctaText[source] as string)!}
                name=""
                title={i18n.t(title[source]!)}
                description={i18n.t(description[source]!)} />

            <div className="row mb-3 d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <LR localResource={message[source]!}></LR>
                </div>
            </div>

            <div className="row d-flex justify-content-center">
                <div className="col-md-4 col-11 justify-content-center">
                    <MemberForm
                        handleDirty={() => { }}
                        type={currentMemberType}
                        submitting={submitting}
                        member={member}
                        isEdit={false}
                        submitted={(member) => AddMember(member)}
                    />
                </div>
            </div>
        </>
    );
};


export { MemberAdd };
