import { styled, Tab, tabClasses, Tabs, tabsClasses } from "@mui/material";
import { colors } from "../../../main/Theme";
import shadows from "@mui/material/styles/shadows";

export const CustomTabs = styled(Tabs)(({ theme }) => ({
  [`&.${tabsClasses.root}`]: {
    color: colors.white,
    minHeight: 'auto'
  },
  [`& .${tabsClasses.indicator}`]: {
    backgroundColor: theme.palette.primary.main,
  },
  [`& .${tabsClasses.flexContainer}`]: {
    position: 'relative',
    zIndex: 1,
  },
  [`& .${tabsClasses.indicator}`]: {
    height: "auto",
    top: 0,
    background: colors.primary,
    borderRadius: 2,
    boxShadow: shadows[5],
  }
}))

export const CustomTab = styled(Tab)(({ theme }) => ({
  [`&.${tabClasses.selected}`]: {
    color: colors.white,
    backgroundColor: 'transparent',
  },
  [`&.${tabClasses.root}`]: {
    padding: '10px 15px',
    minHeight: 'auto',
    boxShadow: 'none',
  }
}));