import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Box, Grid, Tooltip } from "@mui/material";
import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { pontProductsConfig } from '../../data/order/OrderData';
import { useBasketCreate } from '../../hooks/Order/basket';
import { useFetchOrders } from '../../hooks/Order/useOrder';
import { useSaveOrderFilterLocalStorage } from '../../hooks/Order/useSaveOrderFilterLocalStorage';
import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { OrderSearchV3ItemResponseDto } from '../../types/Order/OrderSearchResponseDto';
import DateNavigation from '../Common/DateNavigation/DateNavigation';
import MessageAlert from '../Common/Errors/MessageAlert';
import { LR } from "../Common/Help/LR";
import { SearchInput } from '../Common/SearchInput';
import { CustomButton } from '../Common/UI/CustomButton';
import { CustomDataGrid } from '../Common/UI/CustomDataGrid';
import { MaterialIcon } from '../Common/UI/MaterialIcon';


const calculateDates = (fromDate: Date): { from: Date, to: Date } => {
  const firstDayOfMonth = new Date(fromDate.getFullYear(), fromDate.getMonth(), 1);
  const lastDayOfMonth = new Date(fromDate.getFullYear(), fromDate.getMonth() + 1, 0);
  return { from: firstDayOfMonth, to: lastDayOfMonth };
};

const formatDateToLocalString = (date: Date): any => {
  const year = date.getFullYear();
  const month = (`0${date.getMonth() + 1}`).slice(-2);
  const day = (`0${date.getDate()}`).slice(-2);
  return `${year}-${month}-${day}`;
};

type Args = {
  userLogged: ApplicationUser;
};


const IconToShow = ({ productTypeName }: { productTypeName: string }) => {
  const productConfig = pontProductsConfig.find(x => x.productTypeName === productTypeName);
  if (productConfig) {
    return (
      <Tooltip title={i18n.t(productConfig.productTypeName)}>
        <Box>
          <MaterialIcon name={productConfig.icon} size={20} />
        </Box>
      </Tooltip>
    )
  }
  return <></>;
}

const getPeriod = (order: OrderSearchV3ItemResponseDto): string => {
  const fromDate = order.fromDate ? dayjs(order.fromDate) : undefined;
  const toDate = order.toDate ? dayjs(order.toDate) : undefined;

  if (!fromDate) {
    return "N/A";
  }

  const fromDateString = fromDate.format('DD, MMM YYYY');
  const toDateString = toDate?.format('DD, MMM YYYY') || "";

  const fromTime = fromDate.format('HH:mm');
  const toTime = toDate?.format('HH:mm') || "";

  if (fromDateString === toDateString && fromTime !== toTime) {
    return `${fromDateString}   ${fromTime} - ${toTime}`;
  } else if (fromDateString === toDateString) {
    return `${fromDateString}`;
  } else {
    return `${fromDateString} - ${toDateString}`
  }
};

const colors: any = {
  Draft: '#06b6d4',
  Provisional: '#f97316',
}


export const Orders = ({ userLogged }: Args) => {

  const navigate = useNavigate();
  const now = new Date();
  const defaultDates = calculateDates(now);
  const defaultPagination = { page: 0, pageSize: 10 }
  const [paginationModel, setPaginationModel] = useState(defaultPagination);
  const { storedFilterValue, setStoredFilterValue } = useSaveOrderFilterLocalStorage()
  const [filterParam, setFilterParam] = useState({
    fromDate: storedFilterValue?.fromDate ? storedFilterValue?.fromDate : formatDateToLocalString(defaultDates.from),
    toDate: storedFilterValue?.toDate ? storedFilterValue?.toDate : formatDateToLocalString(defaultDates.to),
    searchRequirement: ""
  });
  const { mutate: createBasketFromOrder } = useBasketCreate();

  const { data, isLoading } = useFetchOrders({
    locationId: userLogged.location!.id || "",
    pageNumber: paginationModel?.page + 1,
    pageSize: paginationModel?.pageSize,
    ...filterParam,
    options: {
      enabled: true
    }
  })

  const columns: GridColDef[] = [
    {
      field: 'orderId',
      headerName: 'Order Number',
      sortable: false,
      flex: 3,
      renderCell: (params: any) => <>
        <Box sx={{ color: colors[params.row.orderStatusName] || '' }}>{params.row.orderExternalReference || `ORD-${params.row.orderId}`}</Box>
      </>
    },
    {
      field: 'customer',
      headerName: 'Customer',
      sortable: false,
      flex: 5,
      renderCell: (params: any) => `${params.row.firstName} ${params.row.lastName || ''}`
    },
    {
      field: 'companyName',
      headerName: 'Company',
      sortable: false,
      flex: 5,
    },
    {
      field: 'period',
      headerName: 'Period',
      sortable: false,
      renderCell: (params: any) => getPeriod(params.row),
      flex: 5,
    },
    {
      field: 'productTypeName',
      headerName: 'Product',
      sortable: false,
      flex: 2,
      renderCell: (params: any) => <>
        {params.row.productTypeNames.map((productTypeName: string, index: number) => <Box key={params.row.orderId + index} sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap"
        }}>
          <IconToShow productTypeName={productTypeName} />
        </Box>)}
      </>
    },
    {
      field: 'description',
      headerName: 'Details',
      sortable: false,
      flex: 5,
      renderCell: (params: any) => <Box sx={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
      }}>
        {params.row.description}
      </Box>
    },
    {
      field: 'action',
      headerName: '',
      sortable: false,
      flex: 1,
      renderCell: (params: any) => <div
        // onClick={() => handleViewClick(params)}
        style={{
          cursor: 'pointer',
        }}
      >
        <ArrowForwardIosRoundedIcon className='fa-arrow-small' />
      </div>
    },
  ];


  const handleAddNewOrder = () => {
    navigate('/orders/new-order');
  };

  const handleViewClick = (params: any) => {
    const order: OrderSearchV3ItemResponseDto = params.row;
    if (!userLogged.location) return
    createBasketFromOrder({
      locationId: userLogged.location.id,
      memberId: order.memberId,
      orderId: order.orderId,
    }, {
      onSuccess: (basket) => {
        navigate(`/orders/basket/${basket.id}/view`)
      }
    })
  };

  const handelPaginationChange = (pagination: any) => {
    setPaginationModel((prev) => ({
      ...prev,
      page: pagination?.pageSize !== paginationModel.pageSize ? 0 : pagination?.page,
      pageSize: pagination?.pageSize,
    }));
  }

  const handleDateRangeChange = (startDate: Date, endDate: Date) => {
    setStoredFilterValue({
      fromDate: formatDateToLocalString(startDate),
      toDate: formatDateToLocalString(endDate)
    })

    setFilterParam((prev) => ({
      ...prev,
      fromDate: formatDateToLocalString(startDate),
      toDate: formatDateToLocalString(endDate)
    }));
  };

  return (
    <div className="mainpage-container-layout">
      <div className='col-md-10 col-11 justify-content-center'>
        <Box sx={{ my: 1 }}>
          <Tooltip title={i18n.t('ACTIVITY_LABEL_NEWORDER')}>
            <CustomButton onClick={handleAddNewOrder} color='secondary' sx={{ textTransform: 'none', marginBottom: 2 }}>
              <AddCircleRoundedIcon fontSize="medium" />
              <Box component={"span"} ml={1}><LR localResource="ACTIVITY_LABEL_NEWORDER" /></Box>
            </CustomButton>
          </Tooltip>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <DateNavigation
              onDateSelect={handleDateRangeChange} fromDate={new Date(filterParam?.fromDate)} toDate={new Date(filterParam?.toDate)}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <SearchInput onSearchChange={(query) => {
              setPaginationModel((prev) => ({
                ...prev,
                page: 0
              }));

              setFilterParam((prev) => ({
                ...prev,
                searchRequirement: query,
                page: 0
              }));
            }
            } placeholder={'SEARCH_BY_ORDER'} />
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            {
              !data?.records || data?.records?.length === 0 ? <MessageAlert message={i18n.t("COMMON_LABEL_NO_ORDER")} /> :
                <CustomDataGrid
                  columns={columns}
                  rows={data?.records}
                  initialState={{
                    pagination: {
                      paginationModel: defaultPagination,
                    },
                  }}
                  onRowClick={handleViewClick}
                  loading={isLoading}
                  getRowId={(row) => row.orderId}
                  rowCount={data?.totalRecords}
                  paginationModel={paginationModel}
                  onPaginationModelChange={handelPaginationChange}
                  sx={{
                    '& .MuiDataGrid-row': {
                      transition: 'background-color 0.3s ease', // Optional smooth transition effect
                    }
                  }}
                />
            }
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
