import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { Box, Tooltip } from "@mui/material";
import { useState } from 'react';
import { useNavigate } from "react-router";
import { useSearchParams } from 'react-router-dom';
import i18n from "../../i18n";
import { colors } from '../../main/Theme';
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { CustomerSearchItemResponseDto } from '../../types/Customer/CustomerSearchItemResponseDto';
import { LR } from "../Common/Help/LR";
import { CustomButton } from '../Common/UI/CustomButton';
import { CustomTab, CustomTabs } from '../Common/UI/CustomTabs';
import CustomerFeed from './CustomerFeed';

type Args = {
  userLogged: ApplicationUser;
  handleArrowClick?: (customer: CustomerSearchItemResponseDto) => void;
  compact?: boolean;
};

type memberType = "member" | "company";

const Customers = ({ userLogged, handleArrowClick, compact }: Args) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams()
  const [type, setType] = useState<memberType | undefined>(searchParams?.get('type') as memberType || 'individual');

  const [value, setValue] = useState(type === 'company' ? 1 : 0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setType(newValue === 0 ? 'member' : 'company');
    setSearchParams({ type: newValue === 0 ? 'member' : 'company' });
  };

  return (
    <div className='mainpage-container-layout'>
      <div className='col-md-10 col-11 justify-content-center'>
        <Box sx={{
          mb: 2,
          pb: 1,
          borderBottom: `1px solid ${colors.grey[600]}`
        }}>
          <CustomTabs value={value} onChange={handleChange} aria-label="basic tabs example" scrollButtons>
            <CustomTab label="Members" />
            <CustomTab label="Companies" />
          </CustomTabs>
        </Box>
        <Box sx={{ mb: 2 }} hidden={value !== 0}>
          <Box sx={{ mb: 2 }}>
            <Tooltip title={i18n.t('ACTIVITY_LABEL_NEWMEMEBER')}>
              <CustomButton onClick={() => navigate('/customers/add-member')} color='secondary' sx={{ textTransform: 'none' }}>
                <AddCircleRoundedIcon />
                <Box sx={{ ml: 1 }}>
                  <LR localResource="ACTIVITY_LABEL_NEWMEMEBER" />
                </Box>
              </CustomButton>
            </Tooltip>
          </Box>
          <CustomerFeed
            userLogged={userLogged}
            headers={[
              'Account Reference',
              'First Name',
              'Last Name',
              'Company',
            ]}
            handleArrowClick={handleArrowClick}
            compact={compact}
            searchPlaceHolder='SEARCH_BY_NAME_OR_REFERENCE'
            excludeMember={['Company']}
            usePointSystem
            showIcons
          />
        </Box>
        <Box sx={{ mb: 2 }} hidden={value !== 1}>
          <Box sx={{ mb: 2 }}>
            <Tooltip title={i18n.t('ACTIVITY_LABEL_NEWCOMPANY')}>
              <CustomButton onClick={() => navigate('/customers/companies/add-new')} color='secondary' sx={{ textTransform: 'none' }}>
                <AddCircleRoundedIcon />
                <Box sx={{ ml: 1 }}>
                  <LR localResource="ACTIVITY_LABEL_NEWCOMPANY" />
                </Box>
              </CustomButton>
            </Tooltip>
          </Box>
          <CustomerFeed
            userLogged={userLogged}
            headers={[
              'Account Reference',
              'Company',
            ]}
            handleArrowClick={handleArrowClick}
            compact={compact}
            searchPlaceHolder='SEARCH_BY_NAME_OR_REFERENCE'
            excludeMember={['CompanyContact', "Individual"]}
            usePointSystem
          />
        </Box>
      </div>
    </div>
  );
};

export { Customers };

