import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { useFetchMemberById } from "../../hooks/Member/member";
import ApiStatus from "../Common/Errors/ApiStatus";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import i18n from "../../i18n";
import { LR } from "../Common/Help/LR";
import { Card, IconButton, Tooltip } from "@mui/material";
import { SectionHead } from "../Common/Help/SectionHead";
import { useFetchNotificationList } from "../../hooks/Notification/notification";
import { useFetchLocations } from "../../hooks/Location/location";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { GetRowClassName } from "../../functions/utils/helper";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { CircleFlag } from "react-circle-flags";
import { pontCountries } from "../../data/en/pontCountries";
import MessageAlert from "../Common/Errors/MessageAlert";
import { colors } from "../../main/Theme";

type Args =
    {
        userLogged: ApplicationUser
    }

const HostLocations = ({ userLogged }: Args) => {

    const nav = useNavigate();
    const queryStringParams = useParams();
    if (!queryStringParams.id) throw Error("Operator id needed");

    const linkCallBack = () => {
        nav(`/operators/${queryStringParams.id}/summary`);
    }

    const addNewItem = () => {
        nav(`/operators/${queryStringParams.id}/location/add`);
    };


    const { data: locations } = useFetchLocations(queryStringParams.id!)
    const { data: operator, status: operatorStatus, isSuccess: operatorIsSuccess } = useFetchMemberById(queryStringParams.id!)
    const { data, status, isSuccess } = useFetchNotificationList(queryStringParams.id!);

    if ((!isSuccess || !operatorIsSuccess) && (status !== 'success') && (operatorStatus !== 'success')) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <ApiStatus status={status} />
                </div>
            </div>
        </>
    )

    if (!locations) return <MessageAlert message="MEMBER_LABEL_MEMBERNOTFOUND" params={{ param0: '' }}></MessageAlert>;


    if (!data || !operator) return <ApiStatus status={status} />;

    return (
        <>

            <SectionHead linkCallBack={linkCallBack} ctaText={i18n.t('MEMBER_SUMMARY_OPERATORMAINPAGE')!}
                name={`${operator.fullName} (${operator.externalReference})`}
                title={i18n.t('COMMON_SUMMARY_LOCATIONS')}
                description={i18n.t('COMMON_SUMMARY_OPERATORMANAGEMENT')} />

            <div className="mt-1 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">

                    <Tooltip title={i18n.t('MEMBER_TITLE_NEWLOCATION')}>
                        <IconButton onClick={addNewItem} className="btn plusButton">
                            <AddCircleRoundedIcon fontSize="medium" sx={{ color: colors.primary }} />
                        </IconButton>
                    </Tooltip>
                    <span className="lead align-self-center">
                        <LR localResource="MEMBER_TITLE_NEWLOCATION" />
                    </span>
                </div>
            </div>
            <div className="row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <Card variant="outlined">
                        <DataGrid
                            rows={locations}
                            columns={[
                                {
                                    field: 'countryIso3Code',
                                    headerName: i18n.t('COMMON_LABEL_COUNTRYSHORT')!,
                                    flex: 0.2,
                                    align: "center",
                                    renderCell: (params) => {
                                        const iso2Code = pontCountries.find((i) => i.Iso3Code === params.value)?.Iso2Code.toLowerCase();
                                        return <CircleFlag countryCode={iso2Code!} height="20" />
                                    },
                                },
                                { field: 'externalReference', headerName: i18n.t('COMMON_LABEL_EXTERNALREFERENCE')!, flex: 0.5 },
                                { field: 'name', headerName: i18n.t('LOCATION_LABEL_NAMESHORT')!, flex: 1 },
                                {
                                    field: 'id', headerName: '', flex: 0.1, align: "center",
                                    renderCell: (params) => {

                                        return <Tooltip title={i18n.t('COMMON_TOOLTIP_DETAILS')}>
                                            <IconButton className="fa-info" onClick={() => nav(`${params.value}/summary`)}>
                                                <EditRoundedIcon sx={{ color: colors.primary }} />
                                            </IconButton>
                                        </Tooltip>
                                    },
                                }
                            ]}

                            slots={{
                                toolbar: GridToolbar
                            }}
                            classes={{
                                toolbarContainer: 'toolbarContainer'
                            }}

                            getRowClassName={GetRowClassName}

                            checkboxSelection={false}
                            disableColumnSelector={true}
                            disableDensitySelector={true}
                        />
                    </Card>
                </div>
            </div>
        </>
    );
};


export { HostLocations }