import { darken, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from "@mui/material"
import { formatPrice } from "../../../../functions/utils/fiscal"
import { formaOrderLineAvailability } from "../../../../functions/utils/helper"
import i18n from "../../../../i18n"
import { colors } from "../../../../main/Theme"
import { Input } from "../../../Common/formComponents/Input"
import { useOrderContext } from "../OrderProvider"
import { useOrderLineContext } from "./OrderLineProvider"
import { useOrderLinePreview } from "./useOrderLinePreview"

export const OrderLinePreview = () => {
  const { isOrderLineCustom, currentOrderLine, productConfig } = useOrderLineContext()
  const { handleRowPriceChange, subTotal, handleRowOccupantChange } = useOrderLinePreview()
  const { readOnlyMode, getActionFlags, basketId, orderLineEditMode } = useOrderContext()

  const { quantityLabelOverride, allowOccupancyAdjustment } = productConfig || {}
  const titleForQuantity = i18n.t(quantityLabelOverride!).toString()

  if (!currentOrderLine) {
    return <>No current order line</>
  }

  if (!isOrderLineCustom) {

    return (
      <TableContainer component={Paper} sx={{ marginBottom: 2, position: 'relative', zIndex: 0 }}>
        <Table sx={{ minWidth: 650 }} stickyHeader>
          <TableHead>
            <TableRow sx={{ backgroundColor: colors.primary }}>
              <TableCell>{i18n.t("ORDERLINE_LABEL_PERIOD")}</TableCell>
              <TableCell align="right">{i18n.t("COMMON_LABEL_UNIT_PRICE")}</TableCell>
              <TableCell align="right">{i18n.t("ORDERLINE_LABEL_UNIT")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                {formaOrderLineAvailability(
                  currentOrderLine.fromDate,
                  currentOrderLine.toDate
                )}
              </TableCell>
              <TableCell align="right">
                {formatPrice(
                  currentOrderLine.currencyCode,
                  currentOrderLine.unitPriceExTax
                )}
              </TableCell>
              <TableCell align="right">
                {currentOrderLine.unitName}
              </TableCell>
            </TableRow>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row" align="right">
                {i18n.t("ORDER_LABEL_SUBTOTAL")}
              </TableCell>
              <TableCell align="right">
                {formatPrice(
                  currentOrderLine.currencyCode,
                  currentOrderLine.priceExTax
                )}
              </TableCell>
              <TableCell align="right">
                {i18n.t("ORDER_LABEL_EXVAT")}
              </TableCell>
            </TableRow>
            {
              currentOrderLine.priceIncTax &&
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row" align="right">
                  {i18n.t("ORDER_LABEL_TOTAL")}
                </TableCell>
                <TableCell align="right">
                  {formatPrice(
                    currentOrderLine.currencyCode,
                    currentOrderLine.priceIncTax
                  )}
                </TableCell>
                <TableCell align="right">
                  {i18n.t("ORDER_LABEL_INCVAT")}
                </TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
  // const isEditableOrderStatusName = readOnlyMode ? true : basketId ? !permission.OrderStatusName?.includes("ACTIONFLAG_EDIT") : orderEditMode
  console.log(orderLineEditMode, "row");

  return (
    <TableContainer component={Paper} sx={{ marginBottom: 2, maxHeight: 500, position: 'relative', zIndex: 0 }}>
      <Table sx={{ minWidth: 650 }} stickyHeader>
        <TableHead>
          <TableRow sx={{ backgroundColor: colors.primary }}>
            <TableCell>{i18n.t("ORDERLINE_LABEL_PERIOD")}</TableCell>
            {allowOccupancyAdjustment ? <TableCell align="right">{titleForQuantity}</TableCell> : null}
            <TableCell align="right">{i18n.t("COMMON_LABEL_UNIT_PRICE")}</TableCell>
            <TableCell align="right">{i18n.t("ORDERLINE_LABEL_UNIT")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

          {currentOrderLine?.priceAdjustments?.map((row, index) => {
            const occupancyAdjustmentsPermission = getActionFlags(currentOrderLine?.occupancyAdjustments?.[index]?.actionFlags)
            const pricePermission = getActionFlags(row?.actionFlags)
            const isDisableOccupancyAdjustment = readOnlyMode ? true : basketId ? orderLineEditMode ? !occupancyAdjustmentsPermission?.includes("ACTIONFLAG_EDIT") : false : !isOrderLineCustom
            const isDisableUnitePrice = readOnlyMode ? true : basketId ? orderLineEditMode ? !pricePermission?.includes("ACTIONFLAG_EDIT") : false : !isOrderLineCustom
            return (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>
                  {formaOrderLineAvailability(row.fromDate, row.toDate)}
                </TableCell>
                {allowOccupancyAdjustment ? (
                  <TableCell align="right">
                    <Input
                      type="number"
                      inputProps={{
                        min: 0,
                        sx: { textAlign: 'right' }
                      }}
                      onChange={(e) => handleRowOccupantChange(index, e)}
                      value={currentOrderLine?.occupancyAdjustments?.[index]?.quantity ?? 0}
                      sx={{ maxWidth: '70px' }}
                      disabled={isDisableOccupancyAdjustment}
                    ></Input>
                  </TableCell>
                ) : null}
                <TableCell align="right">
                  <Input
                    type="number"
                    inputProps={{
                      min: 0,
                      sx: { textAlign: 'right' }
                    }}
                    onChange={(e) => handleRowPriceChange(index, e)}
                    value={row.unitPriceExTax}
                    sx={{ maxWidth: '130px' }}
                    disabled={isDisableUnitePrice}
                  ></Input>
                </TableCell>
                <TableCell align="right">
                  /{currentOrderLine.unitName}
                </TableCell>
              </TableRow>
            )
          }


          )}
        </TableBody>
        <TableFooter style={{ position: 'sticky', bottom: '-1px', backgroundColor: colors.grey[300] }}>
          <TableRow sx={{ '&:last-child td, &:last-child th': { borderBottom: 0, borderTop: `1px solid ${darken(colors.white, 0.6)}` } }}>
            <TableCell component="th" scope="row" colSpan={allowOccupancyAdjustment ? 2 : 1} align="right">
              {i18n.t("ORDER_LABEL_SUBTOTAL")}
            </TableCell>
            <TableCell align="right">
              {formatPrice(
                currentOrderLine.currencyCode,
                subTotal || 0
              )}
            </TableCell>
            <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
              {i18n.t("ORDER_LABEL_EXVAT")}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}