import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { Collapse, IconButton, List, Paper, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import ApiStatus from '../Common/Errors/ApiStatus';
import MessageAlert from '../Common/Errors/MessageAlert';
import "./cards.css";

import HelpIcon from '@mui/icons-material/Help';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import SettingsIcon from '@mui/icons-material/Settings';
import i18n from '../../i18n';
import { ApplicationUser } from '../../types/Authentication/ApplicationUser';
//import { GlobalStateContext } from '../Common/GlobalStateProvider';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { useFetchMembers } from '../../hooks/Member/member';
import { LR } from '../Common/Help/LR';
import { colors } from '../../main/Theme';

type Args = {
  userLogged: ApplicationUser
  setHelp: (value: string) => void
}

export const LandlordsCard = ({ userLogged, setHelp }: Args) => {
  const { data: operators, status, isSuccess } = useFetchMembers(16384, "", 2);

  const nav = useNavigate();

  const [isListVisible, setIsListVisible] = useState(false);

  const handleToggleList = () => {
    setIsListVisible(!isListVisible);
  };

  if (!isSuccess) return (
    <>
      <div className="mt-5 row d-flex justify-content-center">
        <div className="col-md-7 col-11 justify-content-center">
          <ApiStatus status={status} />
        </div>
      </div>
    </>
  )

  if (!operators) return <MessageAlert message="MEMBER_LABEL_LANDLORDSNOTFOUND"></MessageAlert>;

  return (
    <>
      <Box sx={{ minWidth: 300, maxWidth: 300, minHeight: 400, maxHeight: 400 }}>
        <Card>
          <React.Fragment>
            {/* Header with Title and Arrow */}
            <CardContent className='DashBoardCard' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography className='cardTitle' gutterBottom>
                <LR localResource='COMMON_LABEL_LANDLORDS'></LR>
              </Typography>
              <IconButton className="fa-arrow" onClick={handleToggleList}>
                {!isListVisible && <ArrowForwardIosRoundedIcon />}
                {isListVisible && <ArrowForwardIosRoundedIcon className='fa-arrow-down' />}
              </IconButton>
            </CardContent>

            <div className="list-container" >
              {/* Initial Display */}
              {!isListVisible && (
                <div className='cardCounter locationCardCounter'>
                  <Typography variant="h1">{operators.length}</Typography>
                </div>
              )}

              {/* List Container */}
              <Collapse in={isListVisible}>
                <List component="nav" aria-label="main mailbox folders">
                  {operators.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Paper key={index} elevation={3} className="fa-paper-card">
                          <div className="d-flex align-items-center">
                            <div className="fa-name flex-grow-1 fa-name-card">
                              {item.fullName}
                            </div>
                            <div className="fa-icons">
                              <IconButton onClick={() => nav(`/landlords/${item.id}/summary`)}>
                                <ArrowForwardIosRoundedIcon className='fa-arrow-small' />
                              </IconButton>
                            </div>
                          </div>
                        </Paper>
                      </React.Fragment>
                    );
                  })}
                </List>
              </Collapse>
            </div>

            {/* Card Actions */}
            <CardActions className='DashBoardCard'
              disableSpacing
              sx={{
                alignSelf: "stretch",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-start",
                p: 0,
              }}>
              <IconButton aria-label="share" className='btn plusButton mr-2' onClick={() => nav("/landlords/add")}>
                <Tooltip title={i18n.t('LANDLORD_ADD_TITLE')}>
                  <AddCircleRoundedIcon sx={{ color: colors.primary }} />
                </Tooltip>
              </IconButton>
              <IconButton aria-label="share" onClick={() => nav("/landlords")}>
                <Tooltip title={i18n.t('LANDLORD_SEARCH_TOOLTIP')}>
                  <SearchRoundedIcon />
                </Tooltip>
              </IconButton>
              <IconButton aria-label="share" onClick={() => nav("/landlords/configuration/memberupload")}>
                <Tooltip title={i18n.t('LOCATION_LABEL_MEMBERUPLOAD')}>
                  <SettingsIcon />
                </Tooltip>
              </IconButton>
              <IconButton aria-label="share" onClick={(e) => setHelp('LOCATION_MESSAGE_NEWLOCATION')}>
                <Tooltip title={i18n.t('COMMON_LABEL_HELP')}>
                  <HelpIcon />
                </Tooltip>
              </IconButton>
            </CardActions>
          </React.Fragment>
        </Card>
      </Box>
    </>
  );
}