import { Box, DialogContent, DialogTitle } from "@mui/material";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { CustomerSearchItemResponseDto } from "../../types/Customer/CustomerSearchItemResponseDto";
import { LR } from "../Common/Help/LR";
import { CustomDialog } from "../Common/UI/CustomDialog";
import CustomerFeed from "../Customer/CustomerFeed";
import { min } from "lodash";

type Args = {
  userLogged: ApplicationUser;
  open: boolean;
  handleConfirm: (selectedCustomer: CustomerSearchItemResponseDto) => void,
  handleCancel: () => void
  headers?: string[]
}

export const DialogCompanySelector = ({ userLogged, handleCancel, handleConfirm, open, headers }: Args) => {
  return (
    <CustomDialog open={open} onClose={handleCancel} maxWidth="md" fullWidth scroll="body">
      <DialogTitle>
        <LR localResource="COMMON_LABEL_SELECTCOMPANY"></LR>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ minHeight: 400 }}>
          <CustomerFeed
            userLogged={userLogged}
            compact={true}
            handleArrowClick={handleConfirm}
            headers={[
              'Account Reference',
              'Company',
            ]}
            excludeMember={["CompanyContact", "Individual"]}
            usePointSystem
          />
        </Box>
      </DialogContent>
    </CustomDialog>
  );
};
