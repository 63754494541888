import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { Box, Grid, IconButton, Tooltip } from "@mui/material";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ApplicationConfig } from "../../config/ApplicationConfig";
import { GenerateExternalReference } from "../../functions/utils/helper";
import { useFetchLocations } from "../../hooks/Location/location";
import { useFetchPersonas, useFetchPersonasForLinkedMember, useUpdateLinkedMemberPersonas } from "../../hooks/Personas/persona";
import i18n from "../../i18n";
import { LocationResponseDto } from "../../types/Location/LocationResponseDto";
import { MemberRequestDto } from "../../types/Member/MemberRequestDto";
import { LinkedMemberLocationPersonaDto, LinkedMemberPersonasDto } from "../../types/Persona/LinkedMemberPersonasDto";
import { PersonaResponseDto } from "../../types/Persona/PersonaResponseDto";
import CountryDropDown from "../Common/CountryDropDown";
import { CustomSelect } from '../Common/formComponents/CustomSelect';
import { Input } from '../Common/formComponents/Input';
import { ValidationError } from '../Common/formComponents/ValidationError';
import { LR } from "../Common/Help/LR";
import { CustomButton } from '../Common/UI/CustomButton';

type Args = {
    isEdit: boolean,
    member: MemberRequestDto,
    submitting: boolean
    type?: string
    submitted: (member: MemberRequestDto) => void;
    handleDirty: () => void,
};

export const MemberForm = ({ member, isEdit, type, submitting, submitted, handleDirty }: Args) => {

    const { parentId, id } = useParams();

    const [memberState, setMemberState] = useState<MemberRequestDto>({ ...member });
    const [emailError, setEmailError] = useState('')
    const [phoneError, setPhoneError] = useState('')
    const [locationsState, setLocationsState] = useState<LocationResponseDto[]>([]);
    const [locationPersonasState, setLocationPersonasState] = useState<LinkedMemberLocationPersonaDto[]>([]);
    const [personasState, setPersonasState] = useState<PersonaResponseDto[]>([]);

    const { data: locations } = useFetchLocations(parentId ?? '', isEdit);
    const { data: memberPersonas } = useFetchPersonasForLinkedMember(parentId ?? '', id ?? '', isEdit);
    const { data: personas } = useFetchPersonas();
    const update = useUpdateLinkedMemberPersonas(parentId ?? '', id ?? '');

    useEffect(() => {
        if (locations) {
            setLocationsState(locations);
        }
    }, [locations]);

    useEffect(() => {
        if (personas) {
            setPersonasState(personas);
        }
    }, [personas]);

    useEffect(() => {
        if (memberPersonas) {
            setLocationPersonasState(memberPersonas);
        }
    }, [memberPersonas]);

    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            memberState.externalReference = (!memberState.externalReference) ? GenerateExternalReference(memberState.fullName) : memberState.externalReference;
            e.preventDefault();
            if (isEdit && id && parentId) {
                let personaUpdateRequest: LinkedMemberPersonasDto = {
                    parentMemberId: parentId!,
                    memberId: id!,
                    personas: locationPersonasState
                }
                update.mutate(personaUpdateRequest);
            }

            submitted(memberState);
        } catch (err: any) {
            console.error(err);
        }
    }

    const setEmail = (value: string) => {
        const emailRegex = ApplicationConfig.emailRegex;
        if (!emailRegex.test(value.trim())) {
            setEmailError('COMMON_ERROR_EMAILFORMATTING')
        } else {
            setEmailError('');
        }
        setMemberState({ ...memberState, person: { ...memberState.person, email: value } });
    }

    const setPhone = (value: string) => {
        if (isValidPhoneNumber(value)) {
            const parsedPhoneNumber = parsePhoneNumber(value);
            value = parsedPhoneNumber.formatInternational()
            setPhoneError('');
        } else {
            if (value.trim() === '') {
                setPhoneError('');
            } else {
                setPhoneError('COMMON_ERROR_PHONEFORMATTING')
            }
        }
        setMemberState({ ...memberState, person: { ...memberState.person, telephone: value } });
    }

    const ChangeCountry = (value: string) => {
        setMemberState({ ...memberState, countryIso3Code: value })
    }

    const addLocationPersona = () => {
        setLocationPersonasState((prev) => [
            ...prev,
            { locationId: '', personaId: '' }, // Add new empty object
        ]);
    };

    // Handle change for location or role in a specific locationRole object
    const handleLocationRoleChange = (index: number, field: "locationId" | "personaId", value: string) => {
        setLocationPersonasState((prev) =>
            prev.map((lr, i) => (i === index ? { ...lr, [field]: value } : lr))
        );
    };


    const handleDeleteLocationRole = (index: number) => {
        setLocationPersonasState((prevState) =>
            prevState.filter((_, i) => i !== index)
        );
    };

    const isButtonDisabled = () => {
        if (submitting) return true;

        if (['Operator', 'Landlord', 'LegalEntity'].includes(type as string)) {
            return memberState.fullName.length < 4;
        }

        return (emailError !== '' ||
            phoneError !== '' ||
            memberState.person!.firstName!.length < 2 ||
            memberState.person!.lastName!.length < 2 ||
            memberState.person!.email!.length < 2);
    }

    return (
        <form onSubmit={onFormSubmit}>
            <Grid container spacing={2}>
                {['OperatorContact', 'LandlordContact'].includes(type as string) &&
                    <>
                        {isEdit &&
                            <Grid item xs={12}>
                                <Input
                                    type="text"
                                    placeholder={i18n.t("COMMON_LABEL_EXTERNALREFERENCE").toString()}
                                    value={memberState.externalReference}
                                    labelProps={{
                                        message: 'COMMON_LABEL_EXTERNALREFERENCE',
                                        mandatory: false
                                    }}
                                    readOnly
                                    disabled
                                />

                            </Grid>
                        }

                        <Grid item xs={12} md={6}>
                            <Input
                                type="text"
                                placeholder={i18n.t("COMMON_LABEL_FIRSTNAME").toString()}
                                value={memberState.person?.firstName}
                                required
                                onChange={e => {
                                    setMemberState({
                                        ...memberState,
                                        person: {
                                            ...memberState.person,
                                            firstName: e.target.value
                                        }
                                    });
                                    handleDirty()
                                }}
                                labelProps={{
                                    message: 'COMMON_LABEL_FIRSTNAME',
                                    mandatory: true
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Input
                                type="text"
                                placeholder={i18n.t("COMMON_LABEL_LASTNAME").toString()}
                                value={memberState.person?.lastName}
                                required
                                onChange={e => {
                                    setMemberState({
                                        ...memberState,
                                        person: {
                                            ...memberState.person,
                                            lastName: e.target.value
                                        }
                                    });
                                    handleDirty()
                                }}
                                labelProps={{
                                    message: 'COMMON_LABEL_LASTNAME',
                                    mandatory: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                type="email"
                                placeholder={i18n.t("COMMON_PLACEHOLDER_EMAIL").toString()}
                                value={memberState.person?.email}
                                title={`${isEdit ? i18n.t(`COMMON_LABEL_EMAILNOTEDITABLE`) : ``}`}
                                required
                                readOnly={isEdit}
                                onChange={e => { setEmail(e.target.value); handleDirty() }}
                                labelProps={{
                                    message: 'COMMON_LABEL_EMAIL',
                                    mandatory: true,
                                    helpMessage: isEdit ? 'COMMON_LABEL_EMAILNOTEDITABLE' : undefined
                                }}
                                disabled={isEdit}
                            />
                            {emailError !== "" && <ValidationError message={i18n.t(emailError).toString()}></ValidationError>}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                type="text"
                                placeholder={i18n.t("COMMON_PLACEHOLDER_PHONE").toString()}
                                value={memberState.person?.telephone}
                                onChange={e => { setPhone(e.target.value); handleDirty() }}
                                labelProps={{
                                    message: 'COMMON_LABEL_PHONE',
                                    mandatory: false
                                }}
                            ></Input>
                            {phoneError !== "" && <ValidationError message={i18n.t(phoneError).toString()}></ValidationError>}
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                type="text"
                                placeholder={i18n.t("COMMON_LABEL_JOBTITLE").toString()}
                                value={memberState.person?.jobTitle}
                                onChange={e => {
                                    setMemberState({
                                        ...memberState,
                                        person: {
                                            ...memberState.person,
                                            jobTitle: e.target.value
                                        }
                                    });
                                    handleDirty()
                                }}
                                labelProps={{
                                    message: 'COMMON_LABEL_JOBTITLE',
                                    mandatory: false
                                }}
                            />
                        </Grid>


                        {isEdit &&
                            <Grid item container justifyContent={'center'} xs={12} spacing={2}>
                                <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Tooltip title={i18n.t('PERSONA_LABEL_ASSIGNNEW')}>
                                        <CustomButton
                                            onClick={() => addLocationPersona()}
                                            color='secondary'
                                            sx={{ textTransform: 'none' }}
                                        >
                                            <AddCircleRoundedIcon />
                                            <Box sx={{ ml: 1 }}>
                                                <LR localResource="PERSONA_LABEL_ASSIGNNEW" />
                                            </Box>
                                        </CustomButton>
                                    </Tooltip>
                                </Grid>

                                {locationPersonasState?.map((locationPersona, index) => {
                                    // Get the IDs of currently selected locations, excluding the current index
                                    const selectedLocationIds = locationPersonasState
                                        .filter((_, i) => i !== index)
                                        .map((lp) => lp.locationId);

                                    // Filter options to exclude already selected locations
                                    const filteredLocations = locationsState.filter(
                                        (location) => !selectedLocationIds.includes(location.id)
                                    );

                                    return (
                                        <Grid container item spacing={2} key={index} alignItems="center">
                                            <Grid item xs={6}>
                                                <CustomSelect
                                                    value={
                                                        locationsState.find(
                                                            (location) => location.id === locationPersona.locationId
                                                        ) || null
                                                    }
                                                    onChange={(selectedOption) =>
                                                        handleLocationRoleChange(
                                                            index,
                                                            "locationId",
                                                            selectedOption?.id || ""
                                                        )
                                                    }
                                                    options={filteredLocations}
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.id}
                                                    placeholder={i18n.t("COMMON_LABEL_SELECTLOCATION")}
                                                    isClearable
                                                />
                                            </Grid>
                                            <Grid item xs={5}>
                                                <CustomSelect
                                                    value={
                                                        personasState.find(
                                                            (persona) => persona.id === locationPersona.personaId
                                                        ) || null
                                                    }
                                                    onChange={(selectedOption) =>
                                                        handleLocationRoleChange(
                                                            index,
                                                            "personaId",
                                                            selectedOption?.id || ""
                                                        )
                                                    }
                                                    options={personas}
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.id!}
                                                    placeholder={i18n.t("COMMON_LABEL_SELECTPERSONA")}
                                                    isClearable
                                                />
                                            </Grid>
                                            <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <Tooltip title={i18n.t("COMMON_TOOLTIP_DELETE", { param0: i18n.t("COMMON_LABEL_ROLE"), })} >
                                                    <IconButton
                                                        className="mt-2"
                                                        onClick={(e) => handleDeleteLocationRole(index)}
                                                    >
                                                        <DeleteRoundedIcon color={"error"} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        }
                    </>
                }
                {['Operator', 'Landlord', 'LegalEntity'].includes(type as string) &&
                    <Grid item xs={12}>
                        <Input
                            type="text"
                            placeholder={i18n.t("COMMON_LABEL_FULLNAME").toString()}
                            value={memberState.fullName}
                            onChange={e => {
                                setMemberState({
                                    ...memberState,
                                    fullName: e.target.value
                                });
                                handleDirty()
                            }}
                            labelProps={{
                                message: 'COMMON_LABEL_FULLNAME',
                                mandatory: true,
                            }}
                            required
                        />
                    </Grid>
                }
                <Grid item xs={12}>
                    <CountryDropDown
                        valueChanged={ChangeCountry}
                        inputCountry={isEdit ? memberState.countryIso3Code : ''}
                        labelProps={{
                            message: 'COMMON_LABEL_COUNTRY',
                            mandatory: true
                        }}
                    />
                </Grid>
                <Grid item container justifyContent={'center'} xs={12}>
                    <Grid item xs={12} md={4}>
                        <CustomButton
                            disabled={isButtonDisabled()}
                            type="submit"
                            color='secondary'
                            variant="contained"
                            loading={submitting}
                            fullWidth
                        >
                            <LR localResource="COMMON_LABEL_SAVE"></LR>
                        </CustomButton>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
}