import { Box, Grid, Switch } from "@mui/material"
import { useCreateCopyOfProduct, useFetchProductList, useFetchProductPortfolios } from "../../hooks/Product/product"
import i18n from "../../i18n"
import { ApplicationUser } from "../../types/Authentication/ApplicationUser"
import { CustomSelect } from "../Common/formComponents/CustomSelect"
import { Input } from "../Common/formComponents/Input"
import { Label } from "../Common/formComponents/Label"
import { SectionHead } from "../Common/Help/SectionHead"
import { CustomButton } from "../Common/UI/CustomButton"
import yup from "../../functions/utils/yup"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { colors } from "../../main/Theme"
import { useNavigate } from "react-router-dom"

type Args = {
  userLogged: ApplicationUser,
}

export const CopyProduct = ({ userLogged }: Args) => {
  const operatorId = userLogged.operator?.id
  const nav = useNavigate();

  if (!operatorId) {
    alert("Operator is required")
    throw new Error("Operator is required")
  }

  const { data: products, isLoading: isLoadingProducts } = useFetchProductList({
    iscopyable: true,
    operatormemberid: operatorId
  }, {
    enabled: !!operatorId
  });

  const { data: portfolios, isLoading: isLoadingPortfolios } = useFetchProductPortfolios({
    operatormemberid: operatorId
  }, {
    enabled: !!operatorId
  })

  const { mutate: createCopyOfProduct, isLoading: isLoadingCopy } = useCreateCopyOfProduct();

  const schema = yup.object({
    productId: yup.string().required(i18n.t('VALIDATION_PRODUCTID_REQUIRED').toString()),
    operatorMemberId: yup.string().required(i18n.t('VALIDATION_OPERATORMEMBERID_REQUIRED').toString()),
    portfolioId: yup.string().required(i18n.t('VALIDATION_PORTFOLIOID_REQUIRED').toString()),
    productName: yup.string().required(i18n.t('VALIDATION_PRODUCTNAME_REQUIRED').toString()),
    copyBusinessRules: yup.boolean(),
  })

  const { control, handleSubmit, watch } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      operatorMemberId: operatorId,
    }
  })

  const portfolioId = watch('portfolioId')
  const portfolioDescription = portfolios?.find(x => x.id === portfolioId)?.description

  const onSubmit = (data: any) => {
    createCopyOfProduct(data, {
      onSuccess: (response) => {
        nav(`/products/${response.id}`)
      },
    })
  }

  console.log(products,"products");


  return (
    <>
      <SectionHead linkCallBack={() => { nav('/products/add-selector') }}
        ctaText={i18n.t('PRODUCTS_SUMMARY_PAGE')!}
        name=""
        title={i18n.t('Copy an existing product')}
        description={i18n.t('COMMON_SUMMARY_PRODUCTMANAGEMENT')} />

      <div className="row d-flex justify-content-center">
        <div className="col-md-7 col-11 justify-content-center">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="productId"
                  control={control}
                  render={({ field, fieldState }) => (
                    <CustomSelect
                      labelProps={{
                        message: "PRODUCT_TO_COPY",
                        mandatory: true,
                      }}
                      options={products}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      value={products?.find(x => x.id === field.value)}
                      onChange={(e) => {
                        field.onChange(e?.id);
                      }}
                      validationError={fieldState.error}
                      isLoading={isLoadingProducts}
                    ></CustomSelect>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="productName"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Input
                      labelProps={{
                        message: "NEW_PRODUCT_NAME",
                        mandatory: true,
                      }}
                      field={field}
                      validationError={fieldState.error}
                    ></Input>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="portfolioId"
                  control={control}
                  render={({ field, fieldState }) => (
                    <CustomSelect
                      labelProps={{
                        message: "TARGET_PORTFOLIO",
                        mandatory: true,
                      }}
                      options={portfolios}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      value={portfolios?.find(x => x.id === field.value)}
                      onChange={(e) => {
                        field.onChange(e?.id);
                      }}
                      validationError={fieldState.error}
                      isLoading={isLoadingPortfolios}
                    ></CustomSelect>
                  )}
                />
                {portfolioDescription &&
                  <Box
                    sx={{
                      color: colors.grey[200],
                      fontSize: '0.8rem',
                      marginTop: '10px'
                    }}>
                    {i18n.t('COMMON_SUMMARY_DESCRIPTIONS')}: {portfolioDescription}
                  </Box>
                }
              </Grid>
              <Grid item xs={12}>
                <Label
                  message="INCLUDE_BUSINESS_RULES"
                  mandatory={true}
                /> <br />
                <Controller
                  name="copyBusinessRules"
                  control={control}
                  render={({ field }) => (
                    <Switch
                      checked={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                      }}
                      color="secondary"
                    ></Switch>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4} mx={'auto'}>
                <CustomButton
                  variant="contained"
                  fullWidth
                  type="submit"
                  disabled={isLoadingCopy}
                  loading={isLoadingCopy}
                >
                  {i18n.t('COMMON_BUTTON_NEXT')}
                </CustomButton>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </>
  )
}