import axios, { AxiosError, AxiosResponse } from "axios";
import { HookConfig } from "../../config/HookConfig";
import { operatorDefaultHeaders } from "../../functions/api/api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { PersonaResponseDto } from "../../types/Persona/PersonaResponseDto";
import { RoleResponseDto } from "../../types/Persona/RoleResponseDto";
import { ScopeResponseDto } from "../../types/Persona/ScopeResponseDto";
import { ScopeUpdateRequestDto } from "../../types/Persona/ScopeUpdateRequestDto";
import { LinkedMemberLocationPersonaDto, LinkedMemberPersonasDto } from "../../types/Persona/LinkedMemberPersonasDto";

const useFetchPersonas = () => {
  return useQuery<PersonaResponseDto[], AxiosError>(["personas"], () => {
    let url = `${HookConfig.memberMgmtUrl}/personas`;

    return axios.get(url, {
      withCredentials: true,
      headers: operatorDefaultHeaders()
    })
      .then((resp) => resp.data);
  });

}

const useFetchPersonasForLinkedMember = (parentMemberId: string, memberId: string, doFetch: boolean = true) => {
  return useQuery<LinkedMemberLocationPersonaDto[], AxiosError>(["persona", memberId], () =>
    axios.get(`${HookConfig.memberMgmtUrl}/linked-member/${parentMemberId}/${memberId}/personas`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      })
      .then(
        (response) => {
          var roles: LinkedMemberLocationPersonaDto[] = response.data;
          return roles;
        }
      ), { enabled: doFetch });
}

const useFetchPersonaById = (id: string, doFetch: boolean = true) => {
  return useQuery<PersonaResponseDto, AxiosError>(["persona", id], () =>
    axios.get(`${HookConfig.memberMgmtUrl}/personas/${id}`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      })
      .then(
        (response) => {
          var persona: PersonaResponseDto = response.data;
          return persona;
        }
      ), { enabled: doFetch });
};

const useCreatePersona = () => {
  return useMutation<{ id: string }, AxiosError, PersonaResponseDto>(
    (personaRequest) => axios.post(`${HookConfig.memberMgmtUrl}/personas`, personaRequest, {
      withCredentials: true,
      headers: operatorDefaultHeaders()
    }).then(res => res.data),
  );
};

const useFetchScopes = (personaId: string) => {
  return useQuery<ScopeResponseDto[], AxiosError>(["scopes", personaId], () => {
      let url = `${HookConfig.memberMgmtUrl}/personas/${personaId}/scopes`;

    return axios.get(url, {
      withCredentials: true,
      headers: operatorDefaultHeaders()
    })
      .then((resp) => resp.data);
  });
}

const useUpdateScopes = (callback: () => void) => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError, ScopeUpdateRequestDto>(
      (scopeUpdateRequest) => axios.put(`${HookConfig.memberMgmtUrl}/personas/${scopeUpdateRequest.personaId}/scopes`,
      scopeUpdateRequest,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ),
    {
      onSuccess: (_, request) => {
        queryClient.invalidateQueries(["scopes", request.personaId]);
        callback();
      },
      onError: (_, error) => {
        console.log(error);
      }
    }
  );
};

const useUpdateLinkedMemberPersonas = (parentMemberId: string, memberId: string) => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError, LinkedMemberPersonasDto>(
    (scopeUpdateRequest) => axios.put(`${HookConfig.memberMgmtUrl}/linked-member/${parentMemberId}/${memberId}/personas`,
      scopeUpdateRequest,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ),
    {
      onSuccess: (_, request) => {
        queryClient.invalidateQueries(["linked-member-personas", memberId]);
      },
      onError: (_, error) => {
        console.log(error);
      }
    }
  );
};


const useFetchRoles = () => {
  return useQuery<RoleResponseDto[], AxiosError>(["roles"], () => {
    let url = `${HookConfig.memberMgmtUrl}/roles`;

    return axios.get(url, {
      withCredentials: true,
      headers: operatorDefaultHeaders()
    })
      .then((resp) => resp.data);
  });

}

export {
  useFetchPersonas,
  useCreatePersona,
  useFetchPersonaById,
  useFetchScopes,
  useUpdateScopes,
  useFetchRoles,
  useFetchPersonasForLinkedMember,
  useUpdateLinkedMemberPersonas
}