import { Accordion, AccordionDetails, AccordionSummary, Paper, Typography, } from "@mui/material";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { LR } from "../Common/Help/LR";
import { NotificationDto } from "../../types/Notification/NotificationDto";
import MarkunreadRoundedIcon from '@mui/icons-material/MarkunreadRounded';
import { useState } from "react";
import { Link } from "react-router-dom";
import MessageAlert from "../Common/Errors/MessageAlert";
import DraftsRoundedIcon from '@mui/icons-material/DraftsRounded';
import { useDeleteNotification, useMarkAllAsRead, useUpdateNotification } from "../../hooks/Notification/notification";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { NotificationGetByListOfEntitiesResponse } from "../../types/Notification/NotificationGetByListOfEntitiesResponse";
import { colors } from "../../main/Theme";

type Args = {
  items: NotificationGetByListOfEntitiesResponse[]
};

const NotificationItemCollection = ({ items }: Args) => {

  const [itemsState, setItemsState] = useState<NotificationGetByListOfEntitiesResponse[]>(items);
  const deleteNotification = useDeleteNotification();
  const updateNotification = useUpdateNotification();
  const markAllAsRead = useMarkAllAsRead();

  const refreshGroupStateAfterItemChange = (group: NotificationGetByListOfEntitiesResponse, item: NotificationDto) => {
    if (group.groupName === item.groupName)
      group.groupItems.map((i) => (i.id === item.id ? item : i));
    return group;
  }

  const refreshGroupStateAfterItemRemoval = (group: NotificationGetByListOfEntitiesResponse, item: NotificationDto) => {
    if (group.groupName === item.groupName) {
      const index = group.groupItems.findIndex(x => x.id === item.id);
      if (index > -1) {
        group.groupItems.splice(index, 1);
      }
    }

    if (!group.groupItems || !group.groupItems.length)
      return null;

    return group;
  }

  const updateItem = async (item: NotificationDto, status: boolean) => {
    item.isRead = status;
    const updateResult = await updateNotification(item) as { success: boolean; error?: any };;
    if (updateResult.success) {
      const updatedItemsState = itemsState.map((group) => refreshGroupStateAfterItemChange(group, item));
      setItemsState(updatedItemsState);
      console.log('Update successful');

    } else {
      console.log('Error updating item:', updateResult.error!);
    }
  };

  const deleteItem = async (item: NotificationDto) => {
    const deletionResult = await deleteNotification(item) as { success: boolean; error?: any };;
    if (deletionResult.success) {
      console.log('Deletion successful');
      const updatedGroupsState = itemsState.flatMap((group) => refreshGroupStateAfterItemRemoval(group, item) ?? []);
      setItemsState(updatedGroupsState);
    } else {
      // Deletion encountered an error, handle accordingly
      console.log('Error deleting item:', deletionResult.error!);
    }
  };

  const markGroupAsRead = async (group: NotificationGetByListOfEntitiesResponse) => {
    const updatedGroup = group.groupItems.map(async (item) => {
      if (item.isRead === false) {
        const updatedItem = { ...item, isRead: true };
        return updatedItem;
      }
      else {
        return item;
      }
    });

    group.groupItems = await Promise.all(updatedGroup); // Wait for all updateItem promises to resolve
    return group;
  }

  const markAllItemsAsRead = async () => {
    markAllAsRead(items);

    const updatedGroupsPromise = itemsState.map(group => {
      return markGroupAsRead(group);
    });

    await Promise.all(updatedGroupsPromise);

    const updatedGroups = itemsState.map((item) => ({
      ...item,
    }));

    setItemsState(updatedGroups);
  }

  return (
    <>
      <div className="d-flex justify-content-end">
        <div>
          {itemsState.length > 0 &&
            <Typography variant="caption">
              <Link to="#" onClick={markAllItemsAsRead}>
                <LR localResource='COMMON_LABEL_MARKALLASREAD'></LR>
              </Link>
            </Typography>
          }

        </div>
      </div>

      {(itemsState.length === 0) &&
        <div className="mt-5 row d-flex justify-content-center">
          <div className="col-md-7 col-11 justify-content-center">
            <MessageAlert message="MEMBER_LABEL_NOTIFCATIONSNOTFOUND" variant="success"></MessageAlert>
          </div>
        </div>
      }



      {itemsState.map((item) => (
        <Accordion key={item.groupName}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            key={item.groupName}
          >
            <Typography>{item.groupName}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {item.groupItems.map(((subItem: NotificationDto) => (
              <Paper key={subItem.id} elevation={3} className="fa-paper form-control">
                <div className="row d-flex my-auto justify-content-center">
                  <div className="col-3 col-md-1 my-auto">
                    <button className="btn btn-link fa-info" onClick={() => updateItem(subItem, !subItem.isRead)}>
                      {subItem.isRead && <DraftsRoundedIcon />}
                      {!subItem.isRead && <MarkunreadRoundedIcon />}
                    </button>
                  </div>
                  <div className="col-6 col-md-10 my-auto fa-name">
                    <Typography variant="overline" style={!subItem.isRead ? { fontWeight: 'bold', color: colors.primary } : { fontWeight: 'light' }}>
                      {subItem.description}
                    </Typography>
                  </div>

                  <div className="col-3 col-md-1 my-auto fa-icons d-flex justify-content-end" >
                    <button
                      className="btn btn-link fa-info"
                      onClick={() => deleteItem(subItem)}>
                      <DeleteRoundedIcon color="error" />
                    </button>
                  </div>
                </div>
              </Paper>
            )))}
          </AccordionDetails>
        </Accordion>
      ))}

    </>
  );
}

export {
  NotificationItemCollection
}