import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { pontBenefitTypeConstants, pontBenefitTypes } from '../../data/en/pontBenefitTypes';
import { pontCreditPeriodUnits } from '../../data/en/pontCreditPeriodUnits';
import { pontCreditUnits } from '../../data/en/pontCreditUnits';
import { pontDiscountTypes } from '../../data/en/pontDiscountTypes';
import { useDirtyStateV2 } from '../../hooks/Common/dirtyV2';
import { useFetchBenefitById, useManageProductBenefit } from '../../hooks/Product/benefits';
import { useFetchProductTypes } from '../../hooks/Product/product';
import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { ProductBenefitDto } from '../../types/Product/ProductBenefitDto';
import { ProductTypeResponseDto } from '../../types/Product/ProductTypeResponseDto';
import { ConfirmationChangesDialog } from '../Common/ConfirmationChangesDialog';
import ApiStatus from '../Common/Errors/ApiStatus';
import MessageAlert from '../Common/Errors/MessageAlert';
import { CustomSelect } from '../Common/formComponents/CustomSelect';
import { Input } from '../Common/formComponents/Input';
import { LR } from "../Common/Help/LR";
import { SectionHead } from '../Common/Help/SectionHead';
import "../Common/react-draft-wysiwyg.css";
import { CustomButton } from '../Common/UI/CustomButton';
import { showSnackbar } from '../Common/UI/CustomSnackbar';
import { ProductType } from './Configuration/ProductType';

type Args = {
    userLogged: ApplicationUser,
    isEdit: boolean
}

export const BenefitDetails = ({ userLogged, isEdit }: Args) => {
    const nav = useNavigate();
    const params = useParams();

    if (!params.productId) throw Error("Product id needed");
    const productId = params.productId;
    const benefitId = params.id;

    const emptyProductBenefitDto: ProductBenefitDto = {
        id: '',
        name: '',
        benefitTypeName: 'Note',
        displayOrder: 0,
        description: '',
        creditUnitName: '',
        creditValue: 0,
        discountTypeName: '',
        discountValue: 0,
        productTypeFlags: 0,
        productId: productId,
        creditPeriodUnitName: ''
    };

    const { data: benefit, status, isSuccess } = useFetchBenefitById(productId, benefitId!, isEdit);
    const { setDirty, showConfirmation, cancelDirtyAction, confirmDirtyAction, handleDirtyAction, unsetDirty } = useDirtyStateV2();
    const [useBenefit, setUseBenefit] = useState(emptyProductBenefitDto);

    const [selectedBenefitType, setSelectedBenefitType] = useState(pontBenefitTypes[0]);
    const creditUnitOptions = pontCreditUnits.map(x => ({ label: x.label, value: x.id.toString() }));
    const creditPeriodUnitOptions = pontCreditPeriodUnits.map(x => ({ label: x.label, value: x.id.toString() }));
    const [selectedCreditOption, setSelectedCreditOption] = useState(creditUnitOptions[0]);
    const [selectedCreditPeriodOption, setSelectedCreditPeriodOption] = useState(creditPeriodUnitOptions[0]);
    const [selectedDiscountOption, setSelectedDiscountOption] = useState(pontDiscountTypes[0]);
    const { data: productTypes } = useFetchProductTypes();
    const [useProductTypes, setUseProductTypes] = useState<ProductTypeResponseDto[]>([]);
    const [initialToggles, setInitialProductToggles] = useState<boolean[]>();

    const linkCallBack = () => {
        let url = `/products/${productId}`
        handleDirtyAction(() => nav(url))
    }

    const {mutate:update, isLoading:isUpdateLoading} = useManageProductBenefit(productId, linkCallBack);

    useEffect(() => {
        if (productTypes) {
            setUseProductTypes(productTypes.slice(0, 6));
        }
    }, [productTypes]);

    useEffect(() => {
        if (isSuccess && benefit) {
            benefit.productId = productId;
            setUseBenefit(benefit);
            let loadedBenefitType = pontBenefitTypes.find(x => x.value === benefit.benefitTypeName);
            if (loadedBenefitType) {
                setSelectedBenefitType(loadedBenefitType);
            }

            let creditUnitLookup = pontCreditUnits.find(x => x.unitName === benefit.creditUnitName);
            let loadedCreditOption = creditUnitOptions.find(x => x.value === creditUnitLookup?.id.toString());
            if (loadedCreditOption) {
                setSelectedCreditOption(loadedCreditOption);
            }

            let loadedDiscountOption = pontDiscountTypes.find(x => x.value === benefit.discountTypeName);
            if (loadedDiscountOption) {
                setSelectedDiscountOption(loadedDiscountOption);
            }

            let creditPeriodLookUp = pontCreditPeriodUnits.find(x => x.unitName === benefit.creditPeriodUnitName);
            let loadedCreditPeriodOption = creditPeriodUnitOptions.find(x => x.value === creditPeriodLookUp?.id.toString());
            if (loadedCreditPeriodOption) {
                setSelectedCreditPeriodOption(loadedCreditPeriodOption);
            }

            const flags = [];
            for (let i = 0; i < 6; i++) {
                flags.push((benefit.productTypeFlags & (1 << i)) !== 0);
            }
            setInitialProductToggles(flags);

        }
    }, [isSuccess, benefit]);

    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            update(useBenefit, {
                onSuccess: () => {
                    unsetDirty()
                    showSnackbar(i18n.t('COMMON_LABEL_SAVE_SUCCESS'), {
                        variant: 'success'
                    });
                    nav(`/products/${productId}`);
                }
            });
            e.preventDefault();
        } catch (err: any) {
            console.error(err);
        }
    }

    const handleTypeChange = (selectedOption: any) => {
        setSelectedBenefitType(selectedOption);
        let creditUnit = '';
        let discountUnit = '';
        if (selectedOption.value === pontBenefitTypeConstants.credit) {
            creditUnit = pontCreditUnits[0].unitName;
        }
        if (selectedOption.value === pontBenefitTypeConstants.discount) {
            discountUnit = pontDiscountTypes[0].value;
        }
        if (selectedOption.value === pontBenefitTypeConstants.discountAndCredit) {
            creditUnit = pontCreditUnits[0].unitName;
            discountUnit = pontDiscountTypes[0].value;
        }
        setUseBenefit({ ...useBenefit!, benefitTypeName: selectedOption.value, creditUnitName: creditUnit, discountTypeName: discountUnit });
        setDirty();
    }

    const handleCreditUnitChange = (selectedOption: any) => {
        setSelectedCreditOption(selectedOption);
        setUseBenefit({ ...useBenefit, creditUnitName: pontCreditUnits[selectedOption.value].unitName });
        setDirty();
    }

    const handleCreditPeriodUnitChange = (selectedOption: any) => {
        setSelectedCreditPeriodOption(selectedOption);
        setUseBenefit({ ...useBenefit, creditPeriodUnitName: pontCreditPeriodUnits[selectedOption.value].unitName });
        setDirty();
    }

    const handleDiscountUnitChange = (selectedOption: any) => {
        setSelectedDiscountOption(selectedOption);
        setUseBenefit({ ...useBenefit, discountTypeName: selectedOption.value });
        setDirty();
    }

    const rulesProductsTypesCallBack = (flags: boolean[]) => {
        let flagValue: number = 0;
        flags.forEach((flag, index) => {
            if (flag) {
                flagValue |= (1 << index);
            }
        });
        setUseBenefit({ ...useBenefit, productTypeFlags: flagValue });
    }

    if (isEdit && !isSuccess) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <ApiStatus status={status} />
                </div>
            </div>
        </>
    )

    if (!benefit && isEdit) return <MessageAlert message="BENEFIT_LABEL_BENEFITNOTFOUND" params={{ param0: params.id }}></MessageAlert>;

    return (
        <form onSubmit={onFormSubmit}>
            <ConfirmationChangesDialog
                handleConfirm={confirmDirtyAction}
                handleCancel={cancelDirtyAction}
                showConfirmation={showConfirmation}
            />

            <SectionHead linkCallBack={linkCallBack} ctaText={i18n.t("COMMON_SUMMARY_PRODUCTMANAGEMENT")!}
                name={`${useBenefit.name}`}
                title={i18n.t('BENEFIT_TITLE_EDIT')}
                description={i18n.t('COMMON_SUMMARY_PRODUCTMANAGEMENT')} />

            <div className="row d-flex justify-content-center mb-3">
                <div className="col-md-7 col-11 justify-content-center">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Input type="text"
                                value={useBenefit!.name! || ''}
                                onChange={(e) => {
                                    setUseBenefit({ ...useBenefit!, name: e.target.value, description: e.target.value });
                                    setDirty();
                                }}
                                placeholder={i18n.t("BENEFIT_LABEL_NAME").toString()}
                                required
                                labelProps={{
                                    message: 'BENEFIT_LABEL_NAME',
                                    mandatory: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomSelect
                                value={selectedBenefitType}
                                getOptionLabel={(option) => { return i18n.t(option.label) }}
                                options={pontBenefitTypes}
                                onChange={handleTypeChange}
                                placeholder={i18n.t("BENEFIT_LABEL_TYPE").toString()}
                                labelProps={{
                                    message: 'BENEFIT_LABEL_TYPE',
                                    mandatory: true
                                }}
                            />
                        </Grid>
                        {(useBenefit.benefitTypeName === 'Credit' || useBenefit.benefitTypeName === 'Discount and Credit') &&
                            <>
                                <Grid item xs={12} md={4}>
                                    <Input
                                        type="number"
                                        id="credit"
                                        inputProps={{
                                            min: 0,
                                            max: 10000,
                                        }}
                                        value={useBenefit.creditValue}
                                        placeholder={i18n.t('BENEFIT_LABEL_CREDITVALUE')!}
                                        onChange={(e) => { setUseBenefit({ ...useBenefit, creditValue: Number(e.target.value) }); setDirty(); }}
                                        labelProps={{
                                            message: 'BENEFIT_LABEL_CREDITVALUE',
                                            mandatory: true
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <CustomSelect
                                        value={selectedCreditOption} getOptionLabel={(option) => { return i18n.t(option.label) }}
                                        options={creditUnitOptions}
                                        onChange={handleCreditUnitChange}
                                        placeholder={i18n.t("BENEFIT_LABEL_CREDITUNIT").toString()}
                                        labelProps={{
                                            message: 'BENEFIT_LABEL_CREDITUNIT',
                                            mandatory: true
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <CustomSelect
                                        value={selectedCreditPeriodOption} getOptionLabel={(option) => { return i18n.t(option.label) }}
                                        options={creditPeriodUnitOptions}
                                        onChange={handleCreditPeriodUnitChange}
                                        placeholder={i18n.t("BENEFIT_LABEL_CREDITPERIODUNIT").toString()}
                                        labelProps={{
                                            message: 'BENEFIT_LABEL_CREDITPERIODUNIT',
                                            mandatory: true
                                        }}
                                    />
                                </Grid>
                            </>
                        }
                        {(useBenefit.benefitTypeName === 'Discount' || useBenefit.benefitTypeName === 'Discount and Credit') &&
                            <>
                                <Grid item xs={12} md={6}>
                                    <Input
                                        type="number"
                                        id="discount"
                                        inputProps={{
                                            min: 0,
                                            max: 10000,
                                        }}
                                        value={useBenefit.discountValue}
                                        placeholder={i18n.t('BENEFIT_LABEL_DISCOUNTVALUE')!}
                                        onChange={(e) => { setUseBenefit({ ...useBenefit, discountValue: Number(e.target.value) }); setDirty(); }}
                                        labelProps={{
                                            message: 'BENEFIT_LABEL_DISCOUNTVALUE',
                                            mandatory: true
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <CustomSelect
                                        value={selectedDiscountOption} getOptionLabel={(option) => { return i18n.t(option.label) }}
                                        options={pontDiscountTypes}
                                        onChange={handleDiscountUnitChange}
                                        placeholder={i18n.t("BENEFIT_LABEL_DISCOUNTUNIT").toString()}
                                        labelProps={{
                                            message: 'BENEFIT_LABEL_DISCOUNTUNIT',
                                            mandatory: true
                                        }}
                                    />
                                </Grid>
                            </>
                        }


                        {useBenefit.benefitTypeName !== 'Note' && productTypes &&
                            <Grid item xs={12}>
                                <ProductType
                                    initialToggles={initialToggles!}
                                    mutationCallBack={rulesProductsTypesCallBack}
                                    items={useProductTypes!}
                                />
                            </Grid>
                        }

                        <Grid item xs={12}>
                            <Input
                                type="number"
                                id="displayorder"
                                inputProps={{
                                    min: 0,
                                    max: 100,
                                }}
                                value={useBenefit.displayOrder}
                                placeholder={i18n.t('BENEFIT_LABEL_DISPLAYORDER')!}
                                onChange={(e) => { setUseBenefit({ ...useBenefit, displayOrder: Number(e.target.value) }); setDirty(); }}
                                labelProps={{
                                    message: 'BENEFIT_LABEL_DISPLAYORDER',
                                    mandatory: true
                                }}
                            />
                        </Grid>
                        <Grid item container justifyContent={'center'} xs={12}>
                            <Grid item xs={3} mx={'auto'}>
                                <CustomButton
                                    type="submit"
                                    color='secondary'
                                    variant='contained'
                                    sx={{
                                        width: '100%'
                                    }}
                                    disabled={!useBenefit.name || isUpdateLoading}
                                    loading={isUpdateLoading}
                                >
                                    <LR localResource="COMMON_LABEL_SAVE"></LR>
                                </CustomButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>

        </form >
    );
};