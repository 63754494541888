import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { Collapse, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { FormatDate } from "../../functions/utils/helper";
import { useDeleteUnavailability } from "../../hooks/Inventory/inventory";
import i18n from "../../i18n";
import { InventoryUnavailabilityDto } from "../../types/Inventory/InventoryUnavailabilityDto";
import ".././Common/SummaryItems/SummaryItem.css";
import MessageAlert, { AlertStyle } from "../Common/Errors/MessageAlert";
import { LabelAndHelp } from "../Common/Help/LabelAndHelp";
import { LR } from "../Common/Help/LR";
import { CustomConfirmDialog } from "../Common/UI/CustomConfirmDialog";
import { colors } from '../../main/Theme';

type Args = {
  items: InventoryUnavailabilityDto[],
  locationId?: string,
  handleDirtyAction: (cb: () => void) => void
};

const InventoryUnavailabilities = ({ items, locationId, handleDirtyAction }: Args) => {
  const nav = useNavigate();
  const deleteUnavailabilities = useDeleteUnavailability();
  const [unavailabilities, setUnavailabilities] = useState<InventoryUnavailabilityDto[]>(items);
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
  const [deleteName, setDeleteName] = useState<string>('');
  const [deleteId, setDeleteId] = useState<string>('');
  const [deleteInventoryId, setDeleteInventoryId] = useState<string>('');
  const [error, setError] = useState<string>('');

  useEffect(() => {
    setUnavailabilities(items);
  }, [items]);

  const handleDelete = (name: string, id: string, inventoryId: string) => {
    setDeleteName(name);
    setDeleteId(id);
    setDeleteInventoryId(inventoryId);
    setDeleteConfirmation(true);
  }

  const confirmDeleteItem = async (id: string, inventoryId: string) => {
    const resultDelete = await deleteUnavailabilities(id, inventoryId);
    if (resultDelete.success) {
      setUnavailabilities(unavailabilities.filter(x => x.id !== id))
    } else {
      setError("INVENTORY_ERROR_ALLOCATIONDELETION");
    }
    setDeleteConfirmation(false);
  }

  const addItem = () => {
    if (handleDirtyAction) {
      handleDirtyAction(() => {
        !!locationId ? nav(`${locationId}/unavailability/add`) : nav(`unavailability/add`);
      })
    } else {
      !!locationId ? nav(`${locationId}/unavailability/add`) : nav(`unavailability/add`);
    }
  }

  return (
    <>
      <LabelAndHelp mandatory={false} fullscreen={true} message='INVENTORY_LABEL_UNAVAILABILITYTEXT' ></LabelAndHelp>
      <Paper elevation={3} className="fa-paper-light">
        <div className="d-flex align-items-center">
          <div className="fa-name flex-grow-1">
            <Tooltip title={i18n.t('INVENTORY_LABEL_NEWUNAVAILABILITY')}>
              <IconButton onClick={addItem} className="btn plusButton">
                <AddCircleRoundedIcon fontSize="medium" sx={{ color: colors.primary }} />
              </IconButton>
            </Tooltip>
            <Typography variant="h6" className="linkbutton">
              <LR localResource="INVENTORY_LABEL_UNAVAILABILITIES" /> ({unavailabilities.length})
            </Typography>
          </div>
          <div className="fa-icons">
            <IconButton className="fa-arrow" onClick={() => setIsCollapseOpen(!isCollapseOpen)}>
              {!isCollapseOpen && <ArrowForwardIosRoundedIcon />}
              {isCollapseOpen && <ArrowForwardIosRoundedIcon className='fa-arrow-down' />}
            </IconButton>
          </div>
        </div>
      </Paper>

      <Collapse in={isCollapseOpen}>
        <div >
          {unavailabilities.map((item, index) => (
            <Paper key={item.id} elevation={24} className="fa-paper-grid">
              <div className="d-flex align-items-center">
                <div className="fa-name flex-grow-1">
                  {item.inventoryUnavailabilityTypeName},
                  From {FormatDate(item.fromDate!)}
                  &nbsp;to {FormatDate(item.toDate!)}
                </div>
                {
                  item.inventoryUnavailabilityTypeName?.toLowerCase() !== "booking" &&
                  <Tooltip title={i18n.t('COMMON_TOOLTIP_DELETE', { param0: item.reason })}>
                    <IconButton onClick={(e) => handleDelete(item.inventoryUnavailabilityTypeName!, item.id!.toString(), item.inventoryId!.toString())}>
                      <DeleteRoundedIcon key={index} color={'error'} />
                    </IconButton>
                  </Tooltip>
                }
              </div>
            </Paper>
          ))}

          {
            error && <div className="row d-flex justify-content-center">
              <div className="justify-content-center">
                <MessageAlert message={error} variant="danger" style={AlertStyle.Tiny} additionalClassName="fa-alert-tiny"></MessageAlert>
              </div>
            </div>
          }

        </div>
      </Collapse>
      <CustomConfirmDialog
        isOpen={deleteConfirmation}
        onClose={() => setDeleteConfirmation(false)}
        title={i18n.t(`COMMON_CONTACT_DELETECONFIRMATIONTITLE`)}
        content={i18n.t(`COMMON_BUTTON_DELETETEXT`, { name: deleteName })}
        confirmButtonColor='error'
        confirmButtonTitle={i18n.t(`COMMON_BUTTON_DELETE`)}
        handleConfirm={() => { confirmDeleteItem(deleteId, deleteInventoryId); }}
      ></CustomConfirmDialog>
    </>
  );
}

export {
  InventoryUnavailabilities
};
