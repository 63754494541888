import { Box } from "@mui/material";
import { colors } from "../../../main/Theme";

export const ValidationError = ({ message, disabled }: { message?: string, disabled?: boolean }) => {
  if (!message) return null;
  return (
    <Box sx={{ color: colors.red[100], fontSize: '0.8rem', marginTop: '0.5rem', opacity: disabled ? 0.5 : 1 }}>
      <span>{message}</span>
    </Box>
  );
};