import { useLocalStorage } from "usehooks-ts";
import { useEffect } from "react";
import dayjs from "dayjs";
import { ApplicationConfig } from "../../config/ApplicationConfig";

type filterValue = {
  fromDate: Date
  toDate: Date
};


export const useSaveOrderFilterLocalStorage = () => {
  const [storedValue, setStoredValue, removeStoredValue] = useLocalStorage<{
    data: filterValue | null;
    timestamp: string;
  } | null>(ApplicationConfig.orderFilterStorageKey, null);

  const isExpired = (timestamp: string) => {
    const expirationTime = dayjs(timestamp).add(ApplicationConfig.expirationHours, 'hour');
    return dayjs().isAfter(expirationTime);
  };

  const setStoredFilterValue = (data: filterValue | null) => {
    const timestamp = dayjs().toISOString();
    setStoredValue({ data, timestamp });
  };

  const removeStoredFilterData = () => {
    removeStoredValue();
  };

  useEffect(() => {
    if (storedValue && isExpired(storedValue.timestamp)) {
      removeStoredValue();
    }
  }, [storedValue, removeStoredValue]);

  const storedFilterValue = storedValue && !isExpired(storedValue.timestamp)
    ? storedValue.data
    : null;

  return {
    storedFilterValue,
    setStoredFilterValue,
    removeStoredFilterData,
  };
};
