import { Box, DialogContent, DialogTitle } from "@mui/material";
import { ApplicationUser } from "../../../types/Authentication/ApplicationUser";
import { CustomerSearchItemResponseDto } from "../../../types/Customer/CustomerSearchItemResponseDto";
import { CustomerTypeName } from "../../../types/Customer/CustomerSearchResponseDto";
import { LR } from "../../Common/Help/LR";
import { CustomDialog } from "../../Common/UI/CustomDialog";
import CustomerFeed from "../../Customer/CustomerFeed";

type Args =
  {
    userLogged: ApplicationUser;
    open: boolean;
    memberTypeName: CustomerTypeName;
    handleConfirm: (selectedCustomer: CustomerSearchItemResponseDto) => void,
    handleCancel: () => void
    headers?: string[],
    companyMemberId?: string
  }

export const DialogMemberSelectorForOrder = ({ userLogged, memberTypeName, handleCancel, handleConfirm, open, headers, companyMemberId }: Args) => {

  const defaultHeaders = [
    'First Name',
    'Last Name',
    'Company',
    'Relationship',
    'Type',
  ];

  return (
    <CustomDialog open={open} onClose={handleCancel} maxWidth="md" fullWidth>
      <DialogTitle>
        <LR localResource="COMMON_LABEL_SELECTCLIENT"></LR>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ height: 700, width: '100%' }}>
          <CustomerFeed
            userLogged={userLogged}
            compact={true}
            handleArrowClick={handleConfirm}
            headers={headers ?? defaultHeaders}
            usePointSystem
            excludeMember={["Company"]}
            companyMemberId={companyMemberId}
          />
        </Box>
      </DialogContent>
    </CustomDialog>
  );
};
