import { alpha, darken, styled } from '@mui/material/styles';
import { DataGrid, DataGridProps, gridClasses, GridRowSpacingParams } from '@mui/x-data-grid';
import { colors } from '../../../main/Theme';
import { useCallback } from 'react';

interface CustomDataGridProps extends DataGridProps {
  // You can add more custom props here if needed
  rowSpace?: number;
}

// Creating a styled version of DataGrid
const CustomDataGridStyle = styled(DataGrid)<DataGridProps>(({ theme }) => ({
  // Customize DataGrid root
  [`&.${gridClasses.root}`]: {
    border: 'none',
    borderRadius: '0px',
    color: theme.palette.text.primary,
    backgroundColor: 'transparent',
    '--DataGrid-overlayHeight': "0"
  },
  // Customize header
  [`& .${gridClasses.columnHeaderTitle}`]: {
    fontWeight: 'normal !important',
    color: alpha(colors.white, 0.5),
    fontSize: '0.8rem'
  },
  [`& .${gridClasses.columnHeaders}`]: {
    border: 'none',
    borderRadius: '0px !important',
  },
  [`& .${gridClasses.columnHeader}`]: {
    backgroundColor: 'transparent',
    color: '#fff',
    fontWeight: 'normal',
    borderRadius: '0 !important',
    padding: '2px 16px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    [`& .${gridClasses.columnSeparator}`]: {
      display: 'none'
    }
  },

  // Customize rows
  [`& .${gridClasses.row}`]: {
    backgroundColor: colors.grey[100],
    borderRadius: 6,
    border: 'none',
    padding: '5px 0',
    transition: 'background-color 0.3s ease',
    cursor: 'pointer',
    '&:hover, &.Mui-hovered': {
      backgroundColor: darken(colors.grey[200], 0.2),
    },
  },
  [`& .${gridClasses.cell}`]: {
    border: 'none',
    outline: 'none !important',
    padding: '8px 16px',
  },
  [`& .${gridClasses.footerContainer}`]: {
    border: 'none !important',
    marginTop: '10px'
  },
  [`& .${gridClasses.row}.Mui-selected`]: {
    backgroundColor: theme.palette.secondary.light,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
}));



export const CustomDataGrid = (props: CustomDataGridProps) => {
  const {
    columnHeaderHeight,
    rowHeight,
    disableColumnFilter = true,
    disableColumnSelector = true,
    disableColumnMenu = true,
    disableRowSelectionOnClick = true,
    checkboxSelection = false,
    autoHeight,
    rowSpace = 5,
    ...rest
  } = props;

  const getRowSpacing = useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 0 : rowSpace,
      bottom: params.isLastVisible ? 0 : rowSpace,
    };
  }, []);
  return <CustomDataGridStyle
    columnHeaderHeight={columnHeaderHeight || 25}
    pageSizeOptions={[5, 10, 20]}
    getRowHeight={() => 'auto'}
    paginationMode = 'server'
    sortingMode = 'server'
    disableColumnFilter={disableColumnFilter}
    disableColumnSelector={disableColumnSelector}
    disableColumnMenu={disableColumnMenu}
    disableRowSelectionOnClick={disableRowSelectionOnClick}
    checkboxSelection={checkboxSelection}
    getRowSpacing={getRowSpacing}
    autoHeight
    {...rest}
  />
}