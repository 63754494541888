import React, { useState } from 'react'
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { LocationsCard } from '../Admin/LocationsCard';
import { LegalEntity } from '../Admin/LegalEntityCard';
import { ProductsCard } from '../Admin/ProductsCard';
import { OperatorsCard } from '../Admin/OperatorsCard';
import { HelpDialog } from '../Common/Help/HelpDialog';
import { PersonasCard } from './PersonasCard';
import { LandlordsCard } from './LandlordsCard';
import { Grid } from '@mui/material';

type Args =
  {
    userLogged: ApplicationUser
  }


const Admin = ({ userLogged }: Args) => {
  const [helpOpen, setHelpOpen] = useState(false);
  const [helpReference, setHelpReference] = useState('');

  const handleOpen = () => {
    setHelpOpen(true);
  };

  const handleClose = () => {
    setHelpOpen(false);
  };

  const setHelp = (value: string) => {
    setHelpReference(value);
    handleOpen();
  }

  return (
    <>
      <HelpDialog contentReference={helpReference} showHelp={helpOpen} handleClose={handleClose} handleConfirm={handleOpen} ></HelpDialog>
      <div className="row d-flex justify-content-center">
        <div className="col-md-10 col-11 justify-content-center">
          <Grid container spacing={4} sx={{ mt: 10 }} justifyContent={"center"}>
            {
              [LocationsCard, LegalEntity, ProductsCard, OperatorsCard, PersonasCard, LandlordsCard].map((Card, index) => {
                return (
                  <Grid item xs={6} sm={6} md={3} sx={{ display: 'flex', justifyContent: 'center' }} key={index}>
                    <Card userLogged={userLogged} setHelp={setHelp} />
                  </Grid>
                )
              })
            }
          </Grid>
        </div>
      </div>
    </>
  )
}

export { Admin };