// ConnectedAccountForm
// autogenerated
import { useEffect, useState } from "react";
import i18n from "../../i18n";
import { LR } from "../Common/Help/LR";
import { LabelAndHelp } from "../Common/Help/LabelAndHelp";
import { useDirtyState } from '../../hooks/Common/dirty';
import { ParentMemberSelector } from "../Member/ParentMemberSelector";
import { MemberResponseDto } from "../../types/Member/MemberResponseDto";
import { useFetchMerchantAccountLookUp } from "../../hooks/Order/merchantAccountLookUp";
import { LookUpDto } from "../../types/Common/LookUpDto";
import Select, { SingleValue } from "react-select";
import { ConnectedAccountDto } from "../../types/Order/ConnectedAccount";

type Args = {
    isEdit: boolean
    connectedAccount: ConnectedAccountDto,
    submitted: (member: ConnectedAccountDto) => void;
};

const ConnectedAccountForm = ({ connectedAccount, isEdit, submitted }: Args) => {

    const [connectedAccountState, setconnectedAccountState] = useState({ ...connectedAccount });
    const [submitting, setSubmitting] = useState(false);
    const [isDirty, setDirty, unsetDirty] = useDirtyState();
    const [legalEntityMemberId, setLegalEntityMemberId] = useState('');
    const { data: dataMerchantAccount, status, isSuccess } = useFetchMerchantAccountLookUp();
    const [merchantAccounts, setMerchantAccounts] = useState<LookUpDto[]>([]);
    const [selectedMerchantAccountId, setSelectedMerchantAccountId] = useState<{ value: string, label: string }>({ label: "", value: "" });

    const handleLegalEntityMemberIdChange = (operator: MemberResponseDto) => {
        setconnectedAccountState({ ...connectedAccountState, legalEntityMemberId: operator.id });
        setLegalEntityMemberId(operator.id);
        handleDirty();
    }
    const handleMerchantAccountIdChange = (selectedOption: any) => {
        setSelectedMerchantAccountId(selectedOption);
        setconnectedAccountState({ ...connectedAccountState, merchantAccountId: selectedOption.value });
        handleDirty();
        console.log(selectedOption.value);
        console.log(isDirty);
        console.log(submitting);

    }
    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            setSubmitting(true);
            submitted(connectedAccountState);
            setSubmitting(false);
            unsetDirty();

        } catch (err: any) {
            setSubmitting(false)
            console.error(err);
        }
    }
    const handleDirty = () => {
        setDirty();
    };

    useEffect(() => {
        if (dataMerchantAccount) {

            setMerchantAccounts(dataMerchantAccount);
            setSelectedMerchantAccountId({ label: connectedAccountState.merchantAccountName, value: connectedAccountState.merchantAccountId });
        }
    }, [dataMerchantAccount]);

    return (
        <>
            <form onSubmit={onFormSubmit}>
                <div className="row d-flex justify-content-center mb-2">
                    <div className="justify-content-center">
                        <ParentMemberSelector
                            key={'LegalEntityMemberIdState'}
                            legalEntityMemberId={legalEntityMemberId!}
                            placeholder="CONNECTEDACCOUNT_LABEL_LEGALENTITYMEMBERID"
                            flagType={512}
                            operatorId={legalEntityMemberId!}
                            handleConfirm={handleLegalEntityMemberIdChange}
                            labelProps={{
                                message: 'CONNECTEDACCOUNT_LABEL_LEGALENTITYMEMBERID',
                                mandatory: true,
                            }}
                        />
                    </div>
                </div>


                <LabelAndHelp mandatory={true} fullscreen={true} message='CONNECTEDACCOUNT_LABEL_MERCHANTACCOUNTID' ></LabelAndHelp>
                <div className="row d-flex justify-content-center mb-2">
                    <div className="justify-content-center">
                        <Select
                            value={selectedMerchantAccountId}
                            className="form-control fa-form fa-dropdown-container" classNamePrefix="fa-dropdown"
                            options={merchantAccounts}
                            onChange={handleMerchantAccountIdChange}
                            placeholder={i18n.t("CONNECTEDACCOUNT_LABEL_MERCHANTACCOUNTID").toString()}
                        />
                    </div>
                </div>


                <LabelAndHelp mandatory={true} fullscreen={true} message='CONNECTEDACCOUNT_LABEL_NAME' ></LabelAndHelp>
                <div className="row d-flex justify-content-center mb-2">
                    <div className="justify-content-center">
                        <input
                            type="text" maxLength={100}
                            className="form-control fa-form"
                            placeholder={i18n.t("CONNECTEDACCOUNT_LABEL_NAME").toString()}
                            value={connectedAccountState.name}
                            onChange={e => {
                                setconnectedAccountState({ ...connectedAccountState, name: e.target.value });
                                handleDirty();
                            }
                            } required></input>
                    </div>
                </div>

                <LabelAndHelp mandatory={true} fullscreen={true} message='CONNECTEDACCOUNT_LABEL_PSPACCOUNTIDENTIFIER' ></LabelAndHelp>
                <div className="row d-flex justify-content-center mb-2">
                    <div className="justify-content-center">
                        <input
                            type="text" maxLength={100}
                            className="form-control fa-form"
                            placeholder={i18n.t("CONNECTEDACCOUNT_LABEL_PSPACCOUNTIDENTIFIER").toString()}
                            value={connectedAccountState.pspAccountIdentifier}
                            onChange={e => {
                                setconnectedAccountState({ ...connectedAccountState, pspAccountIdentifier: e.target.value });
                                handleDirty();
                            }
                            } required></input>
                    </div>
                </div>
                <div className="row d-flex justify-content-center mb-2">
                    <div className="col-6 justify-content-center">

                        <button className="btn btn-green btn-primary btn-block" disabled={!isDirty || submitting} type="submit">
                            <LR localResource="COMMON_LABEL_SAVE"></LR>
                        </button>

                    </div>
                </div>

            </form>
        </>


    );
}
export { ConnectedAccountForm }
