import { Box, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useState } from "react";
import { GetRowClassName } from "../../functions/utils/helper";
import i18n from "../../i18n";
import { LocationResponseDto } from "../../types/Location/LocationResponseDto";
import { LR } from "../Common/Help/LR";
import { CustomButton } from "../Common/UI/CustomButton";
import { CustomDialog } from "../Common/UI/CustomDialog";

type Args =
  {
    open: boolean
    locations: LocationResponseDto[]
    handleConfirm: (location: LocationResponseDto) => void,
    handleCancel: () => void
  }

const DialogLocationSelector = ({ handleCancel, handleConfirm, open, locations: locations }: Args) => {

  const [selectedRow, setSelectedRow] = useState<LocationResponseDto | null>(null);

  const handleRowSelection = (params: any) => {
    const selectedLocation = locations.find(x => x.id === params[0])
    setSelectedRow(selectedLocation!);
  };

  const handleRowDoubleClick = (params: any) => {
    const selectedLocation = locations.find(x => x.id === params[0])
    setSelectedRow(selectedLocation!);
    handleValueSelection();
  };

  const handleValueSelection = () => {
    if (selectedRow) {
      handleConfirm(selectedRow);
      handleCancel();
    }
  };

  return (
      <CustomDialog open={open} onClose={handleCancel} maxWidth="md" fullWidth>
        <DialogTitle>
          <LR localResource="COMMON_LABEL_SELECTLOCATION"></LR>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ height: 400 }}>
            <DataGrid
              rows={locations}
              columns={[
                { field: 'name', headerName: i18n.t('LOCATION_LABEL_NAMESHORT')!, flex: 1 },
                { field: 'externalReference', headerName: i18n.t('COMMON_LABEL_EXTERNALREFERENCE')!, flex: 0.5 },
              ]}
              onRowSelectionModelChange={handleRowSelection}
              onRowDoubleClick={handleRowDoubleClick}
              slots={{
                toolbar: GridToolbar
              }}
              classes={{
                toolbarContainer: 'toolbarContainer'
              }}
              getRowClassName={GetRowClassName}
              checkboxSelection={false}
              disableColumnSelector={true}
              disableDensitySelector={true}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleCancel} variant='contained' color='info'>
            <LR localResource='COMMON_BUTTON_CANCEL' />
          </CustomButton>
          <CustomButton color={'secondary'} autoFocus onClick={handleValueSelection} variant='contained' disabled={!selectedRow}>
            <LR localResource={'COMMON_LABEL_SELECT'} />
          </CustomButton>
        </DialogActions>
      </CustomDialog>
  );
};


export { DialogLocationSelector };

