import { Box } from '@mui/material';
import i18n from '../../i18n';
import { IconDisplayer } from './Help/IconDisplayer';
import { Input } from './formComponents/Input';
import { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';

type SearchInputProps = {
  onSearchChange: (query: string) => void;
  searchQuery?: string;
  suggestions?: string[];
  placeholder?: string;
};

const SearchInput = ({ onSearchChange, suggestions = [], placeholder = '' }: SearchInputProps) => {
  const [isInitialized, setIsInitialized] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const handleDebouncedSearchChange = useCallback(
    debounce((term) => {
      onSearchChange(term);
    }, 500), // 1 second delay
    []
  );

  useEffect(() => {
    if (!isInitialized) {
      setIsInitialized(true);
      return;
    }
    handleDebouncedSearchChange(searchQuery);
  }, [searchQuery]);

  return (
    <div className="search-container">
      <Input
        id="outlined-basic"
        fullWidth
        inputProps={{
          placeholder: i18n.t(placeholder)
        }}
        value={searchQuery}
        rightIcon={<Box sx={{ pr: 2 }}><IconDisplayer type={'COMMON_LABEL_SEARCH'} /></Box>}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
    </div>
  );
};


export { SearchInput };

