import axios, { AxiosError } from "axios";
import { useQuery } from "react-query";
import { HookConfig } from "../../config/HookConfig";
import { operatorDefaultHeaders } from "../../functions/api/api";
import { SummaryItemDto } from "../../types/Common/SummaryItemDto";
import { MerchantAccountSearchResponseDto } from "../../types/Order/paymentMethodDto";

export const useFetchOrderSummaryConfig = () => {
  return useQuery<SummaryItemDto[], AxiosError>(["orderManagement-system-summary"], () => {
    return axios.get(`${HookConfig.orderUrl}/system/summary`, {
      withCredentials: true,
      headers: operatorDefaultHeaders()
    })
      .then((resp) => resp.data);
  });
}




export const useFetchPaymentMethods = (params: {
  pageSize: number,
  pageNumber: number
}) => {
  const { pageSize, pageNumber } = params;
  return useQuery<MerchantAccountSearchResponseDto, AxiosError>(["orderManagement-system-summary",pageSize, pageNumber], () => {
    return axios.get(`${HookConfig.orderUrl}/merchant-accounts`, {
      params: {
        pageSize: pageSize,
        pageNumber: pageNumber
      },
      withCredentials: true,
      headers: operatorDefaultHeaders()
    })
      .then((resp) => resp.data);
  });
}



