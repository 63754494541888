import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import {  useFetchMemberById, useFetchMembers } from "../../hooks/Member/member";
import ApiStatus from "../Common/Errors/ApiStatus";
import i18n from "../../i18n";
import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { LR } from "../Common/Help/LR";
import { useState } from "react";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { SectionHead } from "../Common/Help/SectionHead";
import { colors } from "../../main/Theme";

type Args =
    {
        userLogged: ApplicationUser
    }

const LegalEntities = ({ userLogged }: Args) => {
    const nav = useNavigate();
    const queryStringParams = useParams();
    if (!queryStringParams.id) throw Error("Operator id needed");

    const linkCallBack = () => {
        nav(`/legal-entities/${queryStringParams.id}/summary`);
    }



    const addNewItem = () => {
        nav(`/legal-entities/add`);
    };

    const { data: operator, status: operatorStatus, isSuccess: operatorIsSuccess } = useFetchMemberById(queryStringParams.id!)
    const { data, status, isSuccess } = useFetchMembers(512, userLogged.operator!.id, 3)
    const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
    const [deleteName, setDeleteName] = useState<string>('');
    const [deleteId, setDeleteId] = useState<string>('');


    const handleDelete = (name: string, id: string) => {
        setDeleteName(name);
        setDeleteId(id);
        setDeleteConfirmation(true);
    }

    const confirmDeleteContact = (id: string) => {
        setDeleteConfirmation(false);
    }

    if ((!isSuccess || !operatorIsSuccess) && (status !== 'success') && (operatorStatus !== 'success')) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <ApiStatus status={status} />
                </div>
            </div>
        </>
    )

    if (!data || !operator) return <ApiStatus status={status} />;

    return (
        <>

<SectionHead linkCallBack={linkCallBack} ctaText={i18n.t('MEMBER_SUMMARY_OPERATORMAINPAGE')!}
                name={`${operator.fullName} (${operator.externalReference})`}
                title={i18n.t('COMMON_SUMMARY_LEGALENTITIES'!)}
                description={i18n.t('COMMON_SUMMARY_OPERATORMANAGEMENT')} />




            <div className="mt-1 mb-5 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <Tooltip title={i18n.t('MEMBER_TITLE_NEWLEGALENTITY')}>
                        <IconButton onClick={addNewItem} className="btn plusButton">
                            <AddCircleRoundedIcon fontSize="medium" sx={{ color: colors.primary }} />
                        </IconButton>
                    </Tooltip>
                    <span className="lead align-self-center">
                        <LR localResource="MEMBER_TITLE_NEWLEGALENTITY" />
                    </span>
                </div>
            </div>

            <div className="mt-1 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <Grid container spacing={2} style={{ width: '100%', marginLeft: '0px ' }}>
                        <Grid item className="gridItem headerItem" style={{ width: '100%' }}>
                            <div style={{ display: 'flex', width: '100%' }}>
                                <div className="col-3">Ref</div>
                                <div className="col-4">Name</div>
                                <div className="col-1"></div>
                            </div>
                        </Grid>
                        {data.map((item, index) => (
                            <Grid item key={item.id} style={{ width: '100%' }} className={`gridItem ${index % 2 === 0 ? 'alternatingItem' : ''}`}>
                                <div style={{ display: 'flex', width: '100%' }} className="d-flex align-items-center">
                                    <div className="col-3">{item.externalReference}</div>
                                    <div className="col-4">{item.fullName}</div>

                                    <div className="col-1 d-flex justify-content-end" style={{ flex: 1 }}>

                                        <Tooltip title={i18n.t('COMMON_TOOLTIP_DETAILS')}>
                                            <IconButton className="fa-info" onClick={() => nav(`/legal-entities/${item.id}/summary`)}>
                                                <EditRoundedIcon />
                                            </IconButton>
                                        </Tooltip>
                                        {/*
                                        TODO ==> implement deleting a Legal entity. Today this would have cascading effects on the locations etc.
                                        <Tooltip title={i18n.t('COMMON_TOOLTIP_DELETE', { param0: item.fullName })}>
                                            <IconButton disabled={item.id === userLogged.memberId} onClick={(e) => handleDelete(item.fullName, item.id)}>
                                                <DeleteRoundedIcon color={(item.id === userLogged.memberId) ? 'disabled' : 'error'} />
                                            </IconButton>
                                        </Tooltip> */}
                                    </div>
                                </div>
                            </Grid>
                        ))}

                        <Dialog open={deleteConfirmation} onClose={() => setDeleteConfirmation(false)}>
                            <Card>
                                <CardContent>
                                    <DialogTitle><LR localResource='COMMON_CONTACT_DELETECONFIRMATIONTITLE' /></DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                            <Typography variant="body1" color="textSecondary">
                                                <LR localResource='COMMON_BUTTON_DELETETEXT' params={{ name: deleteName }} />
                                            </Typography>
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button className='btn-green' onClick={() => setDeleteConfirmation(false)}>
                                            <LR localResource='COMMON_BUTTON_CANCEL' />
                                        </Button>
                                        <Button className='btn-danger' onClick={() => { confirmDeleteContact(deleteId); }} color="error" autoFocus>
                                            <LR localResource='COMMON_BUTTON_DELETE' />
                                        </Button>
                                    </DialogActions>
                                </CardContent>
                            </Card>
                        </Dialog>
                    </Grid>
                </div>
            </div>
        </>
    );
};


export { LegalEntities }