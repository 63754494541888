import { useUpdateMember } from "../../hooks/Member/member";
import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { MemberRequestDto } from "../../types/Member/MemberRequestDto";
import MessageAlert, { AlertStyle } from "../Common/Errors/MessageAlert";
import { MemberForm } from "./MemberForm";
import { useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import { MemberResponseDto } from "../../types/Member/MemberResponseDto";
import { useDirtyState } from "../../hooks/Common/dirty";
import { ConfirmationChangesDialog } from "../Common/ConfirmationChangesDialog";
import { SectionHead } from "../Common/Help/SectionHead";
import { ApplicationConfig } from "../../config/ApplicationConfig";
import { cleanLocalStorage } from "../../hooks/Authentication/userinfoV2";

type Args =
    {
        userLogged: ApplicationUser
    }

const ChangePartner = ({ userLogged }: Args) => {
    const nav = useNavigate();
 

    useEffect(() => {
        console.log('ready to reset');
        cleanLocalStorage();
        console.log('reset completed');
        window.location.href = "/";
      }, [])



    return (
        <>  
        <h1>Changing partner...</h1>    
        </>
    );
};


export { ChangePartner }