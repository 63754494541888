import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { LR } from "../Common/Help/LR";
import i18n from "../../i18n";
import ApiStatus from '../Common/Errors/ApiStatus';
import MessageAlert from '../Common/Errors/MessageAlert';
import "../Common/react-draft-wysiwyg.css";
import { SectionHead } from '../Common/Help/SectionHead';
import { useFetchEventById, useUpdateEventDescription } from '../../hooks/Events/event';
import { EventResponseDto } from '../../types/Event/EventResponseDto';
import CustomQuillEditor from '../Common/Input/CustomQuillEditor';
import { SaveSnackBar } from '../Common/SaveSnackBar';
import { useDirtyState } from '../../hooks/Common/dirty';
import { ConfirmationChangesDialog } from "../Common/ConfirmationChangesDialog";
import 'react-quill/dist/quill.snow.css';
import './EventDescription.css';
import '../../main/App.css'
import { ApplicationConfig } from '../../config/ApplicationConfig';

type Args =
    {
        userLogged: ApplicationUser
    }

const EventCheckoutNote = ({ userLogged }: Args) => {
    const nav = useNavigate();
    const params = useParams();

    if (!params.id) throw Error("Event id needed");

    const emptyEventResponseDto: EventResponseDto = {
        id: params.id,
        name: '',
        isOnlineEvent: false,
        locationId: ApplicationConfig.emptyGuid,
        timeZoneName: '',
        ownerMemberId: ApplicationConfig.emptyGuid,
        legalEntityMemberId: ApplicationConfig.emptyGuid,
        quantity: 0,
        retainedQuantity: 0,
        quantityLimitPerOrder: 0,
        externalReference: '',
        isDisabled: false,
        internalIdentifier: '',
        fromDate: new Date(),
        toDate: new Date(),
        prices: [],
        ticketsSold: 0,
        saleCount: 0,
        ownerMemberFullName: '',
        legalEntityMemberFullName: '',
        locationName: '',
        shortDescription: '',
        description: '',
        checkOutNote: ''
    };

    const [checkoutNoteState, setCheckoutNoteState] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const { data: event, status, isSuccess } = useFetchEventById(params.id)
    const [useEvent, setUseEvent] = useState(emptyEventResponseDto);

    const [isDirty, setDirty, unsetDirty] = useDirtyState();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [targetUrl, setTargetUrl] = useState('');

    const dialogConfirm = () => {
        setShowConfirmation(false);
        unsetDirty();
        nav(targetUrl);
    };

    const dialogCancel = () => {
        setShowConfirmation(false);
    };

    useEffect(() => {
        if (isSuccess && event) {
            setUseEvent(event);
            setCheckoutNoteState(event.checkOutNote);
        }
    }, [isSuccess, event]);

    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            await updateMutation.mutateAsync(useEvent);
            setSnackbarOpen(true);
            unsetDirty();
        } catch (err: any) {
            console.error(err);
        }
    };

    const linkCallBack = () => {
        const url = `/events/${event!.id}/summary`;
        if (isDirty) {
            setTargetUrl(url);
            setShowConfirmation(true);
        } else {
            nav(url);
        }
    };


    const handleSnackClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const updateMutation = useUpdateEventDescription(linkCallBack, "CheckOutNote");


    if (!isSuccess) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <ApiStatus status={status} />
                </div>
            </div>
        </>
    )

    if (!event) return <MessageAlert message="MEMBER_LABEL_MEMBERNOTFOUND" params={{ param0: params.id }}></MessageAlert>;

    return (
        <form onSubmit={onFormSubmit}>
            <SectionHead linkCallBack={linkCallBack} ctaText={i18n.t("EVENT_SUMMARY_EVENTMAINPAGE")!}
                name={`${event.name} (${event.externalReference})`}
                title={i18n.t('COMMON_SUMMARY_CHECKOUTNOTE')}
                description={i18n.t('COMMON_SUMMARY_EVENTMANAGEMENT')} />

            <CustomQuillEditor
                value={checkoutNoteState}
                onChange={(value) => {
                    setCheckoutNoteState(value);
                    setUseEvent({ ...useEvent, checkOutNote: value });
                    setDirty();
                }}
                placeholder={i18n.t("COMMON_SUMMARY_CHECKOUTNOTE", { eventName: event.name })}
            />

            <div className="row d-flex justify-content-center mt-3">
                <div className="col-md-2 col-6 justify-content-center">
                    <button type="submit" className="btn btn-green btn-primary btn-block">
                        <LR localResource="COMMON_LABEL_SAVE"></LR>
                    </button>
                </div>
            </div>
            {isDirty && (
                <div className="alert alert-center" role="alert">
                    <p className="warning-label">
                        {i18n.t('WARNING_LABEL')}
                    </p>
                </div>
            )}

            <SaveSnackBar onClose={handleSnackClose} isOpen={snackbarOpen} />
            {isDirty && (
                <ConfirmationChangesDialog
                    handleConfirm={dialogConfirm}
                    handleCancel={dialogCancel}
                    showConfirmation={showConfirmation}
                />
            )}

        </form>
    );
};

export { EventCheckoutNote }