import { Grid, Paper, Radio } from "@mui/material";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { Label } from "../Common/formComponents/Label";
import { SectionHead } from "../Common/Help/SectionHead";
import { CustomButton } from "../Common/UI/CustomButton";



type Args = {
    userLogged: ApplicationUser
}

type memberDto = "individual" | "contact";

const MemberTypeSelection = ({ userLogged }: Args) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [type, setType] = useState<memberDto | undefined>(searchParams?.get('type') as memberDto || 'individual');
    const nav = useNavigate();


    const memberTypeList = [
        { value: 'individual', label: i18n.t('LABEL_ADDCUSTOMER_INDIVIDUAL'), description: i18n.t('LABEL_ADDCUSTOMER_INDIVIDUAL_DESCRIPTION') },
        { value: 'contact', label: i18n.t('LABEL_ADDCUSTOMER_CONTACT'), description: i18n.t('LABEL_ADDCUSTOMER_CONTACT_DESCRIPTION') },
        // { value: 'company', label: i18n.t('LABEL_ADDCUSTOMER_COMPANY'), description: i18n.t('LABEL_ADDCUSTOMER_COMPANY_DESCRIPTION') },
    ]

    const handleSelection = (value: memberDto) => {
        setType(value);
        setSearchParams({ type: value });
    }

    const handleNext = () => {
        const link: Record<memberDto, string> = {
            "individual": "/customers/individuals/add-new",
            "contact": "/customers/contacts/add-new",
            // "company": "/customers/companies/add-new",
        };

        if (type === undefined) return;

        nav(link[type as memberDto]);
    }

    const homeCallBack = () => {
        nav('/customers');
    }

    return (
        <>
            <SectionHead linkCallBack={homeCallBack}
                ctaText={i18n.t('COMMON_SUMMARY_MEMBERS')!}
                name=""
                title={i18n.t('LABEL_ADDMEMBER_FROMTITLE')}
                description={i18n.t('COMMON_SUMMARY_MEMBERMANAGEMENT')} />

            <div className="row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Label
                                message="LABEL_ADDCUSTOMER_MEMBERTYPEFEILD"
                                mandatory={true}
                            ></Label>
                        </Grid>
                        <Grid item xs={12}>
                            {memberTypeList.map((item, index) => (
                                <Paper
                                    component={"label"}
                                    key={index}
                                    className="p-0 w-100 row mx-0 align-align-items-stretch fa-paper-card rounded-0 shadow-none mb-1"
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <div className="col-md-3 p-3 d-flex align-items-center">
                                        <Radio
                                            key={index}
                                            checked={type === item.value}
                                            onChange={(e) => { handleSelection(e.target.value as memberDto) }}
                                            value={item.value}
                                            name="member-type"
                                            color="secondary"
                                        />
                                        <span>{item.label}</span>
                                    </div>
                                    <div className="col-md-9 p-3 rounded-0 shadow-none d-flex align-items-center border-start border-dark text-white-50">
                                        <span>{item.description}</span>
                                    </div>
                                </Paper>
                            ))}
                        </Grid>
                        <Grid item xs={12} md={4} mx={'auto'}>
                            <CustomButton
                                variant="contained"
                                fullWidth
                                type="button"
                                onClick={handleNext}
                                disabled={type === undefined}>
                                {i18n.t('COMMON_BUTTON_NEXT')}
                            </CustomButton>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
};


export { MemberTypeSelection };
