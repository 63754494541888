import { ApplicationUser } from "../../types/Authentication/ApplicationUser";

const canAccessScope = (userLogged: ApplicationUser, locationId: string, scopeName: string) => {
    if (userLogged.isFullAdmin) {
        return true;
    }

    if (!locationId || !scopeName) {
        return false;
    }

    const permission = userLogged.permissions?.find(x => x.locationId === locationId);

    return permission?.roles.find(x => x.scopeName === scopeName)?.roleName !== 'Access Denied';
}

const canViewScope = (userLogged: ApplicationUser, locationId: string, scopeName: string) => {
    if (userLogged.isFullAdmin) {
        return true;
    }

    if (!locationId || !scopeName) {
        return false;
    }

    const permission = userLogged.permissions?.find(x => x.locationId === locationId);

    return permission?.roles.find(x => x.scopeName === scopeName)?.roleName !== 'Access Denied';
}

const canEditscope = (userLogged: ApplicationUser, locationId: string, scopeName: string) => {
    if (userLogged.isFullAdmin) {
        return true;
    }

    if (!locationId || !scopeName) {
        return false;
    }

    const permission = userLogged.permissions?.find(x => x.locationId === locationId);

    var roleName = permission?.roles.find(x => x.scopeName === scopeName)?.roleName;

    return roleName === 'Editor' || roleName === 'Admin';
}

const isScopeAdmin = (userLogged: ApplicationUser, locationId: string, scopeName: string) => {
    if (userLogged.isFullAdmin) {
        return true;
    }

    if (!locationId || !scopeName) {
        return false;
    }

    const permission = userLogged.permissions?.find(x => x.locationId === locationId);

    var roleName = permission?.roles.find(x => x.scopeName === scopeName)?.roleName;

    return roleName === 'Admin';
}

export {
    canAccessScope,
    canViewScope,
    canEditscope,
    isScopeAdmin
}