export type CommonSummaryHeaderProps = {
  ctaText?: string,
  title?: string,
  description?: string,
  name?: string,
  backLinkPath?: string
}


export enum SourceEnum {
  customers = "customers",
  operators = "operators",
  legalEntity = "legal-entities",
  companies = "companies",
  individuals = "individuals",
  contacts = "contacts",
  locations = "locations",
  landlords = "landlords",
}