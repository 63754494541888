import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from "react-router-dom";
import { pontCurrencies } from '../../../data/en/pontCurrencies';
import yup from '../../../functions/utils/yup';
import { useDirtyStateV2 } from '../../../hooks/Common/dirtyV2';
import { useFetchProductPortfolioById, useFetchProductPortfolioProducts, useManageProductPortfolio, useManageProductPortfolioPrices } from '../../../hooks/Product/product';
import i18n from "../../../i18n";
import { ApplicationUser } from "../../../types/Authentication/ApplicationUser";
import { OperationResultDto } from '../../../types/Common/OperationResultDto';
import { PriceDto } from '../../../types/Price/PriceDto';
import { ProductPortfolioProductInstanceDto } from '../../../types/Product/ProductPortfolioProductInstanceDto';
import { ConfirmationChangesDialog } from '../../Common/ConfirmationChangesDialog';
import { CustomDatePicker } from '../../Common/formComponents/CustomDatepicker';
import { CustomSelect } from '../../Common/formComponents/CustomSelect';
import { Input } from '../../Common/formComponents/Input';
import { LR } from "../../Common/Help/LR";
import { SectionHead } from '../../Common/Help/SectionHead';
import { CustomButton } from '../../Common/UI/CustomButton';
import { showErrorMessage, showSuccessMessage } from '../../Common/UI/CustomSnackbar';
import { Loader } from '../../Common/UI/Loader';
import { ProductPortfolioProductInstance } from './ProductPortfolioProductInstance';

type Args = {
    userLogged: ApplicationUser
}

export const ProductPortfolioDetails = ({ userLogged }: Args) => {
    const nav = useNavigate();
    const { id } = useParams();
    const isEdit = Boolean(id);
    const [productsList, setProductsList] = useState<ProductPortfolioProductInstanceDto[]>();
    const [prices, setPrices] = useState<PriceDto[]>([]);

    const schema = yup.object().shape({
        currencyCode: yup.string(),
        name: yup.string().required(i18n.t('VALIDATION_NAME_REQUIRED') as string),
        description: yup.string().required(i18n.t('VALIDATION_DESCRIPTION_REQUIRED') as string),
        operatorMemberId: yup.string().required(),
        id: yup.string(),
        fromDate: yup.date().required(i18n.t('VALIDATION_FROM_DATE_MUST_BE_A_VALID_DATE') as string),
        toDate: yup.date().required(i18n.t('VALIDATION_TO_DATE_CANNOT_BE_EARLIER_THAN_FROM_DATE') as string),
    });

    type FormType = yup.InferType<typeof schema>

    const { control, watch, setValue, formState: { isDirty: formIsDirty, errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            currencyCode: undefined,
            fromDate: undefined,
            toDate: undefined,
            operatorMemberId: userLogged.operator?.id,
            id: ""
        }
    })

    const fromDate = watch("fromDate")

    //#region  CALLBACKS
    const mutationCallBack = (operationResult: OperationResultDto) => {
        if (operationResult.isSuccess) {
            showSuccessMessage()
        } else {
            showErrorMessage(operationResult.errorMessage)
        }
    }

    const pricesCallBack = (operationResult: OperationResultDto) => {
        if (!operationResult.isSuccess) {
            showErrorMessage(operationResult.errorMessage)
        }
    }

    const { data: productPortfolioDetail, isLoading: productPortfolioLoading } = useFetchProductPortfolioById(id, {
        enabled: Boolean(id),
        onSuccess: (data) => {
            setValue('currencyCode', data.currencyCode);
            setValue('fromDate', dayjs(data.fromDate).toDate());
            setValue('toDate', dayjs(data.toDate).toDate());
            setValue('name', data.name);
            setValue('description', data.description);
            setValue('id', data.id);
        }
    })

    const { isLoading: productsLoading } = useFetchProductPortfolioProducts(id, {
        enabled: Boolean(id),
        onSuccess: (data) => {
            setProductsList(data);
        }
    })

    const updateMutation = useManageProductPortfolio();
    const { cancelDirtyAction, confirmDirtyAction, handleDirtyAction, setDirty, showConfirmation, unsetDirty } = useDirtyStateV2();
    const pricesMutation = useManageProductPortfolioPrices();

    const handleDirty = () => {
        setDirty();
    };

    const linkCallBack = () => {
        handleDirtyAction(() => {
            nav('/products/configuration/pricingportfolio/')
        })
    };

    const onFormSubmit = (data: FormType) => {
        const { description, fromDate, id, name, operatorMemberId, toDate, currencyCode } = data
        pricesMutation.mutate([prices, pricesCallBack]);
        updateMutation.mutate({
            ...productPortfolioDetail!,
            fromDate: fromDate,
            currencyCode: currencyCode!,
            toDate: toDate!,
            description: description,
            name: name,
            id: id || "",
            operatorMemberId: operatorMemberId
        }, {
            onSuccess: (data: any) => {
                showSuccessMessage()
                if(isEdit){
                    nav(`/products/configuration/pricingportfolio`)
                }else{
                    nav(`/products/configuration/pricingportfolio/${data.id}`)
                }
            },
            onError: (error) => {
                showErrorMessage(error.response?.data)
            }
        });
    }

    const handlePrices = (value: PriceDto) => {
        handleDirty();
        setPrices((prevPrices) => {
            const index = prevPrices.findIndex((price) => price.entityKey === value.entityKey && price.unitName === value.unitName);
            if (index !== -1) {
                const newPrices = [...prevPrices];
                newPrices[index] = value;
                return newPrices;
            } else {
                return [...prevPrices, value];
            }
        });
    };

    const headerName = productPortfolioDetail?.externalReference ? `${productPortfolioDetail!.name} (${productPortfolioDetail.externalReference})` : ""
    const headerTitle = i18n.t(isEdit ? "PRODUCT_LABEL_PRICINGCATALOGUEDETAIL" : "PRODUCT_LABEL_ADDPRICINGPORTFOLIO")

    useEffect(() => {
        if (formIsDirty) {
            setDirty()
        } else {
            unsetDirty()
        }
    }, [formIsDirty])

    const Header = () => (
        <SectionHead
            linkCallBack={linkCallBack}
            ctaText={i18n.t("PRODUCT_LABEL_PRICINGPORTFOLIO")!}
            name={headerName}
            title={headerTitle}
            description={i18n.t('COMMON_SUMMARY_PRODUCTMANAGEMENT')}
        />
    )


    if (productPortfolioLoading || productsLoading) {
        return <>
            <Header />
            <Loader />
        </>
    }

    return (
        <>
            <ConfirmationChangesDialog
                handleConfirm={confirmDirtyAction}
                handleCancel={cancelDirtyAction}
                showConfirmation={showConfirmation}
            />
            <Header />
            <form onSubmit={handleSubmit(onFormSubmit)}>
                <div className="row d-flex justify-content-center mb-3">
                    <div className="col-md-7 col-11 justify-content-center">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="name"
                                    render={({ field, fieldState }) => (
                                        <Input
                                            type="text"
                                            labelProps={{
                                                message: 'PRODUCT_LABEL_PRODUCTPORTFOLIONAME',
                                                mandatory: true
                                            }}
                                            placeholder={i18n.t("PRODUCT_LABEL_PRODUCTPORTFOLIONAME").toString()}
                                            field={field}
                                            validationError={fieldState.error}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="description"
                                    render={({ field, fieldState }) => (
                                        <Input
                                            type="text"
                                            labelProps={{
                                                message: 'PRODUCT_LABEL_PRODUCTPORTFOLIODESCRIPTION',
                                                mandatory: true
                                            }}
                                            placeholder={i18n.t("PRODUCT_LABEL_PRODUCTPORTFOLIODESCRIPTION").toString()}
                                            field={field}
                                            validationError={fieldState.error}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="currencyCode"
                                    render={({ field, fieldState }) => (
                                        <CustomSelect
                                            labelProps={{
                                                message: 'OPERATIONAL_DETAIL_LABEL_CURRENCY',
                                                mandatory: true
                                            }}
                                            options={pontCurrencies}
                                            placeholder={i18n.t('INVENTORY_LABEL_TYPE')}
                                            field={field}
                                            value={pontCurrencies.find(x => x.value === field.value)}
                                            onChange={(value) => field.onChange(value?.value)}
                                            validationError={fieldState.error}
                                        />
                                    )}
                                />

                            </Grid>
                            <Grid item xs={6}>
                                <Controller
                                    control={control}
                                    name="fromDate"
                                    render={({ field, fieldState }) => (
                                        <CustomDatePicker
                                            labelProps={{
                                                message: 'COMMON_LABEL_FROMDATE',
                                                mandatory: true
                                            }}
                                            value={field.value ? dayjs(field.value) : null}
                                            onChange={(e) => {
                                                if (dayjs(e).isValid()) {
                                                    field.onChange(e.toDate())
                                                } else {
                                                    field.onChange(undefined)
                                                }
                                            }}
                                            validationError={fieldState.error}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Controller
                                    control={control}
                                    name="toDate"
                                    render={({ field, fieldState }) => (
                                        <CustomDatePicker
                                            labelProps={{
                                                message: 'COMMON_LABEL_TODATE',
                                                mandatory: true
                                            }}
                                            value={field.value ? dayjs(field.value) : null}
                                            onChange={(e) => {
                                                if (dayjs(e).isValid()) {
                                                    field.onChange(e.toDate())
                                                } else {
                                                    field.onChange(undefined)
                                                }
                                            }}
                                            validationError={fieldState.error}
                                            disabled={!fromDate}
                                            minDate={dayjs(fromDate || new Date()).add(1, 'day')}
                                        />
                                    )}
                                />
                            </Grid>
                            {isEdit && productsList?.length && (
                                <>
                                    <Grid item xs={12}>
                                        <Typography variant={'h5'}><LR localResource='COMMON_SUMMARY_PRODUCTS'></LR> </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ProductPortfolioProductInstance
                                            mutationSwitchCallBack={mutationCallBack}
                                            pricingCallBack={handlePrices}
                                            handleDirty={handleDirty}
                                            currencyCode={productPortfolioDetail?.currencyCode || "EUR"}
                                            items={productsList}
                                        />
                                    </Grid>
                                </>
                            )}
                            <Grid item container justifyContent={'center'} xs={12}>
                                <Grid item xs={12} md={4}>
                                    <CustomButton
                                        type="submit"
                                        color='secondary'
                                        variant='contained'
                                        disabled={false}
                                        sx={{ width: '100%' }}
                                        loading={updateMutation.isLoading || pricesMutation.isLoading}
                                    >
                                        <LR localResource="COMMON_LABEL_SAVE"></LR>
                                    </CustomButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </form>
        </>
    );
};

