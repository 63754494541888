import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import ApiStatus from "../Common/Errors/ApiStatus";
import MessageAlert from "../Common/Errors/MessageAlert";
import { useNavigate } from "react-router";
import { SectionHead } from "../Common/Help/SectionHead";
import { useCreateTicket, useCreateTicketComment, useFetchTicketById, useFetchTicketConfiguration, useUpdateTicket } from "../../hooks/Ticket/ticket";
import { useParams } from "react-router-dom";
import { LabelAndHelp } from "../Common/Help/LabelAndHelp";
import { useEffect, useState } from "react";
import { Divider, IconButton, Paper, TextareaAutosize, Tooltip } from "@mui/material";
import { LR } from "../Common/Help/LR";
import { SaveSnackBar } from "../Common/SaveSnackBar";
import { TicketDto } from "../../types/Ticket/TicketDto";
import { SummaryItemDto } from "../../types/Common/SummaryItemDto";
import { SummaryItemCollection } from "../Common/SummaryItems/SummaryItemCollection";
import { LocationSelector } from "../Location/LocationSelector";
import Select, { SingleValue } from "react-select";
import { LocationResponseDto } from "../../types/Location/LocationResponseDto";
import { LookUpIdDto } from "../../types/Common/LookUpIdDto";
import { useDirtyState } from "../../hooks/Common/dirty";
import { ConfirmationChangesDialog } from '../Common/ConfirmationChangesDialog';
import Modal from 'react-bootstrap/Modal';
import { TicketAttachments } from "./TicketAttachments";
import "./TicketAttachments.css";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { CustomerSelector } from "../Member/CustomerSelector";
import { format } from "date-fns";
import { TicketCommentDto } from "../../types/Ticket/TicketCommentDto";

type Args =
  {
    userLogged: ApplicationUser
  }

const TicketEdit = ({ userLogged }: Args) => {
  const nav = useNavigate();
  const params = useParams();
  if (!params.id) {
    throw Error("Ticket id needed");
  }

  const { data: ticket, status, isSuccess } = useFetchTicketById(params.id!);
  const [ticketState, setTicketState] = useState<TicketDto>();
  const [newDescriptionState, setNewDescriptionState] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedComment, setSelectedComment] = useState('');

  useEffect(() => {
    if (isSuccess && ticket) {
      ticket.updateDateUtc = ticket.updateDateUtc ? new Date(ticket.updateDateUtc) : new Date();
      ticket.createDateUtc = ticket.createDateUtc ? new Date(ticket.createDateUtc) : new Date();
      ticket.comments?.forEach((childTicket) => {
        if (childTicket.updateDateUtc) {
          childTicket.updateDateUtc = new Date(childTicket.updateDateUtc);
        }
      });
      setTicketState(ticket);
    }
  }, [isSuccess, ticket]);

  const { data: configuration } = useFetchTicketConfiguration();

  const [priorities, setPriorities] = useState<LookUpIdDto[]>([]);
  const [selectedPriority, setSelectedPriority] = useState<LookUpIdDto>();

  const [categories, setCategories] = useState<LookUpIdDto[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<LookUpIdDto>();

  const [statuses, setStatuses] = useState<LookUpIdDto[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<LookUpIdDto>();

  const [isParentDirty, setParentDirty, unsetParentDirty] = useDirtyState();
  const [isCommentDirty, setCommentDirty, unsetCommentDirty] = useDirtyState();

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [targetUrl, setTargetUrl] = useState('');

  useEffect(() => {
    if (configuration) {
      setPriorities(configuration!.priorities.map(x => ({ label: x.label, value: x.id })));
      setStatuses(configuration!.statuses.map(x => ({ label: x.label, value: x.id })));
      setCategories(configuration!.categories.map(x => ({ label: x.label, value: x.id })));
    }
  }, [configuration]);

  useEffect(() => {
    if (priorities) {
      if (ticketState?.priorityId) {
        setSelectedPriority(priorities.find(x => x.value === ticketState.priorityId));
      }
      else {
        setSelectedPriority(priorities[0]);
      }
    }
  }, [priorities, ticketState?.priorityId]);

  useEffect(() => {
    if (statuses) {
      if (ticketState?.statusId) {
        setSelectedStatus(statuses.find(x => x.value === ticketState.statusId));
      }
      else {
        setSelectedStatus(statuses[0]);
      }
    }
  }, [statuses, ticketState?.statusId]);

  useEffect(() => {
    if (categories) {
      if (ticketState?.categoryId) {
        setSelectedCategory(categories.find(x => x.value === ticketState.categoryId));
      }
      else {
        setSelectedCategory(categories[0]);
      }
    }
  }, [categories, ticketState?.categoryId]);

  const handleDropdownChangePriority = (newValue: SingleValue<{ value: number, label: string | undefined }>) => {
    const selectedOption = priorities.find(x => x.value === newValue?.value);

    if (selectedOption) {
      setSelectedPriority(selectedOption);
      setTicketState({ ...ticketState!, priorityId: selectedOption.value });
      setParentDirty();
    }
  };

  const handleDropdownChangeCategory = (newValue: SingleValue<{ value: number, label: string | undefined }>) => {
    const selectedOption = categories.find(x => x.value === newValue?.value);

    if (selectedOption) {
      setSelectedCategory(selectedOption);
      setTicketState({ ...ticketState!, categoryId: selectedOption.value });
      setParentDirty();
    }
  };

  const handleDropdownChangeStatus = (newValue: SingleValue<{ value: number, label: string | undefined }>) => {
    const selectedOption = statuses.find(x => x.value === newValue?.value);

    if (selectedOption) {
      setSelectedStatus(selectedOption);
      setTicketState({ ...ticketState!, statusId: selectedOption.value });
      setParentDirty();
    }
  };

  const confirmLocation = (location: LocationResponseDto) => {
    setTicketState({ ...ticketState!, locationId: location.id });
  }

  const formatDate = (inputDate: any) => {
    return format(inputDate, "dd/MM/yyyy HH:ss");
  }

  const createdCallback = (ticketId: string) => {
    if (ticketState?.comments && ticketState?.comments.length > 0) {
      ticketState.comments[ticketState.comments.length - 1].id = ticketId;
    }
    setSnackbarOpen(true);
  }
  const create = useCreateTicketComment(createdCallback);

  const homeCallBack = () => {
    const url = `/tickets`;
    if (isParentDirty || isCommentDirty) {
      setShowConfirmation(true);
      setTargetUrl(url);
    } else {
      nav(url);
    }
  };

  const dialogConfirm = () => {
    setShowConfirmation(false);
    unsetParentDirty();
    unsetCommentDirty();
    nav(targetUrl);
  };

  const dialogCancel = () => {
    setShowConfirmation(false);
  };


  const enableSave = () => {
    return (isCommentDirty && newDescriptionState !== '') || isParentDirty;
  }

  const handleSnackClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };
  const linkCallBackCollection = (item: SummaryItemDto) => {
    switch (item.name) {
      case "FilesSummary":
        nav(`/tickets/${params.id}/digital-assets/${item.uri.substring(item.uri.indexOf('group=') + 6)}`);
        break;
      default:
        alert(`Item ${item.name} does not have a link yet`);
        break;
    }
  }

  const ticketSummary: SummaryItemDto = {
    id: 1,
    entityName: "Ticket",
    entityKey: "b791b63a-8251-460c-3c18-08dc62cdd395",
    uri: "operator-management/tickets/{ticketId}/digital-assets?group=files",
    name: "FilesSummary",
    description: "Files",
    label: "COMMON_SUMMARY_FILES",
    hasCountryCode: false,
    countryCode: "",
    hasCounter: false,
    counter: 0,
    hasCheckBox: false,
    checkBoxValue: false,
    hasCallToAction: false,
    callToActionDescription: "",
    callToActionDetail: "",
    editRoleFlags: 64,
    viewRoleFlags: 192,
    route: null,
    mandatoryCount: 0,
    completedMandatoryCount: 0,
    optimumCount: 1
  }

  const { mutate } = useUpdateTicket(createdCallback);

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();

      if (isCommentDirty && ticketState) {
        let newComment: TicketCommentDto =
        {
          parentId: ticketState.id!,
          memberId: userLogged.memberId,
          companyMemberId: userLogged.operator?.id,
          description: newDescriptionState,
          memberName: userLogged.name,
          companyName: userLogged.operator?.fullName,
          updateDateUtc: new Date()
        }
        await create.mutate(newComment);
        ticketState.comments?.push({ ...newComment });
        setNewDescriptionState('');
        unsetCommentDirty();
      }

      if (isParentDirty) {
        mutate(ticketState as TicketDto);
        unsetParentDirty();
      }

    } catch (err: any) {
      console.error(err);
    }
  }

  const openModal = async (ticketId: string) => {
    await setSelectedComment(ticketId)
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedComment('');
  };

  const confirmCustomer = () => {

  }

  const getDatesFormatted = () => {

    if (ticketState) {
      let result = "";
      if (ticket?.createDateUtc) {
        result = `${i18n.t('MENU_LABEL_CREATEDATE')} ${format(ticketState.createDateUtc!, 'dd/MM/yyyy HH:mm')}`
      }

      if (ticket?.updateDateUtc) {
        result += ` ${i18n.t('MENU_LABEL_UPDATEDATE')} ${format(ticketState.updateDateUtc!, 'dd/MM/yyyy HH:mm')}`
      }

      return result;
    }

  }

  if (!isSuccess) return (
    <>
      <div className="mt-5 row d-flex justify-content-center">
        <div className="col-md-5 col-11 justify-content-center">
          <ApiStatus status={status} />
        </div>
      </div>
    </>
  )

  if (!ticket || !ticketState) return <MessageAlert message="TICKET_LABEL_TICKETNOTFOUND" params={{ param0: '' }}></MessageAlert>;

  return (
    <>
      <SectionHead linkCallBack={homeCallBack}
        ctaText={i18n.t('MENU_LABEL_TICKETS')!}
        name={`${ticketState.subject} - ${getDatesFormatted()}`}
        title={i18n.t("MENU_LABEL_TICKETS")}
        description={i18n.t("COMMON_SUMMARY_TICKETMANAGEMENT")} />

      <ConfirmationChangesDialog
        handleConfirm={dialogConfirm}
        handleCancel={dialogCancel}
        showConfirmation={showConfirmation}
      />


      <form onSubmit={onFormSubmit} >

        <div className="row d-flex justify-content-center">
          <div className="col-md-7 col-11 justify-content-center">
            <div className="row d-flex justify-content-center mb-2">
              <div className="col-md-3 col-6 justify-content-center">
                <LabelAndHelp mandatory={false} fullscreen={true} message='TICKET_LABEL_INTERNALIDENTIFIER' ></LabelAndHelp>
                <input
                  type="text"
                  className="form-control fa-form"
                  placeholder={i18n.t("TICKET_LABEL_INTERNALIDENTIFIER").toString()}
                  readOnly={true}
                  value={ticketState.internalIdentifier} />
              </div>
              <div className="col-md-9 col-6 justify-content-center">
                <LabelAndHelp mandatory={false} fullscreen={true} message='TICKET_LABEL_SUBJECT' ></LabelAndHelp>
                <div className="row d-flex justify-content-center mb-2">
                  <div className="justify-content-center">
                    <input
                      type="text"
                      className="form-control fa-form"
                      placeholder={i18n.t("TICKET_LABEL_SUBJECT").toString()}
                      value={ticketState.subject}
                      onChange={e => { setTicketState({ ...ticketState, subject: e.target.value }); setParentDirty(); }}></input>
                  </div>
                </div>
              </div>
            </div>
            <div className="row d-flex mb-2">
              <div className="col-md-7 col-6 justify-content-center">
                <LabelAndHelp mandatory={false} fullscreen={true} message='TICKET_LABEL_CATEGORY' ></LabelAndHelp>
                <Select value={selectedCategory} options={categories} className="form-control fa-form fa-dropdown-container" classNamePrefix="fa-dropdown"
                  onChange={(e) => handleDropdownChangeCategory(e)} placeholder={i18n.t('TICKET_LABEL_CATEGORY')}></Select>
              </div>
              <div className="col-md-3 col-6 justify-content-center">
                <LabelAndHelp mandatory={false} fullscreen={true} message='TICKET_LABEL_PRIORITY' ></LabelAndHelp>
                <Select value={selectedPriority} options={priorities} className="form-control fa-form fa-dropdown-container" classNamePrefix="fa-dropdown"
                  onChange={(e) => handleDropdownChangePriority(e)} placeholder={i18n.t('TICKET_LABEL_PRIORITY')}></Select>


              </div>
              <div className="col-md-2 col-6 justify-content-center">
                <LabelAndHelp mandatory={false} fullscreen={true} message='TICKET_LABEL_STATUS' ></LabelAndHelp>
                <Select value={selectedStatus} options={statuses} className="form-control fa-form fa-dropdown-container" classNamePrefix="fa-dropdown"
                  onChange={(e) => handleDropdownChangeStatus(e)} placeholder={i18n.t('TICKET_LABEL_STATUS')}></Select>
              </div>
            </div>

            <div className="row d-flex mt-3 disabled-content">
              <LocationSelector
                locationId={ticketState.locationId!}
                placeholder="MEMBERSHIP_LABEL_LOCATION"
                operatorId={userLogged.operator?.id!}
                handleConfirm={confirmLocation}
                labelProps={{
                  mandatory: false,
                  message: 'TICKET_LABEL_LOCATION',
                }}
              />

              <LabelAndHelp mandatory={false} fullscreen={true} message='TICKET_LABEL_MEMBER'></LabelAndHelp>
              <CustomerSelector userLogged={userLogged} customerMemberId={ticketState.memberId!} handleConfirm={confirmCustomer} ></CustomerSelector>
            </div>

            <div className="mt-3">
              <LabelAndHelp mandatory={false} fullscreen={true} message='TICKET_LABEL_DESCRIPTION' ></LabelAndHelp>
              <TextareaAutosize className="fa-form" style={{ resize: 'none', width: '100%' }}
                aria-label={i18n.t("TICKET_LABEL_DESCRIPTION").toString()}
                minRows={5}
                maxRows={5}
                disabled
                value={ticketState.description}
                onChange={(value) => { setTicketState(({ ...ticketState, description: value.target.value })); setParentDirty(); }}
                placeholder={i18n.t("TICKET_LABEL_DESCRIPTION").toString()} />
            </div>

            <SummaryItemCollection linkCallBack={linkCallBackCollection} items={[ticketSummary]}></SummaryItemCollection>

            <h2>
              <LabelAndHelp mandatory={false} fullscreen={true} message='TICKET_LABEL_COMMENTS' ></LabelAndHelp>
            </h2>
            {ticketState.comments?.map((comment, index) => (
              <div key={index}>
                <Paper key={index} elevation={3} className="fa-paper-card fa-name-card">
                  <div className="ticket-comment-container">
                    <span className="ticket-comment-name">{comment?.memberName}</span>
                    <span className="ticket-comment-date">
                      {formatDate(comment?.updateDateUtc)}
                    </span>
                  </div>
                  <div className="disabled-content ticket-comment-textarea">
                    {comment.description}
                  </div>
                  <div className="ticket-comment-attachment">
                    <IconButton onClick={() => openModal(comment.id!)}>
                      <Tooltip title={i18n.t('TICKET_LABEL_ADDATTACHMENT')}>
                        <AttachFileIcon />
                      </Tooltip>
                      {i18n.t('TICKET_LABEL_ADDATTACHMENT')}
                    </IconButton>
                  </div>
                </Paper>
              </div>
            ))}

            <LabelAndHelp mandatory={false} fullscreen={true} message='TICKET_LABEL_NEWCOMMENT' ></LabelAndHelp>
            <TextareaAutosize
              className="fa-form"
              style={{ resize: 'none', width: '100%' }}
              aria-label={i18n.t("TICKET_LABEL_NEWCOMMENT").toString()}
              minRows={3}
              maxRows={5}
              value={newDescriptionState}
              onChange={(value) => { setNewDescriptionState(value.target.value); setCommentDirty(); }}
              placeholder={i18n.t("TICKET_LABEL_NEWCOMMENT").toString()}
            />

            <Modal style={{ backgroundColor: 'transparent' }} show={isModalOpen} onHide={closeModal}>
              <Modal.Body style={{ backgroundColor: 'transparent' }}>
                <TicketAttachments ticketId={selectedComment} userLogged={userLogged} />
              </Modal.Body>
            </Modal>

            <div className="row d-flex justify-content-center mt-3">
              <div className="col-md-4 col-6 justify-content-center">
                <Tooltip title={!enableSave() ? i18n.t('TICKET_REQUIRED_TOOLTIP') : ''}>
                  <span>
                    <button type="submit" disabled={!enableSave()} style={!enableSave() ? { pointerEvents: 'none' } : {}} className="btn btn-green btn-primary btn-block">
                      <LR localResource="COMMON_LABEL_SAVE"></LR>
                    </button>
                  </span>
                </Tooltip>
              </div>
            </div>
            <SaveSnackBar onClose={handleSnackClose} isOpen={snackbarOpen} />
          </div>
        </div>

      </form>
    </>
  );
};

export { TicketEdit }