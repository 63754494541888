import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import i18n from "../../i18n";
import { Button, IconButton, InputBase, Paper, Tooltip, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { LR } from "../Common/Help/LR";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { debounce } from 'lodash';
import { useFetchNotificationRequests, useFetchNotificationRequestStatuses } from "../../hooks/Notification/notification-requests";
import { NotificationRequestDto } from "../../types/Notification/NotificationRequestDto";
import DataRow from "../Common/DataRow/DataRow";
import { IconDisplayer } from "../Common/Help/IconDisplayer";
import Select from "react-select";
import { colors } from "../../main/Theme";

type Args =
  {
    userLogged: ApplicationUser,
  }

const ApplicationNotificationsConfiguration = ({ userLogged }: Args) => {
  const nav = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const queryStringParams = useParams();
  if (!queryStringParams.id) throw Error("Application id needed");
  const applicationId = queryStringParams.id;
  const [statusFilter, setStatusFilter] = useState<number>();
  const { data: notificationRequestData } = useFetchNotificationRequests(applicationId, debouncedSearchTerm, pageNumber, pageSize, statusFilter);
  const [filteredItems, setFilteredItems] = useState<NotificationRequestDto[]>([]);
  const { data: statuses } = useFetchNotificationRequestStatuses();
  const [selectedStatus, setSelectedStatus] = useState<{ value: number, label: string }>();
  const [statusOptions, setStatusOptions] = useState<{ value: number, label: string }[]>([]);

  useEffect(() => {
    if (statuses) {
      let allStatusOptions = statuses.map(x => ({ label: x.label, value: x.id }));
      setStatusOptions(allStatusOptions);
    }
  }, [statuses])

  useEffect(() => {
    if (selectedStatus && selectedStatus.value !== 0) {
      setStatusFilter(selectedStatus.value);
    }
    else {
      setStatusFilter(undefined);
    }
  }, [selectedStatus])

  useEffect(() => {
    if (notificationRequestData) {
      setFilteredItems(notificationRequestData.records);
      setTotalPageCount(notificationRequestData.totalPages);
      if (notificationRequestData.totalPages < pageNumber) {
        setPageNumber(1);
      }
    }
    else {
      setFilteredItems([]);
    }
  }, [notificationRequestData]);

  const handleSearchChange = useCallback(
    debounce((term) => {
      setDebouncedSearchTerm(term);
    }, 500), // 1 second delay
    []
  );

  const handleStatusFilterChange = (selectedOption: any) => {
    setSelectedStatus(selectedOption);
  }

  useEffect(() => {
    // Whenever the searchTerm changes, call the debounce function
    handleSearchChange(searchTerm);
  }, [searchTerm]);

  const handleArrowIconClick = (id: string) => {
    nav(`${id}`);
  };

  const addNewItem = () => {
    nav(`/application/${applicationId}/configuration/notifications/add`);
  };

  return (
    <>
      <div className="mt-1 row d-flex justify-content-center">
        <div className="col-md-7 col-11 d-flex align-items-center">
          <Tooltip title={i18n.t('NOTIFICATIONREQUEST_LABEL_NEWNOTIFICATION')}>
            <IconButton onClick={addNewItem} className="btn plusButton mr-2"> {/* Added mr-2 for some spacing */}
              <AddCircleRoundedIcon fontSize="medium" sx={{ color: colors.primary }} />
            </IconButton>
          </Tooltip>
          <span className="lead mb-0"> {/* Set margin-bottom to 0 */}
            <LR localResource="NOTIFICATIONREQUEST_LABEL_NEWNOTIFICATION" />
          </span>
        </div>
      </div>

      <div className="row d-flex justify-content-center">
        <div className="col-md-7 col-11 justify-content-center">

          <div className="row">
            <div className="col-4">
              <Select
                value={selectedStatus}
                className="form-control fa-form fa-dropdown-container"
                classNamePrefix="fa-dropdown"
                options={statusOptions}
                getOptionLabel={(option) => i18n.t(option.label)}
                onChange={handleStatusFilterChange}
                isClearable
                placeholder={i18n.t('NOTIFICATIONREQUEST_LABEL_STATUS').toString()}
              />
            </div>
            <div className="col-8">
              <Paper
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: '0px 0',
                  padding: '10px 10px',
                  border: '1px solid #ccc'
                }}
              >
                <InputBase
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder={i18n.t('NOTIFICATIONREQUEST_LABEL_SEARCH').toString()}
                  style={{ flexGrow: 1 }}
                />
                <SearchIcon color="action" />
              </Paper>
            </div>
          </div>

          <div className="activity-table">
            <div className="activity-header">
              <div className="col-md-3 activity-header-item">{i18n.t('NOTIFICATIONREQUEST_LABEL_DESCRIPTION')}</div>
              <div className="col-md-2 activity-header-item">{i18n.t('NOTIFICATIONREQUEST_LABEL_STATUS')}</div>
              <div className="col-md-2 activity-header-item">{i18n.t('NOTIFICATIONREQUEST_LABEL_DELIVERIES')}</div>
            </div>

            {filteredItems.map((item, index) => {
              const dataToShow = [
                { content: item.description },
                { content: i18n.t(statuses?.find(status => status.id === item.notificationRequestStatusId)?.label!) },
                { content: item.details },
              ];

              return (
                <div key={index}>
                  <DataRow
                    data={dataToShow}
                    actionIcon={
                      <div
                        style={{
                          color: colors.primary,
                          cursor: 'pointer',
                          transition: 'color 0.3s ease',
                          marginRight: '10px',
                        }}
                      >
                        <IconDisplayer
                          type={'COMMON_LABEL_ARROWFORWARD'}
                        />
                      </div>
                    }
                    isExpanded={false}
                    onActionClick={() => handleArrowIconClick(item.id!)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};


export { ApplicationNotificationsConfiguration }