import { Grid } from "@mui/material";
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import React, { useState } from "react";

import i18n from "../../i18n";
import { CommunicationTypeDto } from "../../types/Common/CommunicationTypeDto";
import { Input } from "./formComponents/Input";
import { LR } from "./Help/LR";
import "./SummaryItems/SummaryItem.css";
import { CustomButton } from "./UI/CustomButton";
import { CustomSnackbar } from "./UI/CustomSnackbar";
import { ApplicationConfig } from "../../config/ApplicationConfig";

type Args = {
  items: CommunicationTypeDto[]
  submitData: (item: CommunicationTypeDto[]) => void,
  handleDirty: () => void
};

const CommunicationTypeCollection = ({ items, submitData, handleDirty }: Args) => {
  const isFormValid = items.every((item) => !item.isMandatory || item.value.trim() !== '');
  const [itemsState, setItemsState] = useState<CommunicationTypeDto[]>(items);
  const [isFormValidState, setIsFormValidState] = useState<boolean>(isFormValid);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [snackbarContent, setSnackbarContent] = useState<any>()

  const setItemValue = (item: CommunicationTypeDto, value: string) => {
    let updatedItem = { ...item, value: value };
    updatedItem = validateForm(updatedItem);
    const updatedItemsState = itemsState.map((i) => (i === item ? updatedItem : i));
    setIsFormValidState(updatedItemsState.every((item) => ((!item.isMandatory || item.value.trim() !== '') && !item.errorMessage)));
    setItemsState(updatedItemsState);
  };

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      setSubmitting(true);
      e.preventDefault();
      submitData(itemsState);
      setSubmitting(false);

    } catch (err: any) {
      console.error(err);
      setSubmitting(false);
    }
  }

  const validateForm = (item: CommunicationTypeDto): CommunicationTypeDto => {
    switch (item.communicationGroupName.toLowerCase()) {
      case "phone":

        if (isValidPhoneNumber(item.value)) {
          const parsedPhoneNumber = parsePhoneNumber(item.value);
          item.value = parsedPhoneNumber.formatInternational()
          item.errorMessage = '';
        } else {
          if (!item.isMandatory && item.value.trim() === '') {
            item.errorMessage = '';
          } else {
            item.errorMessage = 'COMMON_ERROR_PHONEFORMATTING'
          }
        }
        break;
      case "email":
        const emailRegex = ApplicationConfig.emailRegex;
        if (!emailRegex.test(item.value.trim())) {
          if (!item.isMandatory && item.value.trim() === '') {
            item.errorMessage = '';
          } else {
            item.errorMessage = 'COMMON_ERROR_EMAILFORMATTING'
          }
        } else {
          item.errorMessage = '';
        }
        break;
      case "web":
        const webRegex = /^(https?:\/\/)?([w]{3}\.)?([a-zA-Z0-9-]{3,}(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,})$/;
        if (!webRegex.test(item.value)) {
          item.errorMessage = 'COMMON_ERROR_WEBFORMATTING';
        } else {
          // Remove "http://" or "https://"
          let cleanedValue = item.value.replace(/^(https?:\/\/)/, '');

          // Add "www" prefix if it doesn't exist
          if (!cleanedValue.startsWith('www.')) {
            cleanedValue = 'www.' + cleanedValue;
          }

          item.value = cleanedValue;
          item.errorMessage = '';
        }

        break;
    }
    return item;
  };

  return (
    <>
      <CustomSnackbar
        snackbarContent={snackbarContent}
        setSnackbarContent={setSnackbarContent}
        severity='error'
      ></CustomSnackbar>
      <form onSubmit={onFormSubmit}>
        <Grid container spacing={2}>
          {itemsState.map((item) => (
            <Grid item xs={12} key={item.communicationTypeLabel}>
              <Input
                labelProps={{
                  message: item.communicationTypeLabel,
                  mandatory: item.isMandatory
                }}
                type="text"
                value={item.value}
                id={item.communicationTypeName}
                onChange={(e) => { setItemValue(item, e.target.value); handleDirty() }}
                placeholder={i18n.t(`COMMON_PLACEHOLDER_${item.communicationGroupName.toUpperCase()}`).toString()}
                required={item.isMandatory}
                validationError={item?.errorMessage ? { message: i18n.t(item?.errorMessage).toString() } : undefined}
              />
            </Grid>
          ))}
          <Grid item xs={12} md={4} mx={'auto'} mt={3}>
            <CustomButton
              type="submit"
              disabled={!isFormValidState || submitting}
              color="secondary"
              variant="contained"
              fullWidth
              loading={submitting}
            >
              <LR localResource="COMMON_LABEL_SAVE" />
            </CustomButton>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export {
  CommunicationTypeCollection
};
