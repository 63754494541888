import DeleteIcon from '@mui/icons-material/Delete';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { HookConfig } from '../../config/HookConfig';
import { operatorDefaultHeaders } from '../../functions/api/api';
import { useFetchLocationById, useFetchLocationTypes, useUpdateLocation } from '../../hooks/Location/location';
import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { AddressDto } from "../../types/Common/AddressDto";
import { LocationResponseDto } from '../../types/Location/LocationResponseDto';
import { MemberResponseDto } from '../../types/Member/MemberResponseDto';
import AddressContainer from '../Common/Address/AddressContainer';
import { ConfirmationChangesDialog } from '../Common/ConfirmationChangesDialog';
import CountryDropDown from "../Common/CountryDropDown";
import ApiStatus from '../Common/Errors/ApiStatus';
import MessageAlert from '../Common/Errors/MessageAlert';
import { LR } from "../Common/Help/LR";
import { ParentMemberSelector } from '../Member/ParentMemberSelector';

import { Box, Grid, IconButton } from '@mui/material';
import { ApplicationConfig } from '../../config/ApplicationConfig';
import { useDirtyStateV2 } from '../../hooks/Common/dirtyV2';
import { Input } from '../Common/formComponents/Input';
import { SectionHead } from '../Common/Help/SectionHead';
import { CustomButton } from '../Common/UI/CustomButton';
import { CustomSnackbar, showSuccessMessage } from '../Common/UI/CustomSnackbar';

import SearchIcon from "@mui/icons-material/Search";
import { DialogLandlordSelector } from './DialogLandlordSelector';
import { isNil } from 'lodash';
import { CustomSelect } from '../Common/formComponents/CustomSelect';
import numeral from 'numeral';
type Args = {
    userLogged: ApplicationUser
}

const LocationDetails = ({ userLogged }: Args) => {
    const nav = useNavigate();
    const params = useParams();
    const [snackbarContent, setSnackbarContent] = useState<any>()

    if (!params.id) throw Error("Location id needed");

    const emptyLocationResponseDto: LocationResponseDto = {
        id: '',
        internalIdentifier: '',
        externalReference: '',
        operatorMemberId: ApplicationConfig.emptyGuid,
        legalEntityMemberId: ApplicationConfig.emptyGuid,
        name: '',
        countryIso3Code: '',
        summaries: [],
        shortDescription: '',
        longDescription: '',
        brandName: '',
        landlordMemberId: ApplicationConfig.emptyGuid,
        landlordName: "",
        locationTypeFlags: undefined
    };

    const emptyAddressDTO: AddressDto = {
        line1: '',
        countryIso3Code: '',
        languageCode: i18n.language,
        addressTypeName: 'Location',
        entityKey: '',
        entityName: '',
        city: '',
        area: '',
    }

    let addressPassed: AddressDto = {
        line1: '',
        countryIso3Code: '',
        languageCode: i18n.language,
        addressTypeName: 'Location',
        entityKey: '',
        entityName: 'Location',
        city: '',
        area: '',
    };



    const { data: location, status, isSuccess } = useFetchLocationById(params.id)
    const [locationData, setLocationData] = useState<LocationResponseDto>(emptyLocationResponseDto);
    const [addressData, setAddressData] = useState<AddressDto>(emptyAddressDTO);
    const [countryData, setCountryData] = useState<string>('');
    const [landlordOpen, setLandlordOpen] = useState(false);

    const { mutate, isLoading: submitting } = useUpdateLocation();



    const { data } = useFetchLocationTypes();



    const { setDirty, showConfirmation, handleDirtyAction, cancelDirtyAction, confirmDirtyAction, unsetDirty, isDirty } = useDirtyStateV2();

    const [isCollapseOpen, setIsCollapseOpen] = useState(false);

    function OperatorFetch(): Promise<any> {
        return fetch(`${HookConfig.memberUrl}/${locationData.operatorMemberId}`,
            { credentials: "include", headers: operatorDefaultHeaders() })
            .then((response) => response.json());
    }





    const copyAddressFromOperator = async () => {
        try {
            const fetchedData: MemberResponseDto = await OperatorFetch();
            if (fetchedData && fetchedData.address) {
                addressPassed.entityKey = location?.id!
                addressPassed.entityName = "location"
                addressPassed.line1 = fetchedData.address.line1;
                addressPassed.line2 = fetchedData.address.line2;
                addressPassed.line3 = fetchedData.address.line3;
                addressPassed.city = fetchedData.address.city;
                addressPassed.area = fetchedData.address.area;
                addressPassed.countryIso3Code = fetchedData.address.countryIso3Code;
                addressPassed.languageCode = i18n.language;
                addressPassed.addressTypeName = 'Location';
                addressPassed.latitude = fetchedData.address.latitude;
                addressPassed.longitude = fetchedData.address.longitude;
                addressPassed.zip = fetchedData.address.zip;

                location!.address = addressPassed
                setAddressData(addressPassed);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        if (isSuccess && location) {
            console.log(location);
            setLocationData(location);
            setCountryData(location.countryIso3Code);
            if (!location.address) {
                addressPassed.countryIso3Code = location.countryIso3Code;
                addressPassed.entityKey = location.id;
                setAddressData(addressPassed);
                setIsCollapseOpen(true);
            } else {
                setAddressData(location.address);
                setIsCollapseOpen(false);
            }
        }
    }, [isSuccess, location]);

    const linkCallBack = () => {
        let url = `/locations/${location!.id}/summary`;
        handleDirtyAction(() => nav(url));
    }

    const setAddress = (value: AddressDto) => {
        if (value !== null && value !== undefined) {
            addressData.id = value.id;
            addressData.latitude = value.latitude;
            addressData.longitude = value.longitude;
            addressData.line1 = value.line1;
            addressData.line2 = value.line2;
            addressData.line3 = value.line3;
            addressData.area = value.area;
            addressData.city = value.city;
            addressData.zip = value.zip;
            addressData.countryIso3Code = value.countryIso3Code;
            setAddressData({ ...addressData, line1: addressData.line1 });
        };
    }

    const changeCountry = (value: string) => {
        setCountryData(value);
        setAddressData({ ...addressData, countryIso3Code: value });
        setLocationData({ ...locationData, countryIso3Code: value })
    }
    const handleDirty = () => {
        setDirty();
    };

    const handleConfirm = (operator: MemberResponseDto) => {
        if (locationData.name) {
            setLocationData({ ...locationData, legalEntityMemberId: operator.id });
            handleDirty();
        }
    }

    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            unsetDirty();
            addressData.entityKey = locationData.id;
            addressData.entityName = "Location";
            locationData.address = addressData;
            if (addressData.countryIso3Code !== locationData.countryIso3Code) {
                locationData.countryIso3Code = addressData.countryIso3Code;
            }
            mutate(locationData, {
                onError: (err) => {
                    setSnackbarContent(err.response?.data)
                }, onSuccess: () => {
                    showSuccessMessage()
                }
            });
        } catch (err: any) {
            console.error(err);
        }
    }

    if (!isSuccess) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <ApiStatus status={status} />
                </div>
            </div>
        </>
    )

    if (!location) return <MessageAlert message="MEMBER_LABEL_MEMBERNOTFOUND" params={{ param0: params.id }}></MessageAlert>;

    const isButtonDisabled = () => (!isDirty ||
        !addressData.line1 ||
        !addressData.city ||
        !addressData.countryIso3Code ||
        !addressData.zip ||
        addressData.line1.length < 3 ||
        addressData.city.length < 3 ||
        addressData.countryIso3Code.length < 3 ||
        addressData.zip.length < 3 ||
        locationData.name.length < 3 ||
        !locationData.name
    );

    return (
        <form onSubmit={onFormSubmit}>
            <CustomSnackbar snackbarContent={snackbarContent} setSnackbarContent={setSnackbarContent} />
            <ConfirmationChangesDialog
                handleConfirm={confirmDirtyAction}
                handleCancel={cancelDirtyAction}
                showConfirmation={showConfirmation}
            />
            <SectionHead
                linkCallBack={linkCallBack}
                ctaText={i18n.t("LOCATION_SUMMARY_LOCATIONMAINPAGE")!}
                name={`${location.name} (${location.externalReference})`}
                title={i18n.t('COMMON_SUMMARY_LOCATIONDETAILS')}
                description={i18n.t('COMMON_SUMMARY_LOCATIONMANAGEMENT')}
            />
            <div className="row d-flex justify-content-center mb-3">
                <div className="col-md-7 col-11 justify-content-center">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Input
                                type="text"
                                value={locationData.name}
                                onChange={(e) => {
                                    setLocationData({ ...locationData, name: e.target.value });
                                    handleDirty();
                                }}
                                labelProps={{
                                    message: "LOCATION_LABEL_NAME",
                                    mandatory: true
                                }}
                                placeholder={i18n.t("LOCATION_LABEL_NAME").toString()}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                type="text"
                                value={locationData.shortDescription}
                                onChange={(e) => {
                                    setLocationData({ ...locationData, shortDescription: e.target.value });
                                    handleDirty();
                                }}
                                inputProps={{
                                    maxLength: 500
                                }}
                                labelProps={{
                                    message: "LOCATION_LABEL_SHORTDESCRIPTION",
                                    mandatory: true
                                }}
                                placeholder={i18n.t("LOCATION_LABEL_SHORTDESCRIPTION").toString()}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                type="text"
                                value={locationData.brandName}
                                onChange={(e) => {
                                    setLocationData({ ...locationData, brandName: e.target.value });
                                    handleDirty();
                                }}
                                inputProps={{
                                    maxLength: 100
                                }}
                                labelProps={{
                                    message: "LOCATION_LABEL_BRANDNAME",
                                    mandatory: true
                                }}
                                placeholder={i18n.t("LOCATION_LABEL_BRANDNAME").toString()}
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <CustomSelect
                                labelProps={{
                                    message: "LOCATION_LABEL_LOCATIONTYPE"
                                }}
                                value={data?.find((val) => val.flag === locationData.locationTypeFlags as any)}
                                options={data || []}
                                getOptionLabel={(val) => i18n.t(val.label)}
                                getOptionValue={(val) => val.flag}
                                placeholder={i18n.t("LOCATION_LABEL_LOCATIONTYPE")}
                                onChange={(e: any) => {
                                    setLocationData({ ...locationData, locationTypeFlags: e?.flag as any })

                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <DialogLandlordSelector
                                open={landlordOpen}
                                handleConfirm={(landlord) => {
                                    setLocationData({
                                        ...locationData,
                                        landlordMemberId: landlord.id,
                                        landlordName: landlord.fullName
                                    });
                                    handleDirty();
                                    setLandlordOpen(false);
                                }}
                                handleCancel={() => { setLandlordOpen(false) }}
                            />

                            <Box position="relative">
                                <Input
                                    labelProps={{
                                        message: i18n.t("LOCATION_LABEL_LANDLORD").toString(),
                                    }}
                                    placeholder={i18n.t("LOCATION_LABEL_LANDLORD").toString()}
                                    sx={{ input: { paddingRight: "3rem !important" } }}
                                    readOnly
                                    value={locationData.landlordName}
                                    onClick={() => setLandlordOpen(true)}
                                    rightIcon={
                                        <>
                                            {
                                                (locationData.landlordMemberId !== ApplicationConfig.emptyGuid && !isNil(locationData.landlordMemberId)) &&
                                                <IconButton
                                                    type="button"
                                                    onClick={() => {
                                                        setLocationData({
                                                            ...locationData,
                                                            landlordMemberId: ApplicationConfig.emptyGuid,
                                                            landlordName: ""
                                                        });
                                                        handleDirty();
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            }
                                            <IconButton
                                                type="button"
                                                onClick={() => setLandlordOpen(true)}
                                            >
                                                <SearchIcon />
                                            </IconButton>
                                        </>
                                    }
                                ></Input>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <ParentMemberSelector
                                flagType={512}
                                legalEntityMemberId={locationData.legalEntityMemberId}
                                handleConfirm={handleConfirm}
                                operatorId={locationData.operatorMemberId}
                                labelProps={{
                                    message: 'LOCATION_LABEL_LEGALENTITY',
                                    mandatory: false,
                                    helpMessage: 'COMMON_LABEL_HELP_SELECTLEGALENTITY'
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                type="text"
                                value={locationData.internalIdentifier}
                                onChange={(e) => {
                                    setLocationData({ ...locationData, internalIdentifier: e.target.value });
                                    handleDirty();
                                }}
                                inputProps={{
                                    maxLength: 100
                                }}
                                labelProps={{
                                    message: "LOCATION_LABEL_INTERNALREFERENCE",
                                    mandatory: false,
                                    helpMessage: 'LOCATION_LABEL_HELP_INTERNALREFERENCE'
                                }}
                                placeholder={i18n.t("LOCATION_LABEL_INTERNALREFERENCE").toString()}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CountryDropDown
                                valueChanged={changeCountry}
                                inputCountry={locationData.countryIso3Code}
                                labelProps={{
                                    message: 'COMMON_LABEL_COUNTRY',
                                    mandatory: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AddressContainer
                                copyaddressCallback={copyAddressFromOperator}
                                source={locationData.operatorMemberId ? 'location' : ''}
                                collapseStatus={isCollapseOpen}
                                collapseCallback={() => setIsCollapseOpen(!isCollapseOpen)}
                                address={addressData}
                                handleDirty={handleDirty}
                                callback={setAddress}
                                country={locationData.countryIso3Code}
                                originalAddress={location.address!}
                            />
                        </Grid>
                        <Grid container item justifyContent={"center"} xs={12}>
                            <Grid item xs={3}>
                                <CustomButton
                                    color={'secondary'}
                                    variant='contained'
                                    type="submit"
                                    disabled={isButtonDisabled()}
                                    fullWidth
                                    loading={submitting}
                                >
                                    <LR localResource="COMMON_LABEL_SAVE" />
                                </CustomButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </form >
    );
};


export { LocationDetails };

