import { useNavigate, useParams } from "react-router-dom";
import { useFetchCommsTypes, useManageCommunication } from "../../hooks/Common/communications";
import { useDirtyStateV2 } from "../../hooks/Common/dirtyV2";
import { useFetchLocationById } from "../../hooks/Location/location";
import { useFetchMemberById, } from "../../hooks/Member/member";
import i18n from "../../i18n";
import { useSource } from "../../providers/SourceProvider";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { CommonSummaryHeaderProps, SourceEnum } from "../../types/Common/CommonSummaryHeaderProps";
import { CommunicationTypeDto } from "../../types/Common/CommunicationTypeDto";
import { LocationResponseDto } from "../../types/Location/LocationResponseDto";
import { MemberResponseDto } from "../../types/Member/MemberResponseDto";
import { CommunicationTypeCollection } from "../Common/CommunicationTypeCollection";
import { ConfirmationChangesDialog } from "../Common/ConfirmationChangesDialog";
import ApiStatus from "../Common/Errors/ApiStatus";
import { SectionHead } from "../Common/Help/SectionHead";

type Args = {
    userLogged: ApplicationUser,
}

const keyTypeList: any = {
    [SourceEnum.locations]: 'Location',
    [SourceEnum.legalEntity]: 'Member',
    [SourceEnum.operators]: 'Member',
    [SourceEnum.companies]: 'Member',
    [SourceEnum.landlords]: 'Member',
}
const baseUrlList: any = {
    [SourceEnum.locations]: '/locations',
    [SourceEnum.legalEntity]: '/legal-entities',
    [SourceEnum.operators]: '/operators',
    [SourceEnum.companies]: '/companies',
    [SourceEnum.landlords]: '/landlords',
}

const HostCommunicationTypes = ({ userLogged }: Args) => {
    const { source: _source } = useSource()
    const source = _source

    const keyType = keyTypeList[source]
    const baseUrl = baseUrlList[source]

    const nav = useNavigate();

    const { id, communicationType } = useParams();
    const { mutate } = useManageCommunication();
    const { setDirty, showConfirmation, handleDirtyAction, cancelDirtyAction, confirmDirtyAction } = useDirtyStateV2();

    if (!id) throw Error(`${source} id needed`);
    if (!communicationType) throw Error("Communication Type is needed");

    const handleSubmitData = (items: CommunicationTypeDto[]) => {
        mutate([id!, communicationType!, items, keyType], {
            onSuccess: () => {
                nav(`${baseUrl}/${id}/summary/communications`)
            }
        });
    }

    const handleDirty = () => {
        setDirty();
    };

    const isMemberType = [SourceEnum.operators, SourceEnum.landlords, SourceEnum.legalEntity, SourceEnum.companies].includes(source)
    const isLocationType = [SourceEnum.locations].includes(source)

    const {
        data: memberData,
        status: memberDataStatus,
        isSuccess: memberDataIsSuccess
    } = useFetchMemberById(id!, {
        enabled: isMemberType
    })

    const {
        data: locationData,
        status: locationDataStatus,
        isSuccess: locationDataIsSuccess
    } = useFetchLocationById(id!, {
        enabled: isLocationType
    })

    const { data, status, isSuccess } = useFetchCommsTypes(id!, communicationType!, keyType);

    let communicationData = null
    let communicationDataStatus = null
    let communicationDataIsSuccess = null
    let headerTitle = null

    if (isMemberType) {
        communicationData = memberData as MemberResponseDto
        communicationDataStatus = memberDataStatus
        communicationDataIsSuccess = memberDataIsSuccess
    }
    if (isLocationType) {
        communicationData = locationData as LocationResponseDto
        communicationDataStatus = locationDataStatus
        communicationDataIsSuccess = locationDataIsSuccess
    }

    if ((!isSuccess || !communicationDataIsSuccess) && (status !== 'success') && (communicationDataStatus !== 'success')) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <ApiStatus status={status} />
                </div>
            </div>
        </>
    )

    if (!data || !communicationData) return <ApiStatus status={status} />;

    let commTypeLabel = `COMMON_LABEL_${communicationType?.toUpperCase()}`

    const handleBackLinkCall = () => {
        let url = `${baseUrl}/${id}/summary/communications`;

        handleDirtyAction(() => nav(url));
    }

    if (isMemberType) {
        headerTitle = `${memberData?.fullName} (${communicationData.externalReference})`
    }
    if (isLocationType) {
        headerTitle = `${locationData?.name} (${communicationData.externalReference})`
    }

    let headerProps: Record<any, CommonSummaryHeaderProps> = {
        [SourceEnum.operators]: {
            description: i18n.t('COMMON_SUMMARY_OPERATORMANAGEMENT')!,
        },
        [SourceEnum.legalEntity]: {
            description: i18n.t('COMMON_SUMMARY_LEGALENTITYMANAGEMENT')!,
        },
        [SourceEnum.companies]: {
            description: i18n.t('COMMON_SUMMARY_COMPANYMANAGEMENT')!,
        },
        [SourceEnum.locations]: {
            description: i18n.t('COMMON_SUMMARY_LOCATIONMANAGEMENT')!,
        },
        [SourceEnum.landlords]: {
            description: i18n.t('COMMON_SUMMARY_LANDLORDMANAGEMENT')!,
        }
    }
    let headerData = headerProps[source]

    return (
        <>
            <ConfirmationChangesDialog
                handleConfirm={confirmDirtyAction}
                handleCancel={cancelDirtyAction}
                showConfirmation={showConfirmation}
            />
            <SectionHead
                linkCallBack={handleBackLinkCall}
                ctaText={i18n.t('COMMON_SUMMARY_COMMUNICATIONS')!}
                name={headerTitle!}
                title={i18n.t(commTypeLabel!)!}
                description={headerData.description!}
            />
            <div className="row d-flex justify-content-center mt-1 mb-3">
                <div className="col-md-7 col-11">
                    <CommunicationTypeCollection
                        handleDirty={handleDirty}
                        items={data}
                        submitData={handleSubmitData}
                    />
                </div>
            </div>

        </>
    );
};


export { HostCommunicationTypes };

