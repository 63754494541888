
import { Box, DialogContent, DialogTitle } from "@mui/material";
import { CustomerSearchItemResponseDto } from "../../types/Customer/CustomerSearchItemResponseDto";
import { LR } from "../Common/Help/LR";
import { CustomDialog } from "../Common/UI/CustomDialog";
import { DataGrid, GridColDef, GridRowParams, GridToolbar } from "@mui/x-data-grid";
import { useFetchMembers } from "../../hooks/Member/member";
import i18n from "../../i18n";
import { MemberResponseDto } from "../../types/Member/MemberResponseDto";


type Args = {
  open: boolean;
  handleConfirm: (selectedLandlord: MemberResponseDto) => void,
  handleCancel: () => void
}

export const DialogLandlordSelector = ({ handleCancel, open, handleConfirm }: Args) => {

  const { data: members } = useFetchMembers(16384, "", 2);

  const columns: GridColDef[] = [
    { field: 'fullName', headerName: i18n.t('MEMBER_LABEL_NAME')!, flex: 1 },
    { field: 'countryIso3Code', headerName: i18n.t('COMMON_LABEL_COUNTRY')!, flex: 1 },
  ]

  const handleRowSelection = (params: GridRowParams<MemberResponseDto>) => {
    handleConfirm(params.row)
  };

  return (
    <CustomDialog open={open} onClose={handleCancel} maxWidth="md" fullWidth>
      <DialogTitle>
        <LR localResource="COMMON_LABEL_SELECTLANDLORD"></LR>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ height: 500, width: '100%' }}>
          <DataGrid
            rows={members || []}
            columns={columns}
            classes={{
              toolbarContainer: 'toolbarContainer'
            }}
            slots={{
              toolbar: GridToolbar,
            }}
            checkboxSelection={false}
            disableColumnSelector={true}
            disableDensitySelector={true}
            sx={{
              minHeight: 200
            }}
            onRowClick={handleRowSelection}
          />
        </Box>
      </DialogContent>
    </CustomDialog>
  );
};
