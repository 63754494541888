import { Box, SxProps, Theme } from '@mui/material';
import { forwardRef } from 'react';
interface IconProps {
  name: string;
  size?: number;
  className?: string;
  sx?: SxProps<Theme>
}

// Use forwardRef to ensure compatibility with refs
export const MaterialIcon = forwardRef<HTMLButtonElement, IconProps>(
  ({ name, size = 24, className, sx }, ref) => (
    <Box
      component={'span'}
      className={`material-symbols-outlined material-icons ${className}`}
      sx={{
        fontSize: size,
        fontVariationSettings: "'FILL' 0, 'wght' 200, 'GRAD' 0, 'opsz' 48;",
        display: 'inline-block',
        ...sx
      }}
      ref={ref}
    >
      {name}
    </Box>
  )
);

// Provide a display name for debugging purposes
MaterialIcon.displayName = 'MaterialIcon';
