import axios, { AxiosError, AxiosResponse } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import Problem from "../../types/Problem";
import { CommunicationTypeDto } from "../../types/Common/CommunicationTypeDto";
import { HookConfig } from "../../config/HookConfig";
import { operatorDefaultHeaders } from "../../functions/api/api";
import { ApplicationConfig } from "../../config/ApplicationConfig";


const useManageCommunication = () => {
  const queryClient = useQueryClient();
  const nav = useNavigate();
  const manageCommunication = async (
    [id, comunicationType, items, entityName]: [string, string, CommunicationTypeDto[], string]
  ): Promise<AxiosResponse<any, any>[]> => {

    const endpoint = (entityName === 'Member') ? HookConfig.memberUrl : HookConfig.locationUrl;

    const promises = items
      .filter(item => item.value.trim() !== '')
      .map(async (item) => {
        const url = item.id === ApplicationConfig.emptyGuid ? `${endpoint}/${id}/communications` : `${endpoint}/${id}/communications/${item.id}`;
        const method = item.id === ApplicationConfig.emptyGuid ? "post" : "put";

        const body = item.id === ApplicationConfig.emptyGuid ?
          { memberId: id, locationid: id, communicationTypeName: item.communicationTypeName, value: item.value } :
          { memberId: id, locationid: id, id: item.id, value: item.value }

        const response = await axios[method](
          url, body,
          {
            withCredentials: true,
            headers: operatorDefaultHeaders(),
          }
        );
        return response;
      });
    const responses = await Promise.all(promises);
    return responses;
  };

  const mutation = useMutation<
    AxiosResponse<any, any>[],
    AxiosError<Problem>,
    [string, string, CommunicationTypeDto[], string]
  >(manageCommunication, {
    onSuccess: (responses, [id, comunicationType, entities, entityName]) => {
      switch (entityName) {
        case "Member":
          queryClient.invalidateQueries(["member-summary", id, comunicationType]);
          break;
        case "Location":
          queryClient.invalidateQueries(["location-summary", id, comunicationType]);
          break;
      }

    },
  });

  return mutation;
};

const useFetchCommsTypes = (id: string, comunicationType: string, entityName: string) => {

  const endpoint = (entityName === 'Member') ? HookConfig.memberUrl : HookConfig.locationUrl;
  console.log(endpoint)

  return useQuery<CommunicationTypeDto[], AxiosError>([(entityName === 'Member') ? "member-summary" : "location-summary", id, comunicationType], () =>
    axios.get(`${endpoint}/${id}/communications?group=${comunicationType}`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      })
      .then(
        (response) => {
          return response.data;
        }
      ));
};

export {
  useManageCommunication,
  useFetchCommsTypes
}
