import { FormControlLabel, FormGroup, Grid, Switch } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useDirtyStateV2 } from '../../hooks/Common/dirtyV2';
import { getLabelsByOperatorType, useFetchMemberById, useUpdateMember } from '../../hooks/Member/member';
import i18n from "../../i18n";
import { useSource } from '../../providers/SourceProvider';
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { AddressDto } from "../../types/Common/AddressDto";
import { SourceEnum } from '../../types/Common/CommonSummaryHeaderProps';
import { MemberResponseDto } from '../../types/Member/MemberResponseDto';
import AddressContainer from '../Common/Address/AddressContainer';
import { ConfirmationChangesDialog } from '../Common/ConfirmationChangesDialog';
import CountryDropDown from "../Common/CountryDropDown";
import ApiStatus from '../Common/Errors/ApiStatus';
import MessageAlert from '../Common/Errors/MessageAlert';
import { Input } from '../Common/formComponents/Input';
import { LR } from "../Common/Help/LR";
import { SectionHead } from '../Common/Help/SectionHead';
import { CustomButton } from '../Common/UI/CustomButton';
import { showErrorMessage, showSuccessMessage } from '../Common/UI/CustomSnackbar';
import { LocationSelector } from '../Location/LocationSelector';
import { LocationResponseDto } from '../../types/Location/LocationResponseDto';

type Args = {
    userLogged: ApplicationUser
}

const CompanyDetails = ({ userLogged }: Args) => {
    const nav = useNavigate();
    const params = useParams();

    const { source } = useSource()

    if (!params.id) throw Error("Member id needed");

    //#region Empty Entities
    const emptyMemberResponseDto: MemberResponseDto = {
        id: '',
        memberTypeFlags: 4,
        fullName: '',
        languageCode: '',
        countryIso3Code: '',
        person: null,
        summaries: [],
        contactDetails: null
    };

    const emptyAddressDTO: AddressDto = {
        line1: '',
        countryIso3Code: '',
        languageCode: i18n.language,
        addressTypeName: 'Business',
        entityKey: '',
        entityName: '',
        city: '',
        area: ''
    }

    let addressPassed: AddressDto = {
        line1: '',
        countryIso3Code: '',
        languageCode: i18n.language,
        addressTypeName: 'Business',
        entityKey: '',
        entityName: 'Member',
        city: '',
        area: '',
    };

    const success = () => {
        showSuccessMessage()
    }

    const key: any = {
        [SourceEnum.operators]: "Operator",
        [SourceEnum.landlords]: "Landlord",
    }

    const { setDirty, showConfirmation, handleDirtyAction, cancelDirtyAction, confirmDirtyAction, unsetDirty } = useDirtyStateV2();

    const [memberData, setMemberData] = useState<MemberResponseDto>(emptyMemberResponseDto);
    const [addressData, setAddressData] = useState<AddressDto>(emptyAddressDTO);
    const [countryData, setCountryData] = useState<string>('');
    const [isCollapseOpen, setIsCollapseOpen] = useState(false);

    const { data: member, status, isSuccess } = useFetchMemberById(params.id)
    const { mutate, isLoading: submitting } = useUpdateMember(key[source], undefined, success);

    useEffect(() => {
        if (isSuccess && member) {
            if (!member.address) {
                addressPassed.countryIso3Code = member.countryIso3Code;
                addressPassed.entityKey = member.id;
                setAddressData(addressPassed);
                setIsCollapseOpen(true);
            } else {
                setAddressData(member.address);
            }

            setMemberData(member);
            setCountryData(member.countryIso3Code);
        }
    }, [isSuccess, member]);

    const linkCallBack = () => {
        let url = `/${source}/${memberData.id}/summary`;
        handleDirtyAction(() => nav(url));
    }

    //#region changeState
    const setAddress = (value: AddressDto) => {
        if (value !== null && value !== undefined) {
            addressData.id = value.id;
            addressData.latitude = value.latitude;
            addressData.longitude = value.longitude;
            addressData.line1 = value.line1;
            addressData.line2 = value.line2;
            addressData.line3 = value.line3;
            addressData.area = value.area;
            addressData.city = value.city;
            addressData.zip = value.zip;
            addressData.countryIso3Code = value.countryIso3Code;
            setAddressData({ ...addressData, line1: addressData.line1 });
        };
    }

    const changeCountry = (value: string) => {
        setCountryData(value);
        setAddressData({ ...addressData, countryIso3Code: value });
        setMemberData({ ...memberData, countryIso3Code: value })
    }

    const handleDirty = () => {
        setDirty();
    };

    const confirmLocation = (location: LocationResponseDto) => {
        setMemberData({ ...memberData, defaultLocationId: location.id });
    }

    const setApplyToLocations = () => {
        setMemberData({ ...memberData, applyLocationToContacts: !memberData.applyLocationToContacts });
    }

    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            unsetDirty();
            addressData.entityKey = memberData.id;
            addressData.entityName = "Member";
            memberData.address = addressData;
            if (addressData.countryIso3Code !== memberData.countryIso3Code) {
                memberData.countryIso3Code = addressData.countryIso3Code;
            }
            mutate(memberData, {
                onError: (err) => {
                    showErrorMessage(err.response?.data)
                }
            });
        } catch (err: any) {
            console.error(err);
        }
    }

    const isButtonDisabled = () => (
        !addressData.line1 ||
        !addressData.city ||
        !addressData.countryIso3Code ||
        !addressData.zip ||
        addressData.line1.length < 3 ||
        addressData.city.length < 3 ||
        addressData.countryIso3Code.length < 3 ||
        addressData.zip.length < 3 ||
        memberData.fullName.length < 3 ||
        !memberData.fullName ||
        submitting
    )


    if (!isSuccess) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <ApiStatus status={status} />
                </div>
            </div>
        </>
    )

    if (!member) return <MessageAlert message="MEMBER_LABEL_MEMBERNOTFOUND" params={{ param0: params.id }}></MessageAlert>;

    const labels = getLabelsByOperatorType(member);

    return (
        <form onSubmit={onFormSubmit}>
            <ConfirmationChangesDialog
                handleConfirm={confirmDirtyAction}
                handleCancel={cancelDirtyAction}
                showConfirmation={showConfirmation}
            />

            <SectionHead linkCallBack={linkCallBack} ctaText={i18n.t(labels!.cta)!}
                name={`${member.fullName} (${member.externalReference})`}
                title={i18n.t('COMMON_SUMMARY_COMPANYDETAILS')}
                description={i18n.t(labels!.label)} />

            <div className="row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Input type="text"
                                value={memberData.fullName}
                                onChange={(e) => {
                                    setMemberData({ ...memberData, fullName: e.target.value });
                                    handleDirty();
                                }}
                                placeholder={i18n.t("MEMBER_LABEL_COMPANYNAME").toString()}
                                labelProps={{
                                    message: "MEMBER_LABEL_COMPANYNAME",
                                    mandatory: true
                                }}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CountryDropDown
                                valueChanged={changeCountry}
                                inputCountry={memberData.countryIso3Code}
                                labelProps={{
                                    message: 'COMMON_LABEL_COUNTRY',
                                    mandatory: true
                                }}
                            />
                        </Grid>
                        {member.memberTypeName === 'Company' &&
                            <>
                                <Grid item xs={9}>
                                    <LocationSelector
                                        locationId={member.defaultLocationId!}
                                        placeholder="MEMBER_LABEL_DEFAULTLOCATION"
                                        operatorId={userLogged.operator?.id!}
                                        handleConfirm={confirmLocation}
                                        labelProps={{
                                            message: 'MEMBER_LABEL_DEFAULTLOCATION',
                                            mandatory: false,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormGroup className="d-flex justify-content-center mt-4">
                                        <FormControlLabel
                                            control={<Switch color="secondary" />}
                                            label={i18n.t("MEMBER_LABEL_APPLYLOCATION")}
                                            checked={memberData.applyLocationToContacts ?? false}
                                            onChange={setApplyToLocations}
                                        />
                                    </FormGroup>
                                </Grid>
                            </>
                        }

                        <Grid item xs={12}>
                            <AddressContainer
                                collapseStatus={isCollapseOpen}
                                collapseCallback={() => setIsCollapseOpen(!isCollapseOpen)}
                                address={addressData}
                                handleDirty={handleDirty}
                                callback={setAddress}
                                country={member.countryIso3Code}
                                originalAddress={member.address!}
                            />
                        </Grid>
                        <Grid item container justifyContent={'center'} xs={12}>
                            <Grid item xs={12} md={4}>
                                <CustomButton
                                    disabled={isButtonDisabled()}
                                    type="submit"
                                    color='secondary'
                                    variant="contained"
                                    loading={submitting}
                                    fullWidth
                                >
                                    <LR localResource="COMMON_LABEL_SAVE"></LR>
                                </CustomButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </form>
    );
};


export { CompanyDetails };

