import { Box, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useReinstatedOrder } from "../../../../hooks/Order/useOrder";
import i18n from "../../../../i18n";
import { colors } from "../../../../main/Theme";
import { LR } from "../../../Common/Help/LR";
import { CustomButton } from "../../../Common/UI/CustomButton";
import { CustomDialog } from "../../../Common/UI/CustomDialog";
import { showSnackbar } from "../../../Common/UI/CustomSnackbar";
import { MaterialIcon } from "../../../Common/UI/MaterialIcon";
import '../OrderForm.css';
import { useOrderContext } from "../OrderProvider";


type Reinstated = {
  open: boolean
  onClose: () => void

}

export const DialogReinstated = ({ open, onClose, }: Reinstated) => {
  const nav = useNavigate()
  const { mutate: reinstatedOrder, isLoading: isReInsistingOrder } = useReinstatedOrder()
  const { basketId } = useOrderContext()

  const reinstated = () => {
    reinstatedOrder({ basketid: basketId }, {
      onSuccess: () => {
        onClose()
        nav(`/orders/basket/${basketId}`)
        showSnackbar(i18n.t("SUCCESS_REINSTATE_ORDER"))
      }, onError: (error: any) => {
        showSnackbar(error.response?.data, {
          persist: true,
          variant: 'error'
        })
      }
    })
  }

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      scroll="body"
    >
      <DialogTitle sx={{ p: 1 }}>
        <LR localResource={i18n.t("COMMON_LABEL_REINSTATE")}></LR>
      </DialogTitle>
      <DialogContent sx={{ p: 1 }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }} >
          <MaterialIcon
            sx={{
              color: colors.primary,
            }} name="warning" size={80} />
          <Typography variant="body1" color="textSecondary">
            <LR localResource={i18n.t("LABEL_REINSTATE_ORDER")}></LR>
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 1, pt: 4 }}>
        <CustomButton variant="text" onClick={onClose} color="secondary">
          <LR localResource='COMMON_BUTTON_CANCEL' />
        </CustomButton>
        <CustomButton variant="contained" onClick={() => reinstated()} loading={isReInsistingOrder} color="primary" autoFocus>
          <LR localResource='COMMON_BUTTON_CONFIRM' />
        </CustomButton>
      </DialogActions>
    </CustomDialog>
  );
};



