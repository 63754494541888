import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { Box, Grid, Paper, Switch, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import { cloneDeep, isNil } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { pontDurations } from '../../data/en/pontDurations';
import { pontAllowedActions } from '../../data/inventory/pontAllowedActions';
import { ApplicationConfig } from '../../config/ApplicationConfig';
import { useDirtyStateV2 } from '../../hooks/Common/dirtyV2';
import { useFetchNetworksByOperator } from '../../hooks/Location/networks';
import { useFetchMembers } from '../../hooks/Member/member';
import { useFetchPortfolioPrices, useFetchPortfolios } from '../../hooks/Product/portfolios';
import {
    useFetchProductById,
    useFetchProductType,
    useFetchRulesByProductId,
    useManageProduct,
    useManageProductPortfolioPrices
} from '../../hooks/Product/product';
import i18n from "../../i18n";
import { colors } from '../../main/Theme';
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { OperationResultDto } from '../../types/Common/OperationResultDto';
import { SummaryItemDto } from '../../types/Common/SummaryItemDto';
import { PriceDto } from '../../types/Price/PriceDto';
import { ProductResponseDto } from '../../types/Product/ProductResponseDto';
import { ProductSubscriptionDto } from '../../types/Product/ProductSubscriptionDto';
import { ExtendedProductTypeResponseDto } from '../../types/Product/ProductTypeResponseDto';
import { ConfirmationChangesDialog } from '../Common/ConfirmationChangesDialog';
import { CustomDatePicker } from '../Common/formComponents/CustomDatepicker';
import { CustomSelect } from '../Common/formComponents/CustomSelect';
import { Input } from '../Common/formComponents/Input';
import { Label } from '../Common/formComponents/Label';
import { LR } from "../Common/Help/LR";
import { SectionHead } from '../Common/Help/SectionHead';
import { PricesCollection } from '../Common/Prices/PricesCollection';
import { SummaryItemCollection } from '../Common/SummaryItems/SummaryItemCollection';
import { CustomButton } from '../Common/UI/CustomButton';
import { showSnackbar } from '../Common/UI/CustomSnackbar';
import { ProductBenefits } from './ProductBenefits';
import { ProductRules } from './ProductRules';
import yup from '../../functions/utils/yup';

type Args = {
    userLogged: ApplicationUser,
}

type productDetailDto = Omit<ProductResponseDto, 'subscription'>

const validateWithYup = async (schema: any, data: any) => {
    try {
        await schema.validate(data, { abortEarly: false });
        return {
            values: data,
            errors: null
        }
    } catch (err: any) {
        if (err.inner) {
            const errors = err.inner.reduce((acc: any, curr: any) => {
                acc[curr.path] = curr.message;
                return acc;
            }, {});
            return { values: null, errors };
        }
        throw err;
    }
};

export const ProductDetails = ({ userLogged }: Args) => {
    const nav = useNavigate();

    const defaultProductSubscription: ProductSubscriptionDto = {
        headline: '',
        supplierLegalEntityMemberId: '',
        isTrialPeriodEnabled: false,
        trialPeriodQuantity: 0,
        trialPeriodUnitName: '',
        soldByNetworkId: '',
        useAtNetworkId: ''
    }

    const defaultProduct: productDetailDto = {
        id: '',
        name: '',
        description: '',
        operatorMemberId: userLogged.operator!.id,
        productTypeName: 'Subscription',
        fromDate: new Date().toISOString(),
        toDate: undefined as any,
        externalReference: '',
        internalIdentifier: null,
        isInUse: true,
        isDisabled: false,
        productRuleCount: 0,
        inventoryVariantCount: 0,
        applicationGroupCount: 0,
        locationNetworkCount: 0,
        summaries: [],
        shortDescription: '',
        summarisedBenefits: [],
        allowedActionFlags: null,
        soldByNetworkId: null
    };

    const { productId } = useParams();
    const isEdit = Boolean(productId)

    const [productDetail, setProductDetail] = useState<productDetailDto>(defaultProduct);
    const [subscriptionDetail, setSubscriptionDetail] = useState<ProductSubscriptionDto>(defaultProductSubscription);

    const [selectedProductType, setSelectedProductType] = useState<ExtendedProductTypeResponseDto | undefined>();
    const pontDurationOptions = pontDurations.map(x => ({ label: x.label, value: x.id.toString() }));
    const [selectedDuration, setSelectedDuration] = useState(pontDurationOptions[0]);
    const [legalEntityOptions, setLegalEntityOptions] = useState<{ label: string, value: string }[]>();
    const [selectedLegalEntity, setSelectedLegalEntity] = useState<{ label: string, value: string }>();

    const [networkOptions, setNetworkOptions] = useState<{ label: string, value: string }[]>();
    const [soldFromNetworkOptions, setSoldFromNetworkOptions] = useState<{ label: string, value: string }[]>();
    const [selectedSaleNetwork, setSelectedSaleNetwork] = useState<{ label: string, value: string }>();
    const [selectedUseNetwork, setSelectedUseNetwork] = useState<{ label: string, value: string }>();

    const [portfolioOptions, setPortfolioOptions] = useState<{ label: string, value: string }[]>();
    const [selectedPortfolio, setSelectedPortfolio] = useState<{ label: string, value: string }>();
    const [allowedActions, setAllowedActions] = useState<number[]>([]);

    const [error, setError] = useState<any>(undefined);

    const [pricesList, setPricesList] = useState<PriceDto[]>();

    const { setDirty, showConfirmation, cancelDirtyAction, confirmDirtyAction, handleDirtyAction, unsetDirty } = useDirtyStateV2();

    const productDetailSchema = yup.object({
        name: yup.string().required(i18n.t('VALIDATION_PRODUCTNAME_REQUIRED').toString()),
        shortDescription: yup.string().required(i18n.t('VALIDATION_SHORTDESCRIPTION_REQUIRED').toString()),
        fromDate: yup
            .date()
            .required(i18n.t('VALIDATION_FROMDATE_REQUIRED').toString()),
        toDate: yup
            .date()
            .required(i18n.t('VALIDATION_TODATE_REQUIRED').toString())
            .test(
                'is-greater-than-fromDate',
                i18n.t('VALIDATION_TODATE_GREATER_THAN_FROMDATE').toString(),
                function (value) {
                    const { fromDate } = this.parent;
                    return value >= new Date(fromDate);
                }
            ),
        isSubscription: yup.boolean(),
        headLine: yup.string().when(['isSubscription'], {
            is: (isSubscription: any) => isSubscription,
            then: schema => schema.required(i18n.t('VALIDATION_HEADLINE_REQUIRED').toString()),
            otherwise: schema => schema
        }),
    })
    const { data: product } = useFetchProductById(productId || '', isEdit, {
        onSuccess: (product) => {
            if (product) {
                if (product.summarisedBenefits === null) {
                    product.summarisedBenefits = [];
                }
                let productType = productTypeList?.find(x => x.name === product.productTypeName);
                if (productType) {
                    setSelectedProductType(productType);
                }
                if (product.productTypeName !== 'Subscription' && !product.shortDescription) {
                    product.shortDescription = '';
                }
                const { subscription, ...rest } = product;

                setProductDetail(rest);
                setSubscriptionDetail(subscription);

                setAllowedActions(getInitialAllowedActions(product.allowedActionFlags));
            }
        }
    });

    const { data: rules } = useFetchRulesByProductId(productId || '', isEdit);
    const { data: legalEntities } = useFetchMembers(512, userLogged.operator!.id, 3);
    const { data: networks } = useFetchNetworksByOperator(userLogged.operator!.id, true);
    const { data: portfolios } = useFetchPortfolios(userLogged.operator!.id);
    const { data: prices } = useFetchPortfolioPrices(selectedPortfolio?.value ?? '', productDetail.id ?? '');
    const { mutateAsync: pricesMutation, isLoading: isPricesLoading } = useManageProductPortfolioPrices();

    const { data: productTypeList } = useFetchProductType({
        options: {
            onSuccess: (data) => {
                if (!selectedProductType) {
                    const filteredProductType = data.filter(x => !x.isInventory);
                    setSelectedProductType(filteredProductType[0]);
                    setProductDetail(prev => ({ ...prev, productTypeName: filteredProductType[0].name }));
                }
            }
        }
    });

    const pontProductTypes = useMemo(() => {
        if (!productTypeList) {
            return [];
        }
        return productTypeList.filter(x => isEdit ? x.isInventory : !x.isInventory);
    }, [productTypeList]);

    const [isInventory, isSubscription, isBenefitEnabled] = useMemo(() => {
        if (!selectedProductType) {
            return [false, false, false];
        }
        const _selectedProductType = productTypeList?.find(x => x.name === selectedProductType.name);
        return [
            Boolean(_selectedProductType?.isInventory),
            Boolean(_selectedProductType?.isSubscription),
            Boolean(_selectedProductType?.isBenefitEnabled)
        ];
    }, [selectedProductType, productTypeList]);

    const showSuccessSnackbar = () => {
        showSnackbar(i18n.t('COMMON_LABEL_SAVE_SUCCESS'), {
            variant: 'success'
        });
    }

    const mutationCallBack = (operationResult: OperationResultDto) => {
        if (operationResult.isSuccess) {
            showSuccessSnackbar()
            if (operationResult.data) {
                if (isEdit) {
                    nav(`/products`);
                } else {
                    nav(`/products/${operationResult.data}`);
                }
            }
        } else {
            showSnackbar(operationResult.errorMessage, {
                persist: true,
                variant: 'error'
            });
        }
    }

    const { mutateAsync: updateMutation, isLoading: isUpdateLoading } = useManageProduct(mutationCallBack);

    const pricesCallBack = (operationResult: OperationResultDto) => {
        if (!operationResult.isSuccess) {
            showSnackbar(operationResult.errorMessage, {
                persist: true,
                variant: 'error'
            });
        }
    }

    const linkCallBackCollection = (item: SummaryItemDto) => {
        switch (item.name) {
            case "DescriptionSummary":
                nav(`/products/${productId}/description`);
                break;
            case "ImageSummary":
            case "DocumentSummary":
                nav(`/products/${productId}/digital-assets/${item.uri.substring(item.uri.indexOf('group=') + 6)}`);
                break;
            default:
                alert(`Item ${item.name} does not have a link yet`);
                break;
        }
    }

    const linkCallBack = () => {
        let url = isEdit ? `/products` : `/products/add-selector`;
        handleDirtyAction(() => nav(url))
    };

    const rulesCallBack = (result: OperationResultDto) => {
        if (result.isSuccess) {
            showSuccessSnackbar()
        } else {
            showSnackbar(result.errorMessage, {
                persist: true,
                variant: 'error'
            });
        }
    }

    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            unsetDirty();
            setError(undefined);
            if (!productDetail) {
                throw Error("Product is not defined");
            }
            const productPayload: any = cloneDeep(productDetail);
            productPayload.soldByNetworkId = selectedSaleNetwork?.value ?? null;

            const { values, errors } = await validateWithYup(productDetailSchema, {
                ...productPayload,
                isSubscription: isSubscription,
                headLine: subscriptionDetail?.headline
            });

            if (values) {
                if (!isSubscription) {
                    productPayload.subscription = null;
                } else {
                    productPayload.subscription = subscriptionDetail
                }

                if (pricesList) {
                    await pricesMutation([pricesList!, pricesCallBack]);
                }

                await updateMutation(productPayload);
            }
            if (errors) {
                setError(errors)
            }

        } catch (err: any) {
            console.error(err);
        }
    }

    const handleProductTypeChange = (selectedOption: any) => {
        setSelectedProductType(selectedOption);

        setProductDetail(prev => ({
            ...prev,
            productTypeName: selectedOption.name
        }));
        setDirty();
    }

    const handleLegalEntityChange = (selectedOption: any) => {
        setSelectedLegalEntity(selectedOption);
        setSubscriptionDetail(prev => ({
            ...prev,
            supplierLegalEntityMemberId: selectedOption.value
        }))
        setDirty();
    }

    const handleUseNetworkChange = (selectedOption: any) => {
        setSelectedUseNetwork(selectedOption);
        setSubscriptionDetail(prev => ({
            ...prev,
            useAtNetworkId: selectedOption.value
        }))
        setDirty();
    }

    const handleSaleNetworkChange = (selectedOption: any) => {
        setSelectedSaleNetwork(selectedOption);
        setSubscriptionDetail(prev => ({
            ...prev,
            soldByNetworkId: selectedOption.value
        }))
        setDirty();
    }

    const handlePortfolioChange = (selectedOption: any) => {
        setSelectedPortfolio(selectedOption);
        //setUseProduct({ ...useProduct!, legalEntity: selectedOption.value });
        setDirty();
    }

    const handleToggleTrial = () => {
        setSubscriptionDetail(prev => ({
            ...prev,
            isTrialPeriodEnabled: !prev.isTrialPeriodEnabled
        }))
        setDirty();
    }

    const handleDurationChange = (selectedOption: any) => {
        setSelectedDuration(selectedOption);
        setSubscriptionDetail(prev => ({
            ...prev,
            trialPeriodQuantity: pontDurations[selectedOption.value].quantity,
            trialPeriodUnitName: pontDurations[selectedOption.value].unitName
        }))
        setDirty();
    }

    const handleAllowedActionChange = (value: number) => {
        let x: any = []
        if (allowedActions.includes(value)) {
            x = [...allowedActions.filter(action => action !== value)];
        } else {
            x = [...allowedActions, value];
        }
        setAllowedActions(x);
        setProductDetail(prev => ({
            ...prev,
            allowedActionFlags: x.reduce((acc: any, curr: any) => acc + curr, 0)
        }));
    };

    const addBenefit = () => {
        nav(`/products/${productId}/benefits/add`);
    }

    const handlePrices = (value: PriceDto) => {
        if (pricesList) {
            var changedPrice = pricesList!.find(x => x.unitName === value.unitName);

            if (!changedPrice)
                throw Error("Price not found!");

            changedPrice.currencyCode = value.currencyCode;
            changedPrice.price = value.price;
            changedPrice.fromDate = new Date();
            changedPrice.toDate = new Date('2099-01-01');

            setPricesList((prices) => prices!.map((price) => {
                if (price.unitName === changedPrice!.unitName) {
                    return changedPrice!;
                }
                return price;
            }));

            setDirty();
        }
    };

    const getInitialAllowedActions = (allAllowedFlags?: number | null): number[] => {
        // We will set default value to 1 if the value is not set
        const allowedActionFlags = allAllowedFlags || 1
        const localPontAllowedActions = pontAllowedActions
            .reduce<number[]>((acc, action) => {
                if ((allowedActionFlags & action.flag) !== 0) {
                    acc.push(action.flag);
                }
                return acc;
            }, []);
        return localPontAllowedActions.length > 0 ? localPontAllowedActions : [1];
    };

    useEffect(() => {
        if (prices) {
            setPricesList(prices);
        }
    }, [prices])

    useEffect(() => {
        if (legalEntities && legalEntities.length > 0) {
            setLegalEntityOptions(legalEntities.map(x => ({ label: x.fullName, value: x.id })));
            let legalEntityIndex = 0;
            if (isEdit && product && product.subscription && product.subscription?.supplierLegalEntityMemberId) {
                legalEntityIndex = legalEntities.findIndex(x => x.id === product.subscription?.supplierLegalEntityMemberId);
            }

            setSelectedLegalEntity({
                label: legalEntities[legalEntityIndex].fullName,
                value: legalEntities[legalEntityIndex].id
            });
            if (!product) {
                setSubscriptionDetail(prev => ({
                    ...prev,
                    supplierLegalEntityMemberId: legalEntities[legalEntityIndex].id
                }))
            }
        }
    }, [legalEntities, product]);

    useEffect(() => {
        if (networks) {
            let soldByNetworkIndex = 0;
            let useNetworkIndex = 0;
            let mappedNetworkOptions = networks.map(x => ({ label: x.name, value: x.id }));
            let soldFromNetworkOptions = [{
                label: 'None',
                value: ApplicationConfig.emptyGuid
            }, ...mappedNetworkOptions];
            if (isEdit && product && product.subscription && product.subscription.useAtNetworkId && product.subscription.soldByNetworkId) {
                useNetworkIndex = mappedNetworkOptions.findIndex(x => x.value === product.subscription.useAtNetworkId);
                soldByNetworkIndex = soldFromNetworkOptions.findIndex(x => x.value === product.subscription.soldByNetworkId);
            }
            setNetworkOptions(mappedNetworkOptions);
            setSoldFromNetworkOptions(soldFromNetworkOptions);
            setSelectedUseNetwork(mappedNetworkOptions[useNetworkIndex]);
            setSelectedSaleNetwork(soldFromNetworkOptions[soldByNetworkIndex]);
            if (!product) {
                setSubscriptionDetail(prev => ({
                    ...prev,
                    useAtNetworkId: mappedNetworkOptions[useNetworkIndex].value,
                    soldByNetworkId: soldFromNetworkOptions[soldByNetworkIndex].value
                }))
            }
        }
    }, [networks, product]);

    useEffect(() => {
        if (portfolios) {
            setPortfolioOptions(portfolios.map(x => ({ label: x.name, value: x.id })));
            setSelectedPortfolio({ label: portfolios[0].name, value: portfolios[0].id })
        }
    }, [portfolios, product]);

    useEffect(() => {
        if (product && productTypeList) {
            let productType = productTypeList.filter(x => isEdit ? x.isInventory : !x.isInventory).find(x => x.name === product.productTypeName);
            if (productType) {
                setSelectedProductType(productType);
            }
        }
    }, [product, productTypeList])

    return (
        <form onSubmit={onFormSubmit}>
            <ConfirmationChangesDialog
                handleConfirm={confirmDirtyAction}
                handleCancel={cancelDirtyAction}
                showConfirmation={showConfirmation}
            />

            <SectionHead
                linkCallBack={linkCallBack}
                ctaText={i18n.t("PRODUCTS_SUMMARY_PAGE")!}
                name={`${productDetail.name}`}
                title={i18n.t('COMMON_SUMMARY_PRODUCTDETAILS')}
                description={i18n.t('COMMON_SUMMARY_PRODUCTMANAGEMENT')}
            />

            <div className="row d-flex justify-content-center mb-3">
                <div className="col-md-7 col-11 justify-content-center">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Input
                                type="text"
                                value={productDetail!.name! || ''}
                                onChange={(e) => {
                                    setProductDetail({
                                        ...productDetail!,
                                        name: e.target.value
                                    });
                                    setDirty();
                                }}
                                placeholder={i18n.t("PRODUCT_LABEL_NAME").toString()}
                                labelProps={{
                                    message: i18n.t('PRODUCT_LABEL_NAME'),
                                    mandatory: true
                                }}
                                validationError={error?.name ? { message: error?.name } : undefined}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomSelect
                                value={selectedProductType}
                                getOptionLabel={(option) => { return i18n.t(option.label) }}
                                getOptionValue={(option) => { return option.name }}
                                options={pontProductTypes}
                                onChange={handleProductTypeChange}
                                placeholder={i18n.t("PRODUCT_LABEL_PRODUCTTYPENAME").toString()}
                                labelProps={{
                                    message: i18n.t('PRODUCT_LABEL_PRODUCTTYPENAME'),
                                    mandatory: true
                                }}
                                isDisabled={isEdit}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input type="text"
                                value={productDetail!.shortDescription! || ''}
                                onChange={(e) => {
                                    setProductDetail({
                                        ...productDetail!,
                                        shortDescription: e.target.value
                                    });
                                    setDirty();
                                }}
                                placeholder={i18n.t("PRODUCT_LABEL_SHORTDESCRIPTION").toString()}
                                labelProps={{
                                    message: i18n.t('PRODUCT_LABEL_SHORTDESCRIPTION'),
                                    mandatory: true
                                }}
                                validationError={error?.shortDescription ? { message: error?.shortDescription } : undefined}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                type="text"
                                value={productDetail!.internalIdentifier! || ''}
                                onChange={(e) => {
                                    setProductDetail({
                                        ...productDetail!,
                                        internalIdentifier: e.target.value
                                    });
                                    setDirty();
                                }}
                                placeholder={i18n.t("PRODUCT_LABEL_INTERNALREFERENCE").toString()}
                                labelProps={{
                                    message: 'PRODUCT_LABEL_INTERNALREFERENCE',
                                    helpMessage: 'PRODUCT_LABEL_HELP_INTERNALREFERENCE'
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CustomDatePicker
                                value={(isNil(productDetail.fromDate) || productDetail.fromDate.trim() === "") ? null : dayjs(productDetail.fromDate)}
                                onChange={(newValue) => {
                                    setProductDetail({
                                        ...productDetail!,
                                        fromDate: newValue?.isValid() ? newValue?.toISOString()! : ""
                                    });
                                    setDirty();
                                }}
                                labelProps={{
                                    message: i18n.t('PRODUCT_LABEL_FROMDATE'),
                                    mandatory: true
                                }}
                                validationError={error?.fromDate ? { message: error?.fromDate } : undefined}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CustomDatePicker
                                value={(isNil(productDetail.toDate) || productDetail.toDate.trim() === "") ? null : dayjs(productDetail.toDate)}
                                onChange={(newValue) => {
                                    setProductDetail({
                                        ...productDetail,
                                        toDate: newValue?.isValid() ? newValue?.toISOString()! : ""
                                    });
                                    setDirty();
                                }}
                                labelProps={{
                                    message: i18n.t('PRODUCT_LABEL_TODATE'),
                                    mandatory: true
                                }}
                                minDate={dayjs(productDetail.fromDate)}
                                validationError={error?.toDate ? { message: error?.toDate } : undefined}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomSelect
                                value={soldFromNetworkOptions?.find((option) => option.value === productDetail?.soldByNetworkId)}
                                getOptionLabel={(option) => { return i18n.t(option.label) }}
                                getOptionValue={(option) => option.value}
                                options={soldFromNetworkOptions}
                                onChange={handleSaleNetworkChange}
                                placeholder={i18n.t("PRODUCT_LABEL_SOLDLOCATIONS").toString()}
                                labelProps={{
                                    message: i18n.t('PRODUCT_LABEL_SOLDLOCATIONS'),
                                    mandatory: true
                                }}
                            />
                        </Grid>
                        {!isInventory &&
                            <>
                                <Grid item xs={12}>
                                    <CustomSelect
                                        value={selectedPortfolio}
                                        getOptionLabel={(option) => { return i18n.t(option.label) }}
                                        options={portfolioOptions}
                                        onChange={handlePortfolioChange}
                                        placeholder={i18n.t("PRODUCT_LABEL_PORTFOLIO").toString()}
                                        labelProps={{
                                            message: i18n.t('PRODUCT_LABEL_PORTFOLIO'),
                                            mandatory: true
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <PricesCollection
                                        countryIsoCode3={'GBR'}
                                        label={''}
                                        itemsPerRow={1}
                                        handleDirty={() => setDirty()}
                                        callBack={handlePrices}
                                        items={pricesList || []}
                                    />
                                </Grid>

                                {/* Allowed actions */}
                                <Grid item xs={12}>
                                    <Label
                                        message={i18n.t('COMMON_LABEL_ALLOWEDACTIONS')}
                                    />
                                    {pontAllowedActions.filter(item => item.flag !== 1).map((action, index) => {
                                        return (
                                            <Paper
                                                key={index}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    marginBottom: '10px',
                                                    background: colors.grey[100],
                                                    p: 1
                                                }}
                                            >
                                                <label className="form-check-label">{i18n.t(action.label)}</label>
                                                <Switch
                                                    checked={allowedActions.includes(action.flag)}
                                                    onChange={() => { handleAllowedActionChange(action.flag) }}
                                                    size="small"
                                                    color="secondary"
                                                />
                                            </Paper>
                                        )
                                    })}
                                </Grid>
                            </>
                        }
                        {isSubscription &&
                            <>
                                <Grid item xs={12}>
                                    <Input type="text"
                                        value={subscriptionDetail.headline}
                                        onChange={(e) => {
                                            setSubscriptionDetail(prev => ({
                                                ...prev,
                                                headline: e.target.value
                                            }))
                                            setDirty();
                                        }}
                                        placeholder={i18n.t("PRODUCT_LABEL_HEADLINE").toString()}
                                        labelProps={{
                                            message: i18n.t('PRODUCT_LABEL_HEADLINE'),
                                            mandatory: true
                                        }}
                                        validationError={error?.headLine ? { message: error?.headLine } : undefined}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <CustomSelect
                                        value={selectedLegalEntity}
                                        getOptionLabel={(option) => { return i18n.t(option.label) }}
                                        options={legalEntityOptions}
                                        onChange={handleLegalEntityChange}
                                        placeholder={i18n.t("PRODUCT_LABEL_LEGALENTITIES").toString()}
                                        labelProps={{
                                            message: i18n.t('PRODUCT_LABEL_LEGALENTITIES'),
                                            mandatory: true
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <CustomSelect
                                        value={selectedUseNetwork}
                                        getOptionLabel={(option) => { return i18n.t(option.label) }}
                                        options={networkOptions}
                                        onChange={handleUseNetworkChange}
                                        placeholder={i18n.t("PRODUCT_LABEL_USEDLOCATIONS").toString()}
                                        labelProps={{
                                            message: i18n.t('PRODUCT_LABEL_USEDLOCATIONS'),
                                            mandatory: true
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} md={2}>
                                    <Box><Label message='PRODUCT_LABEL_TRIAL' mandatory={true}></Label></Box>
                                    <Switch key={'trial'}
                                        checked={subscriptionDetail.isTrialPeriodEnabled}
                                        onChange={() => handleToggleTrial()}
                                        color="secondary"
                                    />
                                </Grid>
                                <Grid item xs={12} md={10}>
                                    <CustomSelect
                                        value={selectedDuration}
                                        getOptionLabel={(option) => { return i18n.t(option.label) }}
                                        options={pontDurationOptions}
                                        onChange={handleDurationChange}
                                        placeholder={i18n.t("PRODUCT_LABEL_DURATION").toString()}
                                        isDisabled={subscriptionDetail.isTrialPeriodEnabled}
                                        labelProps={{
                                            message: i18n.t('PRODUCT_LABEL_DURATION'),
                                            mandatory: true
                                        }}
                                    />
                                </Grid>
                            </>
                        }

                        {
                            isEdit && isBenefitEnabled &&
                            <>
                                <Grid item xs={12}>
                                    <ProductBenefits
                                        mutationCallBack={rulesCallBack}
                                        items={productDetail.summarisedBenefits!}
                                    ></ProductBenefits>
                                </Grid>

                                <Grid item container justifyContent={'center'} xs={12}>
                                    <Grid item xs={3} mx={'auto'}>
                                        <Tooltip title={i18n.t('BENEFIT_LABEL_NEWBENEFIT')}>
                                            <CustomButton
                                                onClick={() => addBenefit()}
                                                color='secondary'
                                                sx={{ textTransform: 'none', width: '100%' }}
                                            >
                                                <AddCircleRoundedIcon />
                                                <Box sx={{ ml: 1 }}>
                                                    <LR localResource="BENEFIT_LABEL_NEWBENEFIT" />
                                                </Box>
                                            </CustomButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </>
                        }

                        {
                            isEdit && rules && rules.length > 0 &&
                            <Grid item xs={12}>
                                <Label mandatory={false} message='PRODUCT_LABEL_RULESASSOCIATIONS'></Label>
                                <ProductRules mutationCallBack={rulesCallBack} items={rules!}></ProductRules>
                            </Grid>
                        }
                        {
                            isEdit &&
                            <Grid item xs={12}>
                                <SummaryItemCollection
                                    linkCallBack={linkCallBackCollection}
                                    items={productDetail.summaries}
                                ></SummaryItemCollection>
                            </Grid>
                        }
                        <Grid item container justifyContent={'center'} xs={12}>
                            <Grid item xs={3} mx={'auto'}>
                                <CustomButton
                                    type="submit"
                                    color='secondary'
                                    variant='contained'
                                    sx={{
                                        width: '100%'
                                    }}
                                    loading={isUpdateLoading || isPricesLoading}
                                    disabled={isUpdateLoading || isPricesLoading}
                                >
                                    <LR localResource="COMMON_LABEL_SAVE"></LR>
                                </CustomButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </form>
    );
};
