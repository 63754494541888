import { Box, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useState } from "react";
import { GetRowClassName } from "../../functions/utils/helper";
import i18n from "../../i18n";
import { MemberResponseDto } from "../../types/Member/MemberResponseDto";
import { LR } from "../Common/Help/LR";
import { CustomButton } from "../Common/UI/CustomButton";
import { CustomDialog } from "../Common/UI/CustomDialog";

type Args =
  {
    open: boolean
    operators: MemberResponseDto[]
    type: string,
    handleConfirm: (operator: MemberResponseDto) => void,
    handleCancel: () => void
  }

const DialogOperatorSelector = ({ handleCancel, type, handleConfirm, open, operators }: Args) => {

  const [selectedRow, setSelectedRow] = useState<MemberResponseDto | null>(null);

  const handleRowSelection = (params: any) => {
    const selectedOperator = operators.find(x => x.id === params[0])
    setSelectedRow(selectedOperator!);
  };

  const handleRowDoubleClick = (params: any) => {
    const selectedOperator = operators.find(x => x.id === params[0])
    setSelectedRow(selectedOperator!);
    handleValueSelection();
  };

  const handleValueSelection = () => {
    if (selectedRow) {
      handleConfirm(selectedRow);
      handleCancel();
    }
  };

  const label: any = {
    Operator: 'COMMON_LABEL_SELECTOPERATOR',
    LegalEntity: 'COMMON_LABEL_SELECTLEGALENTITY',
    Company: 'COMMON_LABEL_SELECTCOMPANY',
    CompanyContact: 'COMMON_LABEL_SELECTCOMPANYCONTACT'
  }

  return (
    <CustomDialog open={open} onClose={handleCancel} maxWidth="md" fullWidth>
      <DialogTitle>
        <LR localResource={label[type]} />
      </DialogTitle>
      <DialogContent>
        <Box sx={{ height: 400 }}>
          <DataGrid
            rows={operators}
            columns={[
              { field: 'fullName', headerName: i18n.t('MEMBER_LABEL_NAME')!, flex: 1 },
              { field: 'countryIso3Code', headerName: i18n.t('COMMON_LABEL_COUNTRY')!, flex: 1 },
            ]}
            onRowSelectionModelChange={handleRowSelection}
            onRowDoubleClick={handleRowDoubleClick}
            slots={{
              toolbar: GridToolbar
            }}
            classes={{
              toolbarContainer: 'toolbarContainer'
            }}
            getRowClassName={GetRowClassName}
            checkboxSelection={false}
            disableColumnSelector={true}
            disableDensitySelector={true}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={handleCancel} variant='contained' color='info'>
          <LR localResource='COMMON_BUTTON_CANCEL' />
        </CustomButton>
        <CustomButton color={'secondary'} autoFocus onClick={handleValueSelection} variant='contained' disabled={!selectedRow}>
          <LR localResource={'COMMON_LABEL_SELECT'} />
        </CustomButton>
      </DialogActions>
    </CustomDialog>
  );
};


export { DialogOperatorSelector };
