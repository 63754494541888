import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Box, IconButton, } from "@mui/material";
import { useEffect, useState } from "react";
import { ApplicationConfig } from "../../config/ApplicationConfig";
import { useFetchLocations } from "../../hooks/Location/location";
import i18n from "../../i18n";
import { LocationResponseDto } from "../../types/Location/LocationResponseDto";
import ApiStatus from "../Common/Errors/ApiStatus";
import MessageAlert from "../Common/Errors/MessageAlert";
import { Input } from '../Common/formComponents/Input';
import { LabelType } from '../Common/formComponents/Label';
import { DialogLocationSelector } from "./DialogLocationSelector";

type Args = {
  operatorId: string,
  locationId: string,
  placeholder?: string,
  handleConfirm: (operator: LocationResponseDto) => void,
  labelProps?: LabelType;
}

const LocationSelector = ({ operatorId, locationId, placeholder, handleConfirm, labelProps }: Args) => {
  const { data: locations, status, isSuccess } = useFetchLocations(operatorId);
  const [selectedRow, setSelectedRow] = useState<LocationResponseDto | null>(null);
  const [open, setOpen] = useState(false);
  const [locationName, setLocationName] = useState('');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleValueSelection = (location: LocationResponseDto) => {
    setLocationName(location.name)
    handleConfirm(location);
    handleClose();
  };

  const getPlaceholder = () => {
    return i18n.t(placeholder ?? "LOCATION_LABEL_LEGALENTITY").toString();
  }

  const handleChange = () => {
    if (!operatorId || !locations) return;
    if (locationId === ApplicationConfig.emptyGuid || locationId === null) return;

    const selectedLocation = locations.find(x => x.id === locationId);
    if (!selectedLocation) return;

    setSelectedRow(selectedLocation);
    handleValueSelection(selectedLocation);
  }

  useEffect(() => {
    handleChange();
  }, [operatorId, locations, locationId]);

  useEffect(() => {
    //only 1 location. we'll select it automatically
    if (locations?.length === 1) {
      setSelectedRow(locations[0]);
      handleValueSelection(locations[0]);
    }
  }, [locations]);

  if (!isSuccess) return (
    <>
      <div className="mt-5 row d-flex justify-content-center">
        <div className="col-md-7 col-11 justify-content-center">
          <ApiStatus status={status} />
        </div>
      </div>
    </>
  )

  if (!locations) return <MessageAlert message="MEMBER_LABEL_MEMBERSNOTFOUND"></MessageAlert>;

  return (
    <>
      <DialogLocationSelector locations={locations} open={open} handleCancel={handleClose} handleConfirm={handleValueSelection}></DialogLocationSelector>
      <div>
        <Input
          style={{ opacity: 1 }}
          type="text"
          placeholder={getPlaceholder()}
          value={locationName}
          disabled
          labelProps={labelProps}
          rightIcon={
            <Box>
              <IconButton onClick={handleOpen}>
                <SearchRoundedIcon style={{ color: "white" }} />
              </IconButton>
            </Box>
          }
        />
      </div>
    </>
  );
};

export { LocationSelector };

