import { useUpdateMember } from "../../hooks/Member/member";
import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { MemberRequestDto } from "../../types/Member/MemberRequestDto";
import MessageAlert, { AlertStyle } from "../Common/Errors/MessageAlert";
import { MemberForm } from "./MemberForm";
import { useNavigate, useParams } from "react-router";
import { useState } from "react";
import { MemberResponseDto } from "../../types/Member/MemberResponseDto";
import { useDirtyState } from "../../hooks/Common/dirty";
import { ConfirmationChangesDialog } from "../Common/ConfirmationChangesDialog";
import { SectionHead } from "../Common/Help/SectionHead";

type Args =
    {
        userLogged: ApplicationUser
        member: MemberRequestDto
        isEdit: boolean
    }

const AccountSettings = ({ userLogged, member, isEdit }: Args) => {
    const memberType = member.memberTypeName;
    const nav = useNavigate();
    const params = useParams();
    const updateMemberMutation = useUpdateMember(member.memberTypeName, "AccountSettings");
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [isDirty, setDirty, unsetDirty] = useDirtyState();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [targetUrl, setTargetUrl] = useState('');
    const [error, setError] = useState<string>('');

    //#region Dirty
    const handleDirty = () => {
        setDirty();
    };

    const dialogConfirm = () => {
        setShowConfirmation(false);
        unsetDirty();
        nav(targetUrl);
    }

    const dialogCancel = () => {
        setShowConfirmation(false);
    }
    //#endregion



    const UpdateMember = async (member: MemberRequestDto) => {
        unsetDirty();
        setSubmitting(true);

        let memberUpdate: MemberResponseDto =
        {
            fullName: `${member.person?.firstName} ${member.person?.lastName}`,
            person: member.person,
            externalReference: member.externalReference,
            countryIso3Code: member.countryIso3Code,
            id: params.id!,
            languageCode: member.languageCode,
            memberTypeFlags: 0
        };
        updateMemberMutation.mutate(memberUpdate);
    }
    const homeCallBack = () => {
        nav(`/`);
    }



    return (
        <>
            <ConfirmationChangesDialog handleConfirm={dialogConfirm} handleCancel={dialogCancel} showConfirmation={showConfirmation}></ConfirmationChangesDialog>


            <SectionHead linkCallBack={homeCallBack} ctaText={i18n.t('COMMON_LABEL_DASHBOARD')!}
                name={`${userLogged.name} ${userLogged.familyName} (${member.externalReference})`}
                title={i18n.t('COMMON_SUMMARY_MYACCOUNTEDIT')}
                description={i18n.t('COMMON_SUMMARY_MYACCOUNTMANAGEMENT')} />



            <div className="row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <MemberForm
                        type={memberType}
                        submitting={submitting}
                        member={member}
                        handleDirty={handleDirty}
                        isEdit={isEdit}
                        submitted={(member) => UpdateMember(member)}
                    ></MemberForm>
                </div>
            </div>
            {(error || updateMemberMutation.isError) &&
                <div className="row d-flex justify-content-center">
                    <div className="col-md-7 col-11 justify-content-center">
                        {
                            error &&
                            <MessageAlert message={error} variant="danger" style={AlertStyle.Tiny} additionalClassName="fa-alert-tiny"></MessageAlert>
                        }
                        {
                            updateMemberMutation.isError &&
                            <MessageAlert message={updateMemberMutation.error.message} variant="danger" style={AlertStyle.Tiny} additionalClassName="fa-alert-tiny"></MessageAlert>
                        }

                    </div>
                </div>
            }
        </>
    );
};


export { AccountSettings }