import { Alert, AlertColor, AlertProps, IconButton, Portal, Snackbar, SnackbarCloseReason, SnackbarProps, styled } from "@mui/material";
import { isObject, isString } from "lodash";
import { closeSnackbar, enqueueSnackbar, MaterialDesignContent, OptionsObject, SnackbarKey, SnackbarProvider } from "notistack";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { colors } from "../../../main/Theme";

import CloseIcon from '@mui/icons-material/Close';
import i18n from "../../../i18n";

type props = {
  snackbarContent: any,
  severity?: AlertColor,
  setSnackbarContent: Dispatch<SetStateAction<any>>
  alertProps?: AlertProps
  snackbarProps?: SnackbarProps
}


export const CustomSnackbar = ({ snackbarContent, severity = 'error', setSnackbarContent, alertProps, snackbarProps }: props) => {
  const [showSnackbar, setShowSnackbar] = useState(false);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackbar(false);
    setSnackbarContent(null)
  };

  useEffect(() => {
    if (snackbarContent) {
      setShowSnackbar(true);
    } else {
      setShowSnackbar(false);
    }
  }, [snackbarContent])

  return (
    <Portal>
      <Snackbar
        open={showSnackbar}
        onClose={handleClose}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
        sx={{
          minWidth: {
            sx: "100%",
            md: '500px'
          }
        }}
        {...snackbarProps}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          variant="filled"
          sx={{ width: '100%' }}
          {...alertProps}
        >
          {isObject(snackbarContent) ? 'Something went wrong! Please contact admin.' : snackbarContent}
        </Alert>
      </Snackbar>
    </Portal>
  )
}


const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  fontSize: '0.9rem',
  '&.notistack-MuiContent-success': {
    backgroundColor: colors.primary,
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: colors.red[100],
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: colors.orange,
  },
}));

export const CustomSnackbarGlobalProvider = ({ children }: { children: React.ReactNode }) => (
  <SnackbarProvider
    maxSnack={3}
    anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
    Components={{
      success: StyledMaterialDesignContent,
      error: StyledMaterialDesignContent,
      warning: StyledMaterialDesignContent,
    }}
  >{children}</SnackbarProvider>
)

export const showSnackbar = (message: any, options?: OptionsObject) => {
  let { persist, variant } = options || {
    variant: "success"
  }
  let action, autoHideDuration

  if (persist) {
    action = (snackbarId: SnackbarKey) => (
      <IconButton onClick={() => closeSnackbar(snackbarId)} sx={{ color: colors.white }}>
        <CloseIcon sx={{ width: 20, height: 20 }} />
      </IconButton>
    )
  }

  if (!persist) {
    autoHideDuration = 3000
  }

  const defaultMessage = {
    'success': 'Success!',
    'error': 'Something went wrong! Please contact admin.',
    'warning': 'Warning!',
    'info': 'Info!',
    'default': 'Default'
  }

  if (!variant) {
    throw Error("variant is required");
  }

  const _message = isString(message) ? message : defaultMessage[variant]

  return enqueueSnackbar(_message, {
    variant: variant,
    action: action,
    autoHideDuration,
    ...options
  });
}

export const showSuccessMessage = (message?: string) => showSnackbar(message || i18n.t('COMMON_LABEL_SAVE_SUCCESS'));
export const showErrorMessage = (message: any) => showSnackbar(message, {
  persist: true,
  variant: 'error'
});

