import { useLocalStorage } from "usehooks-ts";
import { useEffect } from "react";
import dayjs from "dayjs";
import { CustomerSearchItemResponseDto } from "../../types/Customer/CustomerSearchItemResponseDto";
import { ApplicationConfig } from "../../config/ApplicationConfig";


export const useSaveCustomerLocalStorage = () => {
  const [storedValue, setStoredValue, removeStoredValue] = useLocalStorage<{
    data: CustomerSearchItemResponseDto | null;
    timestamp: string;
  } | null>(ApplicationConfig.selectedCustomerStorageKey, null);

  const isExpired = (timestamp: string) => {
    const expirationTime = dayjs(timestamp).add(ApplicationConfig.expirationHours, 'hour');
    return dayjs().isAfter(expirationTime);
  };

  const setCustomerData = (data: CustomerSearchItemResponseDto | null) => {
    const timestamp = dayjs().toISOString();
    setStoredValue({ data, timestamp });
  };

  const removeCustomerData = () => {
    removeStoredValue();
  };

  useEffect(() => {
    if (storedValue && isExpired(storedValue.timestamp)) {
      removeStoredValue();
    }
  }, [storedValue, removeStoredValue]);

  const customerData = storedValue && !isExpired(storedValue.timestamp)
    ? storedValue.data
    : null;

  return {
    customerData,
    setCustomerData,
    removeCustomerData,
  };
};
