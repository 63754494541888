import { v4 as uuidv4 } from 'uuid';
import { ApplicationConfig } from '../../config/ApplicationConfig';


const operatorNoPartnertHeaders = function () {

    return {
        "Pont-Source": "OperatorPortal",
        "Pont-Api-Key": "c498656d-a376-49d8-a610-c00777ff25d6",
        "X-CSRF": "1",
        "Content-Type": "application/json",
        "CorrelationId": uuidv4()
    };
}


const operatorDefaultHeaders = function () {
    var userId =  JSON.parse(localStorage.getItem(ApplicationConfig.currentUserStorageKey) ?? "{}").id;
    var headerOperatorId = '';
    if(!!userId) {
        headerOperatorId =  JSON.parse(localStorage.getItem(`${userId}${ApplicationConfig.currentOperatorStorageKey}`) ?? '{}').id ?? '';
    }

    return {
        "Pont-Source": "OperatorPortal",
        "Pont-Partner": localStorage.getItem(ApplicationConfig.partnerStorageKey) ?? "",
        "Pont-Operator": headerOperatorId,
        "Pont-Api-Key": "c498656d-a376-49d8-a610-c00777ff25d6",
        "X-CSRF": "1",
        "Content-Type": "application/json",
        "CorrelationId": uuidv4()
    };
}




const operatorFileUploadHeaders = function () {
    return {
        "Pont-Source": "OperatorPortal",
        "Pont-Partner": localStorage.getItem(ApplicationConfig.partnerStorageKey) ?? "",
        "X-CSRF": "1",
        "Content-Type": "multipart/form-data",
        "CorrelationId": uuidv4()
    };
}



export {
    operatorDefaultHeaders,
    operatorFileUploadHeaders,
    operatorNoPartnertHeaders

}
