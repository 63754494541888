import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useFetchApplicationSummaryById, useFetchApplications } from "../../hooks/Member/applicationsConfiguration";
import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { SummaryItemDto } from "../../types/Common/SummaryItemDto";
import { LabelAndHelp } from "../Common/Help/LabelAndHelp";
import { SummaryItemCollection } from "../Common/SummaryItems/SummaryItemCollection";
import { CustomSelect } from "../Common/formComponents/CustomSelect";

type Args =
    {
        userLogged: ApplicationUser
    }

const ApplicationConfiguration = ({ userLogged }: Args) => {

    const nav = useNavigate();
    const { data: applications } = useFetchApplications()
    const [useApplication, setUseApplication] = useState<{ value: string, label: string }>();
    const [mappedApplications, setMappedApplications] = useState<{ value: string, label: string }[]>([]);
    const { data: summaries, loading } = useFetchApplicationSummaryById(useApplication?.value ?? '');

    console.log(summaries)

    const linkCallBackCollection = (item: SummaryItemDto) => {
        switch (item.name) {
            case "ApplicationPostSummary":
                nav(`/application/${useApplication?.value}/configuration/posts`);
                break;
            case "ApplicationNotificationSummary":
                nav(`/application/${useApplication?.value}/configuration/notifications`);
                break;
            case "PartnerOperatorSummary":
                nav(`/application/${useApplication?.value}/configuration/operators`);
                break;
            default:
                alert(`Item ${item.name} does not have a link yet`);
                break;
        }
    }

    useEffect(() => {
        if (applications && applications.length > 0) {
            setUseApplication({ value: applications[0].applicationId, label: applications[0].applicationName });
            setMappedApplications(applications.map(app => ({
                label: app.applicationName,
                value: app.applicationId
            })));
        }
    }, [applications])

    const homeCallBack = () => {
        nav(`/`);
    }

    const handleApplicationChange = (selectedOption: any) => {
        setUseApplication(selectedOption);
    }

    return (
        <>
            <div className="mainpage-container-layout">
                <div className='col-md-10 col-11 justify-content-center'>
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-10 col-11 justify-content-center">
                            <CustomSelect
                                value={useApplication}
                                options={mappedApplications}
                                onChange={handleApplicationChange}
                                placeholder={i18n.t("APPLICATION_MAIN_LABEL").toString()}
                                labelProps={{
                                    message: 'APPLICATION_MAIN_LABEL',
                                }}
                            />
                            <SummaryItemCollection linkCallBack={linkCallBackCollection} items={summaries ?? []}></SummaryItemCollection>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};


export { ApplicationConfiguration };
