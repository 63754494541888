const ApplicationConfig = {
    currentUserStorageKey: "currentUser",
    currentOperatorStorageKey: "_currentOperator",
    currentLocationStorageKey: "_currentLocation",
    partnerStorageKey: "currentPartner",
    emptyGuid: '00000000-0000-0000-0000-000000000000',
    selectedCustomerStorageKey: "selectedCustomer",
    orderFilterStorageKey: "orderFilter",
    expirationHours: 8,
    disableQuerySettings: {
        enabled: false,
        retryOnMount: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
    },
    emailRegex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/
}


export {
    ApplicationConfig
}



// export const pontConstants = {
//     emptyGuid: '00000000-0000-0000-0000-000000000000',
//     disableQuerySettings: {
//         enabled: false,
//         retryOnMount: false,
//         refetchOnMount: false,
//         refetchOnReconnect: false,
//         refetchOnWindowFocus: false,
//     },
//     emailRegex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/
// }