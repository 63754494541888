import { alpha, Box } from "@mui/material";
import ReactSelect, { ClearIndicatorProps, components, DropdownIndicatorProps, GroupBase, MultiValueRemoveProps, Props, StylesConfig } from "react-select";

import CloseIcon from '@mui/icons-material/Close';
import { ControllerRenderProps } from "react-hook-form";
import { colors } from "../../../main/Theme";
import { Label, LabelType } from "./Label";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ValidationError } from "./ValidationError";

const selectStyle: StylesConfig<any, any, any> = {
  container: (base: any) => ({
    ...base,
  }),
  control: (base: any, state: any) => ({
    ...base,
    position: 'relative',
    backgroundColor: colors.grey[100],
    fontSize: '1rem',
    lineHeight: '2',
    height: 'auto',
    width: '100%',
    border: '2px solid transparent',
    boxShadow: 'none',
    borderRadius: '0',
    color: colors.white,
    ":hover": {
      borderColor: 'transparent',
    },
    ":focus": {
      borderColor: 'red',
    },
    '&.select__control--menu-is-open': {
      borderColor: colors.grey[200],
    },
    '&.select__control--error': {
      borderColor: alpha(colors.red[100], 0.5),
    },
  }),

  valueContainer: (base: any) => ({
    ...base,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menu: (base: any) => ({
    ...base,
    backgroundColor: colors.grey[300],
    marginTop: '2px',
    borderRadius: '0',
    boxShadow: '0px 10px 10px 0px rgba(0, 0, 0, 0.3)',
  }),
  menuList: (base: any) => ({
    ...base,
    backgroundColor: 'transparent',
  }),
  option: (base: any, state: any) => ({
    ...base,
    color: alpha(colors.white, 0.8),
    backgroundColor: 'transparent',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: alpha(colors.grey[200], 0.5)
    }
  }),
  multiValue: (base: any) => ({
    ...base,
    color: colors.white,
    background: colors.grey[200],
    borderRadius: '0',

  }),
  multiValueLabel: (base: any) => ({
    ...base,
    color: 'inherit'
  }),
  multiValueRemove: (base: any) => ({
    ...base,
    paddingLeft: '0.3rem',
    paddingRight: '0.3rem',
    borderRadius: '0',
    marginLeft: '0.3rem',
    '&:hover': {
      background: colors.red[100],
      color: colors.white,
    },
    '& svg': {
      height: '1rem',
      width: '1rem',
    }
  }),
  singleValue: (base: any) => ({
    ...base,
    color: colors.white
  }),
  input: (base: any) => ({
    ...base,
    color: colors.white
  }),
  clearIndicator: (base) => ({
    ...base,
    cursor: 'pointer',
    padding: '0.3rem',
    '&:hover': {
      color: colors.white,
      '& svg': {
        color: colors.red[100],
      }
    },
    '& svg': {
      height: '1rem',
      width: '1rem',
      color: colors.white
    }
  }),
  dropdownIndicator: () => ({
    width: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
  }),
}

const ClearIndicator = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(props: ClearIndicatorProps<Option, IsMulti, Group>) => {
  return (
    <components.ClearIndicator {...props}>
      <CloseIcon />
    </components.ClearIndicator>
  );
};

const DropdownIndicator =
  <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(props: DropdownIndicatorProps<Option, IsMulti, Group>) => {
    return (
      <components.DropdownIndicator {...props}>
        <KeyboardArrowDownIcon />
      </components.DropdownIndicator>
    );
  };


const MultiValueRemove = (props: MultiValueRemoveProps) => {
  return (
    <components.MultiValueRemove {...props}>
      <CloseIcon />
    </components.MultiValueRemove>
  );
};

const CustomOption = (props: any) => {
  const { data, innerRef, innerProps } = props;

  const Icon = data.icon ? data.icon : null;
  return (
    <Box
      ref={innerRef}
      {...innerProps}
      sx={{
        ...props.getStyles('option', props),
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {data.icon && <Box sx={{ mr: 1 }}>{Icon}</Box>}
      {props.label}
    </Box>
  );
};

export const CustomSelect = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  ...props
}: Props<Option, IsMulti, Group> & {
  labelProps?: LabelType;

  field?: ControllerRenderProps<any, any>;
  validationError?: {
    message?: string;
  }
}) => {
  const { validationError, labelProps, field, isDisabled, ...rest } = props;
  let disabledStyle = isDisabled ? { opacity: 0.5 } : {}
  return (
    <Box sx={disabledStyle}>
      {labelProps && <Label {...labelProps} error={!!validationError} />}
      <ReactSelect
        styles={selectStyle}
        classNamePrefix={'select'}
        components={{
          MultiValueRemove,
          ClearIndicator,
          DropdownIndicator,
          Option: CustomOption
        }}
        classNames={{
          control: () => (!!validationError ? 'select__control--error' : 'select__control'),
        }}
        isDisabled={isDisabled}
        {...field}
        {...rest}
      />
      {validationError && <ValidationError message={validationError?.message} />}
    </Box>
  )
}
