import { Box, Grid } from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { pontCurrencies } from "../../data/en/pontCurrencies";
import { pontTimezones } from "../../data/en/pontTimezones";
import { GenerateExternalReference } from "../../functions/utils/helper";
import { useDirtyStateV2 } from "../../hooks/Common/dirtyV2";
import { useCreateEvent, useFetchEventById, useFetchEventSummaryById, useUpdateEvent } from "../../hooks/Events/event";
import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { SummaryItemDto } from "../../types/Common/SummaryItemDto";
import { EventPriceDto } from "../../types/Event/EventPriceDto";
import { EventResponseDto } from "../../types/Event/EventResponseDto";
import { LocationResponseDto } from "../../types/Location/LocationResponseDto";
import { MemberResponseDto } from "../../types/Member/MemberResponseDto";
import { PriceDto } from "../../types/Price/PriceDto";
import { ConfirmationChangesDialog } from "../Common/ConfirmationChangesDialog";
import { CustomDatePicker } from "../Common/formComponents/CustomDatepicker";
import { CustomSelect } from "../Common/formComponents/CustomSelect";
import { CustomTimePicker } from "../Common/formComponents/CustomTimepicker";
import { Input } from "../Common/formComponents/Input";
import { LR } from "../Common/Help/LR";
import { SectionHead } from "../Common/Help/SectionHead";
import FormattedNumberInput from "../Common/Prices/FormatNumberInput";
import { SummaryItemCollection } from "../Common/SummaryItems/SummaryItemCollection";
import { CustomButton } from "../Common/UI/CustomButton";
import { showErrorMessage, showSuccessMessage } from "../Common/UI/CustomSnackbar";
import { LocationSelector } from "../Location/LocationSelector";
import { ParentMemberSelector } from "../Member/ParentMemberSelector";

type Args = {
    userLogged: ApplicationUser,
    isEdit: boolean
}

const EventSummary = ({ userLogged, isEdit }: Args) => {
    // force to use of UTC in order to keep tz standardized
    // since timezone is handled separate from date object, it just needs to be consistent
    dayjs.extend(utc);
    dayjs.extend(timezone);

    const nav = useNavigate();
    let summaryName: string = '';
    const queryStringParams = useParams();
    if (isEdit && !queryStringParams.id) throw Error("Location id needed");

    if (queryStringParams.summaryName)
        summaryName = queryStringParams.summaryName;

    const linkCallBackCollection = (item: SummaryItemDto) => {
        switch (item.name) {
            case "EventFeaturesSummary":
                nav(`/events/${queryStringParams.id}/features`);
                break;
            case "EventDescriptionSummary":
                nav(`/events/${queryStringParams.id}/description`);
                break;
            case "EventCheckOutNoteSummary":
                nav(`/events/${queryStringParams.id}/checkoutnote`);
                break;
            case "FeatureSummary":
                nav(`/events/${queryStringParams.id}/features`);
                break;
            case "EventImagesSummary":
            case "EventDocsSummary":
                nav(`/events/${queryStringParams.id}/digital-assets/${item.uri.substring(item.uri.indexOf('group=') + 6)}`);
                break;
            default:
                alert(`Item ${item.name} does not have a link yet`);
                break;
        }
    }

    const updatedCallback = () => {
        nav(`/events/${queryStringParams.id}/summary`);
    }

    const createdCallback = (eventId: string) => {
        nav(`/events/${eventId}/summary`);
    }

    // const homeCallBack = () => {
    //     nav(`/`);
    // }

    const homeCallBack = () => {
        const url = `/inventory`;
        handleDirtyAction(() => nav(url))
    };


    const createPriceItem = (eventPriceDto: EventPriceDto, eventId: string) => {
        let priceDto: PriceDto = {
            id: eventPriceDto?.id ?? 0,
            entityName: 'Event',
            entityKey: eventId ?? '',
            unitName: 'Ticket',
            fromDate: eventPriceDto?.fromDate ?? Date,
            toDate: eventPriceDto?.toDate ?? Date,
            currencyCode: eventPriceDto?.currencyCode ?? 'GBP',
            price: eventPriceDto?.price ?? 0
        }
        return priceDto;
    }

    const defaultEvent: EventResponseDto = {
        id: '',
        name: '',
        isOnlineEvent: true,
        locationId: 'DD8952D2-3D72-47FB-BD8E-32039FDA4A0B',
        timeZoneName: '',
        ownerMemberId: '',
        legalEntityMemberId: '',
        quantity: 0,
        retainedQuantity: 0,
        quantityLimitPerOrder: 1,
        externalReference: '',
        isDisabled: false,
        internalIdentifier: '',
        fromDate: new Date(),
        toDate: new Date(),
        prices: [],
        ticketsSold: 0,
        saleCount: 0,
        ownerMemberFullName: '',
        legalEntityMemberFullName: '',
        locationName: '',
        shortDescription: '',
        description: '',
        checkOutNote: '',
    };

    const defaultSummaries: SummaryItemDto[] = [];
    const { data: event, status: eventStatus, isSuccess: eventIsSuccess } = useFetchEventById(queryStringParams.id!, isEdit);
    const { data, status, isSuccess } = useFetchEventSummaryById(queryStringParams.id!, summaryName!, isEdit);
    const [eventState, setEventState] = useState(defaultEvent);
    const [summariesState, setEventSummaries] = useState(defaultSummaries);
    const [selectedPrice, setSelectedPrice] = useState(0);
    const [selectedCurrency, setSelectedCurrency] = useState<{ value: string, label: string, countryCode: string }>({ value: 'GBP', label: 'British Pound Sterling - £', countryCode: 'GBR' });
    const [selectedTimezone, setSelectedTimezone] = useState<{ value: string, label: string }>({ label: "Europe/London", value: "Europe/London" });
    const [priceItemState, setPriceItemState] = useState<PriceDto>(createPriceItem(null!, null!));
    const [isOnline, setIsOnline] = useState<boolean>(true);
    const [legalEntityIdState, setLegalEntityId] = useState('');
    const { mutate: updateEvent, isLoading: isUpdateLoading } = useUpdateEvent(updatedCallback);
    const { mutate: createEvent, isLoading: isCreateLoading } = useCreateEvent(createdCallback);

    const { setDirty, showConfirmation, handleDirtyAction, cancelDirtyAction, confirmDirtyAction, unsetDirty } = useDirtyStateV2();

    // const handleCurrencyChange = (selectedOption: any) => {
    //     setSelectedCurrency(selectedOption);
    //     setPriceItemState({ ...priceItemState!, currencyCode: selectedOption });
    // }

    const handleCurrencyChange = (selectedOption: any) => {
        setSelectedCurrency(selectedOption); // Setting the new currency
        setPriceItemState(prevState => ({ ...prevState, currencyCode: selectedOption.value }));
    };

    const handleZoneChange = (selectedOption: any) => {
        setSelectedTimezone(selectedOption);
        setEventState({ ...eventState, timeZoneName: selectedOption.value });
    }

    const generateUniqueCode = () => {
        if (eventState.externalReference === '' || eventState.externalReference === null) {
            const formattedCode = GenerateExternalReference(eventState.name ?? '');
            setEventState({ ...eventState, externalReference: formattedCode });
        }
    };

    const confirmLegalEntity = (operator: MemberResponseDto) => {
        setEventState({ ...eventState, legalEntityMemberId: operator.id });
        setLegalEntityId(operator.id);
    }

    const confirmOrganizer = (operator: MemberResponseDto) => {
        setEventState({ ...eventState, ownerMemberId: operator.id, legalEntityMemberId: legalEntityIdState });
    }

    const confirmLocation = (location: LocationResponseDto) => {
        setEventState({ ...eventState, locationId: location.id });
    }

    const handleLocationRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsOnline(event.target.value === 'online');
        if (isOnline) {
            eventState.locationId = 'DD8952D2-3D72-47FB-BD8E-32039FDA4A0B';
        }
        setEventState({ ...eventState, isOnlineEvent: isOnline });
    };

    const onPriceItemChange = (value: string) => {
        setPriceItemState({ ...priceItemState!, price: Number(value) });
    }

    const handleTimeChange = (currentDateTime: Date, newTime: Date): Date => {
        // Update only the time component
        let convertedDateTime = new Date(currentDateTime);
        convertedDateTime.setHours(newTime.getHours(), newTime.getMinutes());
        return convertedDateTime;
    };

    const addEventPrice = () => {
        let price: EventPriceDto = {
            id: 0,
            unitName: 'Ticket',
            fromDate: eventState.fromDate,
            toDate: eventState.toDate,
            currencyCode: priceItemState.currencyCode,
            price: priceItemState.price
        }
        eventState.prices.push(price);
    }

    const requiredPopulated = () => {
        return (!!eventState.name &&
            !!eventState.shortDescription &&
            !!legalEntityIdState &&
            !!eventState.ownerMemberId &&
            (eventState.isOnlineEvent || !!eventState.locationId) &&
            eventState.toDate > eventState.fromDate) ||
            isUpdateLoading || isCreateLoading;
    }

    const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            const quantityLimit = eventState.quantityLimitPerOrder;
            const _quantityLimit = quantityLimit == null || isNaN(quantityLimit) || quantityLimit === 0 ? 1 : quantityLimit;
            const updatedEventState = {
                ...eventState,
                quantityLimitPerOrder: _quantityLimit
            };
            let ticketPrice = eventState.prices?.find((x) => x.unitName === 'Ticket');
            if (ticketPrice) {
                ticketPrice.price = priceItemState.price;
                ticketPrice.currencyCode = priceItemState.currencyCode;
                ticketPrice.fromDate = new Date();
                ticketPrice.toDate = eventState.toDate;
            } else {
                addEventPrice();
            }

            setEventState({ ...eventState, legalEntityMemberId: legalEntityIdState });

            const config: any = {
                onSuccess: () => {
                    showSuccessMessage();
                }, onError: (err: any) => {
                    showErrorMessage(err.response?.data);
                }
            }
            if (isEdit) {
                updateEvent(updatedEventState, {
                    ...config
                });
            } else {
                createEvent(updatedEventState, {
                    ...config
                });
            }
            unsetDirty();
        } catch (err: any) {
            console.error(err);
        }
    };


    useEffect(() => {
        if (!isEdit) return;
        if (!eventIsSuccess || !event) return;

        event.fromDate = new Date(event.fromDate);
        event.toDate = new Date(event.toDate);

        if (!event.quantityLimitPerOrder || event.quantityLimitPerOrder === 0) {
            event.quantityLimitPerOrder = 1;
        }
        setEventState(event);
        setSelectedPrice(event?.prices[0]?.price ?? 0);
        const priceCurrency = pontCurrencies.find(x => x.value === (event?.prices[0]?.currencyCode || 'GBP'))
        setSelectedCurrency(priceCurrency!);
        const defaultZone = pontTimezones.find(x => x.label === event?.timeZoneName || 'Europe/London')
        setSelectedTimezone(defaultZone!);
        setIsOnline(event.isOnlineEvent);
        setLegalEntityId(event.legalEntityMemberId);
        setPriceItemState(createPriceItem(event?.prices[0], event.id));
        if (data) {
            setEventSummaries(data)
        }
    }, [status, eventStatus]);

    return (
        <>
            <form onSubmit={onFormSubmit}>
                <ConfirmationChangesDialog
                    handleConfirm={confirmDirtyAction}
                    handleCancel={cancelDirtyAction}
                    showConfirmation={showConfirmation}
                />

                {!queryStringParams.summaryName && (
                    <SectionHead
                        linkCallBack={homeCallBack}
                        ctaText={i18n.t('COMMON_LABEL_INVENTORY')!}
                        name={isEdit ? `${eventState.name} (${eventState.externalReference})` : ''}
                        title={i18n.t(!isEdit ? 'EVENT_SUMMARY_EVENTMAINPAGEADD' : 'EVENT_SUMMARY_EVENTMAINPAGEEDIT'!)}
                        description={i18n.t('COMMON_SUMMARY_EVENTMANAGEMENT')}
                    />
                )}

                <div className="row d-flex justify-content-center">
                    <div className="col-md-7 col-11 justify-content-center">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Input
                                    type="text"
                                    placeholder={i18n.t('EVENT_LABEL_NAME').toString()}
                                    value={eventState.name}
                                    onChange={(e) => {
                                        setEventState({ ...eventState, name: e.target.value });
                                        setDirty();
                                    }}
                                    onBlur={() => generateUniqueCode()}
                                    labelProps={{
                                        message: "EVENT_LABEL_NAME",
                                        mandatory: true,
                                    }}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Input
                                    type="text"
                                    placeholder={i18n.t('EVENT_LABEL_DESCRIPTION').toString()}
                                    value={eventState.shortDescription}
                                    onChange={(e) => {
                                        setEventState({ ...eventState, shortDescription: e.target.value });
                                        setDirty();
                                    }}
                                    labelProps={{
                                        message: "EVENT_LABEL_DESCRIPTION",
                                        mandatory: true,
                                    }}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Input
                                    type="text"
                                    placeholder={i18n.t('EVENT_LABEL_IDENTIFIER').toString()}
                                    value={eventState.externalReference}
                                    onChange={(e) => {
                                        setEventState({ ...eventState, externalReference: e.target.value });
                                        setDirty();
                                    }}
                                    labelProps={{
                                        message: "EVENT_LABEL_IDENTIFIER",
                                        mandatory: true,
                                    }}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ParentMemberSelector
                                    key={'legalEntityIdState'}
                                    legalEntityMemberId={legalEntityIdState!}
                                    placeholder="EVENT_LABEL_LEGALENTITY"
                                    flagType={512}
                                    operatorId={userLogged.operator?.id!}
                                    handleConfirm={confirmLegalEntity}
                                    labelProps={{
                                        message: "EVENT_LABEL_LEGALENTITY",
                                        mandatory: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ParentMemberSelector
                                    key={'ownerMemberId'}
                                    legalEntityMemberId={eventState.ownerMemberId!}
                                    placeholder="EVENT_LABEL_OWNER"
                                    flagType={512}
                                    operatorId={userLogged.operator?.id!}
                                    handleConfirm={confirmOrganizer}
                                    labelProps={{
                                        message: "EVENT_LABEL_OWNER",
                                        mandatory: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <RadioGroup name="use-radio-group" value={isOnline ? "online" : "live"} onChange={handleLocationRadioChange}>
                                    <FormControlLabel value="online" label="Online" control={<Radio color="secondary" />} />
                                    <FormControlLabel value="live" label="In-Person" control={<Radio color="secondary" />} />
                                </RadioGroup>
                            </Grid>
                            <Grid item xs={9}>
                                {isOnline && (
                                    <Box sx={{ position: 'relative', zIndex: 10 }}>
                                        <CustomSelect
                                            value={selectedTimezone}
                                            options={pontTimezones}
                                            onChange={handleZoneChange}
                                            placeholder={i18n.t('COMMON_LABEL_SELECTTIMEZONE').toString()}
                                        />
                                    </Box>
                                )}
                                {!isOnline && (
                                    <LocationSelector
                                        locationId={eventState.locationId!}
                                        placeholder="EVENT_LABEL_LOCATION"
                                        operatorId={userLogged.operator?.id!}
                                        handleConfirm={confirmLocation}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <CustomDatePicker
                                    labelProps={{
                                        message: "EVENT_LABEL_FROMDATE",
                                        mandatory: true,
                                    }}
                                    value={dayjs(eventState.fromDate)}
                                    timezone="UTC"
                                    onChange={(newValue) => {
                                        setEventState({ ...eventState, fromDate: newValue?.toDate()! });
                                        setDirty();
                                    }}
                                ></CustomDatePicker>
                            </Grid>
                            <Grid item xs={6}>
                                <CustomTimePicker
                                    labelProps={{
                                        message: "EVENT_LABEL_FROMTIME",
                                        mandatory: true,
                                    }}
                                    value={dayjs(eventState.fromDate)}
                                    onChange={(newValue) => {
                                        const newDateTimeValue = handleTimeChange(eventState.fromDate, newValue?.toDate()!);
                                        setEventState({ ...eventState, fromDate: newDateTimeValue });
                                        setDirty();
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CustomDatePicker
                                    labelProps={{
                                        message: "EVENT_LABEL_TODATE",
                                        mandatory: true,
                                    }}
                                    value={dayjs(eventState.toDate)}
                                    timezone="UTC"
                                    onChange={(newValue) => {
                                        setEventState({ ...eventState, toDate: newValue?.toDate()! });
                                        setDirty();
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CustomTimePicker
                                    labelProps={{
                                        message: "EVENT_LABEL_TOTIME",
                                        mandatory: true,
                                    }}
                                    value={dayjs(eventState.toDate)}
                                    onChange={(newValue) => {
                                        const newDateTimeValue = handleTimeChange(eventState.toDate, newValue?.toDate()!);
                                        setEventState({ ...eventState, toDate: newDateTimeValue });
                                        setDirty();
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Input
                                    type="number"
                                    id="quantity"
                                    inputProps={{
                                        min: 0,
                                    }}
                                    labelProps={{
                                        message: "EVENT_LABEL_QUANTITY",
                                        mandatory: true,
                                    }}
                                    value={eventState.quantity}
                                    placeholder={i18n.t('EVENT_LABEL_QUANTITY')!}
                                    onChange={(e) => {
                                        setEventState({ ...eventState, quantity: Number(e.target.value) });
                                        setDirty();
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Input
                                    labelProps={{
                                        message: "EVENT_LABEL_RETAINED",
                                        mandatory: true,
                                    }}
                                    type="number"
                                    id="retained"
                                    inputProps={{
                                        min: 0,
                                        max: eventState.quantity,
                                    }}
                                    value={eventState.retainedQuantity}
                                    placeholder={i18n.t('EVENT_LABEL_RETAINED')!}
                                    onChange={(e) => {
                                        setEventState({ ...eventState, retainedQuantity: Number(e.target.value) });
                                        setDirty();
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Input
                                    labelProps={{
                                        message: "EVENT_LABEL_LIMIT",
                                        mandatory: true,
                                    }}
                                    type="number"
                                    id="limit"
                                    inputProps={{
                                        min: 1,
                                    }}
                                    value={eventState.quantityLimitPerOrder ?? 1}
                                    placeholder={i18n.t('EVENT_LABEL_LIMIT')!}
                                    onChange={(e) => {
                                        const value = Number(e.target.value);
                                        setEventState({
                                            ...eventState,
                                            quantityLimitPerOrder: isNaN(value) || value < 0 ? 1 : value,
                                        });
                                        setDirty();
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Input
                                    labelProps={{
                                        message: "EVENT_LABEL_SOLD",
                                        mandatory: true,
                                    }}
                                    type="number"
                                    id="sold"
                                    disabled
                                    inputProps={{
                                        min: 0,
                                    }}
                                    value={eventState.ticketsSold}
                                    placeholder={i18n.t('EVENT_LABEL_SOLD')!}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormattedNumberInput
                                    labelProps={{
                                        message: "COMMON_LABEL_TICKETPRICE",
                                        mandatory: true,
                                    }}
                                    allowEmpty={false}
                                    value={selectedPrice}
                                    onChange={(item, value) => onPriceItemChange(value)}
                                    currencyValue={selectedCurrency!.value}
                                    item={priceItemState!}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CustomSelect
                                    key={JSON.stringify(selectedCurrency)}
                                    value={selectedCurrency}
                                    onChange={handleCurrencyChange}
                                    options={pontCurrencies}
                                    getOptionLabel={(option) => option.label}
                                    getOptionValue={(option) => option.value}
                                    placeholder={i18n.t("COMMON_LABEL_SELECTCURRENCY").toString()}
                                    labelProps={{
                                        message: "COMMON_LABEL_CURRENCY",
                                        mandatory: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SummaryItemCollection linkCallBack={linkCallBackCollection} items={summariesState} />
                            </Grid>
                            <Grid container item justifyContent={"center"} xs={12}>
                                <Grid item xs={3}>
                                    <CustomButton
                                        color={'secondary'}
                                        variant='contained'
                                        type="submit"
                                        disabled={!requiredPopulated()}
                                        fullWidth
                                        loading={isCreateLoading || isUpdateLoading}
                                    >
                                        <LR localResource="COMMON_LABEL_SAVE" />
                                    </CustomButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </form>
        </>
    );
};

export { EventSummary };

