import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { GenerateExternalReference } from "../../functions/utils/helper";
import { useDirtyStateV2 } from "../../hooks/Common/dirtyV2";
import { UseRegisterDelegatedEmail } from "../../hooks/Login/login";
import { useAddMember, useFetchMemberById, useUpdateMember } from "../../hooks/Member/member";
import i18n from "../../i18n";
import { useSource } from "../../providers/SourceProvider";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { SourceEnum } from "../../types/Common/CommonSummaryHeaderProps";
import { UserRegistrationRequest } from "../../types/Login/UserRegistrationRequest";
import { UserRegistrationResponse } from "../../types/Login/UserRegistrationResponse";
import { MemberRequestDto } from "../../types/Member/MemberRequestDto";
import { MemberResponseDto } from "../../types/Member/MemberResponseDto";
import { ConfirmationChangesDialog } from "../Common/ConfirmationChangesDialog";
import ApiStatus from "../Common/Errors/ApiStatus";
import { SectionHead } from "../Common/Help/SectionHead";
import { showErrorMessage } from "../Common/UI/CustomSnackbar";
import { MemberForm } from "./MemberForm";

type Args = {
    userLogged: ApplicationUser;
    member?: MemberRequestDto;
    isEdit: boolean;
};
const ContactManage = ({ userLogged, member, isEdit }: Args) => {

    const { source } = useSource()

    const defaultMemberType:any = {
        [SourceEnum.operators]: 'OperatorContact',
        [SourceEnum.landlords]: 'LandlordContact',
    }

    const memberType = member?.memberTypeName || defaultMemberType[source];
    const nav = useNavigate();
    const params = useParams();
    const parentId = params.parentId;

    if (!parentId) {
        Error("Parent ID needed. Wrong operation attempted.");
    }

    const emptyUserRegistration: UserRegistrationRequest = {
        firstName: "",
        lastName: "",
        confirmPassword: "",
        email: "",
        password: "",
        countryISOCode: ""
    };

    if (!member) {
        member = {
            countryIso3Code: '',
            enrolmentCode: '',
            fullName: '',
            languageCode: i18n.language,
            memberTypeName: memberType,
            person: {
                email: '',
                firstName: '',
                jobTitle: '',
                lastName: '',
            }
        };
    }

    const { data: memberData, status: operatorStatus, isSuccess: operatorIsSuccess } = useFetchMemberById(parentId!);
    const addMemberMutation = useAddMember(memberType, parentId);
    const updateMemberMutation = useUpdateMember(memberType, parentId);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const { setDirty, showConfirmation, handleDirtyAction, cancelDirtyAction, confirmDirtyAction, unsetDirty } = useDirtyStateV2();

    const backLink: any = {
        [SourceEnum.customers]: `/customers/${parentId}/summary`,
        [SourceEnum.operators]: `/operators/${parentId}/contacts`,
        [SourceEnum.landlords]: `/landlords/${parentId}/contacts`,
    }

    const linkCallBack = () => {
        handleDirtyAction(() => nav(backLink[source]));
    };

    const handleDirty = () => {
        setDirty();
    };

    const AddMember = async (member: MemberRequestDto) => {
        unsetDirty();
        setSubmitting(true);
        member.parentMemberId = parentId;
        member.fullName = `${member.person?.firstName} ${member.person?.lastName}`;
        member.externalReference = GenerateExternalReference(member.fullName);

        emptyUserRegistration.authorUserId = userLogged.id!;
        emptyUserRegistration.firstName = member.person?.firstName!;
        emptyUserRegistration.lastName = member.person?.lastName!;
        emptyUserRegistration.countryISOCode = member.countryIso3Code;
        emptyUserRegistration.email = member.person?.email!;

        UseRegisterDelegatedEmail(emptyUserRegistration, function (response: UserRegistrationResponse) {
            if (response.id && !response.error) {
                member.registeredUserId = response.id;
                addMemberMutation.mutate(member, {
                    onError: (error) => {
                        showErrorMessage(error.response?.data)
                    }
                });
            } else {
                showErrorMessage(response.error)
                setSubmitting(false);
            }
        });
    };

    const UpdateMember = async (member: MemberRequestDto) => {
        unsetDirty();
        setSubmitting(true);

        let memberUpdate: MemberResponseDto = {
            fullName: `${member.person?.firstName} ${member.person?.lastName}`,
            person: member.person,
            externalReference: member.externalReference,
            countryIso3Code: member.countryIso3Code,
            id: params.id!,
            languageCode: member.languageCode,
            memberTypeFlags: 0
        };
        updateMemberMutation.mutate(memberUpdate, {
            onError: (error) => {
                showErrorMessage(error.response?.data)
            }
        });
    };

    if (!operatorIsSuccess) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <ApiStatus status={operatorStatus} />
                </div>
            </div>
        </>
    );

    if (!memberData) return <ApiStatus status={operatorStatus} />;

    const ctaText: any = {
        [SourceEnum.customers]: i18n.t('COMMON_SUMMARY_COMPANYCONTACT'),
        [SourceEnum.operators]: i18n.t('COMMON_SUMMARY_OPERATORCONTACT'),
        [SourceEnum.landlords]: i18n.t('COMMON_SUMMARY_LANDLORDCONTACT'),
    }

    const title: any = isEdit ? {
        [SourceEnum.customers]: i18n.t('MEMBER_TITLE_EDITCOMPANYCONTACT'),
        [SourceEnum.operators]: i18n.t('MEMBER_TITLE_EDITOPERATORCONTACT'),
        [SourceEnum.landlords]: i18n.t('MEMBER_TITLE_EDITLANDLORDCONTACT'),
    } : {
        [SourceEnum.customers]: i18n.t('MEMBER_TITLE_NEWCOMPANYCONTACT'),
        [SourceEnum.operators]: i18n.t('MEMBER_TITLE_NEWCONTACT'),
        [SourceEnum.landlords]: i18n.t('MEMBER_TITLE_NEWCONTACT'),
    }

    const description: any = {
        [SourceEnum.customers]: i18n.t('COMMON_SUMMARY_COMPANYCONTACTMANAGEMENT'),
        [SourceEnum.operators]: i18n.t('COMMON_SUMMARY_OPERATORCONTACTMANAGEMENT'),
        [SourceEnum.landlords]: i18n.t('COMMON_SUMMARY_LANDLORDCONTACTMANAGEMENT'),
    }

    return (
        <>
            <ConfirmationChangesDialog
                handleConfirm={confirmDirtyAction}
                handleCancel={cancelDirtyAction}
                showConfirmation={showConfirmation}
            />

            <SectionHead
                linkCallBack={linkCallBack}
                ctaText={ctaText[source]}
                name={`${memberData.fullName} (${memberData.externalReference})`}
                title={title[source]}
                description={description[source]}
            />

            <div className="row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <MemberForm
                        type={memberType}
                        submitting={submitting}
                        member={member}
                        handleDirty={handleDirty}
                        isEdit={isEdit}
                        submitted={(member) => (isEdit) ? UpdateMember(member) : AddMember(member)}
                    />
                </div>
            </div>
        </>
    );
};

export { ContactManage };

