import "./App.css"
import { GlobalStateProvider } from '../components/Common/GlobalStateProvider';
import Pont from './Pont';

function App() {
  return (
    <GlobalStateProvider>
      <Pont></Pont>
    </GlobalStateProvider>
  );
}

export default App;
