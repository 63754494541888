import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { useNavigate, useParams } from "react-router";
import { SectionHead } from "../Common/Help/SectionHead";
import { SaveSnackBar } from "../Common/SaveSnackBar";
import { LR } from "../Common/Help/LR";
import { Grid, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { PersonaResponseDto } from "../../types/Persona/PersonaResponseDto";
import { useDirtyState } from "../../hooks/Common/dirty";
import { ConfirmationChangesDialog } from "../Common/ConfirmationChangesDialog";
import { Input } from "../Common/formComponents/Input";
import { useFetchPersonaById, useFetchScopes, useUpdateScopes } from "../../hooks/Personas/persona";
import { showSnackbar } from "../Common/UI/CustomSnackbar";
import ApiStatus from "../Common/Errors/ApiStatus";
import MessageAlert from "../Common/Errors/MessageAlert";
import pontScopes from "../../data/persona/scopes.json";
import { ScopeRoleUpdateRequestDto, ScopeUpdateRequestDto } from "../../types/Persona/ScopeUpdateRequestDto";

type Args =
  {
    userLogged: ApplicationUser
  }

const PersonaSummary = ({ userLogged }: Args) => {
  let defaultPersona: PersonaResponseDto = {
    name: '',
    description: '',
    partnerMemberId: userLogged.partner?.partnerMemberId!
  }
  const nav = useNavigate();
  const queryStringParams = useParams();

  const { data: persona, status } = useFetchPersonaById(queryStringParams.id!);
  const { data: assignedScopes } = useFetchScopes(queryStringParams.id!);

  const [personaState, setPersonaState] = useState<PersonaResponseDto>(defaultPersona);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [isDirty, setDirty, unsetDirty] = useDirtyState();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [targetUrl, setTargetUrl] = useState('');
  const roles = ["Administrator", "Editor", "Viewer", "Access Denied"];
  const [scopes, setScopes] = useState<ScopeRoleUpdateRequestDto[]>(
    pontScopes.map(scope => ({
      scopeName: scope.name,
      roleName: roles[3] // Default to "Access Denied"
    }))
  );

  useEffect(() => {
    if (persona && status) {
      setPersonaState(persona);
    }
  }, [persona, status]);

  useEffect(() => {
    if (assignedScopes) {
      setScopes((prevScopes) =>
        prevScopes.map(scope => {
          const assignedScope = assignedScopes.find(as => as.scopeName === scope.scopeName);
          return assignedScope ? { ...scope, roleName: assignedScope.roleName } : scope;
        })
      );
    }
  }, [assignedScopes]);

  const updatedCallback = () => {
    setSnackbarOpen(true);
    unsetDirty()
  }

  const update = useUpdateScopes(updatedCallback);

  const homeCallBack = () => {
    let url = `/personas`;
    if (isDirty) {
      setShowConfirmation(true);
      setTargetUrl(url);
    } else {
      nav(url);
    }
  };

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      let updateRequest: ScopeUpdateRequestDto = {
        personaId: queryStringParams.id!,
        scopeRoles: scopes!
      }
      update.mutate(updateRequest, {
        onSuccess: () => {
          updatedCallback();
        },
        onError: (error) => {
          showSnackbar(error.response?.data, {
            persist: true,
            variant: 'error'
          });
        },
      });
    } catch (err: any) {
      console.error(err);
    }
  }

  const handleChange = (name: string, value: any) => {
    setPersonaState({ ...personaState!, [name]: value });
    setDirty();
  }

  const requiredPopulated = () => {
    return true;
  }

  const handleSnackClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleRoleChange = (scopeLabel: string, role: string) => {
    setScopes((prevScopes) =>
      prevScopes.map(scope =>
        scope.scopeName === scopeLabel ? { ...scope, roleName: role } : scope
      )
    );
    setDirty(); // Mark form as dirty
  };

  if (!status) return (
    <>
      <div className="mt-5 row d-flex justify-content-center">
        <div className="col-md-5 col-11 justify-content-center">
          <ApiStatus status={status} />
        </div>
      </div>
    </>
  )

  if (!persona) return <MessageAlert message="PERSONA_LABEL_PERSONANOTFOUND" params={{ param0: '' }}></MessageAlert>;

  return (
    <>

      <SectionHead linkCallBack={homeCallBack}
        ctaText={i18n.t('MENU_LABEL_PERSONAS')!}
        name={`${personaState.name}`}
        title={i18n.t("MENU_LABEL_SUMMARY")}
        description={i18n.t("COMMON_SUMMARY_TICKETMANAGEMENT")} />

      <ConfirmationChangesDialog
        handleConfirm={() => {
          setShowConfirmation(false);
          unsetDirty();
          nav(targetUrl);
        }}
        handleCancel={() => setShowConfirmation(false)}
        showConfirmation={showConfirmation}
      />

      <form onSubmit={onFormSubmit} >

        <div className="row d-flex justify-content-center">
          <div className="col-md-7 col-11 justify-content-center">
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12}>
                <Input
                  placeholder={i18n.t("PERSONA_LABEL_NAME").toString()}
                  value={personaState.name}
                  onChange={e => handleChange('name', e.target.value)}
                  required
                  labelProps={{
                    message: 'PERSONA_LABEL_NAME',
                    mandatory: true
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  placeholder={i18n.t("PERSONA_LABEL_DESCRIPTION").toString()}
                  value={personaState.description}
                  onChange={e => handleChange('description', e.target.value)}
                  required
                  labelProps={{
                    message: 'PERSONA_LABEL_DESCRIPTION',
                    mandatory: true
                  }}
                />
              </Grid>
              {scopes.map((scope) => (
                <Grid item xs={3} key={scope.scopeName}>
                  <h3 style={{ fontSize: "16px", margin: "0 0 10px 0" }}>{i18n.t(scope.scopeName)}</h3>
                  {roles.map((role) => (
                    <label
                      key={role}
                      style={{ display: "block", paddingLeft: "5px", marginBottom: "5px", fontSize: "14px" }} // Adjusted font size
                    >
                      <input
                        type="radio"
                        name={scope.scopeName}
                        value={role}
                        checked={scope.roleName === role}
                        onChange={() => handleRoleChange(scope.scopeName, role)}
                      />
                      {role}
                    </label>
                  ))}
                </Grid>
              ))}

            </Grid>

            <div className="row d-flex justify-content-center mb-2">
              <div className="col-md-4 col-6 justify-content-center">
                <Tooltip title={!requiredPopulated() ? i18n.t('TICKET_REQUIRED_TOOLTIP') : ''}>
                  <span>
                    <button
                      type="submit"
                      disabled={!requiredPopulated() || !isDirty}
                      style={!requiredPopulated() ? { pointerEvents: 'none' } : {}}
                      className="btn btn-green btn-primary btn-block"
                    >
                      <LR localResource="COMMON_LABEL_SAVE"></LR>
                    </button>
                  </span>
                </Tooltip>
              </div>
            </div>
            <SaveSnackBar onClose={handleSnackClose} isOpen={snackbarOpen} />
          </div>
        </div>

      </form>
    </>
  );
};

export { PersonaSummary }