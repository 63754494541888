import { Collapse, IconButton, Paper, Typography } from "@mui/material";
import { useState } from "react";
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import "../../Common/SummaryItems/SummaryItem.css"
import MessageAlert, { AlertStyle } from "../../Common/Errors/MessageAlert";
import Switch from '@mui/material/Switch';
import { useManageProductPortfolioProducts } from "../../../hooks/Product/product";
import { ProductPortfolioProductInstanceDto } from "../../../types/Product/ProductPortfolioProductInstanceDto";
import { ProductPortfolioPricingContainer } from "./ProductPortfolioPricingContainer";
import { PriceDto } from "../../../types/Price/PriceDto";
import { OperationResultDto } from "../../../types/Common/OperationResultDto";
import { ApplicationConfig } from "../../../config/ApplicationConfig";

type Args = {
  items: ProductPortfolioProductInstanceDto[],
  currencyCode: string,
  mutationSwitchCallBack: (operationResult: OperationResultDto) => void,
  pricingCallBack: (item: PriceDto) => void,
  handleDirty: () => void
};

export const ProductPortfolioProductInstance = ({ items, currencyCode, mutationSwitchCallBack, pricingCallBack, handleDirty }: Args) => {
  const internalMutationCallback = (productId: string, responseId: string, operationResult: OperationResultDto) => {
    if (operationResult.isSuccess) {
      const updatedItems = productsItems.map(obj =>
        obj.productId === productId ? { ...obj, id: responseId } : obj
      );
      setProductsItems(updatedItems);
    }
    mutationSwitchCallBack(operationResult);
  };

  const [productIsCollapseOpen, setProductIsCollapseOpen] = useState<boolean[]>(items.map((x) => false));
  const [productsItems, setProductsItems] = useState<ProductPortfolioProductInstanceDto[]>(items);
  const [error, setError] = useState<string>('');
  const mutationUpdate = useManageProductPortfolioProducts(internalMutationCallback)
  const [toggleStates, setToggleStates] = useState<boolean[]>(items.map((x) => (x.id !== ApplicationConfig.emptyGuid)));

  // Handle toggle change for a specific item based on its index
  const handleToggleChange = (index: number, item: ProductPortfolioProductInstanceDto) => {
    setToggleStates((prevStates) => {
      const updatedStates = [...prevStates!];
      updatedStates[index] = !updatedStates[index];
      return updatedStates;
    });

    item.isDelete = item.id !== ApplicationConfig.emptyGuid;
    mutationUpdate.mutate(item);
  };

  const handlCollapse = (index: number) => {
    setProductIsCollapseOpen((prevStates) => {
      const updatedStates = [...prevStates!];
      updatedStates[index] = !updatedStates[index];
      return updatedStates;
    });
  };

  return (
    <>
      <div>
        <div className="row d-flex justify-content-center">
          <div className="justify-content-center">
            {productsItems.map((item, index) => (
              <>
                <Paper key={index} elevation={24} className="fa-paper-grid">
                  <div className="d-flex align-items-center">
                    <div className="col-7 fa-name flex-grow-1">
                      <Typography key={index} variant="h6" className="linkbutton">
                        {item.productName}
                      </Typography>
                    </div>

                    <div className="col-2 d-flex justify-content-end" style={{ flex: 1 }}>
                      <Switch key={index}
                        checked={toggleStates![index]}
                        onChange={() => handleToggleChange(index, item)}
                        color="primary"
                      />
                    </div>
                    <div className="fa-icons">
                      <IconButton key={index} className="fa-arrow" onClick={() => handlCollapse(index)}>
                        {!productIsCollapseOpen![index] && <ArrowForwardIosRoundedIcon />}
                        {productIsCollapseOpen![index] && <ArrowForwardIosRoundedIcon className='fa-arrow-down' />}
                      </IconButton>
                    </div>
                  </div>
                  <Collapse key={index} in={productIsCollapseOpen![index]}>
                    <div className={`row d-flex align-items-center mt-1 ${toggleStates[index] ? '' : 'disabled-content'}`}>
                      <ProductPortfolioPricingContainer
                        catalogueId={item.portfolioId}
                        productId={item.productId}
                        currencyCode={currencyCode}
                        callBack={pricingCallBack}
                        handleDirty={handleDirty}
                      />
                    </div>
                  </Collapse>
                </Paper>
              </>
            ))}
          </div>
        </div>
        {
          error && <div className="row d-flex justify-content-center">
            <div className="justify-content-center">
              <MessageAlert
                message={error}
                variant="danger"
                style={AlertStyle.Tiny}
                additionalClassName="fa-alert-tiny"
              />
            </div>
          </div>
        }
      </div>
    </>
  );
}