import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../../types/Authentication/ApplicationUser";
import i18n from "../../../i18n";
import { SectionHead } from "../../Common/Help/SectionHead";
import { LabelAndHelp } from "../../Common/Help/LabelAndHelp";
import { NotificationRequestDto } from "../../../types/Notification/NotificationRequestDto";
import { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { FormControlLabel, FormGroup, Switch, Tooltip } from "@mui/material";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { LocationSelector } from "../../Location/LocationSelector";
import { LocationResponseDto } from "../../../types/Location/LocationResponseDto";
import Select from "react-select";
import { SaveSnackBar } from "../../Common/SaveSnackBar";
import { LR } from "../../Common/Help/LR";
import { useCreateNotificationRequest, useFetchNotificationRequestById, useFetchNotificationRequestStatuses } from "../../../hooks/Notification/notification-requests";
import { useFetchEvents } from "../../../hooks/Events/event";
import { ConfirmationChangesDialog } from "../../Common/ConfirmationChangesDialog";


type Args =
    {
        userLogged: ApplicationUser,
        isCreate: boolean
    }

const NotificationRequestCreate = ({ userLogged, isCreate }: Args) => {
    let defaultNotificationRequest: NotificationRequestDto = {
        id: "",
        triggerEntityName: "Location",
        triggerEntityKey: "",
        description: "",
        notificationTypeName: "Message",
        groupName: "",
        isRead: false,
        expiryDateUtc: new Date(),
        applicationFlags: 0,
        languageCode: "en-GB",
        hubPushNotification: false,
        ignoreTranslation: false,
        partnerMemberId: userLogged.partner?.partnerMemberId!,
        editUserId: userLogged.id
    };

    dayjs.extend(utc);
    dayjs.extend(timezone);

    const createdCallback = (eventId: string) => {
        nav(`/application/${queryStringParams.id}/configuration/notifications`);
        success();
    }

    const success = () => {
        setSnackbarOpen(true);
    };

    const nav = useNavigate();
    const queryStringParams = useParams();
    const [useNotificationRequest, setUseNotificationRequest] = useState<NotificationRequestDto>(defaultNotificationRequest);
    const targetEntityOptions = [
        { label: 'NOTIFICATIONREQUEST_ENTITY_LOCATION', value: 'Location' },
        { label: 'NOTIFICATIONREQUEST_ENTITY_EVENT', value: 'Event' }
    ];
    const [selectedEntity, setSelectedEntity] = useState<{ value: string, label: string }>({ label: "NOTIFICATIONREQUEST_ENTITY_LOCATION", value: "Location" });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const create = useCreateNotificationRequest(createdCallback);
    const { data: notificationRequest, status: notificationRequestStatus, isSuccess: notificationRequestIsSuccess } = useFetchNotificationRequestById(queryStringParams.notificationRequestId!, !isCreate);
    const { data: events, status: eventsStatus, isSuccess: eventsIsSuccess } = useFetchEvents(userLogged.operator?.id!);
    const [eventOptions, setEventOptions] = useState<{ value: string, label: string }[]>([]);
    const [selectedEvent, setSelectedEvent] = useState<{ value: string, label: string }>();
    const { data: statuses } = useFetchNotificationRequestStatuses();
    const [showConfirmation, setShowConfirmation] = useState(false);

    useEffect(() => {
        if (!isCreate) {
            if (notificationRequestIsSuccess && notificationRequest) {
                notificationRequest.expiryDateUtc = new Date(notificationRequest.expiryDateUtc);

                setSelectedEntity(targetEntityOptions.find(x => x.value === notificationRequest.triggerEntityName)!);
                setUseNotificationRequest(notificationRequest);
            }
        }
    }, [isCreate, notificationRequestStatus, notificationRequest, notificationRequestIsSuccess]);

    useEffect(() => {
        if (eventsIsSuccess) {
            setEventOptions(events.map(x => ({ label: x.name, value: x.id })));
            setSelectedEvent({ label: events[0].name, value: events[0].id });
            if (!isCreate) {
                let preSelectedEvent = events.find(x => x.id === useNotificationRequest.triggerEntityKey);
                setSelectedEvent({ label: preSelectedEvent?.name!, value: preSelectedEvent?.id! });
            }
        }
    }, [isCreate, events, eventsStatus, eventsIsSuccess, useNotificationRequest.triggerEntityKey]);

    const linkCallBack = () => {
        nav(`/application/${queryStringParams.id}/configuration/notifications`);
    }

    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            setShowConfirmation(true);
        } catch (err: any) {
            console.error(err);
        }
    }

    const dialogConfirm = () => {
        setShowConfirmation(false);
        create.mutate(useNotificationRequest);
    };

    const dialogCancel = () => {
        setShowConfirmation(false);
    };

    const confirmLocation = (location: LocationResponseDto) => {
        setUseNotificationRequest({ ...useNotificationRequest, triggerEntityKey: location.id });
    }

    const handleEntityChange = (selectedOption: any) => {
        setSelectedEntity(selectedOption);
        setUseNotificationRequest({ ...useNotificationRequest, triggerEntityName: selectedOption.value });
    }

    const handleSelectedEventChange = (selectedOption: any) => {
        setSelectedEvent(selectedOption);
        setUseNotificationRequest({ ...useNotificationRequest, triggerEntityKey: selectedOption.value });
    }

    const requiredPopulated = () => {
        return !!useNotificationRequest.description &&
            !!useNotificationRequest.groupName &&
            !!useNotificationRequest.triggerEntityKey;
    }

    const handleSnackClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <>
            <form onSubmit={onFormSubmit}>
                <SectionHead linkCallBack={linkCallBack} ctaText={i18n.t("APPLICATION_LABEL_NOTIFICATIONS")!} name={isCreate ? i18n.t('NOTIFICATIONREQUEST_TITLE_CREATE') : i18n.t('NOTIFICATIONREQUEST_TITLE_VIEW')} title={i18n.t('NOTIFICATIONREQUEST_SUMMARY_MAIN')} description={i18n.t('NOTIFICATIONREQUEST_SUMMARY_MAIN')} />

                {!isCreate &&
                    <>
                        <LabelAndHelp mandatory={false} message='NOTIFICATIONREQUEST_TITLE_STATE'></LabelAndHelp>

                        <div className="row d-flex justify-content-center mb-3">
                            <div className="col-md-7 col-11 d-flex align-items-center">
                                <div className="col-6">
                                    <input type="text"
                                        disabled={!isCreate}
                                        className="form-control fa-form"
                                        placeholder={i18n.t(statuses?.find(x => x.id === useNotificationRequest.notificationRequestStatusId)?.label!).toString()}
                                        required>
                                    </input>
                                </div>

                                <div className="col-6 m-3">
                                    <input type="text"
                                        disabled={!isCreate}
                                        value={useNotificationRequest.details}
                                        className="form-control fa-form"
                                        placeholder={i18n.t("NOTIFICATIONREQUEST_LABEL_DETAILS").toString()}
                                        required>
                                    </input>
                                </div>
                            </div>
                        </div>
                    </>
                }

                <LabelAndHelp mandatory={isCreate} message='NOTIFICATIONREQUEST_LABEL_DESCRIPTION'></LabelAndHelp>
                <div className="row d-flex justify-content-center mb-3">
                    <div className={`col-md-7 col-11 justify-content-center ${isCreate ? '' : 'disabled-content'}`}>
                        <input type="text"
                            disabled={!isCreate}
                            value={useNotificationRequest.description}
                            className="form-control fa-form"
                            onChange={(e) => {
                                setUseNotificationRequest({ ...useNotificationRequest, description: e.target.value });
                            }}
                            placeholder={i18n.t("NOTIFICATIONREQUEST_LABEL_DESCRIPTION").toString()}
                            required>
                        </input>
                    </div>
                </div>

                <LabelAndHelp mandatory={isCreate} message='NOTIFICATIONREQUEST_LABEL_GROUPNAME'></LabelAndHelp>
                <div className="row d-flex justify-content-center mb-3">
                    <div className={`col-md-7 col-11 justify-content-center ${isCreate ? '' : 'disabled-content'}`}>
                        <input type="text"
                            disabled={!isCreate}
                            value={useNotificationRequest.groupName}
                            className="form-control fa-form"
                            onChange={(e) => {
                                setUseNotificationRequest({ ...useNotificationRequest, groupName: e.target.value });
                            }}
                            placeholder={i18n.t("NOTIFICATIONREQUEST_LABEL_GROUPNAME").toString()}
                            required>
                        </input>
                    </div>
                </div>

                <LabelAndHelp mandatory={isCreate} message='NOTIFICATIONREQUEST_LABEL_ENTITY'></LabelAndHelp>
                <div className="row d-flex justify-content-center mb-3">
                    <div className={`col-md-7 col-11 justify-content-center ${isCreate ? '' : 'disabled-content'}`}>
                        <Select
                            value={selectedEntity}
                            className="form-control fa-form fa-dropdown-container"
                            classNamePrefix="fa-dropdown"
                            options={targetEntityOptions}
                            getOptionLabel={option => i18n.t(option.label)}
                            onChange={handleEntityChange}
                            placeholder={i18n.t('NOTIFICATIONREQUEST_LABEL_ENTITY').toString()}
                        />
                    </div>
                </div>

                {useNotificationRequest.triggerEntityName === 'Location' &&
                    <>
                        <div className="row d-flex justify-content-center mb-3">
                            <div className="col-md-7 col-11 justify-content-center">
                                <LocationSelector
                                    locationId={useNotificationRequest.triggerEntityKey!}
                                    placeholder="NOTIFICATIONREQUEST_LABEL_LOCATION"
                                    operatorId={userLogged.operator?.id!}
                                    handleConfirm={confirmLocation}
                                    labelProps={{
                                        message: 'NOTIFICATIONREQUEST_LABEL_LOCATION',
                                        mandatory: isCreate,
                                    }}
                                />
                            </div>
                        </div>
                    </>
                }

                {useNotificationRequest.triggerEntityName === 'Event' &&
                    <>
                        <LabelAndHelp mandatory={isCreate} message='NOTIFICATIONREQUEST_LABEL_EVENT'></LabelAndHelp>
                        <div className="row d-flex justify-content-center mb-3">
                            <div className={`col-md-7 col-11 justify-content-center ${isCreate ? '' : 'disabled-content'}`}>
                                <Select
                                    value={selectedEvent}
                                    className="form-control fa-form fa-dropdown-container"
                                    classNamePrefix="fa-dropdown"
                                    options={eventOptions}
                                    onChange={handleSelectedEventChange}
                                    placeholder={i18n.t('NOTIFICATIONREQUEST_LABEL_EVENT').toString()}
                                />
                            </div>
                        </div>
                    </>
                }

                <div className="row d-flex justify-content-center mb-3">
                    <div className="col-md-7 col-11 d-flex align-items-center">
                        <div className="col-4">
                            <DatePicker
                                disabled={!isCreate}
                                label={i18n.t('NOTIFICATIONREQUEST_LABEL_EXPIRYDATE').toString()}
                                className="fa-datepicker w-100"
                                value={dayjs(useNotificationRequest.expiryDateUtc)}
                                timezone="UTC"
                                onChange={(newValue) => {
                                    setUseNotificationRequest({ ...useNotificationRequest, expiryDateUtc: newValue?.toDate()! });
                                }}
                            />
                        </div>

                        <div className="col-4 m-3">
                            <FormGroup className="d-flex justify-content-center">
                                <FormControlLabel
                                    control={<Switch disabled={!isCreate} color="secondary" />}
                                    label={i18n.t("NOTIFICATIONREQUEST_LABEL_PUSHNOTIFICATION")}
                                    checked={useNotificationRequest.hubPushNotification}
                                    onChange={() => setUseNotificationRequest({ ...useNotificationRequest, hubPushNotification: !useNotificationRequest.hubPushNotification })}
                                />
                            </FormGroup>
                        </div>

                        <div className="col-4">
                            <FormGroup className="d-flex justify-content-center">
                                <FormControlLabel
                                    control={<Switch disabled={!isCreate} color="secondary" />}
                                    label={i18n.t("NOTIFICATIONREQUEST_LABEL_IGNORETRANSLATION")}
                                    checked={useNotificationRequest.ignoreTranslation}
                                    onChange={() => setUseNotificationRequest({ ...useNotificationRequest, ignoreTranslation: !useNotificationRequest.ignoreTranslation })}
                                />
                            </FormGroup>
                        </div>
                    </div>
                </div>

                {isCreate && <div className="row d-flex justify-content-center mt-3">
                    <div className="col-md-2 col-6 justify-content-center">
                        <Tooltip title={!requiredPopulated() ? i18n.t('NOTIFICATIONREQUEST_REQUIRED_TOOLTIP') : ''}>
                            <span>
                                <button
                                    type="submit"
                                    disabled={!requiredPopulated()}
                                    style={!requiredPopulated() ? { pointerEvents: 'none' } : {}}
                                    className="btn btn-green btn-primary btn-block"
                                >
                                    <LR localResource="COMMON_LABEL_SAVE" />
                                </button>
                            </span>
                        </Tooltip>
                    </div>
                </div>
                }


                <ConfirmationChangesDialog customConfirmLabel="COMMON_LABEL_SAVE" buttonVariant="success" customTitle="NOTIFICATIONREQUEST_TITLE_SAVEWARNING" customDescription="NOTIFICATIONREQUEST_LABEL_SAVEWARNING" handleConfirm={dialogConfirm} handleCancel={dialogCancel} showConfirmation={showConfirmation} />
                <SaveSnackBar onClose={handleSnackClose} isOpen={snackbarOpen} />
            </form>
        </>
    );
};


export { NotificationRequestCreate }