import EditIcon from '@mui/icons-material/Edit';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { Box, IconButton, Tooltip } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useFetchMembers } from "../../hooks/Member/member";
import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { SectionHead } from "../Common/Help/SectionHead";
import { useSource } from '../../providers/SourceProvider';
import { SourceEnum } from '../../types/Common/CommonSummaryHeaderProps';
import { CustomButton } from '../Common/UI/CustomButton';
import { LR } from '../Common/Help/LR';

type Args = {
    userLogged: ApplicationUser
}

export const MemberList = ({ userLogged }: Args) => {
    const { source } = useSource()

    const path: any = {
        [SourceEnum.landlords]: `landlords`,
        [SourceEnum.operators]: `operators`
    }

    let flag = 4;
    if (source === SourceEnum.landlords) {
        flag = 16384;
    }

    const { data: members } = useFetchMembers(flag, "", 2);
    const nav = useNavigate();

    const columns: GridColDef[] = [
        { field: 'fullName', headerName: i18n.t('MEMBER_LABEL_NAME')!, flex: 1 },
        { field: 'countryIso3Code', headerName: i18n.t('COMMON_LABEL_COUNTRY')!, flex: 1 },
        {
            field: 'edit', headerName: '', flex: 0.1, align: "center", filterable: false,
            renderCell: (params) => {
                return (
                    <IconButton color="secondary" onClick={() => nav(`/${path[source]}/${params.id}/summary`)}>
                        <EditIcon fontSize="small" />
                    </IconButton>
                )
            }
        }
    ]

    const title: any = {
        [SourceEnum.operators]: "COMMON_LABEL_OPERATORS",
        [SourceEnum.landlords]: "COMMON_LABEL_LANDLORDS"
    }
    const description: any = {
        [SourceEnum.operators]: "COMMON_SUMMARY_OPERATORMANAGEMENT",
        [SourceEnum.landlords]: "COMMON_SUMMARY_LANDLORDMANAGEMENT"
    }

    const toolTip: any = {
        [SourceEnum.operators]: "ACTIVITY_LABEL_NEWOPERATOR",
        [SourceEnum.landlords]: "ACTIVITY_LABEL_NEWLANDLORD"
    }
    const addToUrl: any = {
        [SourceEnum.operators]: "/operators/add",
        [SourceEnum.landlords]: "/landlords/add"
    }

    if (!members) {
        return <>Could not find any data</>
    }

    return (
        <>
            <SectionHead linkCallBack={() => { nav('/admin') }}
                ctaText={i18n.t('COMMON_LABEL_ADMIN')!}
                name=""
                title={i18n.t(title[source]!)}
                description={i18n.t(description[source]!)} />

            <div className="row mb-3 d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <Box sx={{ mb: 2 }}>
                        <Tooltip title={i18n.t(toolTip[source]!)}>
                            <CustomButton onClick={() => nav(addToUrl[source]!)} color='secondary' sx={{ textTransform: 'none' }}>
                                <AddCircleRoundedIcon />
                                <Box sx={{ ml: 1 }}>
                                    <LR localResource={toolTip[source]!} />
                                </Box>
                            </CustomButton>
                        </Tooltip>
                    </Box>
                    <DataGrid
                        rows={members}
                        columns={columns}
                        classes={{
                            toolbarContainer: 'toolbarContainer'
                        }}
                        slots={{
                            toolbar: GridToolbar,
                        }}
                        checkboxSelection={false}
                        disableColumnSelector={true}
                        disableDensitySelector={true}
                        sx={{
                            minHeight: 200
                        }}
                    />
                </div>
            </div>
        </>
    );
};
