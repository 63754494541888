import { Box, IconButton, InputAdornment } from "@mui/material";
import i18n from "../../i18n";
import { MemberResponseDto } from "../../types/Member/MemberResponseDto";
import { useEffect, useState } from "react";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { DialogOperatorSelector } from "./DialogOperatorSelector";
import { useFetchMembers } from "../../hooks/Member/member";
import ApiStatus from "../Common/Errors/ApiStatus";
import MessageAlert from "../Common/Errors/MessageAlert";
import { ApplicationConfig } from "../../config/ApplicationConfig";
import { Input } from "../Common/formComponents/Input";
import { LabelType } from "../Common/formComponents/Label";

type Args = {
  operatorId: string,
  legalEntityMemberId: string,
  placeholder?: string,
  displayMemberType?: string,
  handleConfirm: (operator: MemberResponseDto) => void
  flagType: number;
  labelProps?: LabelType;
}

const ParentMemberSelector = ({ operatorId, legalEntityMemberId, placeholder, handleConfirm, flagType, displayMemberType, labelProps }: Args) => {
  const { data: legalEntities, status, isSuccess } = useFetchMembers(flagType, operatorId, 3);
  const [selectedRow, setSelectedRow] = useState<MemberResponseDto | null>(null);
  const [open, setOpen] = useState(false);
  const [operatorName, setOperatorName] = useState('');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleValueSelection = (operator: MemberResponseDto) => {
    setOperatorName(operator.fullName)
    handleConfirm(operator);
    handleClose();
  };

  const getPlaceholder = () => {
    return i18n.t(placeholder ?? "LOCATION_LABEL_LEGALENTITY").toString();
  }

  const handleChange = () => {
    if (!operatorId || !legalEntities) return;
    if (legalEntityMemberId === ApplicationConfig.emptyGuid || legalEntityMemberId === null) return;

    const selectedLegalEntity = legalEntities.find(x => x.id === legalEntityMemberId);
    if (!selectedLegalEntity) return;

    setSelectedRow(selectedLegalEntity);
    handleValueSelection(selectedLegalEntity);
  }

  useEffect(() => {
    handleChange();
  }, [operatorId, legalEntityMemberId, legalEntities]);

  useEffect(() => {
    //only 1 operator. we'll select it automatically
    if (legalEntities?.length === 1) {
      setSelectedRow(legalEntities[0]);
      handleValueSelection(legalEntities[0]);
    }
  }, [legalEntities]);

  if (!isSuccess) return (
    <>
      <div className="mt-5 row d-flex justify-content-center">
        <div className="col-md-7 col-11 justify-content-center">
          <ApiStatus status={status} />
        </div>
      </div>
    </>
  )

  if (!legalEntities) return <MessageAlert message="MEMBER_LABEL_MEMBERSNOTFOUND"></MessageAlert>;

  return (
    <>
      <DialogOperatorSelector type={displayMemberType ?? 'LegalEntity'} operators={legalEntities} open={open} handleCancel={handleClose} handleConfirm={handleValueSelection}></DialogOperatorSelector>
      <div>
        <Input
          style={{ opacity: 1 }}
          type="text"
          placeholder={getPlaceholder()}
          value={operatorName}
          disabled
          labelProps={labelProps}
          rightIcon={
            <Box>
              <IconButton onClick={handleOpen}>
                <SearchRoundedIcon style={{ color: "white" }} />
              </IconButton>
            </Box>
          }
        />
      </div>
    </>
  );
};


export { ParentMemberSelector }