import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Control, useForm } from 'react-hook-form';
import { QueryObserverResult } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { pontOrderStatus, pontOrderSubStatus } from '../../../data/order/OrderData';

import yup from '../../../functions/utils/yup';
import { useSaveCustomerLocalStorage } from '../../../hooks/Customer/useSaveCustomerLocalStorage';
import { useDeleteBasket, useGetBasketById, usePaymentsMethodByUser, usePlaceOrder, useUpdateBasketStatus, useUpdateOrder, useUpdatePlaceOrder } from '../../../hooks/Order/basket';
import i18n from '../../../i18n';
import { ApplicationUser } from '../../../types/Authentication/ApplicationUser';
import { CustomerSearchItemResponseDto } from '../../../types/Customer/CustomerSearchItemResponseDto';
import { BasketResponse } from '../../../types/Order/BasketResponse';
import { OrderLineRequest } from '../../../types/Order/OrderLineRequest';
import { showSnackbar } from '../../Common/UI/CustomSnackbar';
import { ApplicationConfig } from '../../../config/ApplicationConfig';
// Define the type of the context value
interface OrderContextType {
  basketId: string | undefined
  control: Control<any>,
  currentBasket: BasketResponse | null | undefined,
  customerOpen: boolean,
  formattedOrderStatus: any,
  formattedOrderSubStatus: any,
  getLatestBasketDetail: () => void,
  handleCancelMemberSelector: () => void,
  handleCreateNewOrderLine: () => void,
  handleCustomerConfirm: (selectedCustomer: CustomerSearchItemResponseDto) => void,
  handleEditOrderLine: (id: string) => void,
  isBasketPage?: boolean;
  isPlaceOrderLoading: boolean,
  isPlacingOrder: boolean,
  isValid: boolean,
  memberId: string,
  onOrderStatusChange: (value: any) => void,
  onOrderSubStatusChange: (value: any) => void,
  orderEditMode: boolean,
  orderLineEditMode: boolean,
  orderLineOpen: boolean,
  orderLines: OrderLineRequest[],
  orderStatusName?: { label: string; value: string; name: string; },
  orderSubStatusName?: { label: string; value: string; name: string; },
  parentMemberId: string | undefined,
  paymentMethods: any,
  placeOrder: () => void,
  readOnlyMode: boolean | undefined,
  refetchBasket: () => Promise<QueryObserverResult<BasketResponse, AxiosError<unknown, any>>>,
  refetchingBasket: boolean,
  selectedOrderLine: OrderLineRequest | undefined,
  setCustomerOpen: (customerOpen: boolean) => void,
  setOrderLineOpen: (orderLineOpen: boolean) => void,
  setSelectedOrderLine: (selectedOrderLine: OrderLineRequest | undefined) => void,
  userLogged: ApplicationUser,
  rollingContractOptions: {
    label: string;
    value: boolean;
  }[],
  showRollingContract?: boolean
  isDirty?: boolean
  deleteBasket: () => void
  goBack: () => void,
  cancelDirtyAction: () => void,
  confirmDirtyAction: () => void,
  showConfirmation: boolean
  setShowConfirmation: (arg: boolean) => void,
  handelChange: () => void,
  watch: any
  permission: any,
  getActionFlags: (arg: any) => any,
  disabledSaveButton: boolean,
}

// Create the context
const OrderContext = createContext<OrderContextType | undefined>(undefined);

// Provider component
interface OrderProviderProps {
  children: ReactNode;
  userLogged: ApplicationUser;
  isBasketPage?: boolean;
  readOnly?: boolean;
}

const getFormattedOrderSubStatus = (orderSubStatusName: string) => {
  const newFormattedOrderSubStatus = pontOrderSubStatus
    .filter((x) => x.orderStatus === orderSubStatusName)
    .map((item) => ({
      label: i18n.t(item.label),
      value: item.value,
      name: item.value,
    }));
  return newFormattedOrderSubStatus;
}

export const OrderProvider: FC<OrderProviderProps> = (props) => {
  const { children, userLogged, isBasketPage, readOnly } = props;
  const { basketId } = useParams()
  const nav = useNavigate()

  const readOnlyMode = Boolean(readOnly)

  const [disabledSaveButton, setDisabledSaveButton] = useState(false);
  const [permission, setPermission] = useState<any>({});
  const [customerOpen, setCustomerOpen] = useState(false);
  const [orderLineOpen, setOrderLineOpen] = useState(false);
  const [orderLineEditMode, setOrderLineEditMode] = useState(false);
  const [orderLines, setOrderLines] = useState<OrderLineRequest[]>([]);
  const [selectedOrderLine, setSelectedOrderLine] = useState<OrderLineRequest>();
  const [currentBasket, setCurrentBasket] = useState<BasketResponse>();
  const [paymentMethods, setPaymentMethods] = useState<{ label: string, value: string }[]>();
  const [orderId, setOrderId] = useState<string | null>(null)
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [formattedOrderSubStatus, setFormattedOrderSubStatus] = useState(
    getFormattedOrderSubStatus("Draft")
  );

  const { customerData: localSavedCustomerData } = useSaveCustomerLocalStorage()

  const orderEditMode = Boolean(currentBasket?.orderId)



  const formattedOrderStatus = useMemo(() => pontOrderStatus.map((item) => ({
    label: i18n.t(item.label),
    value: item.value,
    name: item.value,
  })), [pontOrderStatus]);

  // Define the flags
  const ACTION_FLAGS = {
    ACTIONFLAG_NONE: 0,
    ACTIONFLAG_VIEW: 1,
    ACTIONFLAG_EDIT: 2,
    ACTIONFLAG_DELETE: 4,
    ACTIONFLAG_CANCEL: 8,
    ACTIONFLAG_REQUESTTERMINATION: 16,
    ACTIONFLAG_PROCESSTERMINATION: 32,
    ACTIONFLAG_MARKASRENEWED: 64,
    ACTIONFLAG_REINSTATE: 128,
  };

  const getActionFlags = (value: number): string[] => {
    const result: string[] = [];
    for (const [key, flagValue] of Object.entries(ACTION_FLAGS)) {
      if ((value & flagValue) === flagValue) {
        result.push(key);
      }
    }
    return result;
  }

  const { refetch: refetchBasket, isLoading: refetchingBasket } = useGetBasketById({
    basketId: basketId,
    options: {
      enabled: !!basketId,
      onSuccess: (basketDetail) => {
        let _permission: any = {};
        setCurrentBasket(basketDetail);
        setValue("memberId", basketDetail.memberId);
        setValue("parentMemberId", basketDetail.parentMemberId ?? undefined);
        setValue("memberName", basketDetail.memberFullName ?? undefined)
        if (basketDetail?.orderId) {
          setValue("purchaseOrderNumber", basketDetail.purchaseOrderNumber ?? undefined)
          setValue("evolution", basketDetail.evolution ?? undefined)
          setValue("comments", basketDetail.comments ?? undefined)
          setValue("isRolling", basketDetail.isRolling ?? undefined)
        }
        // setValue("showRollingContract", basketDetail.showRollingContract ?? undefined)
        setOrderId(basketDetail.orderId)

        const _selectedOrderStatus = formattedOrderStatus.find((x) => x.value === basketDetail.orderStatusName);

        const orderSubStatusList = getFormattedOrderSubStatus(basketDetail.orderStatusName)
        setFormattedOrderSubStatus(orderSubStatusList)

        const _selectedOrderSubStatus = orderSubStatusList.find((x) => x.value === basketDetail.orderSubStatusName);

        if (_selectedOrderStatus) {
          setValue('orderStatusName', _selectedOrderStatus)
        }
        if (_selectedOrderSubStatus) {
          setValue('orderSubStatusName', _selectedOrderSubStatus)
        }

        setDisabledSaveButton(basketDetail?.orderLines?.length > 0 ? basketDetail?.orderLines?.some((item) => item.hasErrors) : false)

        if (basketDetail?.orderLines) {
          setOrderLines(basketDetail.orderLines);
        }
        trigger()

        basketDetail?.actions?.forEach((action) => {
          if (action?.entityName) {
            _permission[action.entityName] = getActionFlags(action?.actionFlags).filter(x => x !== "ACTIONFLAG_NONE");
          }
        });
        setValue("showRollingContract", (_permission?.RollingContract?.includes("ACTIONFLAG_VIEW")) ?? undefined)
        setPermission(_permission);
      }
    }
  })

  const { mutate: updateBasketStatus } = useUpdateBasketStatus()
  const { mutateAsync: updateOrder, isLoading: isPlaceOrderLoading } = useUpdatePlaceOrder()
  const { mutateAsync: placeOrderMutation, isLoading: isPlacingOrder } = usePlaceOrder()
  const { mutateAsync: updateOrderMutation, } = useUpdateOrder()

  const { mutate: _deleteBasket } = useDeleteBasket()

  const deleteBasket = () => {
    if (basketId) {

    } else {
      nav('/orders');
    }
  }


  const orderSchema = yup.object().shape({
    id: yup.string(),
    locationId: yup.string().required("LocationId is required"),
    memberId: yup.string().required("MemberId is required"),
    memberName: yup.string().optional(),
    locationName: yup.string().optional(),
    parentMemberId: yup.string().optional(),
    //invoiceMemberId: yup.string().required("InvoiceMemberId is required"),
    orderStatusName: yup
      .object()
      .shape({
        label: yup.string().required(),
        value: yup.string().required(),
        name: yup.string().required(),
      })
      .required("The Order Status is required"),
    orderSubStatusName: yup
      .object()
      .shape({
        label: yup.string().required(),
        value: yup.string().required(),
        name: yup.string().required(),
      })
      .required("The Order Sub Status is required"),
    purchaseOrderNumber: yup.string(),
    showRollingContract: yup.boolean(),
    isRolling: yup.boolean().when(['showRollingContract'], {
      is: (showRollingContract: boolean) => showRollingContract,
      then: schema => schema.required("Rolling contract status is required."),
      otherwise: schema => schema,
    }),
    evolution: yup.string(),
    comments: yup.string(),
    paymentMethodName: yup
      .object()
      .shape({
        label: yup.string().optional(),
        value: yup.string().optional(),
        name: yup.string().optional(),
      })
      .optional(),
  });

  const {
    control,
    formState: { errors, isValid, isDirty },
    watch,
    setValue,
    trigger,
    handleSubmit,
    getFieldState
  } = useForm({
    resolver: yupResolver(orderSchema),
    defaultValues: {
      id: basketId,
      locationId: userLogged.location?.id,
      locationName: userLogged.location?.name,
      orderSubStatusName: undefined,
    },
  });



  type OrderPayload = yup.InferType<typeof orderSchema>

  const orderStatusName = watch("orderStatusName");
  const orderSubStatusName = watch("orderSubStatusName");
  const memberId = watch("memberId");
  const parentMemberId = watch("parentMemberId");
  const paymentMethod = watch("paymentMethodName");
  const showRollingContract = watch("showRollingContract");
  // const isRolling = watch("isRolling");
  // const comments = watch("comments")
  // const evolution = watch("evolution")
  // const paymentMethodName = watch("paymentMethodName")
  // const purchaseOrderNumber = watch("purchaseOrderNumber")

  usePaymentsMethodByUser({
    memberId: memberId,
    companyId: parentMemberId,
    options: {
      enabled: !!memberId,
      onSuccess(paymentMethods) {
        if (!paymentMethods?.length) return

        const formattedPaymentMethods = paymentMethods.map((item) => ({
          label: `${i18n.t(item.pspName)}${item.isShared ? ` (Shared)` : ''}`,
          value: item.pspName,
          ...item
        }));

        setPaymentMethods(formattedPaymentMethods);

        // Auto payment method selection
        let shouldAutoSelectPaymentMethod = !paymentMethod || !formattedPaymentMethods.some(x => x.pspName === paymentMethod?.name);

        if (shouldAutoSelectPaymentMethod) {
          setValue("paymentMethodName", formattedPaymentMethods.find((x) => x.isDefault) || formattedPaymentMethods[0])
        }
        // trigger();
      },
    }
  })

  const getLatestBasketDetail = () => {
    if (!basketId) {
      throw new Error("BasketId is required")
    }
    refetchBasket()
  };

  const handleCustomerConfirm = async (selectedCustomer: CustomerSearchItemResponseDto) => {
    setValue("memberName", `${selectedCustomer.firstName} ${selectedCustomer.lastName}`);

    if (selectedCustomer.companyId) {
      setValue("parentMemberId", selectedCustomer.companyId);
    }

    setValue("memberId", selectedCustomer.memberId, { shouldValidate: true, });
    setCustomerOpen(false);
  };

  const handleCancelMemberSelector = () => {
    setCustomerOpen(false);
  };

  const handleEditOrderLine = (id: string) => {
    const selectedOrderLine = orderLines.find((x) => x.id === id);
    if (selectedOrderLine) {
      setOrderLineOpen(true);
      setOrderLineEditMode(true);
      setSelectedOrderLine(selectedOrderLine);
    }
  };

  const handleBasketStatusChange = (_orderStatusName?: string, _orderSubStatusName?: string) => {
    // No status change
    if (_orderSubStatusName === orderSubStatusName?.value) return
    if (!_orderStatusName || !_orderSubStatusName || !basketId) return

    updateBasketStatus({
      basketId: basketId,
      orderStatusName: _orderStatusName,
      orderSubStatusName: _orderSubStatusName
    }, {
      onSuccess: () => {
        // showSnackbar('Status updated successfully!')
        getLatestBasketDetail()
      }
    })
  }

  const onOrderStatusChange = (status: any) => {
    if (!status) return

    const newFormattedOrderSubStatus = pontOrderSubStatus
      .filter((x) => x.orderStatus === status.value)
      .map((item) => ({
        label: i18n.t(item.label),
        value: item.value,
        name: item.value,
      }));

    setFormattedOrderSubStatus(newFormattedOrderSubStatus);

    if (newFormattedOrderSubStatus.length > 1) {
      // If there are more than one order sub status, we will set this as undefined
      setValue('orderSubStatusName', undefined as any)
    }

    if (newFormattedOrderSubStatus.length === 1) {
      setValue('orderSubStatusName', newFormattedOrderSubStatus[0])

      // If there is only one subs-status, we will change that on to the server
      handleBasketStatusChange(status.value, newFormattedOrderSubStatus[0].value)
    }
  }

  const onOrderSubStatusChange = (value: any) => {
    // We will update status on server via api, when user change sub-status
    handleBasketStatusChange(orderStatusName.value, value.value)

  }


  const updateOrders = async (hasError: boolean) => {
    if (orderEditMode && basketId) {
      await updateOrderMutation({
        basketId: basketId,
        trackingData: ""
      },
        {
          onSuccess: () => {
            if (!hasError) {
              showSnackbar('Order updated successfully!')
              nav(`/orders`)
            }
          },
          onError: (error) => {
            showSnackbar(error.response?.data, {
              persist: true,
              variant: 'error'
            })
          }
        })
    }
  }



  const submitOrder = async (data: OrderPayload) => {
    try {
      if (!basketId || !data.paymentMethodName?.value) {
        throw new Error("BasketId or payment method is required")
      }
      const commentFieldState = getFieldState('comments')
      const purchaseOrderNumberFieldState = getFieldState('purchaseOrderNumber')
      const evolutionFieldState = getFieldState('evolution')
      const isRollingFieldState = getFieldState('isRolling')

      let hasError = false


      if (commentFieldState.isDirty || purchaseOrderNumberFieldState.isDirty || evolutionFieldState.isDirty || isRollingFieldState.isDirty) {
        await updateOrder({
          basketId: basketId,
          paymentMethodName: data.paymentMethodName?.value,
          purchaseOrderNumber: data.purchaseOrderNumber,
          comments: data.comments,
          evolution: data.evolution,
          isRolling: data.isRolling
        }, {
          onSuccess: () => {
            if (orderId && orderId !== ApplicationConfig.emptyGuid) {
              showSnackbar('Order updated successfully!')
            }
            updateOrders(hasError)
          },
          onError: (error) => {
            showSnackbar(error.response?.data, {
              persist: true,
              variant: 'error'
            })
          }
        })
      } else {
        updateOrders(hasError)
      }

      if (!orderId || orderId === ApplicationConfig.emptyGuid) {
        await placeOrderMutation({
          basketId: basketId,
          trackingData: ""
        }, {
          onSuccess: (data) => {
            if (data.errors.length > 0) {
              hasError = true
              data.errors.map((item, index) => {
                showSnackbar(`Line ${item.lineNumber} :  ${i18n.t(item.errorLabel).toString()}`, {
                  persist: true,
                  variant: 'error'
                })
              })
              if (!hasError) {
                nav(`/orders`)
              }
            } else {
              nav(`/orders`)
              showSnackbar('Order placed successfully!')
            }
          }
        })
      }

    } catch (error: any) {
      console.error(error)
      showSnackbar(error?.message, {
        persist: true,
        variant: 'error'
      })
    }
  }

  const placeOrder = handleSubmit(submitOrder)

  const handleCreateNewOrderLine = () => {
    setSelectedOrderLine(undefined);
    setOrderLineOpen(true);
    setOrderLineEditMode(false);
  }




  const goBack = () => {
    if (readOnlyMode && basketId) {
      _deleteBasket(basketId,
        {
          onSuccess: () => {
            nav(`/orders`)
          },
          onError: (error) => {
            showSnackbar(error.response?.data, {
              persist: true,
              variant: 'error'
            })
          }
        })
    }
    else if (isDirty && !readOnlyMode) {
      setShowConfirmation(true)
    } else {
      nav('/orders');
    }
  };

  const cancelDirtyAction = () => {
    setShowConfirmation(false)
  };
  const confirmDirtyAction = () => {
    if (basketId) {
      _deleteBasket(basketId,
        {
          onSuccess: () => {
            nav(`/orders`)
          },
          onError: (error) => {
            showSnackbar(error.response?.data, {
              persist: true,
              variant: 'error'
            })
          }
        })
    }
    // nav('/orders');
  };

  const functionDebounce = <T extends (...args: any[]) => void>(
    func: T,
    wait: number,
  ): ((...args: Parameters<T>) => void) => {
    let timeout: ReturnType<typeof setTimeout>
    return (...args: Parameters<T>) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => func(...args), wait)
    }
  }
  const applyUpdateOrder = useCallback(() => {
    if (basketId && !readOnlyMode) {
      const _data = watch()
      updateOrder({
        basketId: basketId,
        paymentMethodName: _data.paymentMethodName?.value || "",
        purchaseOrderNumber: _data.purchaseOrderNumber,
        comments: _data.comments,
        evolution: _data.evolution,
        isRolling: _data.isRolling
      })
    }
  }, [basketId])

  const handelChange = useMemo(
    () => functionDebounce(applyUpdateOrder, 500), // 500ms debounce time
    [applyUpdateOrder],
  )


  useEffect(() => {
    if (basketId && !readOnlyMode) {
      const orderData = watch()
      updateOrder({
        basketId: basketId,
        paymentMethodName: orderData.paymentMethodName?.value || "",
        purchaseOrderNumber: orderData.purchaseOrderNumber,
        comments: orderData.comments,
        evolution: orderData.evolution,
        isRolling: orderData.isRolling
      })
    }
  }, [basketId])



  const rollingContractOptions = [{
    label: i18n.t("COMMON_LABEL_YES"),
    value: true,
  }, {
    label: i18n.t("COMMON_LABEL_NO"),
    value: false,
  }]

  useEffect(() => {
    if (localSavedCustomerData && !basketId && localSavedCustomerData.memberTypeName !== "Company") {
      setValue("memberId", localSavedCustomerData.memberId)
      setValue("parentMemberId", localSavedCustomerData.companyId || undefined)
      setValue("memberName", localSavedCustomerData ? `${localSavedCustomerData?.firstName} ${localSavedCustomerData?.lastName}` : undefined);
    }
  }, [])

  useEffect(() => {
    if (!readOnlyMode && basketId) {
      return () => _deleteBasket(basketId)
    }
  }, [])


  return (
    <OrderContext.Provider value={{
      basketId,
      control,
      currentBasket,
      customerOpen,
      formattedOrderStatus,
      formattedOrderSubStatus,
      getLatestBasketDetail,
      handleCancelMemberSelector,
      handleCreateNewOrderLine,
      handleCustomerConfirm,
      handleEditOrderLine,
      isBasketPage,
      isPlaceOrderLoading,
      isPlacingOrder,
      isValid,
      memberId,
      onOrderStatusChange,
      onOrderSubStatusChange,
      orderEditMode,
      orderLineEditMode,
      orderLineOpen,
      orderLines,
      orderStatusName,
      orderSubStatusName,
      parentMemberId,
      paymentMethods,
      placeOrder,
      readOnlyMode,
      refetchBasket,
      refetchingBasket,
      selectedOrderLine,
      setCustomerOpen,
      setOrderLineOpen,
      setSelectedOrderLine,
      userLogged,
      rollingContractOptions,
      showRollingContract,
      isDirty,
      deleteBasket,
      goBack,
      cancelDirtyAction,
      confirmDirtyAction,
      setShowConfirmation,
      showConfirmation,
      handelChange,
      watch,
      permission,
      getActionFlags,
      disabledSaveButton,
    }}>
      {children}
    </OrderContext.Provider>
  );
};

export const useOrderContext = () => {
  const context = useContext(OrderContext);
  if (!context) {
    throw new Error('useOrderContext must be used within a OrderProvider');
  }
  return context;
};