import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import { Box, DialogContent, DialogTitle, Grid, IconButton } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useAddOrderMember, useDeleteOrderMember, useUpdateOrderMember } from '../../../hooks/Order/orderMember';
import { colors } from "../../../main/Theme";
import { CustomerSearchItemResponseDto } from '../../../types/Customer/CustomerSearchItemResponseDto';
import { OrderMemberType } from '../../../types/Order/BasketResponse';
import { LR } from '../../Common/Help/LR';
import { CondensedAccordion, CondensedAccordionDetails, CondensedAccordionSummary } from "../../Common/UI/CondensedAccordion";
import { CustomButton } from "../../Common/UI/CustomButton";
import { CustomDialog } from '../../Common/UI/CustomDialog';
import { Input } from '../../Common/formComponents/Input';
import { DialogMemberSelectorForOrder } from './DialogMemberSelectorForOrder';
import { useOrderContext } from "./OrderProvider";

type OrderMemberPayload = {
  comment?: string,
  fullName: string,
  memberId: string,
  id?: string
};

export const OrderMember = () => {
  const { userLogged, getLatestBasketDetail, currentBasket, readOnlyMode, parentMemberId, permission } = useOrderContext();
  const { basketId } = useParams()
  const [expand, setExpand] = useState(false);

  const [orderMemberState, setOrderMemberState] = useState<OrderMemberPayload | null>(null)

  const [showMemberSelectorModal, setShowMemberSelectorModal] = useState(false)
  const [showAddMemberModal, setShowAddMemberModal] = useState(false)

  const { mutate: addMember, isLoading: addingMember } = useAddOrderMember();
  const { mutate: updateMember, isLoading: updatingMember } = useUpdateOrderMember();
  const { mutate: deleteMember } = useDeleteOrderMember();

  const handleEditMember = (member: OrderMemberType) => {
    setOrderMemberState({
      ...member,
      id: member.id
    })
    setShowAddMemberModal(true)
  }

  const { orderMembers } = currentBasket || {};

  const handleSelectMember = () => {
    if (!expand) {
      setExpand(true)
    }
    setShowAddMemberModal(true)
  }

  const handleCloseAddMemberModal = () => {
    setShowAddMemberModal(false)
    setOrderMemberState(null)
  }

  const handleCommentChange = (e: any) => {
    setOrderMemberState(prev => ({
      ...prev!,
      comment: e?.target?.value || ""
    }));
  }

  const handleConfirmSelection = (selectedCustomer: CustomerSearchItemResponseDto) => {
    setOrderMemberState((prev) => ({
      ...prev,
      fullName: `${selectedCustomer.firstName} ${selectedCustomer.lastName}`,
      memberId: selectedCustomer.memberId
    }));
    setShowMemberSelectorModal(false)
  }

  const handelSave = () => {
    if (!basketId || !orderMemberState) return

    const { id, fullName, memberId, comment } = orderMemberState;

    const onSuccess = () => {
      getLatestBasketDetail();
      handleCloseAddMemberModal();
    };

    if (id) {
      updateMember({ basketId, id, comment: comment ?? '' }, { onSuccess });
    } else {
      addMember({ basketId, fullName, memberId, comment: comment ?? '' }, { onSuccess });
    }
  }

  const handleDeleteMember = (member: OrderMemberType) => {
    if (!basketId || !member.id) return
    deleteMember({
      basketId,
      id: member.id
    }, {
      onSuccess: () => {
        getLatestBasketDetail()
        setOrderMemberState(null)
      }
    })
  }

  const handleToggle = () => {
    if (!orderMembers || orderMembers.length <= 0) {
      setExpand(false)
    }

    if (orderMembers && orderMembers?.length > 0) {
      setExpand(!expand)
    }
  }




  if (!basketId) return null;

  return (
    <>
      <CondensedAccordion expanded={expand} elevation={2}>
        <CondensedAccordionSummary
          onClick={e => e.preventDefault()}
          expandIcon={<ArrowForwardIosRoundedIcon onClick={handleToggle} />}
        >
          <Grid container alignItems={"center"}>
            <Grid item xs={3}>
              <LR localResource="ORDER_LABEL_MEMBERS" /> ({orderMembers?.length || 0})
            </Grid>
            <Grid item xs={9} sx={{ textAlign: "right", pr: 4 }}>

              {
                !readOnlyMode && permission?.Members?.includes("ACTIONFLAG_EDIT") && (
                  <CustomButton color='secondary' onClick={handleSelectMember} sx={{ textTransform: 'none' }}>
                    <AddCircleRoundedIcon fontSize="medium" />
                    <Box component={"span"} ml={1}><LR localResource="ORDER_LABEL_ADD_MEMBER" /></Box>
                  </CustomButton>
                )}
            </Grid>
          </Grid>
        </CondensedAccordionSummary>
        <CondensedAccordionDetails sx={{ padding: 0 }}>
          {
            orderMembers?.map((member, index) => (
              <Grid container spacing={0} sx={{ borderBottom: 2, borderColor: colors.grey[100], "&:last-child": { borderBottom: 0 } }} key={`member-${index}`}>
                <Grid item xs={3} display='flex' alignItems='center' padding={1} sx={{ backgroundColor: colors.grey[200] }}>
                  {member.fullName}
                </Grid>
                <Grid item xs={7} display='flex' alignItems='center' padding={1} sx={{ backgroundColor: colors.grey[300] }}>
                  {member.comment || '-'}
                </Grid>
                <Grid item xs={2} padding={1} sx={{ backgroundColor: colors.grey[300] }}>
                  {
                    !readOnlyMode && (
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {
                          permission?.Members?.includes("ACTIONFLAG_EDIT") &&
                          <IconButton color='secondary' onClick={() => handleEditMember(member)}>
                            <EditIcon fontSize="medium" />
                          </IconButton>
                        }
                        {
                          permission?.Members?.includes("ACTIONFLAG_DELETE") &&
                          <IconButton color='error' onClick={() => handleDeleteMember(member)}>
                            <DeleteIcon fontSize="medium" />
                          </IconButton>
                        }
                      </Box>
                    )
                  }
                </Grid>
              </Grid>
            ))
          }
        </CondensedAccordionDetails>
      </CondensedAccordion>

      <DialogMemberSelectorForOrder
        userLogged={userLogged}
        open={showMemberSelectorModal}
        handleConfirm={handleConfirmSelection}
        handleCancel={() => setShowMemberSelectorModal(false)}
        memberTypeName={"CompanyContact"}
        companyMemberId={parentMemberId}
      ></DialogMemberSelectorForOrder>

      <CustomDialog open={showAddMemberModal} onClose={handleCloseAddMemberModal} maxWidth="sm" fullWidth>
        <DialogTitle>
          <LR localResource="ORDER_LABEL_ADD_MEMBER" />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Input
                labelProps={{
                  message: 'LABEL_SELECT_MEMBER'
                }}
                value={orderMemberState?.fullName ?? ""}
                sx={{ pointerEvents: 'none' }}
                rightIcon={
                  <IconButton
                    type="button"
                    onClick={() => setShowMemberSelectorModal(true)}
                    disabled={!!orderMemberState?.id}
                  ><SearchIcon />
                  </IconButton>
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                labelProps={{
                  message: 'COMMON_LABEL_COMMENT'
                }}
                value={orderMemberState?.comment ?? ""}
                onChange={handleCommentChange}
              />
            </Grid>
            <Grid item xs={6} mx={"auto"}>
              <CustomButton
                variant="contained"
                type='button'
                disabled={!orderMemberState}
                fullWidth
                onClick={() => handelSave()}
                loading={addingMember || updatingMember}
              >
                <LR localResource='COMMON_LABEL_SAVE' />
              </CustomButton>
            </Grid>
          </Grid>
        </DialogContent>
      </CustomDialog>
    </>
  );
}