import { DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import { CustomButton } from '../UI/CustomButton';
import { CustomDialog } from '../UI/CustomDialog';
import { LR } from './LR';

type Args = {
  showHelp: boolean,
  contentReference: string
  handleConfirm: () => void,
  handleClose: () => void
}

const HelpDialog = ({ showHelp, handleConfirm, contentReference, handleClose }: Args) => {
  return (
    <>
      <CustomDialog open={showHelp} onClose={handleClose} hideCloseButton>
        <DialogTitle><LR localResource="COMMON_TITLE_HELPDIALOG"></LR></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body1" color="textSecondary">
              <LR localResource={contentReference}></LR>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CustomButton className='btn-danger' color='error' autoFocus onClick={handleClose} variant='contained'>
            <LR localResource={'COMMON_BUTTON_CLOSE'} />
          </CustomButton>
        </DialogActions>
      </CustomDialog>
    </>
  );
};

export { HelpDialog };

