import axios, { AxiosError } from "axios";
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { OrderReinstatePayload, OrderSearchResponseDto, OrderSearchV3ResponseDto } from "../../types/Order/OrderSearchResponseDto";

import { HookConfig } from "../../config/HookConfig";
import { operatorDefaultHeaders } from "../../functions/api/api";

const orders = require('../../data/order/order.json') as OrderSearchResponseDto[];

export const useFetchOrders = (params: {
  locationId: string,
  searchRequirement?: string,
  pageNumber?: number,
  pageSize?: number,
  fromDate?: string,
  toDate?: string,
  memberId?: string,
  options: UseQueryOptions<OrderSearchV3ResponseDto, AxiosError, OrderSearchV3ResponseDto>
}) => {
  const { locationId, searchRequirement, pageNumber, pageSize, fromDate, toDate, options, memberId } = params;
  return useQuery<OrderSearchV3ResponseDto, AxiosError>(
    ['orders', locationId, searchRequirement, pageNumber, pageSize, fromDate, toDate],
    () => axios.post(`${HookConfig.orderUrl}/orders/searchV3`, {
      locationId: locationId,
      searchRequirement: searchRequirement,
      pageNumber: pageNumber,
      pageSize: pageSize,
      fromDate: fromDate,
      toDate: toDate,
      memberId: memberId
    }, {
      withCredentials: true,
      headers: operatorDefaultHeaders(),
    }).then((response) => response.data),
    {
      enabled: !!locationId,
      ...options
    }
  );
};

export const useFetchOrdersByMemberId = (memberId: string) => {
  return useQuery(['orders', memberId], async () => {
    return orders.filter(order => order.memberId === memberId);
  });
}

export const useFetchOrderById = (id: string) => {
  return useQuery(['order', id], async () => {
    const order = orders.find(order => order.id === id);
    if (!order) throw new Error('Order not found');
    return order;
  });
}

// Create a new order
export const useCreateOrder = (callback: (orderId: string) => void) => {
  const queryClient = useQueryClient();
  return useMutation<{ data: OrderSearchResponseDto }, AxiosError, OrderSearchResponseDto>(
    (newOrder) => {
      orders.push(newOrder);
      return Promise.resolve({ data: newOrder });
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(["orders-summary"]);
        callback(response.data.id);
      }
    }
  );
}

// Update an existing order
export const useUpdateOrder = (orderId: string, callback: () => void) => {
  const queryClient = useQueryClient();
  return useMutation<{ data: OrderSearchResponseDto }, AxiosError, OrderSearchResponseDto>(
    (updatedOrder) => {
      const index = orders.findIndex(order => order.id === orderId);
      if (index !== -1) {
        orders[index] = updatedOrder;
      }
      return Promise.resolve({ data: updatedOrder });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["orders-summary", orderId]);
        callback();
      }
    }
  );
}


export const useDeleteOrder = () => {
  return useMutation<any, AxiosError, any>((basketId: string) =>
    axios.delete(`${HookConfig.orderUrl}/baskets/${basketId}/order`, {
      withCredentials: true,
      headers: operatorDefaultHeaders()
    }).then(
      (response) => {
        return response.data;
      }
    )
  );
}


export const useReinstatedOrder = () => {
  return useMutation<OrderReinstatePayload, AxiosError, any>(
    (param) => axios.post(`${HookConfig.orderUrl}/baskets/${param.basketid}/reinstate`, param,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((response) => {
      return response.data;
    })
  );
};


type RenewedOrderPayload = {
  basketId: string
  orderEndReasonId?: string | null
  requestComments?: string | null
  relatedOrderIds: string[]
};

export const useRenewedOrder = () => {
  return useMutation<any, AxiosError, RenewedOrderPayload>(
    (param) => axios.post(`${HookConfig.orderUrl}/baskets/${param.basketId}/renewed`, param,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((response) => {
      return response.data;
    })
  );
};

type OrderTerminationPayload = {
  basketId: string
  orderEndTypeName?: string | null
  orderEndReasonId?: string | null
  initiatedByName?: string | null
  requestComments?: string | null
  memberId?: string | null
  requestedEndDate?: string | null
  digitalAssetId?: any | null
  memberName?: string | null
  relatedOrderIds?: string[]
};

export const useOrderTermination = () => {
  return useMutation<any, AxiosError, OrderTerminationPayload>(
    (param) => axios.post(`${HookConfig.orderUrl}/baskets/${param.basketId}/termination`, param,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((response) => {
      return response.data;
    })
  );
};

type ProcessTerminationPayload = {
  basketId: string
  decisionNotes?: string
  newOrderEndDate?: string | null
  newInventoryEndDate?: string | null
  orderEndDecisionTypeName: string
};

export const useProcessTermination = () => {
  return useMutation<any, AxiosError, ProcessTerminationPayload>(
    (param) => axios.put(`${HookConfig.orderUrl}/baskets/${param.basketId}/termination`, param,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((response) => {
      return response.data;
    })
  );
};



export type TerminationLetterResponseDto = {
  serviceName: string
  entityName: string
  entityKey: string
  sourceName: string
  sourceKey: string
  label: string
  fileTypeCsv: string
  isMandatory: boolean
  maximumFileCount: number
  sampleFileName: any
};

export const useTerminationLetter = (params: {
  basketId: string,
  orderId: string
}) => {
  const { basketId, orderId } = params;
  return useQuery<TerminationLetterResponseDto, AxiosError>(
    ['terminationLetter', params],
    () => axios.get(`${HookConfig.orderUrl}/baskets/${basketId}/termination-letter`, {
      params: {
        orderId: orderId
      },
      withCredentials: true,
      headers: operatorDefaultHeaders(),
    }).then((response) => response.data),
    {
      enabled: !!basketId,
    }
  );
};


export const useOrderEndingReason = (params: {
  orderEndTypeName: string,
  initiatedByName: string
}) => {
  const { initiatedByName, orderEndTypeName } = params;
  return useQuery<any, AxiosError>(
    ['orderEndingReason', params],
    () => axios.get(`${HookConfig.orderUrl}/order-end/reasons`, {
      params: {
        initiatedByName: initiatedByName,
        orderEndTypeName: orderEndTypeName
      },
      withCredentials: true,
      headers: operatorDefaultHeaders(),
    }).then((response) => response.data),
    {
      enabled: !!orderEndTypeName && !!initiatedByName,
    }
  );
};


export const useOrderDecisionTypes = () => {
  return useQuery<any, AxiosError>(
    ['orderInitiatedBy'],
    () => axios.get(`${HookConfig.orderUrl}/order-end/decision-types`, {
      withCredentials: true,
      headers: operatorDefaultHeaders(),
    }).then((response) => response.data),
    {
      enabled: true,
    }
  );
};