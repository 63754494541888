import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Collapse, IconButton, MenuItem, Paper, Select, SelectChangeEvent, Typography } from "@mui/material";
import Switch from '@mui/material/Switch';
import { useEffect, useState } from "react";
import { pontUnits } from "../../../data/en/pontUnits";
import { useManageProductsRulesBusinessRules } from "../../../hooks/Product/product";
import i18n from "../../../i18n";
import { OperationResultDto } from "../../../types/Common/OperationResultDto";
import { BusinessRuleResponseDto } from "../../../types/Product/BusinessRuleResponseDto";
import { LR } from "../../Common/Help/LR";
import "../../Common/SummaryItems/SummaryItem.css";
import { ValidationError } from '../../Common/formComponents/ValidationError';
import { ApplicationConfig } from '../../../config/ApplicationConfig';

type Args = {
  type: string,
  items: BusinessRuleResponseDto[],
  mutationSwitchCallBack: (operationResult: OperationResultDto) => void,
  itemsChangedCallBack: (items: BusinessRuleResponseDto[], type: string) => void,
  handleDirty: () => void,
  isOrderPage?: boolean,
  handleToggle?: (item: BusinessRuleResponseDto) => void,
  forceRefresh?: boolean
};

export const BusinessRules = ({ type, mutationSwitchCallBack, itemsChangedCallBack, items, handleDirty, isOrderPage, handleToggle, forceRefresh }: Args) => {

  const [businessRulesItems, setBusinessRulesItems] = useState<BusinessRuleResponseDto[]>(items);
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const [ruleIsCollapseOpen, settRuleIsCollapseOpen] = useState<boolean[]>(items.map((x) => false));
  const [toggleStates, setToggleStates] = useState<boolean[]>(items.map((x) => (x.id !== ApplicationConfig.emptyGuid)));

  const internalMutationCallback = (item: BusinessRuleResponseDto, responseId: string, operationResult: OperationResultDto) => {
    if (operationResult.isSuccess) {
      const updatedItems = businessRulesItems.map(obj =>
        obj.productRuleId === item.productRuleId ? { ...obj, id: responseId } : obj
      );
      setBusinessRulesItems(updatedItems);
    }
    mutationSwitchCallBack(operationResult);
  };

  const mutationUpdate = useManageProductsRulesBusinessRules(internalMutationCallback);

  const updateFields = (businessRuleId: number, value: number, fieldName: string) => {
    let updatedItems = businessRulesItems;
    switch (fieldName) {
      case "field1Quantity":
        updatedItems = businessRulesItems.map(obj =>
          obj.businessRuleId === businessRuleId ? {
            ...obj,
            field1Quantity: value, isChanged: true
          } : obj
        );
        break;
      case "field2Quantity":
        updatedItems = businessRulesItems.map(obj =>
          obj.businessRuleId === businessRuleId ? {
            ...obj,
            field2Quantity: value, isChanged: true
          } : obj
        );
        break;
    }

    setBusinessRulesItems(updatedItems);
    itemsChangedCallBack(updatedItems, type);
    handleDirty();
  };

  // React.ChangeEvent<HTMLSelectElement>
  const handleUnitSelectChange = (businessRuleId: number, fieldName: string, e: SelectChangeEvent<string>) => {
    let updatedItems = businessRulesItems;
    switch (fieldName) {
      case "field1UnitName":
        updatedItems = businessRulesItems.map(obj =>
          obj.businessRuleId === businessRuleId ? {
            ...obj,
            field1UnitName: e.target.value as string, isChanged: true
          } : obj
        );
        break;
      case "field2UnitName":
        updatedItems = businessRulesItems.map(obj =>
          obj.businessRuleId === businessRuleId ? {
            ...obj,
            field2UnitName: e.target.value as string, isChanged: true
          } : obj
        );
        break;
    }

    setBusinessRulesItems(updatedItems);
    itemsChangedCallBack(updatedItems, type);
    handleDirty();
  };

  // Handle toggle change for a specific item based on its index
  const handleToggleChange = (index: number, item: BusinessRuleResponseDto) => {
    setToggleStates((prevStates) => {
      const updatedStates = [...prevStates!];
      updatedStates[index] = !updatedStates[index];
      return updatedStates;
    });
    item.isDelete = item.id !== ApplicationConfig.emptyGuid;
    if (!Boolean(isOrderPage)) {
      mutationUpdate.mutate(item);
    }

    handleToggle?.(item);
  };

  const handleCollapse = (index: number) => {
    settRuleIsCollapseOpen((prevStates) => {
      const updatedStates = [...prevStates!];
      updatedStates[index] = !updatedStates[index];
      return updatedStates;
    });
  };

  useEffect(() => {
    if (forceRefresh) {
      setBusinessRulesItems(items)
    }
  }, [items])



  const isValidNumber = (value: any) => {
    return typeof value === 'number' && !Number.isNaN(value) && value >= 0;
  }

  return (
    <>
      <Paper key={type} elevation={3} className="fa-paper-light">
        <div className="d-flex align-items-center">
          <div className="fa-name flex-grow-1">
            <Typography key={type} variant="h6" className="linkbutton">
              <LR localResource={`PRODUCT_LABEL_RULE${type.toUpperCase()}`} /> ({items.filter(item => item.id !== ApplicationConfig.emptyGuid).length})
            </Typography>
          </div>
          <div className="fa-icons">
            <IconButton key={type} className="fa-arrow" onClick={() => setIsCollapseOpen(!isCollapseOpen)}>
              {!isCollapseOpen && <ArrowForwardIosRoundedIcon />}
              {isCollapseOpen && <ArrowForwardIosRoundedIcon className='fa-arrow-down' />}
            </IconButton>
          </div>
        </div>
      </Paper>

      <Collapse in={isCollapseOpen}>
        <div>
          <div className="row d-flex justify-content-center">
            <div className="justify-content-center">
              {businessRulesItems.map((item, index) => {
                return (
                  <Paper key={index + item.businessRuleLabel} elevation={24} className="fa-paper-grid">
                    <div className="d-flex align-items-center">
                      <div className="col-7 fa-name flex-grow-1">
                        <Typography key={index} variant="h6" className="linkbutton">
                          {i18n.t(item.businessRuleLabel)}
                        </Typography>
                      </div>

                      <div className="col-2 d-flex justify-content-end" style={{ flex: 1 }}>
                        <Switch key={index}
                          checked={toggleStates![index]}
                          onChange={() => handleToggleChange(index, item)}
                          color="primary"
                        />
                      </div>
                      <div className="fa-icons">
                        {
                          item.isField1QuantityRequired &&
                          <IconButton key={index} className="fa-arrow" onClick={() => handleCollapse(index)}>
                            {!ruleIsCollapseOpen![index] && <ArrowForwardIosRoundedIcon />}
                            {ruleIsCollapseOpen![index] && <ArrowForwardIosRoundedIcon className='fa-arrow-down' />}
                          </IconButton>
                        }

                      </div>
                    </div>
                    <Collapse key={index} in={ruleIsCollapseOpen![index]}>
                      <div className={`row d-flex align-items-center mt-1 ${toggleStates[index] ? '' : 'disabled-content'}`}>
                        {item.isField1QuantityRequired && (
                          <div className="col-3 col-md-2 text-center">
                            <input
                              className="form-control fa-form"
                              type="number"
                              min={0}
                              value={item.field1Quantity}
                              onChange={(e) => { updateFields(item.businessRuleId, parseFloat(e.target.value), "field1Quantity") }}
                              style={{ textAlign: "center" }}
                            />
                            {
                              item?.isField1QuantityRequired === true && !isValidNumber(item?.field1Quantity) && <ValidationError message={i18n.t("VALIDATION_THIS_REQUIRED").toString()} />
                            }
                          </div>
                        )}


                        {item.isField1UnitRequired && (
                          <div className={`d-flex align-items-center col-5 col-md-3 text-right ${item.isField2QuantityRequired && item.field2Quantity ? 'businessrules_and' : ''}  businessrulescondition`}>
                            <Select
                              className="form-control fa-form fa-businessrule-dropdown"
                              value={item.field1UnitName}
                              onChange={(e) => handleUnitSelectChange(item.businessRuleId, 'field1UnitName', e)}
                            >
                              {pontUnits
                                .filter(unit => unit.unitGroupName === item.unitGroupName)
                                .map(unit => (
                                  <MenuItem key={unit.id} value={unit.unitName}>
                                    {unit.unitName}
                                  </MenuItem>
                                ))
                              }
                            </Select>
                            {item.isField2QuantityRequired &&
                              <span className='businessrules_and_condition' style={{ verticalAlign: 'middle' }}>
                                {i18n.t("COMMON_LABEL_AND")}
                              </span>
                            }
                          </div>
                        )}

                        {item.isField2QuantityRequired ? (
                          <>
                            <div className="col-md-2 col-3  text-right">
                              <input
                                className="form-control fa-form"
                                type="number"
                                min={0}
                                value={item.field2Quantity!}
                                onChange={(e) => { updateFields(item.businessRuleId, parseFloat(e.target.value), "field2Quantity") }}
                                style={{ textAlign: "center" }}
                              />
                              {
                                item?.isField2QuantityRequired === true && !isValidNumber(item?.field2Quantity) && <ValidationError message={i18n.t("VALIDATION_THIS_REQUIRED").toString()} />
                              }
                            </div>
                          </>
                        ) : null}

                        {item.isField2UnitRequired && (
                          <div className="d-flex align-items-center col-5 col-md-3 text-left businessrulescondition">
                            <Select
                              className="form-control fa-form fa-businessrule-dropdown"
                              value={item.field2UnitName!}
                              onChange={(e) => handleUnitSelectChange(item.businessRuleId, 'field2UnitName', e)}
                            >
                              {pontUnits
                                .filter(unit => unit.unitGroupName === item.unitGroupName)
                                .map(unit => (
                                  <MenuItem key={unit.id} value={unit.unitName}>
                                    {unit.unitName}
                                  </MenuItem>
                                ))
                              }
                            </Select>
                          </div>
                        )}
                      </div>
                    </Collapse>
                  </Paper>
                )
              }
              )}
            </div>
          </div>
        </div>
      </Collapse>
    </>
  );
}