import { useMutation, useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { SummaryItemDto } from "../../types/Common/SummaryItemDto";
import axios, { AxiosError, AxiosResponse } from "axios";
import { HookConfig } from "../../config/HookConfig";
import { useNavigate } from "react-router";
import Problem from "../../types/Problem";
import { operatorDefaultHeaders } from "../../functions/api/api";

const useFetchFeaturesByPostId = (postId: string) => {
    return useQuery<SummaryItemDto[], AxiosError>(["postFeatures", postId], () =>
        axios.get(`${HookConfig.postUrl}/posts/${postId}/features/summary`,
            {
                withCredentials: true,
                headers: operatorDefaultHeaders()
            }
        ).then((resp) => resp.data)
    );
}

const useFetchPostFeatureHierarchy = (postId: string, groupName: string, options?: UseQueryOptions<any, AxiosError>) => {
    return useQuery<any, AxiosError>(["postFeatures-hierarchies", postId, groupName], () =>
        axios.get(`${HookConfig.postUrl}/posts/${postId}/feature-heirarchies?group=${groupName}`,
            {
                withCredentials: true,
                headers: operatorDefaultHeaders()
            }
        ).then((resp) => resp.data),
        {
            ...options,
        }
    );
}

const useManagePostFeatures = () => {
    const queryClient = useQueryClient();
    const nav = useNavigate();
    const manageFeatures = async ([applicationId, postId, groupName, items]: [string, string, string, any[]]): Promise<AxiosResponse<any, any>> => {

        const url = `${HookConfig.postUrl}/posts/${postId}/features`;
        const body = { postId: postId, group: groupName, features: items }

        const response = await axios["put"](
            url, body,
            {
                withCredentials: true,
                headers: operatorDefaultHeaders(),
            }
        );

        return response;
    };

    const mutation = useMutation<
        AxiosResponse<any, any>, AxiosError<Problem>, [string, string, string, any[]]>(manageFeatures, {
            onSuccess: (response, [applicationId, postId, groupName]) => {
                queryClient.invalidateQueries(["postFeatures-hierarchies", postId, groupName]);
                nav(`/application/${applicationId}/configuration/posts/${postId}/features`);
            },
        });

    return mutation;
};



export {
    useFetchFeaturesByPostId,
    useFetchPostFeatureHierarchy,
    useManagePostFeatures
}