import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem
} from "@mui/material";
import { isNil } from "lodash";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useDeleteOrder } from "../../../hooks/Order/useOrder";
import i18n from "../../../i18n";
import { ConfirmationChangesDialog } from "../../Common/ConfirmationChangesDialog";
import { CustomSelect } from "../../Common/formComponents/CustomSelect";
import { Input } from "../../Common/formComponents/Input";
import { SectionHead } from "../../Common/Help/SectionHead";
import { CustomButton } from "../../Common/UI/CustomButton";
import { DeleteConfirmationDialog } from "../../Common/UI/DeleteConfirmationDialog";
import { MaterialIcon } from "../../Common/UI/MaterialIcon";
import { Addendum } from "./Addendum";
import { DialogMemberSelectorForOrder } from "./DialogMemberSelectorForOrder";
import { OrderBusinessRules } from "./OrderBusinessRules";
import "./OrderForm.css";
import { OrderItemSummary } from "./OrderItemSummary";
import { DialogOrderLine } from "./orderline/DialogOrderLine";
import { OrderMember } from "./OrderMember";
import { useOrderContext } from "./OrderProvider";
import { DialogRequestTermination } from "./actiondialog/DialogRequestTermination";
import { DialogProcessTerminationModal } from "./actiondialog/DialogProcessTerminationModal";
import { DialogMarkAsRenewed } from "./actiondialog/DialogMarkAsRenewed";
import { DialogReinstated } from "./actiondialog/DialogReinstated";

const OrderForm = () => {
  const nav = useNavigate();
  const { basketId } = useParams()
  const {
    cancelDirtyAction,
    confirmDirtyAction,
    control,
    currentBasket,
    customerOpen,
    formattedOrderStatus,
    formattedOrderSubStatus,
    goBack,
    handleCancelMemberSelector,
    handleCreateNewOrderLine,
    handleCustomerConfirm,
    isPlaceOrderLoading,
    isPlacingOrder,
    isValid,
    onOrderStatusChange,
    onOrderSubStatusChange,
    orderEditMode,
    orderLines,
    orderStatusName,
    paymentMethods,
    placeOrder,
    readOnlyMode,
    rollingContractOptions,
    setCustomerOpen,
    showConfirmation,
    showRollingContract,
    userLogged,
    handelChange,
    permission,
    disabledSaveButton
  } = useOrderContext()

  const disableClientSelection = Boolean(basketId)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  // const [isOpenCancelModal, setIsOpenCancelModal] = useState<boolean>(false)
  const [isOpenRequestTerminationModal, setIsOpenRequestTerminationModal] = useState<boolean>(false)
  const [isOpenProcessTerminationModal, setIsOpenProcessTerminationModal] = useState<boolean>(false)
  const [isOpenMarkAsRenewedModal, setIsOpenMarkAsRenewedModal] = useState<boolean>(false)
  const [isOpenReinstatedModal, setIsOpenReinstatedModal] = useState<boolean>(false)

  const { mutate: deleteOrder, isLoading: deletingOrder } = useDeleteOrder()
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let title = i18n.t(orderEditMode ? "ORDER_LABEL_EDIT_ORDER" : "ORDER_LABEL_CREATE_ORDER").toString()

  if (readOnlyMode) {
    title = i18n.t("ORDER_LABEL_VIEW_ORDER").toString()
  }

  const actionOption: any = {
    ACTIONFLAG_EDIT: { link: `/orders/basket/${basketId}`, label: i18n.t("COMMON_LABEL_EDIT"), },
    ACTIONFLAG_CANCEL: { link: null, label: i18n.t("COMMON_BUTTON_CANCEL"), fun: () => { } },
    ACTIONFLAG_DELETE: { link: null, label: i18n.t("COMMON_LABEL_DELETE"), fun: () => setShowDeleteModal(true) },
    ACTIONFLAG_REQUESTTERMINATION: { link: null, label: i18n.t("COMMON_LABEL_REQUEST_TERMINATION"), fun: () => setIsOpenRequestTerminationModal(true) },
    ACTIONFLAG_PROCESSTERMINATION: { link: null, label: i18n.t("COMMON_LABEL_PROCESS_TERMINATION"), fun: () => setIsOpenProcessTerminationModal(true) },
    ACTIONFLAG_MARKASRENEWED: { link: null, label: i18n.t("COMMON_LABEL_MARK_AS_RENEWED"), fun: () => setIsOpenMarkAsRenewedModal(true) },
    ACTIONFLAG_REINSTATE: { link: null, label: i18n.t("COMMON_LABEL_REINSTATE"), fun: () => setIsOpenReinstatedModal(true) },
  }
  const menuLink = permission.Order?.length > 0 ? Object.keys(actionOption)
    .filter((item: any) => permission.Order.includes(item) && item !== "ACTIONFLAG_VIEW")
    .map((key: string) => actionOption[key]) : []


  const action = readOnlyMode ? (
    <>
      <CustomButton onClick={handleOpen} variant="contained" color="secondary" sx={{ textTransform: 'none', marginBottom: 2 }}>
        Action <MaterialIcon name="keyboard_arrow_down" />
      </CustomButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        disableScrollLock
      >
        {
          menuLink?.map((item: any) => {
            return <MenuItem
              key={item.label}
              onClick={() => {
                item.link ? nav(item.link) : item.fun()
                handleClose();
              }}
            >
              {item.label}
            </MenuItem>
          })
        }
      </Menu>
    </>
  ) : undefined


  const confirmDelete = () => {
    deleteOrder(basketId, {
      onSuccess: () => {
        setShowDeleteModal(false)
        nav(`/orders`)
      }
    })
  }

  const isEditableOrderStatusName = readOnlyMode ? true : basketId ? !permission.OrderStatusName?.includes("ACTIONFLAG_EDIT") : orderEditMode
  const isEditableOrderSubStatusName = isNil(orderStatusName) || readOnlyMode ? true : basketId ? !permission.OrderSubStatus?.includes("ACTIONFLAG_EDIT") : orderEditMode
  const isEditableRollingContract = readOnlyMode ? true : basketId ? !permission.RollingContract?.includes("ACTIONFLAG_EDIT") : orderEditMode

  return (
    <>
      <SectionHead
        linkCallBack={goBack}
        ctaText={i18n.t("COMMON_SUMMARY_ORDERS")!}
        name={title}
        title={title}
        description={
          i18n.t("ORDER_LABEL_ORDER_DESCRIPTION").toString()
        }
        action={action}
      />
      <div className="row d-flex justify-content-center">
        <div className="col-md-7 col-11 justify-content-center">
          <form noValidate onSubmit={placeOrder}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="locationName"
                  render={({ field, fieldState }) => (
                    <Input
                      labelProps={{
                        message: i18n.t("ORDER_LABEL_LOCATION").toString(),
                        mandatory: true,
                      }}
                      placeholder={i18n.t("ORDER_LABEL_LOCATION").toString()}
                      value={field.value}
                      disabled
                      validationError={fieldState.error}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <DialogMemberSelectorForOrder
                  userLogged={userLogged}
                  open={customerOpen}
                  handleConfirm={handleCustomerConfirm}
                  handleCancel={handleCancelMemberSelector}
                  memberTypeName={"CompanyContact"}
                ></DialogMemberSelectorForOrder>

                <Controller
                  name="memberName"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Box position="relative">
                      <Input
                        labelProps={{
                          message: i18n.t("ORDER_LABEL_CLIENT").toString(),
                          mandatory: true,
                        }}
                        placeholder={i18n.t("ORDER_LABEL_CLIENT").toString()}
                        sx={{ input: { paddingRight: "3rem !important" } }}
                        validationError={fieldState.error}
                        readOnly
                        value={field.value}
                        onClick={() => setCustomerOpen(true)}
                        disabled={disableClientSelection || readOnlyMode}
                        rightIcon={
                          <IconButton
                            type="button"
                            onClick={() => setCustomerOpen(true)}
                            disabled={disableClientSelection || readOnlyMode}
                          >
                            <SearchIcon />
                          </IconButton>
                        }
                      ></Input>
                    </Box>
                  )}
                />
              </Grid>

              <Grid item xs={4}>
                <Controller
                  control={control}
                  name="orderStatusName"
                  render={({ field, fieldState }) => {
                    return (
                      <CustomSelect
                        labelProps={{
                          message: "ORDER_LABEL_STATUS",
                          mandatory: true,
                        }}
                        options={formattedOrderStatus}
                        placeholder={i18n.t("ORDER_LABEL_STATUS")}
                        field={field}
                        onChange={(e) => {
                          onOrderStatusChange(e);
                          field.onChange(e);
                        }}
                        validationError={fieldState.error}
                        isDisabled={isEditableOrderStatusName}
                      />
                    );
                  }}
                />
              </Grid>


              <Grid item xs={4}>
                <Controller
                  control={control}
                  name="orderSubStatusName"
                  render={({ field, fieldState }) => {
                    return (
                      <CustomSelect
                        labelProps={{
                          message: "ORDER_LABEL_SUBSTATUS",
                          mandatory: true,
                        }}
                        options={formattedOrderSubStatus}
                        placeholder={i18n.t("ORDER_LABEL_SUBSTATUS")}
                        field={field}
                        value={field.value || null}
                        onChange={(e) => {
                          onOrderSubStatusChange(e);
                          field.onChange(e);
                        }}
                        validationError={fieldState.error}
                        isDisabled={isEditableOrderSubStatusName}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <Controller
                  control={control}
                  name="paymentMethodName"
                  render={({ field, fieldState }) => {
                    return (
                      <CustomSelect
                        labelProps={{
                          message: "ORDER_LABEL_PAYMENTMETHOD",
                          mandatory: true,
                        }}
                        options={paymentMethods !== undefined ? paymentMethods : []}
                        placeholder={i18n.t("ORDER_LABEL_PAYMENTMETHOD")}
                        field={field}
                        onChange={(e) => {
                          handelChange()
                        }}
                        validationError={fieldState.error}
                        getOptionLabel={(option: any) => option.pspName}
                        getOptionValue={(option: any) => option.pspName}
                        isDisabled={readOnlyMode}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12} md={showRollingContract ? 4 : 6}>
                <Controller
                  control={control}
                  name="purchaseOrderNumber"
                  render={({ field, fieldState }) => (
                    <Input
                      labelProps={{
                        message: i18n.t("ORDER_LABEL_PURCHASEORDER").toString(),
                        mandatory: false,
                      }}
                      onChange={(e) => {
                        field.onChange(e.target.value)
                        handelChange()
                      }}
                      placeholder={i18n.t("ORDER_LABEL_PURCHASEORDER").toString()}
                      field={field}
                      validationError={fieldState.error}
                      disabled={readOnlyMode}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={showRollingContract ? 4 : 6}>
                <Controller
                  control={control}
                  name="evolution"
                  render={({ field, fieldState }) => (
                    <Input
                      labelProps={{
                        message: i18n.t("ORDER_LABEL_REASONFORORDER").toString(),
                        mandatory: false,
                      }}
                      placeholder={i18n.t("ORDER_LABEL_REASONFORORDER").toString()}
                      field={field}
                      onChange={(e) => {
                        field.onChange(e.target.value)
                        handelChange()
                      }}
                      validationError={fieldState.error}
                      disabled={readOnlyMode}
                    />
                  )}
                />
              </Grid>
              {
                showRollingContract && (
                  <Grid item xs={12} md={4}>
                    <Controller
                      control={control}
                      name="isRolling"
                      render={({ field, fieldState }) => (
                        <CustomSelect
                          labelProps={{
                            message: "ORDER_LABEL_ISROLLINGCONTRACT",
                            mandatory: true,
                          }}
                          options={rollingContractOptions}
                          placeholder={i18n.t("ORDER_LABEL_ISROLLINGCONTRACT")}
                          field={field}
                          onChange={(e) => {
                            field.onChange(e?.value);
                            handelChange()
                          }}
                          value={rollingContractOptions.find((x) => x.value === field.value)}
                          validationError={fieldState.error}
                          isDisabled={isEditableRollingContract}
                        />
                      )}
                    />
                  </Grid>
                )
              }

              {
                permission?.OrderEnding?.includes("ACTIONFLAG_VIEW") &&
                <Grid item xs={12}>
                  <Input
                    labelProps={{
                      message: i18n.t("LABEL_ENDING").toString(),
                      mandatory: false,
                    }}
                    value={`${currentBasket?.orderEnding?.orderEndTypeName} ${i18n.t("LABEL_BY").toString()} ${currentBasket?.orderEnding?.initiatedByName}, ${i18n.t("LABEL_REASON").toString()} - ${currentBasket?.orderEnding?.orderEndReasonName}`}
                    disabled={true}
                  />
                </Grid>
              }

              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="comments"
                  render={({ field, fieldState }) => (
                    <Input
                      labelProps={{
                        message: i18n.t("ORDER_LABEL_COMMENT").toString(),
                        mandatory: false,
                      }}
                      multiline={true}
                      rows={3}
                      field={field}
                      onChange={(e) => {
                        field.onChange(e.target.value)
                        handelChange()
                      }}
                      validationError={fieldState.error}
                      disabled={readOnlyMode}
                    />
                  )}
                />
              </Grid>
              {
                !readOnlyMode && (
                  <Grid item xs={1} md={4} mx={"auto"} mt={3}>
                    <CustomButton
                      variant="contained"
                      fullWidth
                      disabled={!isValid}
                      type="button"
                      onClick={handleCreateNewOrderLine}
                    >
                      {i18n.t("ORDER_BUTTON_NEWORDERLINE")}
                    </CustomButton>
                  </Grid>
                )
              }
              <Grid item xs={12}>
                <OrderItemSummary></OrderItemSummary>
                <DialogOrderLine />
              </Grid>

              {
                orderLines.length > 0 && (
                  <Grid item container spacing={2} xs={12}>
                    {
                      permission?.BusinessRules?.includes("ACTIONFLAG_VIEW") &&
                      <Grid item xs={12}>
                        <OrderBusinessRules />
                      </Grid>
                    }
                    {
                      permission?.Members?.includes("ACTIONFLAG_VIEW") && (
                        <Grid item xs={12}>
                          <OrderMember />
                        </Grid>
                      )
                    }
                    {
                      permission?.Addendums?.includes("ACTIONFLAG_VIEW") && (
                        <Grid item xs={12}>
                          <Addendum />
                        </Grid>
                      )
                    }
                    {!readOnlyMode && (
                      <Grid item xs={1} md={4} mx={"auto"} mt={3}>
                        <CustomButton
                          variant="contained"
                          fullWidth
                          disabled={!isValid || isPlaceOrderLoading || disabledSaveButton}
                          type="submit"
                          loading={isPlaceOrderLoading || isPlacingOrder}
                        >
                          {i18n.t("COMMON_LABEL_SAVE")}
                        </CustomButton>
                      </Grid>
                    )}
                  </Grid>
                )
              }
            </Grid>
          </form>
        </div>
      </div>

      {
        isOpenRequestTerminationModal &&
        <DialogRequestTermination
          open={isOpenRequestTerminationModal}
          onClose={() => setIsOpenRequestTerminationModal(false)}
        />
      }

      {
        isOpenProcessTerminationModal &&
        <DialogProcessTerminationModal
          open={isOpenProcessTerminationModal}
          onClose={() => setIsOpenProcessTerminationModal(false)}
        />
      }


      {
        isOpenMarkAsRenewedModal &&
        <DialogMarkAsRenewed
          open={isOpenMarkAsRenewedModal}
          onClose={() => setIsOpenMarkAsRenewedModal(false)}
        />
      }

      {
        isOpenReinstatedModal &&
        <DialogReinstated
          open={isOpenReinstatedModal}
          onClose={() => setIsOpenReinstatedModal(false)}
        />
      }

      <ConfirmationChangesDialog
        handleConfirm={confirmDirtyAction}
        handleCancel={cancelDirtyAction}
        showConfirmation={showConfirmation}
      />

      <DeleteConfirmationDialog
        confirm={confirmDelete}
        isLoading={deletingOrder}
        onClose={() => setShowDeleteModal(false)}
        open={showDeleteModal}
        text={i18n.t('COMMON_BUTTON_DELETEORDER').toString()}
      ></DeleteConfirmationDialog>
    </>
  );
};

export { OrderForm };

