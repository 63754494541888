import axios, { AxiosError, AxiosResponse } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { HookConfig } from "../../config/HookConfig";
import { operatorDefaultHeaders } from "../../functions/api/api";
import { ActivityDto } from "../../types/Activity/ActivityDto";
import { ActivitySearchResponseDto } from "../../types/Activity/ActivitySearchResponseDto";
import { SearchActivityRequestDto } from "../../types/Activity/SearchActivityRequestDto";
const activities = require('../../data/activity/activities.json') as ActivityDto[];

const useFetchActivities = (params: SearchActivityRequestDto) => {
  const { locationId, startDate, endDate, searchQuery, pageSize, pageNumber, type, acted } = params;
  return useQuery<ActivitySearchResponseDto, AxiosError>(
    ['activities', params],
    () => axios.get(`${HookConfig.operatorMgmtUrl}/activities`, {
      params: {
        searchQuery: searchQuery,
        pageNumber: pageNumber,
        pageSize: pageSize,
        locationId: locationId,
        searchDateStart: new Date(startDate),
        searchDateEnd: new Date(endDate),
        isRead: type ? type === "true" ? true : false : null,
        isActed: acted ? acted === "true" ? true : false : null,
      },
      withCredentials: true,
      headers: operatorDefaultHeaders(),
    }).then((response) => response.data),
    {
      enabled: !!locationId,
    }
  );
};

const useFetchActivityById = (id: string) => {
  return useQuery<ActivityDto, AxiosError>(["activity", id], async () => {
    const activity = activities.find(activity => activity.entityKey === id);
    if (!activity) throw new Error('Activity not found');
    return activity;
  });
}

const useCreateActivity = (callback: (activityId: string) => void) => {
  const queryClient = useQueryClient();
  return useMutation<{ data: ActivityDto }, AxiosError, ActivityDto>(
    (newActivity) => {
      activities.push(newActivity);
      return Promise.resolve({ data: newActivity });
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(["activities-summary"]);
        callback(response.data.entityKey);
      }
    }
  );
}

const useUpdateActivity = (activityId: string, callback: () => void) => {
  const queryClient = useQueryClient();
  return useMutation<{ data: ActivityDto }, AxiosError, ActivityDto>(
    (updatedActivity) => {
      const index = activities.findIndex(activity => activity.entityKey === activityId);
      if (index !== -1) {
        activities[index] = updatedActivity;
      }
      return Promise.resolve({ data: updatedActivity });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["activities-summary", activityId]);
        callback();
      }
    }
  );
}

const useFetchActivitySummary = (params: {
  locationId: string,
}) => {
  const { locationId } = params;
  return useQuery<any, AxiosError>(
    ['orders', locationId],
    () => axios.get(`${HookConfig.operatorMgmtUrl}/activities/summaries`, {
      params: {
        locationId: locationId
      },
      withCredentials: true,
      headers: operatorDefaultHeaders(),
    }).then((response) => response.data),
    {
      enabled: !!locationId,
    }
  );
};



const useUpdateActivityRead = () => {
  return useMutation<AxiosResponse, AxiosError, any>(
    (payload) => {
      return axios.put(`${HookConfig.operatorMgmtUrl}/activities/${payload.id}/read`, payload, {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }).then((response) => {
        return response;
      })
    }
  );
};

const useUpdateActed = () => {
  return useMutation<{ id: string }, AxiosError, any>(
    (payload) =>
      axios.put(`${HookConfig.operatorMgmtUrl}/activities/${payload.id}/acted`, payload, {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }).then((response) => {
        return response.data;
      })
  );
};




export {
  useCreateActivity, useFetchActivities, useFetchActivityById, useFetchActivitySummary, useUpdateActed, useUpdateActivity, useUpdateActivityRead
};

