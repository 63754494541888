import { Card, IconButton, Tooltip } from "@mui/material";
import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import ApiStatus from "../Common/Errors/ApiStatus";
import MessageAlert from "../Common/Errors/MessageAlert";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { useNavigate } from "react-router";
import { GetRowClassName } from "../../functions/utils/helper";
import { SectionHead } from "../Common/Help/SectionHead";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { LR } from "../Common/Help/LR";
import { useFetchTicketPriorities } from "../../hooks/Ticket/ticketPriority";
import { colors } from "../../main/Theme";


type Args =
  {
    userLogged: ApplicationUser
  }

const TicketPriorities = ({ userLogged }: Args) => {
  const nav = useNavigate();
  const { data: ticketPriorities, status, isSuccess } = useFetchTicketPriorities();

  const homeCallBack = () => {
    nav(`/tickets`);
  }

  const addNewItem = () => {
    nav(`/ticket-priorities/add`);
  };

  if (!isSuccess) return (
    <>
      <div className="mt-5 row d-flex justify-content-center">
        <div className="col-md-5 col-11 justify-content-center">
          <ApiStatus status={status} />
        </div>
      </div>
    </>
  )

  if (!ticketPriorities) return <MessageAlert message="MEMBER_LABEL_MEMBERNOTFOUND" params={{ param0: '' }}></MessageAlert>;

  return (
    <>

      <SectionHead linkCallBack={homeCallBack}
        ctaText={i18n.t('MENU_LABEL_TICKETS')!}
        name={`${userLogged.operator!.fullName} (${userLogged.operator!.externalReference})`}
        title={i18n.t("MENU_LABEL_TICKETPRIORITIES")}
        description={i18n.t("COMMON_SUMMARY_TICKETMANAGEMENT")} />

      <div className="mt-1 row d-flex justify-content-center">
        <div className="col-md-5 col-11 d-flex align-items-center">
          <Tooltip title={i18n.t('TICKET_LABEL_NEWTICKETPRIORITY')}>
            <IconButton onClick={addNewItem} className="btn plusButton mr-2">
              <AddCircleRoundedIcon fontSize="medium" sx={{ color: colors.primary }} />
            </IconButton>
          </Tooltip>
          <span className="lead mb-0">
            <LR localResource="TICKET_LABEL_NEWTICKETPRIORITY" />
          </span>
        </div>
      </div>

      <div className="row d-flex justify-content-center">
        <div className="col-md-5 col-11 justify-content-center">
          <Card variant="outlined">
            <DataGrid
              rows={ticketPriorities}
              columns={[
                { field: 'name', headerName: i18n.t('TICKET_LABEL_NAME')!, flex: 1 },
                { field: 'label', headerName: i18n.t('TICKET_LABEL_LABEL')!, flex: 1 },
                { field: 'internalIdentifier', headerName: i18n.t('TICKET_LABEL_INTERNALIDENTIFIER')!, flex: 1 },
                {
                  field: 'id', headerName: '', flex: 0.1, align: "center",
                  renderCell: (params) => {
                    return <Tooltip title={i18n.t('COMMON_TOOLTIP_DETAILS')}>
                      <IconButton className="fa-info" onClick={() => nav(`${params.value}`)}>
                        <EditRoundedIcon sx={{ color: colors.primary }} />
                      </IconButton>
                    </Tooltip>
                  },
                }
              ]}

              slots={{
                toolbar: GridToolbar
              }}
              classes={{
                toolbarContainer: 'toolbarContainer'
              }}

              getRowClassName={GetRowClassName}

              checkboxSelection={false}
              disableColumnSelector={true}
              disableDensitySelector={true}
            />
          </Card>
        </div>
      </div>
    </>
  );
};

export { TicketPriorities }