import axios from "axios";
import { HookConfig } from "../../config/HookConfig";
import { operatorDefaultHeaders } from "../../functions/api/api";
import { CalendarSearchRequestDto } from "../../types/Calendar/CalendarSearchRequestDto";
import { CalendarSearchResponseDto } from "../../types/Calendar/CalendarSearchResponseDto";

const getCalendarData = async (params: CalendarSearchRequestDto): Promise<CalendarSearchResponseDto[] | null> => {
    try {
        const calendarData = await axios.post(
            `${HookConfig.operatorMgmtUrl}/calendar`, params,
            {
                headers: operatorDefaultHeaders(),
                withCredentials: true,
            }
        );
        return calendarData.data;
    } catch (error: any) {
        console.log(`error: ${error}`)
        return null;
    }
}

export {
    getCalendarData
}