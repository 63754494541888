import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { useNavigate } from "react-router";
import { SectionHead } from "../Common/Help/SectionHead";
import { SaveSnackBar } from "../Common/SaveSnackBar";
import { LR } from "../Common/Help/LR";
import { Grid, Tooltip } from "@mui/material";
import { useState } from "react";
import { PersonaResponseDto } from "../../types/Persona/PersonaResponseDto";
import { useDirtyState } from "../../hooks/Common/dirty";
import { ConfirmationChangesDialog } from "../Common/ConfirmationChangesDialog";
import { Input } from "../Common/formComponents/Input";
import { useCreatePersona } from "../../hooks/Personas/persona";
import { showSnackbar } from "../Common/UI/CustomSnackbar";

type Args =
  {
    userLogged: ApplicationUser
  }

const PersonaAdd = ({ userLogged }: Args) => {
  let defaultPersona: PersonaResponseDto = {
    name: '',
    description: '',
    partnerMemberId: userLogged.partner?.partnerMemberId!
  }

  const nav = useNavigate();
  const [personaState, setPersonaState] = useState(defaultPersona);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [isDirty, setDirty, unsetDirty] = useDirtyState();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [targetUrl, setTargetUrl] = useState('');

  const createdCallback = (personaId: string) => {
    setSnackbarOpen(true);
    nav(`/personas/${personaId}/summary`);
  }
  const create = useCreatePersona();

  const homeCallBack = () => {
    let url = `/personas`;
    if (isDirty) {
      setShowConfirmation(true);
      setTargetUrl(url);
    } else {
      nav(url);
    }
  };

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();

      create.mutate(personaState, {
        onSuccess: ({ id: personaId }) => {
          createdCallback(personaId);
        },
        onError: (error) => {
          showSnackbar(error.response?.data, {
            persist: true,
            variant: 'error'
          });
        },
      });
    } catch (err: any) {
      console.error(err);
    }
  }

  const handleChange = (name: string, value: any) => {
    setPersonaState({ ...personaState, [name]: value });
    setDirty();
  }

  const requiredPopulated = () => {
    return true;
  }

  const handleSnackClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <>

      <SectionHead linkCallBack={homeCallBack}
        ctaText={i18n.t('MENU_LABEL_PERSONAS')!}
        name={`${personaState.name}`}
        title={i18n.t("MENU_LABEL_NEWPERSONA")}
        description={i18n.t("COMMON_SUMMARY_TICKETMANAGEMENT")} />

      <ConfirmationChangesDialog
        handleConfirm={() => {
          setShowConfirmation(false);
          unsetDirty();
          nav(targetUrl);
        }}
        handleCancel={() => setShowConfirmation(false)}
        showConfirmation={showConfirmation}
      />

      <form onSubmit={onFormSubmit} >

        <div className="row d-flex justify-content-center">
          <div className="col-md-7 col-11 justify-content-center">
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12}>
                <Input
                  placeholder={i18n.t("PERSONA_LABEL_NAME").toString()}
                  value={personaState.name}
                  onChange={e => handleChange('name', e.target.value)}
                  required
                  labelProps={{
                    message: 'PERSONA_LABEL_NAME',
                    mandatory: true
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  placeholder={i18n.t("PERSONA_LABEL_DESCRIPTION").toString()}
                  value={personaState.description}
                  onChange={e => handleChange('description', e.target.value)}
                  required
                  labelProps={{
                    message: 'PERSONA_LABEL_DESCRIPTION',
                    mandatory: true
                  }}
                />
              </Grid>
            </Grid>


            <div className="row d-flex justify-content-center mb-2">
              <div className="col-md-4 col-6 justify-content-center">
                <Tooltip title={!requiredPopulated() ? i18n.t('TICKET_REQUIRED_TOOLTIP') : ''}>
                  <span>
                    <button
                      type="submit"
                      disabled={!requiredPopulated() || !isDirty}
                      style={!requiredPopulated() ? { pointerEvents: 'none' } : {}}
                      className="btn btn-green btn-primary btn-block"
                    >
                      <LR localResource="COMMON_LABEL_SAVE"></LR>
                    </button>
                  </span>
                </Tooltip>
              </div>
            </div>
            <SaveSnackBar onClose={handleSnackClose} isOpen={snackbarOpen} />
          </div>
        </div>

      </form>
    </>
  );
};

export { PersonaAdd }