import { useState } from "react";
import i18n from "../../i18n";
import CountryDropDown from "../Common/CountryDropDown";
import { LR } from "../Common/Help/LR";
import { LocationRequestDto } from "../../types/Location/LocationRequestDto";
import { MemberResponseDto } from "../../types/Member/MemberResponseDto";
import { ParentMemberSelector } from "../Member/ParentMemberSelector";
import { LabelAndHelp } from "../Common/Help/LabelAndHelp";
import { GenerateExternalReference } from "../../functions/utils/helper";

type Args = {
    isEdit: boolean
    location: LocationRequestDto,
    submitted: (member: LocationRequestDto) => void;
};

const LocationForm = ({ location, isEdit, submitted }: Args) => {

    const [locationState, setlocationState] = useState({ ...location });
    const [submitting, setSubmitting] = useState(false);


    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            setSubmitting(true);

            submitted(locationState);

        } catch (err: any) {
            setSubmitting(false)
            console.error(err);
        }
    }

    const generateUniqueCode = () => {
        const formattedCode = GenerateExternalReference(locationState.name);
        setlocationState({ ...locationState, externalReference: formattedCode });
    };

    const handleConfirm = (operator: MemberResponseDto) => {
        setlocationState({ ...locationState, legalEntityMemberId: operator.id })
    }


    const ChangeCountry = (value: string) => {
        setlocationState({ ...locationState, countryIso3Code: value })
    }

    return (
        <>

            <form onSubmit={onFormSubmit}>
                <LabelAndHelp mandatory={true} fullscreen={true} message='LOCATION_LABEL_NAME' ></LabelAndHelp>
                <div className="row d-flex justify-content-center mb-2">
                    <div className="justify-content-center">
                        <input
                            type="text"
                            className="form-control fa-form"
                            placeholder={i18n.t("LOCATION_LABEL_NAME").toString()}
                            value={locationState.name}
                            onChange={e => { setlocationState({ ...locationState, name: e.target.value }); }} onBlur={() => generateUniqueCode()} required></input>
                    </div>
                </div>

                <div className="row d-flex justify-content-center mb-2">
                    <div className="justify-content-center">
                        <ParentMemberSelector
                            legalEntityMemberId=""
                            flagType={512}
                            operatorId={locationState.operatorMemberId!}
                            handleConfirm={handleConfirm}
                            labelProps={{
                                message: 'LOCATION_LABEL_LEGALENTITY',
                                mandatory: false,
                                helpMessage: 'COMMON_LABEL_HELP_SELECTLEGALENTITY'
                            }}
                        />
                    </div>
                </div>

                <LabelAndHelp mandatory={false} fullscreen={true} message='LOCATION_LABEL_INTERNALREFERENCE' helpMessage='LOCATION_LABEL_HELP_INTERNALREFERENCE'></LabelAndHelp>

                <div className="row d-flex justify-content-center mb-2">
                    <div className="justify-content-center" style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="text"
                            className="form-control fa-form"
                            placeholder={i18n.t("LOCATION_LABEL_INTERNALREFERENCE").toString()}
                            value={locationState.internalIdentifier}
                            onChange={e => setlocationState({ ...locationState, internalIdentifier: e.target.value })} ></input>
                    </div>
                </div>

                <div className="row d-flex justify-content-center mb-2">
                    <div className="justify-content-center">
                        <CountryDropDown
                        valueChanged={ChangeCountry}
                        labelProps={{
                            message: 'COMMON_LABEL_COUNTRY',
                            mandatory: true
                        }}
                        />
                    </div>
                </div>
                <div className="row d-flex justify-content-center mb-2">
                    <div className="col-6 justify-content-center">

                        <button className="btn btn-green btn-primary btn-block" disabled={submitting || locationState.name.length < 4} type="submit">
                            <LR localResource="COMMON_LABEL_SAVE"></LR>
                        </button>

                    </div>
                </div>

            </form>
        </>

    );
}


export { LocationForm }