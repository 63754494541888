import { Grid, Paper, Radio } from "@mui/material";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { Label } from "../Common/formComponents/Label";
import { SectionHead } from "../Common/Help/SectionHead";
import { CustomButton } from "../Common/UI/CustomButton";

type Args = {
  userLogged: ApplicationUser
}

type productDto = "copyExisting" | "new"

export const ProductTypeSelection = ({ userLogged }: Args) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [type, setType] = useState<productDto>(searchParams?.get('type') as productDto || 'new');
  const nav = useNavigate();

  const productTypeList = [
    { value: 'copyExisting', label: i18n.t('PRODUCT_LABEL_COPY_AN_EXISTING_PRODUCT'), description: i18n.t('PRODUCT_LABEL_DESCRIPTION_OF_COPY_AN_EXISTING_PRODUCT') },
    { value: 'new', label: i18n.t('PRODUCT_LABEL_ADD_A_NEW_PRODUCT'), description: i18n.t('PRODUCT_LABEL_DESCRIPTION_OF_NEW_PRODUCT') },
  ]

  const handleSelection = (value: productDto) => {
    setType(value);
    setSearchParams({ type: value });
  }

  const handleNext = () => {
    if (type === 'new') {
      nav('/products/add-new');
    }
    if (type === 'copyExisting') {
      nav('/products/copy-existing');
    }
  }

  const homeCallBack = () => {
    nav('/products');
  }

  return (
    <>
      <SectionHead linkCallBack={homeCallBack}
        ctaText={i18n.t('PRODUCTS_SUMMARY_PAGE')!}
        name=""
        title={i18n.t('PRODUCT_LABEL_ADD_A_NEW_PRODUCT')}
        description={i18n.t('COMMON_SUMMARY_PRODUCTMANAGEMENT')} />

      <div className="row d-flex justify-content-center">
        <div className="col-md-7 col-11 justify-content-center">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Label
                message="PRODUCT_LABEL_WHAT_WOULD_YOU_LIKE_TO_DO"
                mandatory={true}
              ></Label>
            </Grid>
            <Grid item xs={12}>
              {productTypeList.map((item, index) => (
                <Paper
                  component={"label"}
                  key={index}
                  className="p-0 w-100 row mx-0 align-align-items-stretch fa-paper-card rounded-0 shadow-none mb-1"
                  sx={{ cursor: 'pointer' }}
                >
                  <div className="col-md-3 p-3 d-flex align-items-center">
                    <Radio
                      key={index}
                      checked={type === item.value}
                      onChange={(e) => { handleSelection(e.target.value as productDto) }}
                      value={item.value}
                      name="product-type"
                      color="secondary"
                    />
                    <span>{item.label}</span>
                  </div>
                  <div className="col-md-9 p-3 rounded-0 shadow-none d-flex align-items-center border-start border-dark text-white-50">
                    <span>{item.description}</span>
                  </div>
                </Paper>
              ))}
            </Grid>
            <Grid item xs={12} md={4} mx={'auto'}>
              <CustomButton
                variant="contained"
                fullWidth
                type="button"
                onClick={handleNext}
                disabled={type === undefined}>
                {i18n.t('COMMON_BUTTON_NEXT')}
              </CustomButton>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};