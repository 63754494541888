import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { pontCurrencies } from "../../../data/en/pontCurrencies";
import i18n from "../../../i18n";
import { PriceDto } from "../../../types/Price/PriceDto";
import { CustomSelect } from "../formComponents/CustomSelect";
import FormattedNumberInput from "./FormatNumberInput";

type Args = {
  items: PriceDto[]
  label: string,
  countryIsoCode3?: string,
  currencyCode?: string,
  callBack: (item: PriceDto) => void,
  handleDirty: () => void,
  itemsPerRow?: number
};

export const PricesCollection = ({ items, countryIsoCode3, currencyCode, label, callBack, handleDirty, itemsPerRow = 2 }: Args) => {
  const [itemsState, setItemsState] = useState<PriceDto[]>(items);
  const [selectedCurrency, setSelectedCurrency] = useState<{ value: string, label: string, countryCode: string }>();

  if (!currencyCode && !countryIsoCode3) {
    throw Error("Either Country or Currency are needed");
  }

  useEffect(() => {
    setItemsState(items);
  }, [items]);


  const setItemValue = (item: PriceDto, value: string) => {
    const sanitizedValue = value.replace(/[^0-9.]/g, ''); // Remove non-numeric characters
    const numberValue: number = parseFloat(sanitizedValue);
    let updatedItem = {
      ...item,
      price: numberValue,
      currencyCode: selectedCurrency?.value!,
    };
    const updatedItemsState = itemsState.map((i) => (i === item ? updatedItem : i));
    setItemsState(updatedItemsState);
    callBack(updatedItem);
  };


  const renderItemsPerRow = (items: PriceDto[]) => {
    if (selectedCurrency) {
      const rows = [];
      for (let i = 0; i < items.length; i += itemsPerRow) {
        const rowItems = items.slice(i, i + itemsPerRow);
        const row = (
          <div key={i} className="row">
            {rowItems.map((item, index) => (
              <div key={index} className={`col-lg-${12 / itemsPerRow} col-md-${12 / itemsPerRow} col-sm-${12 / itemsPerRow}`}>
                <div className="row d-flex justify-content-start align-items-center mt-1 ">
                  <div className="col-4">
                    <small className={`form-text small-label ml-2`}>{i18n.t(`COMMON_PLACEHOLDER_${item.unitName.toUpperCase()}`).toString()}</small>
                  </div>
                  <div className="col-8 justify-content-end">
                    <FormattedNumberInput
                      allowEmpty={true}
                      value={item.price}
                      onChange={setItemValue}
                      currencyValue={selectedCurrency!.value}
                      item={item}></FormattedNumberInput>
                  </div>
                </div>
              </div>
            ))}
          </div>
        );
        rows.push(row);
      }
      return rows;
    }

  };

  useEffect(() => {
    let defaultCurrency = [{ value: 'EUR', label: 'Euro - €', countryCode: 'EU' }];
    if (countryIsoCode3) {
      defaultCurrency = pontCurrencies.filter(x => x.countryCode === countryIsoCode3)
      if (defaultCurrency.length === 0) {
        defaultCurrency = pontCurrencies.filter(x => x.value === "EUR")
      }
    }

    if (currencyCode) {
      defaultCurrency = pontCurrencies.filter(x => x.value === currencyCode)
      if (defaultCurrency.length === 0) {
        defaultCurrency = pontCurrencies.filter(x => x.value === "EUR")
      }
    }


    const savedCurrency = items.filter(x => x.currencyCode !== '');

    if (savedCurrency && savedCurrency.length > 0) {
      setSelectedCurrency(pontCurrencies.filter(x => x.value === savedCurrency[0].currencyCode!)[0]);

    } else {
      setSelectedCurrency(defaultCurrency[0]);
    }

  }, [countryIsoCode3, currencyCode]);


  const handleCurrencyChange = (selectedOption: any) => {
    setSelectedCurrency(selectedOption);
    //we are now updating the currency for all items
    const updatedPrices = itemsState.map(price => ({
      ...price,
      currencyCode: selectedOption.value
    }));

    setItemsState(updatedPrices);
    //calling the callback for each item
    for (let i = 0; i < updatedPrices.length; i++) {
      callBack(updatedPrices[i]);
    }
  };

  return (
    <>

      {label &&
        <div className="row d-flex justify-content-center mt-3 mb-2">
          <Typography variant="h6">{label}</Typography>
        </div>
      }
      <div className="row d-flex justify-content-center mb-2">
        <div className="justify-content-center disabled-content">
          <CustomSelect
            value={selectedCurrency}
            options={pontCurrencies}
            onChange={handleCurrencyChange}
            placeholder={i18n.t("COMMON_LABEL_SELECTCURRENCY").toString()}
            labelProps={{
              message: 'COMMON_LABEL_CURRENCY',
              mandatory: false,
            }}
          />
        </div>
      </div>
      {renderItemsPerRow(itemsState)}
    </>
  );
}