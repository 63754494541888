import axios, { AxiosError, AxiosResponse } from "axios";
import { HookConfig } from "../../config/HookConfig";
import { TicketDto } from "../../types/Ticket/TicketDto";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { operatorDefaultHeaders } from "../../functions/api/api";
import { TicketConfiguration } from "../../types/Ticket/TicketConfiguration";
import { useEffect, useState } from "react";
import { DigitalAssetDto } from "../../types/Common/DigitalAssetDto";
import { TicketCommentDto } from "../../types/Ticket/TicketCommentDto";
import { TicketGetResponseDto } from "../../types/Ticket/TicketGetResponseDto";
import { TicketSearchRequestDto } from "../../types/Common/TicketSearchRequestDto";

const useSearchTickets = (searchQuery: string, pageCount: number, pageSize: number) => {
  const [data, setData] = useState<TicketGetResponseDto>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let searchParams: TicketSearchRequestDto = {
      searchQuery: searchQuery,
      pageNumber: pageCount,
      pageSize: pageSize
    }

    const fetchData = async () => {
      setLoading(true);
      try {
        axios.get(`${HookConfig.operatorMgmtUrl}/tickets`,
          {
            params: searchParams,
            withCredentials: true,
            headers: operatorDefaultHeaders()
          }).then((resp) => setData(resp.data))
      } catch (err) {
        console.error('Error fetching data', err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [searchQuery, pageCount, pageSize]);

  return { data, loading };
};


const useFetchTickets = () => {
  return useQuery<TicketGetResponseDto, AxiosError>(["tickets"], () =>
    axios.get(`${HookConfig.operatorMgmtUrl}/tickets`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data)
  );
}

const useFetchTicketConfiguration = () => {
  return useQuery<TicketConfiguration, AxiosError>(["ticket-configuration"], () =>
    axios.get(`${HookConfig.operatorMgmtUrl}/tickets/configuration`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data)
  );
}

const useFetchTicketById = (ticketId: string) => {
  return useQuery<TicketDto, AxiosError>(["ticket", ticketId], () =>
    axios.get(`${HookConfig.operatorMgmtUrl}/tickets/${ticketId}`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data)
  );
}

const useCreateTicket = (callback: (ticketId: string) => void) => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError, TicketDto>(
    (ticketResponse) => axios.post(`${HookConfig.operatorMgmtUrl}/tickets`, ticketResponse,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ),
    {
      onSuccess: (_, ticket) => {
        queryClient.invalidateQueries(["ticket", ticket.id]);
        callback(_.data.id);
      },
      onError: (_, error) => {
        console.log(error);
      }
    }
  );
};


const useCreateTicketComment = (callback: (ticketId: string) => void) => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError, TicketCommentDto>(
    (ticketResponse) => axios.post(`${HookConfig.operatorMgmtUrl}/tickets/${ticketResponse.parentId}/comments`, ticketResponse,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ),
    {
      onSuccess: (_, ticket) => {
        queryClient.invalidateQueries(["ticket-comment", ticket.id]);
        callback(_.data.id);
      },
      onError: (_, error) => {
        console.log(error);
      }
    }
  );
};

const useUpdateTicket = (callback: (ticketId: string) => void) => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError, TicketDto>(
    (ticketResponse) => axios.put(`${HookConfig.operatorMgmtUrl}/tickets/${ticketResponse.id}`, ticketResponse,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ),
    {
      onSuccess: (_, ticket) => {
        queryClient.invalidateQueries(["ticket", ticket.id]);
        callback(_.data.id);
      },
      onError: (_, error) => {
        console.log(error);
      }
    }
  );
};


const useFetchTicketDigitalAssetRequirements = (ticketId: string, groupName: string) => {
  const [data, setData] = useState<DigitalAssetDto[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!ticketId || !groupName) {
      setData([]);
      return;
    }

    const fetchPortfolioPriceData = async () => {
      setLoading(true);
      try {
        let url = `${HookConfig.operatorMgmtUrl}/tickets/${ticketId}/digital-assets?group=${groupName}`;
        const response = await axios.get(
          url,
          {
            withCredentials: true,
            headers: operatorDefaultHeaders()
          })
          .then(
            (response) => {
              return response.data;
            }
          );

        setData(response);
      } catch (err) {
        console.error('Error fetching data', err);
      } finally {
        setLoading(false);
      }
    };

    fetchPortfolioPriceData();
  }, [ticketId, groupName]);

  return { data, loading };
};

export {
  useFetchTickets,
  useFetchTicketById,
  useCreateTicket,
  useCreateTicketComment,
  useFetchTicketConfiguration,
  useUpdateTicket,
  useFetchTicketDigitalAssetRequirements,
  useSearchTickets
}