import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton } from "@mui/material";
import { GridColDef } from '@mui/x-data-grid';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useFetchPaymentMethods } from "../../hooks/Order/orderConfiguration";
import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import MessageAlert from '../Common/Errors/MessageAlert';
import { SectionHead } from "../Common/Help/SectionHead";
import { CustomButton } from '../Common/UI/CustomButton';
import { CustomDataGrid } from "../Common/UI/CustomDataGrid";
type Args =
    {
        userLogged: ApplicationUser
    }

const PaymentMethods = ({ userLogged }: Args) => {
    const defaultPagination = { page: 0, pageSize: 10 }
    const [paginationModel, setPaginationModel] = useState(defaultPagination);
    const { data, isLoading, } = useFetchPaymentMethods(
        {
            pageSize: paginationModel.pageSize,
            pageNumber: paginationModel.page + 1,
        }
    );
    const nav = useNavigate()

    const columns: GridColDef[] = [
        {
            field: 'pspName',
            headerName: i18n.t('COMMON_LABEL_NAME').toString(),
            sortable: false,
            flex: 1
        },
        {
            field: 'isDefault',
            headerName: i18n.t('COMMON_LABEL_IS_DEFAULT_PAYMENT_METHOD').toString(),
            sortable: false,
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: (params: any) => params.row.isDefault ? <CheckIcon color='secondary' /> : <></>
        },
        {
            field: 'action',
            headerName: '',
            description: '',
            sortable: false,
            flex: 1,
            align: "right",
            headerAlign: "right",
            renderCell: (cell) => {
                return (
                    <Box onClick={() => nav(`/system/payment-methods/${cell.row.id}`)}>
                        <IconButton color="secondary">
                            <EditIcon></EditIcon>
                        </IconButton>
                    </Box>
                )
            }
        }
    ];


    const handelPaginationChange = (pagination: any) => {
        setPaginationModel((prev) => ({
            ...prev,
            page: pagination?.pageSize !== paginationModel.pageSize ? 0 : pagination?.page,
            pageSize: pagination?.pageSize,
        }));
    }

    return (
        <>
            <SectionHead linkCallBack={() => nav('/system/summary')}
                ctaText={i18n.t('COMMON_SUMMARY_SYSTEM')!}
                name={''}
                title={i18n.t('COMMON_LABEL_PAYMENT_METHODS')}
                description={i18n.t('COMMON_SUMMARY_SYSTEMMANAGEMENT')} />
            <div className="row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <CustomButton
                        color='secondary'
                        sx={{
                            textTransform: "none",
                            marginBottom: 2
                        }}
                        onClick={() => nav('/system/payment-methods/add')}
                    ><AddCircleIcon /> {i18n.t('COMMON_LABEL_ADD_PAYMENT_METHOD').toString()}</CustomButton>
                    {
                        !data?.records || data?.records?.length === 0 ? <MessageAlert message={i18n.t("COMMON_MESSAGE_NO_ACTIVITIES_FOUND")} /> :
                            <CustomDataGrid
                                columns={columns}
                                rows={data?.records}
                                initialState={{
                                    pagination: {
                                        paginationModel: defaultPagination,
                                    },
                                }}
                                rowCount={data?.totalRecords}
                                paginationModel={paginationModel}
                                onPaginationModelChange={handelPaginationChange}
                                loading={isLoading}
                                sx={{
                                    '& .MuiDataGrid-row': {
                                        transition: 'background-color 0.3s ease', // Optional smooth transition effect
                                    }
                                }}
                            />
                    }
                </div>
            </div>
        </>
    );
};


export { PaymentMethods };

