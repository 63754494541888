import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import PersonIcon from '@mui/icons-material/Person';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import InterpreterModeIcon from '@mui/icons-material/InterpreterMode';
export const pontRelationshipTypes = [
  {
    flag: 32,
    label: "LABEL_RELATIONSHIPTYPE_BILLINGCONTACT",
    value: "BillingContact",
    icon: LocalLibraryIcon
  },
  {
    flag: 1,
    label: "LABEL_RELATIONSHIPTYPE_DECISIONMAKER",
    value: "DecisionMaker",
    icon: LocalPoliceIcon
  },
  {
    flag: 8,
    label: "LABEL_RELATIONSHIPTYPE_ENDUSER",
    value: "EndUser",
    icon: PersonIcon
  },
  {
    flag: 4,
    label: "LABEL_RELATIONSHIPTYPE_GATEKEEPER",
    value: "GateKeeper",
    icon: MeetingRoomIcon
  },
  {
    flag: 2,
    label: "LABEL_RELATIONSHIPTYPE_INFLUENCER",
    value: "Influencer",
    icon: SelfImprovementIcon
  },
  {
    flag: 16,
    label: "LABEL_RELATIONSHIPTYPE_POINTOFCONTACT",
    value: "PointOfContact",
    icon: InterpreterModeIcon
  },
]