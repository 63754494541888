import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { Box, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useFetchPricingCataloues } from "../../../hooks/Product/product";
import i18n from "../../../i18n";
import { ApplicationUser } from "../../../types/Authentication/ApplicationUser";
import { SummaryItemDto } from "../../../types/Common/SummaryItemDto";
import ApiStatus from "../../Common/Errors/ApiStatus";
import { SummaryItemCollection } from "../../Common/SummaryItems/SummaryItemCollection";
import { CustomButton } from "../../Common/UI/CustomButton";
import { LR } from '../../Common/Help/LR';

type Args = {
    userLogged: ApplicationUser
}

const PricingCatalogueConfigurationSummary = ({ userLogged }: Args) => {
    const nav = useNavigate();
    let summaryName = null;

    const titleLabel = "MENU_LABEL_PRODUCTS";
    const productLink = (item: SummaryItemDto) => {
        nav(item.entityKey)
    }

    const handleArrowIconClick = () => {
        nav(`configuration`);
    };


    const { data, status, isSuccess } = useFetchPricingCataloues(userLogged.operator?.id!)

    if ((!isSuccess)) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <ApiStatus status={status} />
                </div>
            </div>
        </>
    )

    if (!data) return <ApiStatus status={status} />;

    return (
        <>

            <div className={`row d-flex justify-content-center`}>
                <div className="col-md-7 col-11 justify-content-center">
                    <Tooltip title={i18n.t('PRODUCT_LABEL_ADDPRICINGPORTFOLIO')}>
                        <CustomButton onClick={() => nav('/products/configuration/pricingportfolio/add')} color='secondary' sx={{ textTransform: 'none' }}>
                            <AddCircleRoundedIcon />
                            <Box sx={{ ml: 1 }}>
                                <LR localResource="PRODUCT_LABEL_ADDPRICINGPORTFOLIO" />
                            </Box>
                        </CustomButton>
                    </Tooltip>
                    <SummaryItemCollection useNameInsteadOfLabel={true} hideIcon={true} linkCallBack={productLink} items={data}></SummaryItemCollection>
                </div>
            </div>
        </>
    );
};


export { PricingCatalogueConfigurationSummary };


