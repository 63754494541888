import { format, parseISO } from "date-fns";
import dayjs from 'dayjs';
import { isEmpty, isNil, isObject, isString } from 'lodash';
import numeral from "numeral";
import 'numeral/locales/en-gb';

export function getLocalDateFormat(): string {
  const formatter = new Intl.DateTimeFormat(undefined, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  });

  const formatParts = formatter.formatToParts(new Date());
  const formatMapping = formatParts.map(part => {
    switch (part.type) {
      case 'day':
        return 'dd';
      case 'month':
        return 'MM';
      case 'year':
        return 'yyyy';
      default:
        return part.value;
    }
  });

  return formatMapping.join('');
}


export const GenerateExternalReference = (input: string) => {
  const consonants = input
    .toUpperCase()
    .replace(/\s+/g, '')
    .split('')
    .filter(char => 'BCDFGHJKLMNPQRSTVWXYZ'.includes(char));

  const randomConsonants = [];
  const consonantCount = consonants.length;

  for (let i = 0; i < 3; i++) {
    const randomIndex = Math.floor(Math.random() * consonantCount);
    randomConsonants.push(consonants[randomIndex]);
  }

  const randomCode = Math.floor(Math.random() * 900000) + 100000;
  return `${randomConsonants.join('')}-${randomCode}`;
};

export function GetFileNameWithoutExtension(filePath: string): string {
  const fileName = filePath.split('/').pop() || filePath.split('\\').pop(); // Handles both forward and backward slashes
  const lastDotIndex = fileName!.lastIndexOf('.');
  return lastDotIndex > 0 ? fileName!.slice(0, lastDotIndex) : fileName!;
}

export function GetRowClassName(params: any) {
  return params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow';
}


export function FormatDate(date: Date): string {
  const parsedDate = new Date(date);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  return new Intl.DateTimeFormat(undefined, options).format(parsedDate);
}

export function GetFileNameFromResponseHeaders(headers: any): string {
  const contentDisposition = headers['content-disposition'];
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(contentDisposition);
  if (matches != null && matches[1]) {
    const rawFileName = matches[1].replace(/['"]/g, '');
    const decodedFileName = decodeURIComponent(rawFileName);
    return decodedFileName;
  }
  return 'file';
}

export function DateShim(date: any): Date {
  let newDate: Date;

  if (date instanceof Date) {
    // If theDate is already a Date object
    newDate = date;
  }
  else {
    newDate = new Date(date);
  }
  newDate.setHours(0, 0, 0, 0);
  var offsetInMinutes = newDate.getTimezoneOffset();
  newDate.setMinutes(newDate.getMinutes() - offsetInMinutes);
  return newDate;
}

export function AddDaysToDate(dateString: any, amount: number) {
  // Parse the date string into a Date object
  const date = new Date(dateString);

  // Add the specified amount of days
  date.setDate(date.getDate() + amount);

  // Convert the updated Date object back to a string in ISO format
  return date;
}

export function IsWithin24Hours(date: Date): boolean {
  const currentTime = new Date();
  const oneDayAgo = new Date(currentTime.getTime() - 24 * 60 * 60 * 1000); // 24 hours ago

  return (new Date(date)) >= oneDayAgo;
}

export const replaceToNull = (val: any) => {
  if (val === undefined) {
    return null;
  }
  return val;
};

export const replaceToUndefined = (val: any) => {
  if (val === null) {
    return undefined;
  }
  return val;
};



export function formaOrderLineAvailability(
  fromDate: string,
  toDate?: string
): string {
  const from = dayjs(fromDate.replace("Z", ""));
  const to = toDate ? dayjs(toDate.replace("Z", "")) : dayjs(fromDate.replace("Z", ""));

  const sameDay = to ? from.isSame(to, 'day') : true;

  const formattedFromTime = from.format("HH:mm") === "00:00" ? '' : from.format("HH:mm");
  const formattedToTime = to.format("HH:mm") === "00:00" ? '' : to.format("HH:mm");
  const formattedFromDate = from.format("DD, MMM YYYY");
  const formattedToDate = to ? to.format("DD, MMM YYYY") : '';

  if (sameDay) {
    let result = formattedFromDate;
    result += `${formattedFromTime ? ` ${formattedFromTime}` : ''}${formattedToTime ? `-${formattedToTime}` : ''}`;
    return result;
  } else {
    return `${formattedFromDate}${formattedFromTime ? ` ${formattedFromTime}` : ''} To \n${formattedToDate}${formattedToTime ? ` ${formattedToTime}` : ''}`;
  }
}

export const cleanValue = (value: any) => {
  if ((isObject(value) && isEmpty(value)) ||
    (isString(value) && value.trim() === '') ||
    isNil(value)) {
    return null;
  }
  return value;
};

export const formatCurrency = (value?: number | null, symbol?: string | null, formatTemplate?: string) => {
  return `${symbol ?? `$`} ${numeral(value || 0).format(formatTemplate ?? '0,0.00')}`;
}


type dateFormateTemplate = 'MMM dd, yyyy HH:mm' | 'MMM dd, yyyy'
export const readableDate = (value: string | null | undefined, formatTemplate: dateFormateTemplate) => {
  if (!value) {
    return '-'
  }
  return format(value, formatTemplate)
}


export const convertUTCToLocalFormat = (utcDateString: string): string => {
  console.log(utcDateString)
  const _date = dayjs(utcDateString)
  console.log(_date)
  const date = _date.date()
  const month = _date.month()
  const year = _date.year()
  const hour = _date.hour()
  const minute = _date.minute()
  return `${year}-${month.toString().padStart(2, '0')}-${date.toString().padStart(2, '0')}T${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}:00Z`;
};

