import { yupResolver } from "@hookform/resolvers/yup";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, DialogContent, DialogTitle, Grid, IconButton } from "@mui/material";
import dayjs from "dayjs";
import { isNil } from "lodash";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { pontInitiatedBy } from "../../../../data/pont";
import { useOrderDecisionTypes, useOrderEndingReason, useProcessTermination } from "../../../../hooks/Order/useOrder";
import i18n from "../../../../i18n";
import { colors } from "../../../../main/Theme";
import { CustomDatePicker } from "../../../Common/formComponents/CustomDatepicker";
import { CustomSelect } from "../../../Common/formComponents/CustomSelect";
import { Input } from "../../../Common/formComponents/Input";
import { Label } from "../../../Common/formComponents/Label";
import { LR } from "../../../Common/Help/LR";
import { CustomButton } from "../../../Common/UI/CustomButton";
import { CustomDialog } from "../../../Common/UI/CustomDialog";
import { showSnackbar } from "../../../Common/UI/CustomSnackbar";
import '../OrderForm.css';
import { useOrderContext } from "../OrderProvider";
type ProcessTerminationArgs = {
  open: boolean
  onClose: () => void

}

export const DialogProcessTerminationModal = ({ open, onClose, }: ProcessTerminationArgs) => {
  const navigate = useNavigate()
  const { currentBasket, basketId } = useOrderContext()
  const orderEnding = currentBasket?.orderEnding
  const { mutate: orderTermination, isLoading: isLoadingOrderTermination } = useProcessTermination()

  const { data: decisionTypesList, isLoading: isLoadingDecisionTypesList } = useOrderDecisionTypes()

  const { data: orderEndingReasonList, isLoading: isOrderEndingReasonLoading } = useOrderEndingReason({
    initiatedByName: orderEnding?.initiatedByName!,
    orderEndTypeName: orderEnding?.orderEndTypeName!
  })

  const formattedInitiatedBy = pontInitiatedBy.map(i => ({
    ...i,
    label: i18n.t(i.label).toString()
  }))

  const formSchema = yup.object().shape({
    initiatedByName: yup.string().required(),
    requestComments: yup.string().nullable(),

    orderEndDecisionTypeName: yup
      .object()
      .shape({
        label: yup.string().required(i18n.t("VALIDATION_THIS_REQUIRED").toString()),
        name: yup.string().required(i18n.t("VALIDATION_THIS_REQUIRED").toString()),
      })
      .required(i18n.t("VALIDATION_THIS_REQUIRED").toString()),

    orderEndReasonId: yup
      .object()
      .shape({
        label: yup.string().required(i18n.t("VALIDATION_THIS_REQUIRED").toString()),
        id: yup.number().required(i18n.t("VALIDATION_THIS_REQUIRED").toString()),
      })
      .required(i18n.t("VALIDATION_THIS_REQUIRED").toString()),

    requestedEndDate: yup
      .string()
      .required(i18n.t("VALIDATION_THIS_REQUIRED").toString())
      .test(
        "is-valid-to-date",
        i18n.t("VALIDATION_TO_DATE_MUST_BE_A_VALID_DATE") as string,
        (value) => {
          return value ? dayjs(value).isValid() : true;
        }
      ).nullable(),


    decisionNotes: yup.string().nullable(),
    supportDocument: yup.string().nullable(),
    withInNoticePeriod: yup.string().nullable(),


    newOrderEndDate: yup.string().when(["initiatedByName", "orderEndDecisionTypeName"], {
      is: (initiatedByName: any, decisionTypeName: any) => initiatedByName === "operator" && decisionTypeName?.name === "Approved",
      then: (schema) => schema.required(i18n.t("VALIDATION_THIS_REQUIRED").toString())
        .test(
          "is-valid-to-date",
          i18n.t("VALIDATION_TO_DATE_MUST_BE_A_VALID_DATE") as string,
          (value) => {
            return value ? dayjs(value).isValid() : true;
          }
        ),
      otherwise: (schema) => schema.nullable(),
    }),

    newInventoryEndDate: yup.string().when(["initiatedByName", "orderEndDecisionTypeName"], {
      is: (initiatedByName: any, decisionTypeName: any) => initiatedByName === "operator" && decisionTypeName?.name === "Approved",
      then: (schema) => schema.required(i18n.t("VALIDATION_THIS_REQUIRED").toString())
        .test(
          "is-valid-to-date",
          i18n.t("VALIDATION_TO_DATE_MUST_BE_A_VALID_DATE") as string,
          (value) => {
            return value ? dayjs(value).isValid() : true;
          }
        ),
      otherwise: (schema) => schema.nullable(),
    }),
  });

  type formType = yup.InferType<typeof formSchema>;
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm<formType>({
    resolver: yupResolver(formSchema),
    mode: 'onChange'
  });

  const decisionTypeName = watch("orderEndDecisionTypeName")

  const onSubmit = (data: formType) => {
    orderTermination({
      basketId: basketId!,
      orderEndDecisionTypeName: data.orderEndDecisionTypeName.name,
      decisionNotes: data.decisionNotes || "",
      newOrderEndDate: data.newOrderEndDate ? dayjs(data.newOrderEndDate).format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]") : null,
      newInventoryEndDate: data.newInventoryEndDate ? dayjs(data.newInventoryEndDate).format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]") : null,

      // initiatedByName: data.initiatedByName,
      // orderEndTypeName: orderEnding?.orderEndTypeName!,
      // orderEndReasonId: data.orderEndReasonId?.id.toString(),
    }, {
      onSuccess: () => {
        onClose()
        navigate(`/orders`)
        showSnackbar(i18n.t("SUCCESS_PROCESS_TERMINATION_ORDER"))
      }, onError: (error: any) => {
        showSnackbar(error.response?.data, {
          persist: true,
          variant: 'error'
        })
      }
    })
  }

  useEffect(() => {
    if (orderEnding) {
      setValue("initiatedByName", orderEnding?.initiatedByName.toLocaleLowerCase())
      setValue("orderEndReasonId", {
        label: orderEnding.orderEndReasonName,
        id: orderEnding.orderEndReasonId,
      })
      setValue("requestComments", orderEnding?.requestComments)
      setValue("supportDocument", orderEnding?.fileName)
      setValue("newOrderEndDate", `${dayjs(currentBasket?.maximumEndDate)}`)
      setValue("newInventoryEndDate", `${dayjs(currentBasket?.maximumEndDate)}`)
      setValue("withInNoticePeriod", orderEnding?.withInNoticePeriod ?
        i18n.t("ORDER_LABEL_RECOMMENDATION_DECLINE").toString() :
        i18n.t("COMMON_LABEL_YES").toString())
      setValue("requestedEndDate", orderEnding?.requestedEndDate ? `${dayjs(orderEnding?.requestedEndDate)}` : null)
    }
  }, [orderEnding, orderEndingReasonList])

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      scroll="body"
    >
      <DialogTitle>
        <LR localResource={i18n.t("COMMON_LABEL_PROCESS_TERMINATION")}></LR>
      </DialogTitle>
      <DialogContent>
        <Box sx={{
          minHeight: '550px',
        }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} mb={2}>

              {/* Who has requested the termination? * */}
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="initiatedByName"
                  render={({ field, fieldState }) => {
                    return (
                      <CustomSelect
                        labelProps={{
                          message: "LABEL_ORDER_TERMINATION",
                          mandatory: true,
                        }}
                        isDisabled
                        value={formattedInitiatedBy.find((x) => x.value === field.value)}
                        options={formattedInitiatedBy}
                        placeholder={i18n.t("COMMON_LABEL_SELECT")}
                        field={field}
                        onChange={(e) => {
                          field.onChange(e?.value);
                        }}
                        validationError={fieldState.error}
                      />
                    );
                  }}
                />
              </Grid>


              {/* Reason for termination */}
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="orderEndReasonId"
                  render={({ field, fieldState }) => {
                    return (
                      <CustomSelect
                        labelProps={{
                          message: "LABEL_REASON_FOR_TERMINATION",
                          mandatory: true,
                        }}
                        isDisabled
                        getOptionValue={(option: any) => option.id}
                        getOptionLabel={(option: any) => i18n.t(option.label).toString()}
                        isLoading={isOrderEndingReasonLoading}
                        options={orderEndingReasonList ? orderEndingReasonList : []}
                        field={field}
                        value={field.value || null}
                        onChange={field.onChange}
                        placeholder={i18n.t("COMMON_LABEL_SELECT")}
                        validationError={fieldState.error}
                      />
                    );
                  }}
                />
              </Grid>

              {/* Any additional comments  */}
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="requestComments"
                  render={({ field, fieldState }) => {
                    return (
                      <Input
                        labelProps={{
                          message: i18n.t("LABEL_ANY_ADDITIONAL_COMMENTS").toString(),
                          mandatory: false,
                        }}
                        multiline={true}
                        rows={3}
                        disabled
                        field={field}
                        onChange={(e) => {
                          field.onChange(e.target.value)
                        }}
                        validationError={fieldState.error}
                      />
                    );
                  }}
                />
              </Grid>

              {/* Request end date  */}
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="requestedEndDate"
                  render={({ field, fieldState, ...rest }) => {
                    return (
                      <CustomDatePicker
                        value={dayjs(field.value)}
                        onChange={(e) => {
                          if (!isNil(e) && e.isValid()) {
                            field.onChange(e.toDate())
                          }
                        }}
                        disabled
                        labelProps={{
                          message: i18n.t("LABEL_REQUEST_END_DATE").toString(),
                          mandatory: true
                        }}
                        validationError={fieldState.error}
                        {...rest}
                      />
                    );
                  }}
                />
              </Grid>


              {/* Supporting Documents */}
              {
                orderEnding?.fileExternalUrl &&
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="supportDocument"
                    render={({ field, fieldState, ...rest }) => {
                      return (
                        <>
                          <Box>
                            <Label message={i18n.t("LABEL_SUPPORTING_DOCUMENTS").toString()} />
                            <Box onClick={() => orderEnding?.fileExternalUrl ? window.open(orderEnding?.fileExternalUrl) : null}
                              sx={{ color: colors.primary, display: 'flex', alignItems: 'center', gap: 1, cursor: 'pointer' }}>
                              {orderEnding?.fileName}
                              <IconButton className="fa-info" >
                                <VisibilityIcon sx={{ color: colors.primary }} />
                              </IconButton>
                            </Box>

                          </Box>
                        </>

                      );
                    }}
                  />
                </Grid>
              }

              {/* With-in Notice Periods  */}
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="withInNoticePeriod"
                  render={({ field, fieldState, ...rest }) => {
                    return (
                      <Input
                        labelProps={{
                          message: i18n.t("LABEL_NOTICE_PERIOD").toString(),
                          mandatory: true
                        }}
                        disabled
                        type="text"
                        field={field}
                        onChange={(e) => {
                          field.onChange(e.target.value)
                        }}
                        value={field.value}
                        validationError={fieldState.error}
                        {...rest}
                      ></Input>
                    );
                  }}
                />
              </Grid>


              {/* Decision */}

              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="orderEndDecisionTypeName"
                  render={({ field, fieldState }) => {
                    return (
                      <CustomSelect
                        labelProps={{
                          message: "LABEL_DECISION",
                          mandatory: true,
                        }}
                        isLoading={isLoadingDecisionTypesList}
                        getOptionLabel={(option: any) => i18n.t(option.label).toString()}
                        getOptionValue={(option: any) => option.name}
                        value={field.value || null}
                        options={decisionTypesList}
                        placeholder={i18n.t("COMMON_LABEL_SELECT")}
                        field={field}
                        onChange={(value) => {
                          field.onChange(value);
                        }}
                        validationError={fieldState.error}
                      />
                    );
                  }}
                />
              </Grid>


              {/* Decision comments  */}
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="decisionNotes"
                  render={({ field, fieldState }) => {
                    return (
                      <Input
                        labelProps={{
                          message: i18n.t("LABEL_DECISION_COMMENTS").toString(),
                          mandatory: false,
                        }}
                        multiline={true}
                        rows={3}
                        field={field}
                        onChange={(e) => {
                          field.onChange(e.target.value)
                        }}
                        validationError={fieldState.error}
                      />
                    );
                  }}
                />
              </Grid>

              {
                orderEnding?.initiatedByName.toLowerCase() === "operator" && decisionTypeName?.name === "Approved" &&
                <>
                  {/* Agreement EndDate */}
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name="newOrderEndDate"
                      render={({ field, fieldState, ...rest }) => {
                        return (
                          <CustomDatePicker
                            value={field.value ? dayjs(field.value) : null}
                            onChange={(e) => {
                              if (!isNil(e) && e.isValid()) {
                                field.onChange(e.toDate())
                              }
                            }}
                            maxDate={dayjs(currentBasket?.maximumEndDate)}
                            minDate={dayjs(currentBasket?.minimumEndDate)}
                            labelProps={{
                              message: i18n.t("LABEL_AGREEMENT_END_DATE").toString(),
                              mandatory: true
                            }}
                            validationError={fieldState.error}
                            {...rest}
                          />
                        );
                      }}
                    />
                  </Grid>

                  {/* Inventory EndDate  */}
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name="newInventoryEndDate"
                      render={({ field, fieldState, ...rest }) => {
                        return (
                          <CustomDatePicker
                            value={field.value ? dayjs(field.value) : null}
                            onChange={(e) => {
                              if (!isNil(e) && e.isValid()) {
                                field.onChange(e.toDate())
                              }
                            }}

                            maxDate={dayjs(currentBasket?.maximumEndDate)}
                            minDate={dayjs(currentBasket?.minimumEndDate)}
                            labelProps={{
                              message: i18n.t("LABEL_INVENTORY_END_DATE").toString(),
                              mandatory: true
                            }}
                            validationError={fieldState.error}
                            {...rest}
                          />
                        );
                      }}
                    />
                  </Grid>
                </>
              }

            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={1} md={4} mx={"auto"}>
                <CustomButton
                  variant="contained"
                  fullWidth
                  type="submit"
                  loading={isLoadingOrderTermination}
                >
                  {i18n.t("COMMON_LABEL_SAVE")}
                </CustomButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </DialogContent>
    </CustomDialog>
  );
};
