import { Box, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Dashboard } from '../components/Admin/Dashboard';
import { GlobalStateContext } from '../components/Common/GlobalStateProvider';
import { AllocationAdd } from '../components/Inventory/AllocationAdd';
import { AllocationEdit } from '../components/Inventory/AllocationEdit';
import { InventoryAdd } from '../components/Inventory/InventoryAdd';
import { InventoryEdit } from '../components/Inventory/InventoryEdit';
import { UnavailabilityAdd } from '../components/Inventory/UnavailabilityAdd';
import { LocationAdd } from '../components/Location/LocationAdd';
import { LocationCommunications } from '../components/Location/LocationCommunications';
import { LocationDescription } from '../components/Location/LocationDescription';
import { LocationDetails } from '../components/Location/LocationDetails';
import { LocationDigitalAssets } from '../components/Location/LocationDigitalAssets';
import { LocationFeatureGroup } from '../components/Location/LocationFeatureGroup';
import { LocationFeatures } from '../components/Location/LocationFeatures';
import { LocationInventories } from '../components/Location/LocationInventories';
import { Locations } from '../components/Location/Locations';
import { LocationSummary } from '../components/Location/LocationSummary';
import Login from '../components/Login/Login';
import Logout from '../components/Login/Logout';
import Register from '../components/Login/Register';
import RecoverAccount from '../components/Login/ResetPassword';
import ResetPasswordAlreadyRegistered from '../components/Login/ResetPasswordAlreadyRegistered';
import { CompanyDetails } from '../components/Member/CompanyDetails';
import { ContactEdit } from '../components/Member/ContactEdit';
import { ContactManage } from '../components/Member/ContactManage';
import { Contacts } from '../components/Member/Contacts';
import { HostCommunicationTypes } from '../components/Member/HostCommunicationTypes';
import { HostDigitalAssets } from '../components/Member/HostDigitalAssets';
import { HostDomains } from '../components/Member/HostDomains';
import { HostFiscalDetails } from '../components/Member/HostFiscalDetails';
import { HostLocations } from '../components/Member/HostLocations';
import { HostSummary } from '../components/Member/HostSummary';
import { KycDetails } from '../components/Member/KycDetails';
import { MemberAdd } from '../components/Member/MemberAdd';
import { Operators } from '../components/Member/Operators';
import Footer from '../components/Navigation/Footer';
import Menu from '../components/Navigation/Menu';
import { Notifications } from '../components/Notification/Notifications';
import i18n from '../i18n';
import { ApplicationUser } from '../types/Authentication/ApplicationUser';
import "./App.css";
import "./cards.css";
import { colors, PontTheme } from './Theme';

// English locales
import 'dayjs/locale/en'; // default English locale, used in many countries
import 'dayjs/locale/en-au'; // Australia
import 'dayjs/locale/en-ca'; // Canada
import 'dayjs/locale/en-gb'; // United Kingdom

// European locales
import 'dayjs/locale/cs'; // Czech Republic
import 'dayjs/locale/de'; // Germany
import 'dayjs/locale/el'; // Greece
import 'dayjs/locale/es'; // Spain
import 'dayjs/locale/fi'; // Finland
import 'dayjs/locale/fr'; // France
import 'dayjs/locale/hu'; // Hungary
import 'dayjs/locale/it'; // Italy
import 'dayjs/locale/nl'; // Netherlands
import 'dayjs/locale/pl'; // Poland
import 'dayjs/locale/pt'; // Portugal
import 'dayjs/locale/ro'; // Romania
import 'dayjs/locale/ru'; // Russia
import 'dayjs/locale/sk'; // Slovakia
import 'dayjs/locale/sv'; // Sweden

// Asian locales
import 'dayjs/locale/id'; // Indonesia
import 'dayjs/locale/ja'; // Japan
import 'dayjs/locale/ko'; // Korea
import 'dayjs/locale/th'; // Thailand
import 'dayjs/locale/vi'; // Vietnam
import 'dayjs/locale/zh-cn'; // China
import 'dayjs/locale/zh-tw'; // Taiwan
import { useContext, useEffect, useRef, useState } from 'react';
import { Activities } from '../components/Activity/Activities';
import { Admin } from '../components/Admin/Admin';
import { Home } from '../components/Admin/Home';
import { ApplicationConfiguration } from '../components/Application/ApplicationConfiguration';
import { ApplicationConfigurationDetails } from '../components/Application/ApplicationConfigurationDetails';
import { NotificationRequestCreate } from '../components/Application/NotificationRequest/NotificationRequestCreate';
import { PostContent } from '../components/Application/Post/PostContent';
import { PostDetails } from '../components/Application/Post/PostDetails';
import { PostDigitalAssets } from '../components/Application/Post/PostDigitalAssets';
import { PostFeatureGroup } from '../components/Application/Post/PostFeatureGroup';
import { PostFeatures } from '../components/Application/Post/PostFeatures';
import { Calendar } from '../components/Calendar/Calendar';
import Sidebar from '../components/Common/Sidebar';
import { Loader } from '../components/Common/UI/Loader';
import { Customers } from '../components/Customer/Customers';
import { EventCheckoutNote } from '../components/Event/EventCheckoutNote';
import { EventDescription } from '../components/Event/EventDescription';
import { EventDigitalAssets } from '../components/Event/EventDigitalAssets';
import { EventFeatureGroup } from '../components/Event/EventFeatureGroup';
import { Events } from '../components/Event/Events';
import { EventSummary } from '../components/Event/EventSummary';
import { DescriptionHandler } from '../components/Host/DescriptionHandler';
import { DigitalAssetsHandler } from '../components/Host/DigitalAssetHandler';
import { Inventory } from '../components/Inventory/Inventory';
import { LocationConfiguration } from '../components/Location/Configuration/LocationConfiguration';
import { LocationConfigurationDetails } from '../components/Location/Configuration/LocationConfigurationDetails';
import { GlobalLocationConfiguration } from '../components/Location/GlobalConfiguration/GlobalLocationConfiguration';
import { GlobalLocationConfigurationDetails } from '../components/Location/GlobalConfiguration/GlobalLocationConfigurationDetails';
import { NearByAmenities } from '../components/Location/NearByAmenities';
import { NearByAmenityForm } from '../components/Location/NearByAmenityForm';
import { CompanyForm } from '../components/Member/CompanyForm';
import { ContactForm } from '../components/Member/ContactForm';
import { EditContactV2 } from '../components/Member/EditContactV2';
import { EditIndividualV2 } from '../components/Member/EditIndividual';
import { IndividualForm } from '../components/Member/IndividualForm';
import { LegalEntities } from '../components/Member/LegalEntities';
import { LegalEntityAdd } from '../components/Member/LegalEntityAdd';
import { MemberTypeSelection } from '../components/Member/MemberTypeSelection';
import { MemberList } from '../components/Member/MemberList';
import { MembershipDescription } from '../components/Membership/MembershipDescription';
import { MembershipDigitalAssets } from '../components/Membership/MembershipDigitalAssets';
import { MembershipFeatureGroup } from '../components/Membership/MembershipFeatureGroup';
import { Memberships } from '../components/Membership/Memberships';
import { MembershipSummary } from '../components/Membership/MembershipSummary';
import { CancelOrder } from '../components/Order/CancelOrder';
import { ConnectedAccountAdd } from '../components/Order/ConnectedAccountAdd';
import { ConnectedAccountEdit } from '../components/Order/ConnectedAccountEdit';
import { InvoiceDetail } from '../components/Order/InvoiceDetail';

import { OrderManagementSystem } from '../components/Order/OrderManagementSystem';
import { Orders } from '../components/Order/Orders';
import { PaymentMethodForm } from '../components/Order/PaymentMethodForm';
import { PaymentMethods } from '../components/Order/PaymentMethods';
import { ViewOrder } from '../components/Order/View/ViewOrder';
import { ViewInvoice } from '../components/Order/ViewInvoice';
import { PartnerSetup } from '../components/Partner/PartnerSetup';
import { BenefitDetails } from '../components/Product/BenefitDetails';
import { ProductConfiguration } from '../components/Product/Configuration/ProductConfiguration';
import { ProductConfigurationDetails } from '../components/Product/Configuration/ProductConfigurationDetails';
import { ProductPortfolioDetails } from '../components/Product/Configuration/ProductPortfolioDetails';
import { ProductRuleDetails } from '../components/Product/Configuration/ProductRuleDetails';
import { ProductDetails } from '../components/Product/ProductDetails';
import { ProductDigitalAssets } from '../components/Product/ProductDigitalAssets';
import { Products } from '../components/Product/Products';
import { ProductTypeSelection } from '../components/Product/ProductTypeSelection';
import { Reports } from '../components/Report/Reports';
import { TicketAdd } from '../components/Ticket/TicketAdd';
import { TicketCategories } from '../components/Ticket/TicketCategories';
import { TicketCategoryAdd } from '../components/Ticket/TicketCategoryAdd';
import { TicketCategoryEdit } from '../components/Ticket/TicketCategoryEdit';
import { TicketDigitalAssets } from '../components/Ticket/TicketDigitalAssets';
import { TicketEdit } from '../components/Ticket/TicketEdit';
import { TicketPriorities } from '../components/Ticket/TicketPriorities';
import { TicketPriorityAdd } from '../components/Ticket/TicketPriorityAdd';
import { TicketPriorityEdit } from '../components/Ticket/TicketPriorityEdit';
import { Tickets } from '../components/Ticket/Tickets';
import { isUserLoggedV2 } from '../hooks/Authentication/userinfoV2';

import { NewOrder } from '../components/Order/OrderForm/NewOrder';
import { CopyProduct } from '../components/Product/CopyProduct';
import { SourceProvider } from '../providers/SourceProvider';
import { SourceEnum } from '../types/Common/CommonSummaryHeaderProps';
import { PersonaList } from '../components/Persona/PersonaList';
import { PersonaAdd } from '../components/Persona/PersonaAdd';
import { PersonaSummary } from '../components/Persona/PersonaSummary';
import { ChangePartner } from '../components/Member/ChangePartner';

function Pont() {
  const globalState = useContext(GlobalStateContext);
  if (!globalState) {
    throw new Error('Used GlobalStateContext outside of GlobalStateProvider');
  }

  const { globalLoggedUser, setGlobalLoggedUser } = globalState;
  const [userInfo, setUserInfo] = useState<ApplicationUser>();

  const navigate = useNavigate();
  const [dataLoaded, setDataLoaded] = useState(false);
  let mainbg = 'pontbg';
  let isLoading = useRef(false);

  useEffect(() => {
    const checkLogin = async () => {
      if (!userInfo && !isLoading.current) {
        isLoading.current = true;
        setUserInfo(await isUserLoggedV2());
      }
    };
    checkLogin()
  }, []);

  const handleLogout = () => {
    if (userInfo) {
      navigate(userInfo.logoutUrl);
    }
  };

  const setupCallBack = (updatedUser: ApplicationUser) => {
    setDataLoaded(true);
    setGlobalLoggedUser(updatedUser);
    navigate("/");
  }

  useEffect(() => {
    if (userInfo && userInfo.isLogged && !dataLoaded) {
      setDataLoaded(true);
      setGlobalLoggedUser(userInfo);
    }
  }, [userInfo, dataLoaded]);

  return (
    <>
      {
        globalLoggedUser && globalLoggedUser.partner && globalLoggedUser.operator && globalLoggedUser.location &&
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language.toLowerCase()} >
          <div className={`${mainbg} fixed-bottom`}>
            {globalLoggedUser.isLogged && globalLoggedUser.doesNeedSetup === false && globalLoggedUser.checkPartner === false &&
              <ThemeProvider theme={PontTheme}>
                <Sidebar userLogged={globalLoggedUser!} />
                <Menu userInfo={globalLoggedUser!}></Menu>
                {
                  dataLoaded && <>
                    <div className="myContainer">
                      <div className="renderZone">
                        <Routes>
                          <Route path="/" element={<Activities userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/loading" element={<Home userLogged={globalLoggedUser!} />}></Route>

                          <Route path="/calendar-view" element={<Calendar userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/reports" element={<Reports userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/inventory" element={<Inventory userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/admin" element={<Admin userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/dashboard" element={<Dashboard userLogged={globalLoggedUser!} />}></Route>

                          <Route path="/legal-entities" element={<SourceProvider source={SourceEnum.legalEntity} />} >
                            <Route index element={<Operators userLogged={globalLoggedUser!} />}></Route>
                            <Route path="add" element={<LegalEntityAdd userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/summary" element={<HostSummary userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/companydetails" element={<CompanyDetails userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/communications/:communicationType" element={<HostCommunicationTypes userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/locations" element={<HostLocations userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/summary/:summaryName" element={<HostSummary userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/fiscal-details/:country" element={<HostFiscalDetails userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/kyc-details/:country" element={<KycDetails userLogged={globalLoggedUser!} />}></Route>
                          </Route>

                          <Route path="/orders" element={<Orders userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/orders/new-order" element={<NewOrder userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/orders/basket/:basketId" element={<NewOrder isBasketPage={true} userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/orders/basket/:basketId/view" element={<NewOrder readOnly={true} isBasketPage={true} userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/orders/:id/cancel" element={<CancelOrder userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/orders/:id/view-order" element={<ViewOrder userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/orders/:id/invoice" element={<ViewInvoice userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/orders/:orderid/invoices/:invoiceid" element={<InvoiceDetail userLogged={globalLoggedUser!} />}></Route>

                          <Route path="/operators" element={<SourceProvider source={SourceEnum.operators} />} >
                            <Route index element={<MemberList userLogged={globalLoggedUser!} />}></Route>
                            <Route path="add" element={<MemberAdd userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/" element={<ResetPasswordAlreadyRegistered userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/summary" element={<HostSummary userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/companydetails" element={<CompanyDetails userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/domains" element={<HostDomains userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/communications/:communicationType" element={<HostCommunicationTypes userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/contacts" element={<Contacts userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/locations" element={<HostLocations userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/legal-entities" element={<LegalEntities userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/summary/:summaryName" element={<HostSummary userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/digital-assets/:groupName" element={<HostDigitalAssets userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/contact-management/notifications" element={<Notifications userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/fiscal-details/:country" element={<HostFiscalDetails userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/kyc-details/:country" element={<KycDetails userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":operatorId/location/add" element={<LocationAdd userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":parentId/contacts/add" element={<ContactManage isEdit={false} userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":parentId/contacts/:id" element={<ContactEdit userLogged={globalLoggedUser!} />}></Route>
                            <Route path="configuration/:configurationName" element={<GlobalLocationConfigurationDetails userLogged={globalLoggedUser!} />}></Route>
                          </Route>
                          <Route path="/landlords" element={<SourceProvider source={SourceEnum.landlords} />} >
                            <Route index element={<MemberList userLogged={globalLoggedUser!} />}></Route>
                            <Route path="add" element={<MemberAdd userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/summary" element={<HostSummary userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/summary/:summaryName" element={<HostSummary userLogged={globalLoggedUser!} />}></Route>

                            <Route path=":id/contacts" element={<Contacts userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":parentId/contacts/add" element={<ContactManage isEdit={false} userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":parentId/contacts/:id" element={<ContactEdit userLogged={globalLoggedUser!} />}></Route>

                            <Route path=":id/communications/:communicationType" element={<HostCommunicationTypes userLogged={globalLoggedUser!} />}></Route>

                            <Route path=":id/companydetails" element={<CompanyDetails userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/fiscal-details/:country" element={<HostFiscalDetails userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/domains" element={<HostDomains userLogged={globalLoggedUser!} />}></Route>
                          </Route>

                          <Route path="/personas" element={<SourceProvider source={SourceEnum.operators} />} >
                            <Route index element={<PersonaList userLogged={globalLoggedUser!} />}></Route>
                            <Route path="add" element={<PersonaAdd userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/summary" element={<PersonaSummary userLogged={globalLoggedUser!} />}></Route>
                          </Route>

                          <Route path="/locations" element={<SourceProvider source={SourceEnum.locations} />} >
                            <Route index element={<Locations userLogged={globalLoggedUser!} />}></Route>
                            <Route path="add" element={<LocationAdd userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/summary" element={<LocationSummary userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/inventories" element={<LocationInventories userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/inventories/add" element={<InventoryAdd userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/inventories/:inventoryId" element={<InventoryEdit userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/inventories/:inventoryId/description" element={<DescriptionHandler idToUse='inventoryId' userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/inventories/:inventoryId/digital-assets/:groupName" element={<DigitalAssetsHandler idToUse='inventoryId' userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/inventories/:inventoryId/unavailability/add" element={<UnavailabilityAdd userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/inventories/:inventoryId/allocations/add" element={<AllocationAdd userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/inventories/:inventoryId/allocations/:inventoryAllocationId" element={<AllocationEdit userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/locationdetails" element={<LocationDetails userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/communications" element={<LocationCommunications userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/configuration" element={<LocationConfiguration userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/configuration/:configurationName" element={<LocationConfigurationDetails userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/features" element={<LocationFeatures userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/features/:featuregroup" element={<LocationFeatureGroup userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/summary/:summaryName" element={<LocationSummary userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/digital-assets/:groupName" element={<LocationDigitalAssets userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/description" element={<LocationDescription userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/communications/:communicationType" element={<HostCommunicationTypes userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/near-by-amenities" element={<NearByAmenities userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/near-by-amenities/add" element={<NearByAmenityForm userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/near-by-amenities/:amenityId" element={<NearByAmenityForm userLogged={globalLoggedUser!} />}></Route>
                            <Route path="configuration" element={<GlobalLocationConfiguration userLogged={globalLoggedUser!} />}></Route>
                            <Route path="configuration/:configurationName" element={<GlobalLocationConfigurationDetails userLogged={globalLoggedUser!} />}></Route>
                          </Route>

                          <Route path="/me/resetpassword/" element={<ResetPasswordAlreadyRegistered userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/me/:id/notifications" element={<Notifications userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/me/change-partner/" element={<ChangePartner userLogged={globalLoggedUser!} />}></Route>
                          
                          <Route path="/me/accountsettings/:id" element={<SourceProvider source={SourceEnum.operators} />} >
                            <Route index element={<ContactEdit userLogged={globalLoggedUser!} isCurrentUserEdit={true} />}></Route>
                          </Route>
                          <Route path="/logout" element={<Logout onLogout={handleLogout}></Logout>}></Route>

                          <Route path="/products" element={<Products userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/products/add-selector" element={<ProductTypeSelection userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/products/add-new" element={<ProductDetails userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/products/copy-existing" element={<CopyProduct userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/products/configuration" element={<ProductConfiguration userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/products/configuration/:configurationName" element={<ProductConfigurationDetails userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/products/configuration/productrules/:id" element={<ProductRuleDetails userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/products/configuration/pricingportfolio/:id" element={<ProductPortfolioDetails userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/products/configuration/pricingportfolio/add" element={<ProductPortfolioDetails userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/products/:productId" element={<ProductDetails userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/products/:id/digital-assets/:groupName" element={<ProductDigitalAssets userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/products/:productId/benefits/add" element={<BenefitDetails key={Math.random()} userLogged={globalLoggedUser!} isEdit={false} />}></Route>
                          <Route path="/products/:productId/benefits/:id" element={<BenefitDetails key={Math.random()} userLogged={globalLoggedUser!} isEdit={true} />}></Route>

                          <Route path="/events/" element={<Events userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/events/add" element={<EventSummary userLogged={globalLoggedUser!} isEdit={false} />}></Route>
                          <Route path="/events/:id/summary" element={<EventSummary userLogged={globalLoggedUser!} isEdit={true} />}></Route>
                          <Route path="/events/:id/digital-assets/:groupName" element={<EventDigitalAssets userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/events/:id/features" element={<EventFeatureGroup userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/events/:id/description" element={<EventDescription userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/events/:id/checkoutnote" element={<EventCheckoutNote userLogged={globalLoggedUser!} />}></Route>

                          <Route path="/memberships/" element={<Memberships userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/memberships/add" element={<MembershipSummary userLogged={globalLoggedUser!} isEdit={false} />}></Route>
                          <Route path="/memberships/:id/summary" element={<MembershipSummary userLogged={globalLoggedUser!} isEdit={true} />}></Route>
                          <Route path="/memberships/:id/digital-assets/:groupName" element={<MembershipDigitalAssets userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/memberships/:id/features" element={<MembershipFeatureGroup userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/memberships/:id/description" element={<MembershipDescription userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/memberships/:inventoryId/summary/:id/unavailability/add" element={<UnavailabilityAdd userLogged={globalLoggedUser!} />}></Route>

                          <Route path="/system/connected-accounts/add" element={<ConnectedAccountAdd userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/system/connected-accounts/:id" element={<ConnectedAccountEdit userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/system/summary" element={<OrderManagementSystem userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/system/payment-methods" element={<PaymentMethods userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/system/payment-methods/add" element={<PaymentMethodForm userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/system/payment-methods/:id" element={<PaymentMethodForm userLogged={globalLoggedUser!} />}></Route>

                          <Route path="/application/configuration" element={<ApplicationConfiguration userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/application/:id/configuration/:configurationName" element={<ApplicationConfigurationDetails userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/application/:id/configuration/posts/add" element={<PostDetails userLogged={globalLoggedUser!} isEdit={false} />}></Route>
                          <Route path="/application/:id/configuration/posts/:postId" element={<PostDetails userLogged={globalLoggedUser!} isEdit={true} />}></Route>
                          <Route path="/application/:id/configuration/posts/:postId/digital-assets/:groupName" element={<PostDigitalAssets userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/application/:id/configuration/posts/:postId/content" element={<PostContent userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/application/:id/configuration/posts/:postId/features" element={<PostFeatures userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/application/:id/configuration/posts/:postId/features/:featuregroup" element={<PostFeatureGroup userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/application/:id/configuration/notifications/add" element={<NotificationRequestCreate userLogged={globalLoggedUser!} isCreate={true} />}></Route>
                          <Route path="/application/:id/configuration/notifications/:notificationRequestId" element={<NotificationRequestCreate userLogged={globalLoggedUser!} isCreate={false} />}></Route>

                          <Route path='/companies' element={<SourceProvider source={SourceEnum.companies} />} >
                            <Route index path=":id/summary" element={<HostSummary userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/companydetails" element={<CompanyDetails userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/domains" element={<HostDomains userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/communications/:communicationType" element={<HostCommunicationTypes userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":parentId/contacts/:id/summary" element={<HostSummary titleSource={SourceEnum.contacts} userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/locations" element={<HostLocations userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/legal-entities" element={<LegalEntities userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/summary/:summaryName" element={<HostSummary userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/digital-assets/:groupName" element={<HostDigitalAssets userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/contact-management/notifications" element={<Notifications userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/fiscal-details/:country" element={<HostFiscalDetails userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/kyc-details/:country" element={<KycDetails userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id/contacts" element={<Contacts userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":parentId/contacts/add" element={<ContactForm userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":parentId/contacts/:id" element={<EditContactV2 userLogged={globalLoggedUser!} />}></Route>
                          </Route>

                          <Route path='/individuals' element={<SourceProvider source={SourceEnum.individuals} />} >
                            <Route index path=":id/summary" element={<HostSummary userLogged={globalLoggedUser!} />}></Route>
                            <Route path=":id" element={<EditIndividualV2 userLogged={globalLoggedUser!} />}></Route>
                          </Route>

                          <Route path="/customers" element={<Customers userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/customers/add-member" element={<MemberTypeSelection userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/customers/companies/add-new" element={<CompanyForm userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/customers/individuals/add-new" element={<IndividualForm userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/customers/contacts/add-new" element={<ContactForm userLogged={globalLoggedUser!} />}></Route>

                          <Route path="/tickets/" element={<Tickets userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/tickets/:id" element={<TicketEdit userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/tickets/add" element={<TicketAdd userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/tickets/:id/digital-assets/:groupName" element={<TicketDigitalAssets userLogged={globalLoggedUser!} />}></Route>

                          <Route path="/ticket-categories/" element={<TicketCategories userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/ticket-categories/:id" element={<TicketCategoryEdit userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/ticket-categories/add" element={<TicketCategoryAdd userLogged={globalLoggedUser!} />}></Route>

                          <Route path="/ticket-priorities/" element={<TicketPriorities userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/ticket-priorities/:id" element={<TicketPriorityEdit userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/ticket-priorities/add" element={<TicketPriorityAdd userLogged={globalLoggedUser!} />}></Route>
                        </Routes>
                      </div>
                    </div>
                  </>
                }
                {!dataLoaded &&
                  <>
                    <h1>Loading...</h1>
                  </>
                }
              </ThemeProvider>
            }
            <Footer></Footer>
          </div>
        </LocalizationProvider>
      }

      {globalLoggedUser && (globalLoggedUser.checkPartner) && (!globalLoggedUser.operator || globalLoggedUser.location) &&
        <div className={`${mainbg} fixed-bottom`}>
          <div>
            <div className="myContainer">
              <div className="renderZone">
                <Routes>
                  <Route path="/" element={<PartnerSetup setupCallBack={setupCallBack} userLogged={globalLoggedUser!} />}></Route>
                  <Route path="/logout" element={<Logout onLogout={handleLogout}></Logout>}></Route>
                </Routes>
              </div>
            </div>
          </div>
        </div>
      }

      {globalLoggedUser && globalLoggedUser.doesNeedSetup &&
        <div className={`${mainbg} fixed-bottom`}>
          <div>
            <div className="myContainer">
              <div className="renderZone">
                You're not authorize to see this page.
              </div>
            </div>
          </div>
        </div>
      }

      {userInfo && userInfo.isDeclined &&
        <div className={`earthbg fixed-bottom`}>
          <div>
            <div className="myContainer">
              <div className="renderZone">
                <Routes>
                  <Route path="/" element={<Login />}></Route>
                  <Route path="/account/login" element={<Login />}></Route>
                  <Route path="/account/resetpassword/" element={<RecoverAccount />}></Route>
                  <Route path="/account/register/" element={<Register />}></Route>
                  <Route path="/account/register/:email" element={<Register />}></Route>
                </Routes>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
      }

      {!globalLoggedUser && !userInfo &&
        <Box sx={{
          display: 'flex',
          height: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          color: colors.white,
        }}>
          <Loader message='Loading...'></Loader>
        </Box>
      }
    </>
  );
}
export default Pont;
