import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { Box, Card, IconButton, Tooltip } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { GetRowClassName } from "../../functions/utils/helper";
import { useFetchMemberById } from "../../hooks/Member/member";
import { useFetchProducts } from "../../hooks/Product/product";
import i18n from "../../i18n";
import { colors } from "../../main/Theme";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import ApiStatus from "../Common/Errors/ApiStatus";
import { LR } from "../Common/Help/LR";
import { SectionHead } from "../Common/Help/SectionHead";
import { CustomButton } from "../Common/UI/CustomButton";

type Args =
  {
    userLogged: ApplicationUser
  }

const Products = ({ userLogged }: Args) => {

  const nav = useNavigate();
  const { data: operator, status: operatorStatus, isSuccess: operatorIsSuccess } = useFetchMemberById(userLogged.operator?.id!)
  const { data, status, isSuccess } = useFetchProducts(userLogged.operator?.id!);

  if ((!operatorIsSuccess || !isSuccess)) return (
    <>
      <div className="mt-5 row d-flex justify-content-center">
        <div className="col-md-7 col-11 justify-content-center">
          <ApiStatus status={status} />
        </div>
      </div>
    </>
  )

  const homeCallBack = () => {
    nav(`/admin`);
  }

  const handleAddNewProduct = () => {
    nav(`/products/add-selector`);
  }

  if (!data || !operator) return <ApiStatus status={status} />;

  return (
    <>


      <SectionHead
        linkCallBack={homeCallBack}
        ctaText={i18n.t('COMMON_LABEL_ADMIN')!}
        name={i18n.t('PRODUCT_TITLE_PAGE')}
        title={i18n.t('PRODUCTS_SUMMARY_PAGE')!}
        description={i18n.t('COMMON_SUMMARY_PRODUCTMANAGEMENT')}
      />

      <div className="row d-flex justify-content-center">
        <div className="col-md-7 col-11 justify-content-center">
          <Tooltip title={i18n.t('PRODUCT_LABEL_ADD_A_NEW_PRODUCT')}>
            <CustomButton onClick={handleAddNewProduct} color='secondary' sx={{ textTransform: 'none', mb: 2 }}>
              <AddCircleRoundedIcon />
              <Box sx={{ ml: 1 }}>
                <LR localResource="PRODUCT_LABEL_ADD_A_NEW_PRODUCT" />
              </Box>
            </CustomButton>
          </Tooltip>
          <Card variant="outlined">
            <DataGrid
              rows={data}
              columns={[
                { field: 'portfolioName', headerName: i18n.t('PRODUCT_LABEL_PORTFOLIO')!, flex: 1 },
                { field: 'productTypeName', headerName: i18n.t('ORDER_LABEL_PRODUCT')!, flex: 1 },
                { field: 'name', headerName: i18n.t('LOCATION_LABEL_NAMESHORT')!, flex: 1 },
                {
                  field: 'id', headerName: '', flex: 0.1, align: "center",
                  renderCell: (params) => {
                    return <Tooltip title={i18n.t('COMMON_TOOLTIP_DETAILS')}>
                      <IconButton className="fa-info" onClick={() => nav(`${params.id}`)}>
                        <EditRoundedIcon sx={{ color: colors.primary }} />
                      </IconButton>
                    </Tooltip>
                  },
                }
              ]}

              slots={{
                toolbar: GridToolbar
              }}
              classes={{
                toolbarContainer: 'toolbarContainer'
              }}

              getRowClassName={GetRowClassName}

              checkboxSelection={false}
              disableColumnSelector={true}
              disableDensitySelector={true}
            />
          </Card>
        </div>
      </div>
    </>
  );
};


export { Products };
