import { Box, Card, Grid, Tooltip } from "@mui/material";
import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useNavigate } from "react-router";
import { useFetchActivities, useFetchActivitySummary, useUpdateActed, useUpdateActivityRead } from '../../hooks/Activity/activity';
import i18n from "../../i18n";
import { colors } from '../../main/Theme';
import { ActivityDto } from '../../types/Activity/ActivityDto';
import { SearchActivityRequestDto } from "../../types/Activity/SearchActivityRequestDto";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import DateNavigation from '../Common/DateNavigation/DateNavigation';
import MessageAlert from '../Common/Errors/MessageAlert';
import { CustomSelect } from "../Common/formComponents/CustomSelect";
import { SearchInput } from '../Common/SearchInput';
import { CustomDataGrid } from '../Common/UI/CustomDataGrid';
import { showSnackbar } from "../Common/UI/CustomSnackbar";
import { ButtonLoader, Loader } from "../Common/UI/Loader";
import { MaterialIcon } from '../Common/UI/MaterialIcon';
import { useBasketCreate } from "../../hooks/Order/basket";
type Args = {
  userLogged: ApplicationUser;
}

const calculateDates = (fromDate: Date): { from: Date, to: Date } => {
  const today = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate());
  return { from: today, to: today };
};

const formatDateToLocalString = (date: Date): string => {
  const year = date.getFullYear();
  const month = (`0${date.getMonth() + 1}`).slice(-2);
  const day = (`0${date.getDate()}`).slice(-2);
  return `${year}-${month}-${day}`;
};

const _activityData: any = {
  CheckIns: "person_pin_circle",
  ActiveMembers: "person_check",
  AgreementsEndingSoon: "event_repeat",
  OpenTicketsCount: "confirmation_number",
  MoveIns: "login",
  MoveOuts: "logout",
};
const filterTypeOption: { label: string, value: string | null }[] = [
  {
    label: 'All',
    value: null
  },
  {
    label: 'Read',
    value: "true"
  }, {
    label: 'Unread',
    value: "false"
  }]


const actionedOption: { label: string, value: string | null }[] = [
  {
    label: 'All',
    value: null
  },
  {
    label: 'Actioned',
    value: "true"
  }, {
    label: 'Nonactioned',
    value: "false"
  }]


const urgencyTypeTextColors: any = {
  Medium: '#f97316',
  Urgent: colors.red[100],
}


const Activities = ({ userLogged }: Args) => {
  const { mutate: createBasketFromOrder } = useBasketCreate();
  const navigate = useNavigate();
  const now = new Date();
  const defaultDates = calculateDates(now);
  const defaultPagination = { page: 0, pageSize: 10 }
  const [paginationModel, setPaginationModel] = useState(defaultPagination);
  const [filterParam, setFilterParam] = useState<SearchActivityRequestDto>({
    startDate: formatDateToLocalString(defaultDates.from),
    endDate: formatDateToLocalString(defaultDates.to),
    type: null,
    acted: null,
    searchQuery: ""
  });

  const navigateActivity = (activity: ActivityDto) => {
    createBasketFromOrder({
      locationId: userLogged.location?.id!,
      // memberId: activity.partnerMemberId,
      orderId: activity.entityKey,
    }, {
      onSuccess: (basket) => {
        navigate(`/orders/basket/${basket.id}/view`)
      }
    })

    // let url = '';
    // switch (activity.entityName) {
    //   case 'Location':
    //     url = `/locations/${activity.entityKey}/summary`;
    //     break;
    //   case 'Ticket':
    //     url = `/tickets/${activity.entityKey}`;
    //     break;
    //   case 'Order':
    //     url = `/orders/${activity.entityKey}/view-order`;
    //     break;
    //   case 'Membership':
    //     url = `/memberships/${activity.entityKey}/summary`;
    //     break;
    //   case 'Event':
    //     url = `/events/${activity.entityKey}/summary`;
    //     break;
    //   case 'Product':
    //     url = `/products/${activity.entityKey}`;
    //     break;
    // }
    // nav(url);
  }

  const handelPaginationChange = (pagination: any) => {
    setPaginationModel((prev) => ({
      ...prev,
      page: pagination?.pageSize !== paginationModel.pageSize ? 0 : pagination?.page,
      pageSize: pagination?.pageSize,
    }));
  }

  const handleDateRangeChange = (startDate: Date, endDate: Date) => {
    setFilterParam((prev) => ({
      ...prev,
      startDate: formatDateToLocalString(startDate),
      endDate: formatDateToLocalString(endDate)
    }));
  };



  const { data: activitySummaryData, isLoading: activitySummaryLoading } = useFetchActivitySummary({
    locationId: userLogged.location!.id || "",
  })


  const { data: activities, isLoading, refetch } = useFetchActivities({
    locationId: userLogged.location?.id!,
    pageNumber: paginationModel?.page + 1,
    pageSize: paginationModel?.pageSize,
    ...filterParam
  })
  const activityData: any = activitySummaryData?.map((item: any) => {
    return {
      ...item, icon: _activityData[item.name]
    }
  })

  const columns: GridColDef[] = [

    {
      field: 'activeDate',
      headerName: 'Date',
      sortable: false,
      flex: 2,
      renderCell: (cell) => dayjs(cell?.row.activeDate).format('DD/MM/YYYY')
    },
    {
      field: 'description',
      headerName: 'Description',
      sortable: false,
      flex: 6,
    },
    {
      field: 'activityTypeName',
      headerName: 'Type',
      sortable: false,
      flex: 3,
    },
    {
      field: 'urgencyTypeName',
      headerName: 'Urgency',
      sortable: false,
      flex: 2,
      renderCell: ({ row }) => {
        return (
          <Box sx={{ color: urgencyTypeTextColors[row.urgencyTypeName] || '' }}>
            {row.urgencyTypeName}
          </Box>
        )
      }
    },

    {
      field: 'isRead',
      headerName: 'Read/Unread',
      sortable: false,
      flex: 1.5,
      renderCell: ({ row }) => <ReadActivityChangesDialog row={row} onSuccess={() => refetch()} />
    },

    {
      field: 'acted',
      headerName: 'Actioned',
      sortable: false,
      flex: 1.5,
      renderCell: ({ row }) => <ActedChangesDialog row={row} onSuccess={() => refetch()} />
    },

    {
      field: 'action',
      headerName: '',
      sortable: false,
      align: "right",
      headerAlign: "right",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", pointerEvents: row.entityName === "Order" ? "auto" : "none", opacity: row.entityName === "Order" ? 1 : 0.5 }}>
            {
              row.entityName === "Order" ? <Box onClick={() => row.entityName === "Order" ? navigateActivity(row) : null}>
                <MaterialIcon
                  sx={{
                    color: colors.primary,
                  }} name="keyboard_arrow_right" size={40} />
              </Box> : <Box sx={{ pointerEvents: "none", opacity: 0.5 }}>
                <MaterialIcon
                  sx={{
                    color: colors.primary,
                  }} name="keyboard_arrow_right" size={40} />
              </Box>
            }
          </Box>
        )
      }
    }
  ];


  return (
    <div className="mainpage-container-layout">
      <div className='col-md-10 col-11 mt-2 justify-content-center'>

        {
          activitySummaryLoading ? <Loader /> :
            <Box sx={{ padding: '0px 10px', marginBottom: 2 }}>
              <Grid container spacing={3}>
                {activityData?.map((item: any, index: number) => (
                  <Grid item xs={12} sm={6} md={4} lg={2} key={`${index}-activity`}
                  >
                    <Card sx={{ padding: "12px", borderColor: colors.primary, borderWidth: 1, borderStyle: "solid" }}>
                      <Box sx={{ display: "flex", alignItems: "center", fontSize: "30px", justifyContent: "space-between" }}>
                        <MaterialIcon size={40} name={item.icon} />
                        <div>{item.counter}</div>
                      </Box>
                      {i18n.t(item.label).toString()}
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
        }

        <Box sx={{ padding: '0px 10px', marginTop: "50px" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <DateNavigation onDateSelect={handleDateRangeChange} fromDate={new Date(filterParam?.startDate)} toDate={new Date(filterParam?.endDate)} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <SearchInput onSearchChange={(query) => {
                setPaginationModel((prev) => ({
                  ...prev,
                  page: 0
                }));

                setFilterParam((prev) => ({
                  ...prev,
                  searchQuery: query,
                  page: 0
                }));
              }
              }
                placeholder={'SEARCH_BY_ACTIVITY'} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CustomSelect
                options={filterTypeOption}
                value={filterTypeOption?.filter(x => x.value === filterParam?.type)}
                onChange={(data) => {
                  setPaginationModel((prev) => ({
                    ...prev,
                    page: 0
                  }));
                  setFilterParam((prev) => ({
                    ...prev,
                    type: data?.value || null,
                    page: 0
                  }));
                }}
              ></CustomSelect>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CustomSelect
                options={actionedOption}
                value={actionedOption?.filter(x => x.value === filterParam?.acted)}
                onChange={(data) => {
                  setPaginationModel((prev) => ({
                    ...prev,
                    page: 0
                  }));
                  setFilterParam((prev) => ({
                    ...prev,
                    acted: data?.value || null,
                    page: 0
                  }));
                }}
              ></CustomSelect>
            </Grid>
          </Grid>
        </Box>


        <div className="activity-feed">
          {
            !activities || activities.records?.length === 0 ? <MessageAlert message="No activities found." /> :
              <CustomDataGrid
                columns={columns}
                rows={activities?.records ? activities?.records : []}
                slots={{
                  noRowsOverlay: () => <>No order found!</>
                }}
                initialState={{
                  pagination: {
                    paginationModel: defaultPagination,
                  },
                }}
                loading={isLoading}
                pageSizeOptions={[5, 10, 20]}
                getRowId={(row) => row.id}
                //  onRowClick={handleViewClick}
                paginationMode='server'
                sortingMode='server'
                rowCount={activities?.totalRecords}
                paginationModel={paginationModel}
                onPaginationModelChange={handelPaginationChange}
                sx={{
                  '& .MuiDataGrid-row': {
                    transition: 'background-color 0.3s ease',
                  }
                }}
              />
          }
        </div>
      </div>
    </div>
  );
}

export { Activities };


const ReadActivityChangesDialog = ({ row, onSuccess }: any) => {

  const { id, isRead } = row

  const { mutate: readActivityMutation, isLoading } = useUpdateActivityRead()

  const changeActivity = (isRead: boolean) => {
    readActivityMutation({ id: id, isRead: isRead }, {
      onSuccess: (data: any) => {
        showSnackbar(i18n.t("LABEL_ACTIVITY_READ").toString())
        onSuccess(data)
      },
      onError: (error: any) => {
        showSnackbar(error.response?.data, {
          persist: true,
          variant: 'error'
        })
      }
    });
  }

  return isLoading ? <ButtonLoader color={colors.primary} /> :
    <Tooltip title={i18n.t(isRead ? "LABEL_READ_ACTIVITY" : "LABEL_UNREAD_ACTIVITY").toString()}>
      <Box onClick={() => changeActivity(!isRead)} >
        <MaterialIcon
          sx={{
            color: colors.primary,
          }} name={isRead ? "drafts" : "mail"} size={35} />
      </Box>
    </Tooltip>
};


const ActedChangesDialog = ({ row, onSuccess }: any) => {
  const { mutate: updateActedMutation, isLoading } = useUpdateActed()
  const { id, isActed } = row
  const updateActed = (IsActed: boolean) => {
    updateActedMutation({ id: id, IsActed: IsActed }, {
      onSuccess: (data: any) => {
        showSnackbar(i18n.t("LABEL_ACTIVITY_ACTIONED").toString())
        onSuccess(data);
      },
      onError: (error: any) => {
        showSnackbar(error.response?.data, {
          persist: true,
          variant: 'error'
        })
      }
    });
  }

  return isLoading ? <ButtonLoader color={colors.primary} /> :
    <Tooltip title={i18n.t(isActed ? "LABEL_ACTED_ACTIVITY" : "LABEL_UNACTED_ACTIVITY").toString()}>
      <Box onClick={() => updateActed(!isActed)} >
        <MaterialIcon
          sx={{
            color: colors.primary,
          }} name={isActed ? "flag_check" : "flag"} size={35} />
      </Box>
    </Tooltip>
};