import { Grid, Typography } from "@mui/material";
import { SummaryIcon, SummaryItem } from "../SummaryItems/SummaryItem";

type Args = {
    linkCallBack?: () => void
    ctaText?: string,
    name: string,
    title: string,
    description: string,
    action?: JSX.Element
};

const SectionHead = ({ ctaText, name, title, description, linkCallBack, action }: Args) => {
    return (
        <>
            <div className="row d-flex justify-content-center fixed-top fixSummary">
                <div className="col-md-4 col-4 justify-content-center">
                    {
                        ctaText &&
                        <SummaryItem linkOnText={true} simpleText={true} linkCallBack={linkCallBack} name={ctaText} ></SummaryItem>
                    }
                </div>
                <div className="col-md-3 col-7 justify-content-center">
                    <SummaryItem icon={SummaryIcon.None} textAlignFlex='flex-end' textClass='entityName' simpleText={true} name={description} ></SummaryItem>
                </div>
            </div>
            <div className="row d-flex justify-content-center firstElement mb-4">
                <div className="col-md-7 col-11 justify-content-center">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8}>
                            <Typography variant="h5">{title}</Typography>
                            <Typography variant="body2" className="entityName">{name}</Typography>
                        </Grid>
                        {action &&
                            <Grid item container xs={12} md={4} justifyContent={'flex-end'}>
                                <Grid item>
                                    {action}
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </div>
            </div>
        </>
    );
}

export {
    SectionHead
};

