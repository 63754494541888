import { useOrderLineContext } from "./OrderLineProvider";

export const useOrderLinePreview = () => {
  const { currentOrderLine, setCurrentOrderLine } = useOrderLineContext()

  const handleRowPriceChange = (index: number, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!currentOrderLine) return
    let price = Number(e.target.value)
    if (price < 0) {
      price = 0
    }

    setCurrentOrderLine({
      ...currentOrderLine,
      priceAdjustments: currentOrderLine.priceAdjustments?.map((item, i) => {
          if (i === index) {
              let priceExTax = parseFloat((price * item.quantity).toFixed(2));
              return {
                  ...item,
            priceExTax: priceExTax,
            unitPriceExTax: price,
          }
        } else {
          return item
        }
      })
    })
  }

  const handleRowOccupantChange = (index: number, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!currentOrderLine) return
    let occupant = Number(e.target.value)
    if (occupant < 0) {
      occupant = 0
    }
    setCurrentOrderLine({
      ...currentOrderLine,
      occupancyAdjustments: currentOrderLine.occupancyAdjustments?.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            quantity: occupant,
          }
        } else {
          return item
        }
      })
    })
  }

  const subTotal = currentOrderLine?.priceAdjustments?.reduce((acc, row) => acc + ((row?.priceExTax || 0)), 0);

  return { handleRowPriceChange, subTotal, handleRowOccupantChange }
}