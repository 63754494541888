import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Alert, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import { Fragment, useState } from "react";
import { formatPrice } from "../../../functions/utils/fiscal";
import { formaOrderLineAvailability } from "../../../functions/utils/helper";
import { useBasketOrderLineDelete } from "../../../hooks/Order/basket";
import i18n from "../../../i18n";
import { colors } from "../../../main/Theme";
import { OrderLineRequest } from "../../../types/Order/OrderLineRequest";
import { CustomConfirmDialog } from "../../Common/UI/CustomConfirmDialog";
import { showSnackbar } from "../../Common/UI/CustomSnackbar";
import { useOrderContext } from "./OrderProvider";

export const OrderItemSummary = () => {
  const {
    orderLines,
    handleEditOrderLine,
    currentBasket,
    readOnlyMode,
    basketId,
    getLatestBasketDetail,
    getActionFlags
  } = useOrderContext()

  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
  const { mutate: deleteOrderLine } = useBasketOrderLineDelete()
  const [itemToDelete, setItemToDelete] = useState<OrderLineRequest | null>(null)

  const handleDeleteOrderLine = () => {
    if (!itemToDelete || !Boolean(itemToDelete.id)) return

    const id = itemToDelete.id
    deleteOrderLine({ params: { basketId: basketId!, orderLineId: id } }, {
      onSuccess: (response) => {
        getLatestBasketDetail()
      },
      onError: (error) => {
        showSnackbar(error.response?.data, {
          persist: true,
          variant: 'error'
        })
      },
    });
    setDeleteConfirmation(false);
  };

  if (!(orderLines && orderLines.length > 0)) {
    return <></>
  }


  return (
    <>
      <TableContainer component={Paper} sx={{ marginBottom: 2, marginTop: 2, position: 'relative', zIndex: 0 }}>
        <Table sx={{ minWidth: 650 }} stickyHeader>
          <TableHead>
            <TableRow sx={{ backgroundColor: colors.primary }}>
              <TableCell>{i18n.t("ORDERLINE_LABEL_DESCRIPTION")}</TableCell>
              <TableCell align="right">{i18n.t("ORDERLINE_LABEL_PERIOD")}</TableCell>
              <TableCell align="right">{i18n.t("ORDERLINE_LABEL_PRICE")}</TableCell>
              {/* <TableCell align="right">{i18n.t("ORDERLINE_LABEL_UNIT")}</TableCell> */}
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderLines.map((item, index) => {
              const permission = getActionFlags(item.actionFlags)
              return (
                <Fragment key={`${index}-orderLines`}>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {item.description}
                    </TableCell>
                    <TableCell align="right">
                      {formaOrderLineAvailability(item.fromDate, item.toDate)}
                    </TableCell>
                    <TableCell align="right">
                      {formatPrice(item.currencyCode, item.priceExTax)}
                    </TableCell>
                    {/* <TableCell align="right">
                    {item.unitName}
                  </TableCell> */}
                    <TableCell align="right">
                      <Tooltip title={i18n.t("COMMON_TOOLTIP_DETAILS")}>
                        <IconButton
                          className="fa-info"
                          onClick={() =>
                            handleEditOrderLine(item.id!)
                          }
                        >
                          {
                            readOnlyMode ? <VisibilityIcon sx={{ color: colors.primary }} /> : permission?.includes("ACTIONFLAG_EDIT") ? <EditRoundedIcon sx={{ color: colors.primary }} /> : ""
                          }
                        </IconButton>
                      </Tooltip>
                      {!readOnlyMode && permission?.includes("ACTIONFLAG_DELETE") && (
                        <Tooltip
                          title={i18n.t("COMMON_TOOLTIP_DELETE", {
                            param0: item.description,
                          })}
                        >
                          <IconButton
                            onClick={(e) => {
                              setDeleteConfirmation(true)
                              setItemToDelete(item)
                            }}
                          >
                            <DeleteRoundedIcon color={"error"} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                  {item?.hasErrors &&
                    <TableRow key={index + "error"} sx={{ border: 0 }}>
                      <TableCell colSpan={4} component="th" scope="row" sx={{ padding: 0 }}>
                        <Alert variant="filled" severity="error">
                          {item?.errorLabel && i18n.t(`${item?.errorLabel}`)}
                        </Alert>
                      </TableCell>
                    </TableRow>}
                </Fragment>
              )
            }
            )}
            {currentBasket &&
              <>
                <TableRow>
                  <TableCell component="th" scope="row" colSpan={2} align="right">
                    {i18n.t("ORDER_LABEL_SUBTOTAL")}
                  </TableCell>
                  <TableCell align="right">
                    {formatPrice("GBP", currentBasket.totalPriceExTax)}
                  </TableCell>
                  <TableCell align="right">
                    {i18n.t("ORDER_LABEL_EXVAT")}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row" colSpan={2} align="right">
                    {i18n.t("ORDER_LABEL_TOTAL")}
                  </TableCell>
                  <TableCell align="right">
                    {formatPrice("GBP", currentBasket.totalPriceIncTax)}
                  </TableCell>
                  <TableCell align="right">
                    {i18n.t("ORDER_LABEL_INCVAT")}
                  </TableCell>
                </TableRow>
              </>
            }
          </TableBody>
        </Table>
      </TableContainer>
      <CustomConfirmDialog
        isOpen={deleteConfirmation}
        onClose={() => setDeleteConfirmation(false)}
        title={i18n.t(`INVENTORY_LABEL_DELETEALLOCATION`)}
        content={i18n.t(`COMMON_BUTTON_DELETETEXT`, { name: itemToDelete?.description || "" })}
        confirmButtonColor={'error'}
        confirmButtonTitle={i18n.t(`COMMON_BUTTON_DELETE`)}
        handleConfirm={() => { handleDeleteOrderLine(); }}
      ></CustomConfirmDialog>
    </>
  )
}