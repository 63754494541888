import axios, { AxiosError, AxiosResponse } from "axios";
import { isNil } from "lodash";
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { ApplicationConfig } from "../../config/ApplicationConfig";
import { HookConfig } from "../../config/HookConfig";
import { operatorDefaultHeaders } from "../../functions/api/api";
import { OperationResultDto } from "../../types/Common/OperationResultDto";
import { ProductSearchResponseDto, SummaryItemDto } from "../../types/Common/SummaryItemDto";
import { PortfolioProductPriceDto } from "../../types/Price/PortfolioProductPriceDto";
import { PriceDto } from "../../types/Price/PriceDto";
import Problem from "../../types/Problem";
import { BusinessRuleResponseDto } from "../../types/Product/BusinessRuleResponseDto";
import { ProductPortfolioProductInstanceDto } from "../../types/Product/ProductPortfolioProductInstanceDto";
import { ProductPortfolioResponseDto } from "../../types/Product/ProductPortfolioResponseDto";
import { ProductProductRuleRequestDto } from "../../types/Product/ProductProductRuleRequestDto";
import { ProductResponseDto } from "../../types/Product/ProductResponseDto";
import { ProductRuleProductTypeDto } from "../../types/Product/ProductRuleProductTypeDto";
import { ProductRuleResponseDto } from "../../types/Product/ProductRuleResponseDto";
import { ProductRuleSummaryResponseDto } from "../../types/Product/ProductRuleSummaryResponseDto";
import { ExtendedProductTypeResponseDto, ProductTypeResponseDto } from "../../types/Product/ProductTypeResponseDto";
import { RuleProductTypeResponseDto } from "../../types/Product/RuleProductTypeResponseDto";

export const useFetchProducts = (memberId: string) => {
  return useQuery<ProductSearchResponseDto[], AxiosError>(["products_summary", memberId], () =>
    axios.get(`${HookConfig.productUrl}/products`,
      {
        params: {
          operatorMemberId: memberId,
        },
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data)
  );
}


export const useFetchProductRules = (memberId: string) => {
  return useQuery<SummaryItemDto[], AxiosError>(["productrules_summary", memberId], () =>
    axios.get(`${HookConfig.productUrl}/product-rules/summary?operatormemberid=${memberId}`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data)
  );
}

export const useFetchPricingCataloues = (memberId: string) => {
  return useQuery<SummaryItemDto[], AxiosError>(["pricingportfolio_summary", memberId], () =>
    axios.get(`${HookConfig.productUrl}/portfolios/summary?operatormemberid=${memberId}`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data)
  );
}

export const useFetchProductPortfolioById = (catalogueId?: string, options?: UseQueryOptions<ProductPortfolioResponseDto, AxiosError>) => {
  return useQuery<ProductPortfolioResponseDto, AxiosError>(["pricingportfolio", catalogueId], () =>
    axios.get(`${HookConfig.productUrl}/portfolios/${catalogueId}`, {
      withCredentials: true,
      headers: operatorDefaultHeaders()
    }).then((resp) => resp.data),
    {
      ...options
    });
}

export const useFetchProductPortfolioProducts = (catalogueId?: string, options?: UseQueryOptions<ProductPortfolioProductInstanceDto[], AxiosError>) => {
  return useQuery<ProductPortfolioProductInstanceDto[], AxiosError>(["pricingportfolio-products", catalogueId], () =>
    axios.get(`${HookConfig.productUrl}/portfolios/${catalogueId}/products`, {
      withCredentials: true,
      headers: operatorDefaultHeaders()
    }).then((resp) => resp.data),
    {
      ...options
    });
}

export const useFetchProductType = ({ options }: { options?: UseQueryOptions<ExtendedProductTypeResponseDto[], AxiosError> }) => {
  return useQuery<ExtendedProductTypeResponseDto[], AxiosError>(["productsType"], () =>
    axios.get(`${HookConfig.productUrl}/product-types`, {
      withCredentials: true,
      headers: operatorDefaultHeaders()
    }).then((resp) => resp.data),
    {
      ...options,
    });
}

export const useFetchProductById = (productId: string, doFetch: boolean, options?: UseQueryOptions<ProductResponseDto, AxiosError, ProductResponseDto>) => {
  return useQuery<ProductResponseDto, AxiosError>(["products", productId], () =>
    axios.get(`${HookConfig.productUrl}/products/${productId}`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data),
    {
      enabled: doFetch,
      ...options
    }
  );
}

export const useFetchProductRuleById = (ruleId: string) => {
  return useQuery<ProductRuleResponseDto, AxiosError>(["product-rules", ruleId], () =>
    axios.get(`${HookConfig.productUrl}/product-rules/${ruleId}`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data)
  );
}

export const useFetchRuleProductTypes = (ruleId: string) => {
  return useQuery<RuleProductTypeResponseDto[], AxiosError>(["rule-product-type", ruleId], () =>
    axios.get(`${HookConfig.productUrl}/product-rules/${ruleId}/product-types`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data)
  );
}

export const useFetchProductTypes = () => {
  return useQuery<ProductTypeResponseDto[], AxiosError>(["product-types"], () =>
    axios.get(`${HookConfig.productUrl}/product-types`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data)
  );
}

export const useFetchBusinessRulesRule = (ruleId: string, typeName: string) => {
  return useQuery<BusinessRuleResponseDto[], AxiosError>(["business-rule", ruleId, typeName], () =>
    axios.get(`${HookConfig.productUrl}/product-rules/${ruleId}/business-rules?businessRuleTypeName=${typeName}`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data)
  );
}

export const useFetchCataloguePrices = (catalogueId: string, productId: string) => {
  return useQuery<PriceDto[], AxiosError>(["catalogue-prices", catalogueId, productId], () =>
    axios.get(`${HookConfig.productUrl}/portfolios/${catalogueId}/products/${productId}/prices`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      })
      .then(
        (response) => {
          return response.data;
        }
      ));
};

export const useFetchRulesByProductId = (productId: string, doFetch: boolean) => {
  return useQuery<ProductRuleSummaryResponseDto[], AxiosError>(["products-rules", productId], () =>
    axios.get(`${HookConfig.productUrl}/products/${productId}/product-rules`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then(
      (resp) =>
        resp.data),
    { enabled: doFetch }
  );
}

export const useManageProductsRules = (callback: (productRuleId: string, responseId: string, operationResult: OperationResultDto) => void) => {

  return useMutation<AxiosResponse, AxiosError, ProductProductRuleRequestDto>(
    (productRuleRequest) => {
      const method = productRuleRequest.isDelete ? 'delete' : 'post';
      const url = productRuleRequest.isDelete ?
        `${HookConfig.productUrl}/products/${productRuleRequest.productId}/product-rules/${productRuleRequest.productRuleId}` :
        `${HookConfig.productUrl}/products/${productRuleRequest.productId}/product-rules/`;

      const config = {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      };

      // If the method is 'delete', don't include a body in the request
      return method === 'delete' ?
        axios[method](url, config) :
        axios[method](url, productRuleRequest, config);
    },
    {
      onSuccess: (_, productRule) => {
        callback(productRule.productRuleId, (productRule.isDelete ? ApplicationConfig.emptyGuid : _.data.id), { isSuccess: true, errorMessage: '' });
      },
      onError: (_, productRule) => {
        callback(productRule.productRuleId, '', { isSuccess: false, errorMessage: _.message });
      }
    }
  );
};

export const useManageProductPortfolioProducts = (callback: (productId: string, responseId: string, operationResult: OperationResultDto) => void) => {

  return useMutation<AxiosResponse, AxiosError, ProductPortfolioProductInstanceDto>(
    (productPortfolioRequest) => {
      const method = productPortfolioRequest.isDelete ? 'delete' : 'post';
      const url = `${HookConfig.productUrl}/portfolios/${productPortfolioRequest.portfolioId}/products/${productPortfolioRequest.productId}`

      const config = {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      };

      return method === 'delete' ?
        axios[method](url, config) :
        axios[method](url, productPortfolioRequest, config);
    },
    {
      onSuccess: (_, productPortfolioRequest) => {
        callback(productPortfolioRequest.productId, (productPortfolioRequest.isDelete ? ApplicationConfig.emptyGuid : _.data.id), { isSuccess: true, errorMessage: '' });
      },
      onError: (_, productPortfolioRequest) => {
        callback(productPortfolioRequest.productId, '', { isSuccess: false, errorMessage: _.message });
      }
    }
  );
};

export const useManageProductsRulesProductTypes = (callback: (productRuleId: string, responseId: string, operationResult: OperationResultDto) => void) => {

  return useMutation<AxiosResponse, AxiosError, ProductRuleProductTypeDto>(
    (productRuleProductTypeRequest) => {
      const method = productRuleProductTypeRequest.isDelete ? 'delete' : 'post';
      const url = productRuleProductTypeRequest.isDelete ?
        `${HookConfig.productUrl}/product-rules/${productRuleProductTypeRequest.productRuleId}/product-types/${productRuleProductTypeRequest.productTypeName}` :
        `${HookConfig.productUrl}/product-rules/${productRuleProductTypeRequest.productRuleId}/product-types/`;

      const config = {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      };

      // If the method is 'delete', don't include a body in the request
      return method === 'delete' ?
        axios[method](url, config) :
        axios[method](url, productRuleProductTypeRequest, config);
    },
    {
      onSuccess: (_, productRuleProductTypeRequest) => {
        callback(productRuleProductTypeRequest.productRuleId, (productRuleProductTypeRequest.isDelete ? ApplicationConfig.emptyGuid : _.data.id), { isSuccess: true, errorMessage: '' });
      },
      onError: (_, productRuleProductTypeRequest) => {
        callback(productRuleProductTypeRequest.productRuleId, '', { isSuccess: false, errorMessage: _.message });
      }
    }
  );
};

export const useManageProductsRulesBusinessRules = (callback: (item: BusinessRuleResponseDto, responseId: string, operationResult: OperationResultDto) => void) => {

  return useMutation<AxiosResponse, AxiosError, BusinessRuleResponseDto>(
    (productRuleBusinessRule) => {
      const method = productRuleBusinessRule.isDelete ? 'delete' : (productRuleBusinessRule.id === ApplicationConfig.emptyGuid) ? 'post' : 'put';
      const url = productRuleBusinessRule.isDelete ?
        `${HookConfig.productUrl}/product-rules/${productRuleBusinessRule.productRuleId}/business-rules/${productRuleBusinessRule.id}` :
        (productRuleBusinessRule.id === ApplicationConfig.emptyGuid) ?
          `${HookConfig.productUrl}/product-rules/${productRuleBusinessRule.productRuleId}/business-rules/` :
          `${HookConfig.productUrl}/product-rules/${productRuleBusinessRule.productRuleId}/business-rules/${productRuleBusinessRule.id}`;

      const config = {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      };

      // If the method is 'delete', don't include a body in the request
      return method === 'delete' ?
        axios[method](url, config) :
        axios[method](url, productRuleBusinessRule, config);
    },
    {
      onSuccess: (_, productRuleBusinessRule) => {
        callback(productRuleBusinessRule, (productRuleBusinessRule.isDelete ? ApplicationConfig.emptyGuid : _.data.id), { isSuccess: true, errorMessage: '' });
      },
      onError: (_, productRuleBusinessRule) => {
        callback(productRuleBusinessRule, '', { isSuccess: false, errorMessage: _.message });
      }
    }
  );
};

export const useManageProductRule = (callback: (operationResult: OperationResultDto) => void) => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse, AxiosError, ProductRuleResponseDto>(
    (productRule) => axios[productRule.id === '' ? 'post' : 'put'](
      productRule.id === '' ? `${HookConfig.productUrl}/product-rules/` : `${HookConfig.productUrl}/product-rules/${productRule.id}`,
      productRule,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ),
    {
      onSuccess: (_, productRule) => {

        queryClient.invalidateQueries(["product-rules", productRule.id]);
        callback({ isSuccess: true, errorMessage: '' });
      },
      onError: (_, error) => {
        console.log(error);
        callback({ isSuccess: false, errorMessage: _.message });
      }
    }
  );
};

export const useManageProduct = (callback: (operationResult: OperationResultDto) => void) => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse, AxiosError, ProductResponseDto>(
    (product) => axios[product.id === '' ? 'post' : 'put'](
      product.id === '' ? `${HookConfig.productUrl}/products/` : `${HookConfig.productUrl}/products/${product.id}`,
      product,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ),
    {
      onSuccess: (response, product) => {
        queryClient.invalidateQueries(["products", product.id]);
        callback({ isSuccess: true, errorMessage: '', data: response.data.id });
      },
      onError: (_, error) => {
        console.log(error);
        callback({ isSuccess: false, errorMessage: _.message });
      }
    }
  );
};

export const useManageProductPortfolio = () => {
  return useMutation<AxiosResponse, AxiosError, ProductPortfolioResponseDto>(
    (payload) => axios[payload.id === '' ? 'post' : 'put'](
      `${HookConfig.productUrl}/portfolios/${payload.id}`,
      payload,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then(response => response.data)
  );
};

export const useManageProductPortfolioPrices = () => {
  const managePrices = async (
    [items, callback]: [PriceDto[], (operationResult: OperationResultDto) => void]
  ): Promise<AxiosResponse<any, any>[]> => {
    // Id == 0 -> show empty string
    // Id == 0 & some price -> post
    // Id != 0 & some price -> put
    // Id != 0 & no price -> delete

    const container: (PriceDto & { method: "post" | "put" | "delete" })[] = [];

    const cleanValue = (value: any) => {
      return value.toString().replace(/[^0-9.]/g, '').replace(/\s/g, '');
    }
    items.forEach((item) => {
      const { id, price } = item;
      if (id === 0) {
        if (isNil(price) || price === 0 || cleanValue(price).length === 0) return
        container.push({
          ...item,
          method: "post",
        })
      } else {
        if (isNil(price) || cleanValue(price).length === 0) {
          container.push({
            ...item,
            method: "delete",
          })
        } else {
          container.push({
            ...item,
            method: "put",
          })
        }
      }
    });


    const promises = container.map(async (item) => {
      const { method, ...rest } = item
      const { id, entityKey } = rest
      const entityKeyValues = entityKey.split('~');
      let portfolioPriceItem: PortfolioProductPriceDto = rest as PortfolioProductPriceDto;
      portfolioPriceItem.portfolioId = entityKeyValues[0];
      portfolioPriceItem.productId = entityKeyValues[1];

      const config = {
        post: `${HookConfig.productUrl}/portfolios/${entityKeyValues[0]}/products/${entityKeyValues[1]}/prices`,
        put: `${HookConfig.productUrl}/portfolios/${entityKeyValues[0]}/products/${entityKeyValues[1]}/prices/${id}`,
        delete: `${HookConfig.productUrl}/portfolios/${entityKeyValues[0]}/products/${entityKeyValues[1]}/prices/${id}`
      }

      if (method === "delete") {
        return await axios[method](
          config[method],
          {
            withCredentials: true,
            headers: operatorDefaultHeaders(),
          }
        );
      } else {
        return await axios[method](
          config[method], portfolioPriceItem,
          {
            withCredentials: true,
            headers: operatorDefaultHeaders(),
          }
        );
      }
    });
    const responses = await Promise.all(promises);
    return responses;
  };


  const mutation = useMutation<
    AxiosResponse<any, any>[],
    AxiosError<Problem>,
    [prices: PriceDto[], callback: (operationResult: OperationResultDto) => void]>(
      managePrices, {
      onSuccess: (responses, [items, callback]) => {
        callback({ isSuccess: true, errorMessage: '' });
      },
      onError: (_, [items, callback]) => {
        callback({ isSuccess: false, errorMessage: _.message });
      }
    });

  return mutation;
};

type productListResponse = {
  id: string
  name: string
  description: string
  productTypeName: string
}
export const useFetchProductList = (payload: {
  operatormemberid?: string;
  iscopyable?: boolean;
}, options?: UseQueryOptions<productListResponse[], AxiosError>) => {
  return useQuery<productListResponse[], AxiosError>(["product_list", payload], () =>
    axios.get(`${HookConfig.productUrl}/products`, {
      withCredentials: true,
      headers: operatorDefaultHeaders(),
      params: payload,
    }).then((resp) => resp.data),
    {
      ...options
    }
  );
};


type productPortfolioListResponse = {
  id: string
  name: string
  description: string
}
export const useFetchProductPortfolios = (payload: {
  operatormemberid?: string;
}, options?: UseQueryOptions<productPortfolioListResponse[], AxiosError>) => {
  return useQuery<productPortfolioListResponse[], AxiosError>(["product_portfolio", payload], () =>
    axios.get(`${HookConfig.productUrl}/portfolios`, {
      withCredentials: true,
      headers: operatorDefaultHeaders(),
      params: payload,
    }).then((resp) => resp.data),
    {
      ...options
    }
  );
};

type CopyOfProductPayload = {
  productId: string,
  portfolioId: string,
  productName: string,
  copyBusinessRules: boolean,
  operatorMemeberId: string
}
export const useCreateCopyOfProduct = () => {
  return useMutation<{ id: string }, AxiosError, CopyOfProductPayload>(
    (payload) => axios.post(`${HookConfig.productUrl}/products/${payload.productId}/copy`,
      payload,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data),
  );
};