import { isNaN, isNil, isNumber } from "lodash";
import React, { useEffect, useState } from "react";
import i18n from "../../../i18n";
import { PriceDto } from "../../../types/Price/PriceDto";
import { Input } from "../formComponents/Input";
import { LabelType } from "../formComponents/Label";

type Args = {
    item: PriceDto,
    currencyValue: string,
    value: number,
    allowEmpty: boolean,
    onChange: (item: PriceDto, value: string) => void,
    labelProps?: LabelType;
};


const FormattedNumberInput = ({ item, value, currencyValue, allowEmpty, onChange, labelProps }: Args) => {
    // const numberFormatter = new Intl.NumberFormat(i18n.language, {
    //     style: "currency",
    //     currency: currencyValue,
    // });

    // const inputRef = React.createRef<HTMLInputElement>();
    // const [currentValue, setCurrentValue] = useState(value);
    // const [currentFloatValue, setCurrentFloatValue] = useState(value);

    // useEffect(() => {
    //     setCurrentValue(value);
    //     setCurrentFloatValue(value);
    // }, [value]);


    // const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const inputValue = e.target.value;
    //     let sanitizedValue = inputValue.replace(/[^0-9.]/g, ''); // Remove non-numeric characters
    //     setCurrentValue(sanitizedValue as any);
    //     setCurrentFloatValue(parseFloat(sanitizedValue));
    // };

    // const handleInputFocus = () => {
    //     if (!allowEmpty)
    //         setCurrentValue(currentFloatValue.toString() as any);
    // };


    // const handleInputOnBlur = () => {
    //     onChange(item, currentFloatValue.toString());
    //     if (currentValue as any === '') {
    //         if (!allowEmpty) {
    //             setCurrentValue('0' as any);
    //             setCurrentFloatValue(parseFloat('0'));
    //         }
    //         return;
    //     }

    //     setCurrentValue(currentFloatValue);
    // };

    const cleanValue = (value: any) => {
        return value.toString().replace(/[^0-9.]/g, '').replace(/\s/g, '');
    }
    const getNumberOrEmpty = (value: any) => {

        if (isNil(value)) return ""

        let x = cleanValue(value)

        if (x.length === 0) return ""

        return parseFloat(value.toString()).toFixed(2);
    }

    const inputRef = React.createRef<HTMLInputElement>();
    const valueToShow = value === 0 && item.id === 0 ? "" : value;
    const [currentValue, setCurrentValue] = useState<string>(getNumberOrEmpty(valueToShow));

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        if (/^[0-9]*\.?[0-9]*$/.test(inputValue)) {
            setCurrentValue(inputValue);
        }
    }
    const handleInputFocus = () => {
        if (!allowEmpty) {
            setCurrentValue(getNumberOrEmpty(value || "0"));
        }
    }

    const handleInputOnBlur = () => {
        const valueToPass = getNumberOrEmpty(currentValue)
        setCurrentValue(valueToPass);
        onChange(item, valueToPass);
    }

    useEffect(() => {
        const valueToShow = value === 0 && item.id === 0 ? "" : getNumberOrEmpty(value);
        setCurrentValue(valueToShow);
    }, [value]);

    return (
        <Input
            ref={inputRef}
            type="text"
            value={currentValue}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            onBlur={handleInputOnBlur}
            inputProps={{
                style: { textAlign: "right" }
            }}
            labelProps={labelProps}
            placeholder={i18n.t('COMMON_LABEL_ENTERVALUE').toString()}
        />
    );
};

export default FormattedNumberInput;
