import { Box, IconButton, InputLabel, Tooltip } from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import i18n from "../../../i18n";
import { useState } from "react";
import { HelpDialog } from "../Help/HelpDialog";
import { LR } from "../Help/LR";
import { colors } from "../../../main/Theme";


export type LabelType = {
  message: string,
  helpMessage?: string | null,
  mandatory?: boolean
  error?: boolean
  disabled?: boolean
};

const Label = ({ message, helpMessage, mandatory, error, disabled }: LabelType) => {

  const [helpOpen, setHelpOpen] = useState(false);
  const [helpReference, setHelpReference] = useState('');

  const handleOpen = () => {
    setHelpOpen(true);
  };

  const handleClose = () => {
    setHelpOpen(false);
  };

  const setHelp = (value: string) => {
    setHelpReference(value);
    handleOpen();
  }

  let disabledStyle = disabled ? { opacity: 0.5 } : {}

  return (
    <>
      <HelpDialog contentReference={helpReference} showHelp={helpOpen} handleClose={handleClose} handleConfirm={handleOpen} ></HelpDialog>
      <Box component={'label'} sx={{
        color: error ? colors.red[100] : '',
        mb: 0.2,
        ...disabledStyle,
        position: 'relative',
      }}>
        <small><LR localResource={message}></LR></small> {mandatory && <>*</>}
        {helpMessage &&
          <IconButton
            sx={{ position: 'absolute' }}
            aria-label="share"
            onClick={(e) => setHelp(helpMessage)}
          >
            <Tooltip title={i18n.t('COMMON_LABEL_HELP')}>
              <HelpIcon style={{ fontSize: '0.9rem' }} />
            </Tooltip>
          </IconButton>
        }
      </Box>
    </>
  );
}

export {
  Label
}