import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { ApplicationConfig } from "../../config/ApplicationConfig";
import { useCheckEmailAlreadyRegistered, useCreateIndividual } from "../../hooks/Customer/useCustomer";
import { useRegisterDelegatedEmailV2 } from "../../hooks/Login/login";
import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { IndividualCreateRequestDto } from "../../types/Customer/CustomerDto";
import CustomCountryDropDown from "../Common/formComponents/CustomCountryDropDown";
import { Input } from "../Common/formComponents/Input";
import { SectionHead } from "../Common/Help/SectionHead";
import { CustomButton } from "../Common/UI/CustomButton";
import { CustomSnackbar } from "../Common/UI/CustomSnackbar";

type Args = {
  userLogged: ApplicationUser
}

const emailRegex = ApplicationConfig.emailRegex;

const IndividualForm = ({ userLogged }: Args) => {
  const nav = useNavigate();
  const [isEmailExist, setIsEmailExist] = useState(false);
  const [registeredUserId, setRegisteredUserId] = useState<null | string>(null);
  const [snackbarContent, setSnackbarContent] = useState<any>()

  const schema = yup.object({
    email: yup
      .string()
      .required(i18n.t('VALIDATION_EMAIL_REQUIRED').toString())
      .matches(emailRegex, i18n.t('VALIDATION_EMAIL_INVALID').toString()),
    countryIso3Code: yup.string().required(i18n.t('VALIDATION_COUNTRY_REQUIRED').toString()),
    firstName: yup.string().required(i18n.t('VALIDATION_FIRSTNAME_REQUIRED').toString()),
    lastName: yup.string().required(i18n.t('VALIDATION_LASTNAME_REQUIRED').toString()),
    languageCode: yup.string().nullable(),
  })

  type IndividualFormData = yup.InferType<typeof schema>;

  const {
    handleSubmit,
    control,
    reset,
    watch,
  } = useForm<IndividualFormData>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      languageCode: i18n.language,
      email: '',
      firstName: '',
      lastName: '',
      countryIso3Code: '',
    }
  })

  const email = watch('email')

  const {
    refetch: checkEmail,
    isLoading: checkingEmail,
  } = useCheckEmailAlreadyRegistered({ email }, {
    staleTime: 0,
    ...ApplicationConfig.disableQuerySettings,
    onSuccess: (response) => {
      setIsEmailExist(true);
      setRegisteredUserId(response[0].memberId);
    },
    onError: (error) => {
      if (error.status === 404) {
        setSnackbarContent(null)
        setIsEmailExist(false);
        setRegisteredUserId(null);
      }
    }
  })

  const { mutate: registerDelegatedEmail, isLoading: registeringEmail } = useRegisterDelegatedEmailV2({
    preRegister: true
  });
  const { mutate: createIndividual, isLoading: creatingIndividual } = useCreateIndividual();

  const handleEmailExist = () => {
    checkEmail()
  }

  const registerIndividual = async (data: IndividualCreateRequestDto) => {
    createIndividual(data, {
      onSuccess: (response) => {
        const id = response.id;
        if (id) {
          nav(`/individuals/${id}/summary`)
        }
      },
      onError: (error) => {
        setSnackbarContent(error.response?.data)
      }
    })
  }

  const onSubmit: SubmitHandler<IndividualFormData> = (data) => {
    if (!isEmailExist) {
      registerDelegatedEmail({
        email: data.email!,
        firstName: data.firstName!,
        lastName: data.lastName!,
        countryISOCode: data.countryIso3Code,
        confirmPassword: '',
        password: '',
        authorUserId: userLogged.id!
      }, {
        onSuccess: (response) => {
          registerIndividual({
            ...data,
            registeredUserId: response.data.id
          })
        }
      })
    }

    if (isEmailExist && registeredUserId) {
      registerIndividual({
        ...data,
        registeredUserId: registeredUserId
      })
    }
  }

  const homeCallBack = () => {
    nav(-1);
  }

  useEffect(() => {
    if (i18n.language) {
      reset({
        languageCode: i18n.language,
      })
    }
  }, [i18n.language])

  return (
    <>
      <SectionHead linkCallBack={homeCallBack}
        ctaText={i18n.t('COMMON_SUMMARY_MEMBERS')!}
        name={i18n.t('LABEL_ADDCUSTOMER_INDIVIDUAL_PAGE_DESCRIPTION')}
        title={i18n.t('LABEL_ADDCUSTOMER_INDIVIDUAL_PAGE_TITLE')}
        description={i18n.t('COMMON_SUMMARY_MEMBERMANAGEMENT')} />

      <CustomSnackbar snackbarContent={snackbarContent} setSnackbarContent={setSnackbarContent}></CustomSnackbar>

      <div className="row d-flex justify-content-center">
        <div className="col-md-7 col-11 justify-content-center">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Input
                      placeholder={i18n.t("COMMON_LABEL_EMAIL").toString()}
                      labelProps={{ message: 'COMMON_LABEL_EMAIL', mandatory: true }}
                      validationError={fieldState.error}
                      field={field}
                      onBlur={() => {
                        handleEmailExist();
                        field.onBlur();
                      }}
                      loading={checkingEmail}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Input
                      placeholder={i18n.t("COMMON_LABEL_FIRSTNAME").toString()}
                      labelProps={{ message: 'COMMON_LABEL_FIRSTNAME', mandatory: true }}
                      validationError={fieldState.error}
                      field={field}
                    ></Input>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="lastName"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Input
                      placeholder={i18n.t("COMMON_LABEL_LASTNAME").toString()}
                      labelProps={{ message: 'COMMON_LABEL_LASTNAME', mandatory: true }}
                      field={field}
                      validationError={fieldState.error}
                    ></Input>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="countryIso3Code"
                  control={control}
                  render={({ field, fieldState }) => (
                    <CustomCountryDropDown
                      valueChanged={(value) => { field.onChange(value) }}
                      inputCountry={field.value}
                      labelProps={{
                        message: 'COMMON_LABEL_COUNTRY',
                        mandatory: true
                      }}
                      validationError={fieldState.error}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={4} mx={'auto'} mt={3}>
                <CustomButton
                  variant="contained"
                  fullWidth
                  type="submit"
                  disabled={checkingEmail || registeringEmail || creatingIndividual}
                  loading={checkingEmail || registeringEmail || creatingIndividual}>
                  {i18n.t('COMMON_LABEL_SAVE')}
                </CustomButton>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </>
  );
}

export { IndividualForm };

