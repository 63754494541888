import { yupResolver } from "@hookform/resolvers/yup";
import { Box, DialogContent, DialogTitle, Grid } from "@mui/material";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFetchOrders, useOrderEndingReason, useRenewedOrder } from "../../../../hooks/Order/useOrder";
import i18n from "../../../../i18n";
import { CustomSelect } from "../../../Common/formComponents/CustomSelect";
import { Input } from "../../../Common/formComponents/Input";
import { LR } from "../../../Common/Help/LR";
import { CustomButton } from "../../../Common/UI/CustomButton";
import { CustomDialog } from "../../../Common/UI/CustomDialog";
import { showSnackbar } from "../../../Common/UI/CustomSnackbar";
import '../OrderForm.css';
import { useOrderContext } from "../OrderProvider";

type DialogMarkAsRenewedArgs = {
  open: boolean
  onClose: () => void

}

export const DialogMarkAsRenewed = ({ open, onClose, }: DialogMarkAsRenewedArgs) => {
  const navigate = useNavigate()
  const { currentBasket, basketId } = useOrderContext()
  const { mutate: renewedOrder, isLoading: isLoadingRenewedOrder } = useRenewedOrder()

  const { data: orderEndingReasonList, isLoading: isOrderEndingReasonLoading } = useOrderEndingReason({
    initiatedByName: "customer",
    orderEndTypeName: "renewed"
  })

  const { data: ordersList, isLoading: isLoadingRenewedOrderOption } = useFetchOrders({
    locationId: currentBasket?.locationId!,
    memberId: currentBasket?.memberId!,
    fromDate: currentBasket?.minimumEndDate!,
    toDate: `${dayjs(currentBasket?.minimumEndDate).add(5, 'year').format("YYYY-MM-DDTHH:mm:ss")}`,
    pageNumber: 1,
    pageSize: 1000,
    options: {
      enabled: true
    }
  })

  const renewedOrderOption = ordersList?.records?.map((order: any) => {
    return {
      value: order.orderId,
      label: `${order?.description} - ${order.orderExternalReference}`,
    }
  })

  const formSchema = yup.object().shape({
    orderEndReasonId: yup.string().required(i18n.t("VALIDATION_THIS_REQUIRED").toString()),
    requestComments: yup.string(),
    relatedOrderIds: yup.array().min(1, i18n.t("VALIDATION_THIS_REQUIRED").toString()).required(i18n.t("VALIDATION_THIS_REQUIRED").toString()),
  });

  type formType = yup.InferType<typeof formSchema>;
  const {
    control,
    handleSubmit,
  } = useForm<formType>({
    resolver: yupResolver(formSchema),
    mode: 'onChange'
  });

  const onSubmit = (data: formType) => {
    renewedOrder({
      basketId: basketId!,
      orderEndReasonId: data.orderEndReasonId,
      requestComments: data.requestComments,
      relatedOrderIds: data?.relatedOrderIds?.map((relatedOrder: any) => relatedOrder.value)!
    }, {
      onSuccess: () => {
        onClose()
        navigate(`/orders`)
        showSnackbar(i18n.t("SUCCESS_MARK_AS_RENEWED_ORDER"))
      }, onError: (error: any) => {
        showSnackbar(error.response?.data, {
          persist: true,
          variant: 'error'
        })
      }
    })
  }

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      scroll="body"
    >
      <DialogTitle>
        <LR localResource={i18n.t("COMMON_LABEL_MARK_AS_RENEWED")}></LR>
      </DialogTitle>
      <DialogContent>
        <Box sx={{
          minHeight: '550px',
        }}>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="orderEndReasonId"
                  render={({ field, fieldState, }) => {
                    return (
                      <CustomSelect
                        labelProps={{
                          message: "LABEL_REASON_FOR_TERMINATION",
                          mandatory: true,
                        }}
                        getOptionValue={(option: any) => option.id}
                        getOptionLabel={(option: any) => i18n.t(option.label).toString()}
                        isLoading={isOrderEndingReasonLoading}
                        options={orderEndingReasonList ? orderEndingReasonList : []}
                        field={field}
                        value={orderEndingReasonList?.find((reason: any) => reason.id === field.value) || null}
                        onChange={(e: any) => {
                          field.onChange(e?.id || null)
                        }}
                        placeholder={i18n.t("COMMON_LABEL_SELECT")}
                        validationError={fieldState.error}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="requestComments"
                  render={({ field, fieldState }) => {
                    return (
                      <Input
                        labelProps={{
                          message: i18n.t("LABEL_ANY_ADDITIONAL_COMMENTS").toString(),
                          mandatory: false,
                        }}
                        multiline={true}
                        rows={3}
                        field={field}
                        onChange={(e) => {
                          field.onChange(e.target.value)
                        }}
                        validationError={fieldState.error}
                      />
                    );
                  }}
                />
              </Grid>


              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="relatedOrderIds"
                  render={({ field, fieldState, ...rest }) => {
                    return (
                      <CustomSelect
                        labelProps={{
                          message: "LABEL_RENEWED_ORDER",
                          mandatory: true,
                        }}
                        isMulti
                        isLoading={isLoadingRenewedOrderOption}
                        options={renewedOrderOption ? renewedOrderOption : []}
                        value={field?.value || []}
                        onChange={(e: any) => {
                          field.onChange(e)
                        }}
                        field={field}
                        placeholder={i18n.t("COMMON_LABEL_SELECT")}
                        validationError={fieldState.error}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={1} md={4} mx={"auto"}>
                <CustomButton
                  variant="contained"
                  fullWidth
                  loading={isLoadingRenewedOrder}
                  type="submit"
                >
                  {i18n.t("COMMON_LABEL_SAVE")}
                </CustomButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </DialogContent>
    </CustomDialog>
  );
};
