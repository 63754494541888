import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import i18n from "../../i18n";
import { Button, IconButton, InputBase, Paper, Tooltip, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { useSearchPosts } from "../../hooks/Posts/posts";
import { PostBaseItemDto } from "../../types/Post/PostItemDto";
import { LR } from "../Common/Help/LR";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { debounce } from 'lodash';
import { colors } from "../../main/Theme";

type Args =
  {
    userLogged: ApplicationUser,
  }

const ApplicationPostsConfiguration = ({ userLogged }: Args) => {
  const nav = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const queryStringParams = useParams();
  if (!queryStringParams.id) throw Error("Application id needed");
  const applicationId = queryStringParams.id;
  const { data: postData } = useSearchPosts(applicationId, debouncedSearchTerm, pageNumber, pageSize);
  const [filteredItems, setFilteredItems] = useState<PostBaseItemDto[]>([]);

  useEffect(() => {
    if (postData) {
      setFilteredItems(postData.records);
      setTotalPageCount(postData.totalPages);
      if(postData.totalPages < pageNumber) {
        setPageNumber(1);
      }
    }
    else {
      setFilteredItems([]);
    }
  }, [postData]);

  const handleSearchChange = useCallback(
    debounce((term) => {
      setDebouncedSearchTerm(term);
    }, 500), // 1 second delay
    []
  );

  useEffect(() => {
    // Whenever the searchTerm changes, call the debounce function
    handleSearchChange(searchTerm);
  }, [searchTerm]);

  const handleArrowIconClick = (id: string) => {
    nav(`${id}`);
  };

  const addNewItem = () => {
    nav(`/application/${applicationId}/configuration/posts/add`);
  };

  return (
    <>
      <div className="mt-1 row d-flex justify-content-center">
        <div className="col-md-7 col-11 d-flex align-items-center">
          <Tooltip title={i18n.t('POSTS_LABEL_NEWPOST')}>
            <IconButton onClick={addNewItem} className="btn plusButton mr-2"> {/* Added mr-2 for some spacing */}
              <AddCircleRoundedIcon fontSize="medium" sx={{ color: colors.primary }} />
            </IconButton>
          </Tooltip>
          <span className="lead mb-0"> {/* Set margin-bottom to 0 */}
            <LR localResource="POSTS_LABEL_NEWPOST" />
          </span>
        </div>
      </div>

      <div className="row d-flex justify-content-center">
        <div className="col-md-7 col-11 justify-content-center">
          <Paper style={{ display: 'flex', alignItems: 'center', margin: '15px 0', padding: '5px 10px', border: '1px solid #ccc' }}>
            <InputBase
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={i18n.t('POSTS_LABEL_SEARCH').toString()!}
              style={{ flexGrow: 1 }}
            />
            <SearchIcon color="action" />
          </Paper>

          {filteredItems.map((item, index) => (
            <Paper key={index} elevation={3} className="fa-paper ">
              <div className="d-flex align-items-center">
                <div className="fa-name flex-grow-1">
                  <Typography variant="h6">
                    <Button type="button" onClick={() => handleArrowIconClick(item.id!)} className="linkbutton" key={item.id}>
                      {item.title}
                    </Button>
                  </Typography>
                </div>
                <div className="fa-icons">
                  <IconButton onClick={() => handleArrowIconClick(item.id!)} className="fa-arrow">
                    <ArrowForwardIosRoundedIcon />
                  </IconButton>
                </div>
              </div>
            </Paper>
          ))}
          {totalPageCount !== 0 &&
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-md-2 col-2 justify-content-center">
                <Button type="button" disabled={pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)} className={(pageNumber === 1 ? '' : 'greenlinkbutton')} key={'previous'}>
                  <LR localResource="COMMON_BUTTON_PREVIOUS"></LR>
                </Button>
              </div>
              <div className="col-md-2 col-2 d-flex justify-content-center ">
                <span>{pageNumber} / {totalPageCount}</span>
              </div>
              <div className="col-md-2 col-2 justify-content-center">
                <Button disabled={pageNumber === totalPageCount} type="button" onClick={() => setPageNumber(pageNumber + 1)} className={(pageNumber === totalPageCount ? '' : 'greenlinkbutton')} key={'next'}>
                  <LR localResource="COMMON_BUTTON_NEXT"></LR>
                </Button>
              </div>
            </div>
          }
        </div>
      </div>
    </>
  );
};


export { ApplicationPostsConfiguration }