import EditIcon from '@mui/icons-material/Edit';
import { Box, Chip, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton, Paper, Table, TableBody, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { GridSearchIcon } from '@mui/x-data-grid';
import { registerLicense } from '@syncfusion/ej2-base';
import {
  ActionEventArgs,
  EventRenderedArgs,
  EventSettingsModel,
  Inject,
  NavigatingEventArgs,
  PopupOpenEventArgs,
  ResourceDirective,
  ResourcesDirective,
  ScheduleComponent,
  SelectEventArgs,
  TimelineMonth,
  TimelineViews,
  ViewDirective,
  ViewsDirective
} from '@syncfusion/ej2-react-schedule';
import { format, } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { v4 as uuidv4 } from 'uuid';
import { pontProductsConfig } from '../../data/order/OrderData';
import { DateShim } from '../../functions/utils/helper';
import { getCalendarData } from '../../hooks/Calendar/calendar';
import { useSaveCustomerLocalStorage } from '../../hooks/Customer/useSaveCustomerLocalStorage';
import { useFetchProductTypesByLocation } from '../../hooks/Location/location';
import i18n from '../../i18n';
import { colors } from '../../main/Theme';
import { ApplicationUser } from '../../types/Authentication/ApplicationUser';
import { CalendarSearchDetailResponseDto } from '../../types/Calendar/CalendarSearchDetailResponseDto';
import { CalendarSearchRequestDto } from '../../types/Calendar/CalendarSearchRequestDto';
import { CalendarSearchResponseDto } from '../../types/Calendar/CalendarSearchResponseDto';
import { CustomerSearchItemResponseDto } from '../../types/Customer/CustomerSearchItemResponseDto';
import { FormattedProductTypeDto } from '../../types/Product/ProductTypeDto';
import { CustomDatePicker } from '../Common/formComponents/CustomDatepicker';
import { CustomSelect } from '../Common/formComponents/CustomSelect';
import { Input } from '../Common/formComponents/Input';
import { Label } from '../Common/formComponents/Label';
import { LR } from '../Common/Help/LR';
import { SearchInput } from '../Common/SearchInput';
import { CustomConfirmDialogWithContent } from '../Common/UI/CustomConfirmDialogWithContent';
import { CustomDialog } from '../Common/UI/CustomDialog';
import { Loader } from '../Common/UI/Loader';
import { MaterialIcon } from '../Common/UI/MaterialIcon';
import { DialogMemberSelectorForOrder } from '../Order/OrderForm/DialogMemberSelectorForOrder';
import './Calendar.css';

registerLicense("ORg4AjUWIQA/Gnt2U1hhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5bd0djX35ec3ZQR2Fd");

type CalendarFeedProps = {
  userLogged: ApplicationUser;
};


interface FilterParam {
  productTypeNames: string[];
  inventoryName: string;
  orderExternalReference: string;
  memberId: string | null;
}

const DetailsDialog = ({ data, productConfig }: any) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Box onClick={() => setOpen(true)}>
        <MaterialIcon
          sx={{
            color: colors.primary,
            cursor: 'pointer'
          }}
          name="keyboard_arrow_right"
          size={30} />
      </Box>
      <CustomDialog maxWidth="sm" fullWidth scroll="body" open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{data?.sellableItemName}</DialogTitle>
        <DialogContent >
          <DialogContentText>
            {
              data.shortDescription
              &&
              <Box>
                <Divider sx={{ mb: 1 }}>
                  <Chip label={i18n.t("TICKET_LABEL_DESCRIPTION").toString()} size="small" />
                </Divider>
                <Typography variant="caption" sx={{ color: colors.white }}>
                  {data.shortDescription}
                </Typography>
              </Box>
            }
            {
              data.areaInSqm
              &&
              <Box sx={{ my: 2 }}>
                <Divider sx={{ mb: 1 }}>
                  <Chip label={i18n.t("INVENTORY_LABEL_AREASQM").toString()} size="small" />
                </Divider>
                <Typography variant="caption" sx={{ color: colors.white }}>
                  {data.areaInSqm}{i18n.t("INVENTORY_LABEL_SQM")}
                </Typography>
              </Box>
            }
            {
              data.productTypes &&
              <Box sx={{ my: 2 }}>
                <Divider sx={{ mb: 1 }}>
                  <Chip label={i18n.t("PRODUCT_LABEL_RULEPRODUCTTYPE").toString()} size="small" />
                </Divider>
                <Box sx={{ my: 1, width: '100%', minWidth: "100%" }}>
                  <Grid container sx={{ width: '100%', minWidth: "100%" }} gap={1}>
                    {
                      productConfig?.map((product: any) => {
                        return (
                          <Grid sx={{ width: '100%', minWidth: "100%" }} gap={1} item xs={12} sm={6} md={4} >
                            <Paper elevation={3} sx={{ p: 2 }}>
                              <Box sx={{ display: "flex", alignItems: "center", fontSize: "14px" }}>
                                <IconToShow productTypeName={product.productTypeName} />
                                {product.quantity > 1 &&
                                  <Box sx={{ display: "flex", alignItems: "center", gap: 2, pl: 1, color: colors.grey }}>
                                    {product?.quantity} {i18n.t("COMMON_LABEL_PERSON")} {i18n.t(product?.config?.productLabel!).toString()}
                                  </Box>}
                              </Box>
                            </Paper>
                          </Grid>
                        )
                      })
                    }
                  </Grid>
                </Box>
              </Box>
            }
            {
              data.mainImageUrl &&
              <Box sx={{ my: 2 }}>
                <Divider sx={{ mb: 1 }}>
                  <Chip label={i18n.t("COMMON_SUMMARY_EVENTIMAGES").toString()} size="small" />
                </Divider>
                <Box sx={{ my: 1, }} >
                  <img src={data.mainImageUrl} alt="mainImageUrl" width="100%" ></img>
                </Box>
              </Box>
            }

          </DialogContentText>
        </DialogContent>
      </CustomDialog>
    </>
  )
}

const resourceHeaderTemplate = (props: any) => {

  const { areaInSqm, productTypes } = props.resourceData.data

  const productConfig = productTypes && productTypes?.map((type: any) => {
    return {
      config: pontProductsConfig.find(
        (x) => x.productTypeName === type.productTypeName
      ),
      ...type
    }
  })

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", px: 1, }}>
        <Typography variant="caption" >{props.resourceData.text}</Typography>
        <DetailsDialog productConfig={productConfig} data={props.resourceData.data} />
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", px: 1, fontSize: '10px', color: colors.grey[200] }}>
        <Box>
          {productConfig?.map((product: any) => {
            return (
              <Box>
                {product?.config?.requiresQuantity && <Box>
                  {product?.quantity} {i18n.t("COMMON_LABEL_PERSON")} {i18n.t(product?.config?.productLabel!).toString()}
                </Box>
                }
              </Box>
            )
          })}
        </Box>
        {areaInSqm &&
          <Box >
            {props.resourceData.data.areaInSqm?.toFixed(2)}{i18n.t("INVENTORY_LABEL_SQM")}
          </Box>
        }
      </Box>
    </>
  );
};

const headerTemplate = (props: { [key: string]: string }): JSX.Element => {
  return (
    <>
      {props.Subject &&
        <>
          <div className="quick-info-header calendar-popup-header">
            <div className="e-header-icon-wrapper">
              {/* <button className="e-edit e-control e-btn e-lib e-flat e-round e-small e-icon-btn" title="Edit"><span className="e-btn-icon e-icons e-edit-icon"></span>
           </button> */}
            </div>
            <div className="quick-info-header-content">
              <div className="quick-info-title">
                <h3>{props.MemberName ? props.MemberName : props.Description}</h3>
              </div>
              <Box className="duration-text">
                Start: <strong>{format(props.StartTime, 'dd/MM/yyyy HH:mm')}</strong>{" "}
                End: <strong>{format(props.EndTime, 'dd/MM/yyyy HH:mm')}</strong>
              </Box>
              {/* {
                props.ExternalReference &&
                <Box sx={{ my: 2 }}>Booking n° {props.ExternalReference}</Box>
              } */}
            </div>
          </div>
        </>
      }
    </>
  );
}

const IconToShow = ({ productTypeName }: { productTypeName: string }) => {
  const productConfig = pontProductsConfig.find(x => x.productTypeName === productTypeName);
  if (productConfig) {
    return (
      <Tooltip title={i18n.t(productConfig.productTypeName)}>
        <Box>
          <MaterialIcon name={productConfig.icon} size={25} />
        </Box>
      </Tooltip>
    )
  }
  return <></>;
}

// const contentTemplate = (props: any): JSX.Element => {
//   return (
//     <Box sx={{ width: '100%', minWidth: "100%", py: 2 }}>
//       {
//         props.Reason &&
//         <Box sx={{ my: 2 }}>
//           <Divider sx={{ mb: 1 }}>
//             <Chip label="Reason" size="small" />
//           </Divider>
//           <Box sx={{ my: 1 }}>{props.Reason}</Box>
//         </Box>
//       }
//     </Box>
//   );
// }
const contentTemplate = (props: { [key: string]: string }): JSX.Element => {
  return (
    <>
      <div className="quick-info-content calendar-popup-content">
        <div className="event-content">
          {
            props.ExternalReference &&
            <div className="meeting-subject-wrap">
              <span>Booking n° {props.ExternalReference}</span>
            </div>
          }
          {
            props.Reason &&
            <div className="meeting-subject-wrap">
              <span>{props.Reason}</span>
            </div>
          }
        </div>
      </div>
    </>
  );
}

const footerTemplate = (props: { [key: string]: Object }): JSX.Element => {
  return (
    <></>
  );
}

export const CalendarFeed = ({ userLogged }: CalendarFeedProps) => {
  const nav = useNavigate()
  const scheduleObj = useRef<ScheduleComponent>(null);
  const [selectedInventoryId, setSelectedInventoryId] = useState<string>('');
  const [events, setEvents] = useState<EventSettingsModel>({ dataSource: [] });
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedDateFrom, setSelectedDateFrom] = useState<Date>(new Date());
  const [calendarData, setCalendarData] = useState<CalendarSearchResponseDto[]>();
  const [ownerData, setOwnerData] = useState<any>([]);
  const [openOrderBookingDialog, setOpenOrderBookingDialog] = useState(false);
  const [openCustomerSelectorDialog, setOpenCustomerSelectorDialog] = useState(false);
  const [orderBookingData, setOrderBookingData] = useState<{
    startDate: string,
    endDate: string,
    startTime: string,
    endTime: string,
    sellableItemName: string,
    sellableItemId: string,
  } | null>(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [productTypes, setProductTypes] = useState<FormattedProductTypeDto[]>();
  const [customerOpen, setCustomerOpen] = useState(false);
  const [memberName, setMemberName] = useState('');
  const [filterParam, setFilterParam] = useState<FilterParam>({
    productTypeNames: [],
    inventoryName: '',
    orderExternalReference: '',
    memberId: null
  });

  // Fetch product types by location
  const { isLoading: fetchingProductTypes } = useFetchProductTypesByLocation({ locationId: userLogged.location?.id!, isInventory: true }, {

    onSuccess: (products) => {
      if (products) {
        setProductTypes(products.map((item) => ({
          label: i18n.t(item.description),
          value: item.sellableItemId,
          productTypeName: item.productTypeName,
          defaultUnitName: item.defaultUnitName,
          defaultQuantity: item.defaultQuantity,
          icon: <MaterialIcon name={pontProductsConfig.find(x => x.productTypeName === item.productTypeName)?.icon || ""} />
        })).sort((a, b) => a.label.localeCompare(b.label)));
      }
    }
  });

  const { customerData, setCustomerData } = useSaveCustomerLocalStorage()
  const [selectedCalendarView, saveSelectedCalendarView] = useLocalStorage<"TimelineDay" | "TimelineMonth" | "TimelineWeek" | null>('selectedCalendarView', null)

  //@ts-ignore
  const calendarKey = `${selectedCalendarView}-${selectedDateFrom?.toISOString()}-${events?.dataSource?.length || 0}`;

  const calculateDates = (fromDate: Date): { from: Date, to: Date } => {
    const firstDayOfMonth = new Date(fromDate.getFullYear(), fromDate.getMonth(), 1);
    const lastDayOfMonth = new Date(fromDate.getFullYear(), fromDate.getMonth() + 1, 0);
    return {
      from: firstDayOfMonth,
      to: lastDayOfMonth
    };
  };

  const handleInventoryChange = (value: string) => {
    setSelectedInventoryId(value);
  };

  const handleNavigating = (args: NavigatingEventArgs): void => {
    if (args?.currentView) {
      saveSelectedCalendarView(args?.currentView as any)
    }
  };

  const onActionComplete = (args: ActionEventArgs): void => {
    if (args.requestType === 'dateNavigate' && scheduleObj.current) {
      const newDate = scheduleObj.current.getCurrentViewDates()[0]; // Get the new date selected
      if (newDate.getTime() !== selectedDateFrom.getTime()) {
        setSelectedDateFrom(newDate); // Update the state with the new date
      }
    }
  };

  const onEventRendered = (args: EventRenderedArgs): void => {
    args.element.classList.add(args.data.CustomStyle);
  }

  const onActionBegin = (args: ActionEventArgs): void => {
    if (args.requestType === 'eventCreate' || args.requestType === 'eventChange') {
      let data = args.data instanceof Array ? args.data[0] : args.data;
      if (data) {
        args.cancel = !scheduleObj.current!.isSlotAvailable(data);
      }
    }
  };

  const inventoryOptions = calendarData ? [...calendarData] : [];
  inventoryOptions.unshift({ sellableItemName: "All Inventories", sellableItemId: "" } as any)

  const onSelect = (args: SelectEventArgs): void => {
    const { requestType, data } = args;
    if (requestType === "cellSelect" && data) {
      const { StartTime, EndTime, RoomId } = data as any
      const roomId = inventoryOptions.find(x => x.sellableItemId === RoomId)
      setOpenOrderBookingDialog(true);
      setOrderBookingData({
        startDate: dayjs(StartTime).format('DD/MM/YYYY'),
        endDate: dayjs(EndTime).format('DD/MM/YYYY'),
        startTime: dayjs(StartTime).format('HH:mm'),
        endTime: dayjs(EndTime).format('HH:mm'),
        sellableItemName: roomId?.sellableItemName || '',
        sellableItemId: roomId?.sellableItemId || ''
      });
    }
  }

  const onPopupOpen = (args: PopupOpenEventArgs): void => {
    args.cancel = true;
  };

  const quickInfoTemplates = {
    header: headerTemplate,
    content: contentTemplate,
    footer: footerTemplate
  };

  const cancelOrderBooking = () => {
    setOpenOrderBookingDialog(false);
    setOrderBookingData(null)
  }

  const confirmOrderBooking = () => {
    const path = "/orders/new-order";
    const searchParams = new URLSearchParams({ ...orderBookingData });

    // Navigate to the URL with search parameters
    nav(`${path}?${searchParams.toString()}`);
  }

  const handleSelectCustomer = (customer: CustomerSearchItemResponseDto) => {
    setCustomerData(customer);
    setOpenCustomerSelectorDialog(false);
  }

  useEffect(() => {
    const fetchCalendarData = async () => {
      setLoading(true);
      const dates = calculateDates(selectedDateFrom);
      const params: CalendarSearchRequestDto = {
        fromDate: DateShim(dates.from).toISOString().split('T')[0],
        toDate: DateShim(dates.to).toISOString().split('T')[0],
        operatorMemberId: userLogged.operator?.id!,
        locationId: userLogged.location!.id,
        ...filterParam
      }

      const data = await getCalendarData(params)
      if (data)
        setCalendarData(data);
    }

    fetchCalendarData();
  }, [selectedDateFrom, filterParam]);


  useEffect(() => {
    if (calendarData) {
      if (selectedInventoryId) {
        setOwnerData(calendarData?.filter(item => item.sellableItemId === selectedInventoryId).map((data: CalendarSearchResponseDto) => ({
          text: data.sellableItemName,
          id: data.sellableItemId,
          color: '#ff0',
          type: 'Conference',
          data: data
        })));
      } else {
        setOwnerData(calendarData?.map((data: CalendarSearchResponseDto) => ({
          text: data.sellableItemName,
          id: data.sellableItemId,
          color: '#ff0',
          data: data
        })));
      }

      const filteredData = selectedInventoryId ? calendarData.filter(item => item.sellableItemId === selectedInventoryId) : calendarData;

      const timeZone = 'Europe/London';
      const newEvents = filteredData.flatMap((data: CalendarSearchResponseDto) =>
        data.unavailabilites?.map((unavailability: CalendarSearchDetailResponseDto) => {
          // Parse the date string without the "Z"
          const startDateLocalString = new Date(unavailability.fromDate.replace("Z", "+01:00")); //TODO=> remove the hardcoded for UK locations.
          const endDateLocalString = new Date(unavailability.toDate.replace("Z", "+01:00"));

          // Convert to UTC first, then to Europe/London time zone
          const startDateLocal = formatInTimeZone(startDateLocalString, timeZone, "yyyy-MM-dd HH:mm:ssXXX");
          const endDateLocal = formatInTimeZone(endDateLocalString, timeZone, "yyyy-MM-dd HH:mm:ssXXX")

          return {
            Id: unavailability.orderId || uuidv4(),
            Subject: `${unavailability.memberFullName ? `${unavailability.memberFullName}, ` : ''} ${unavailability.unavailabilityTypeName}${unavailability.orderExternalReference ? ` n° ${unavailability.orderExternalReference}` : ''}`,
            StartTime: startDateLocal,
            Reason: unavailability.reason,
            EndTime: endDateLocal,
            ExternalReference: unavailability.orderExternalReference,
            OrderId: unavailability.orderId,
            MemberName: unavailability.memberFullName,
            Description: unavailability.unavailabilityTypeName || '--',
            RoomId: data.sellableItemId,
            CustomStyle: unavailability.orderExternalReference ? 'custom-event-color' : 'custom-event-unavailable',
            shortDescription: data.shortDescription,
            AreaInSqm: data.areaInSqm,
            productTypes: data.productTypes,
            mainImageUrl: data.mainImageUrl

          };
        }) || []
      );
      setEvents({ dataSource: newEvents });
      setLoading(false);
    }
  }, [calendarData, selectedInventoryId]);



  const handleCustomerConfirm = async (selectedCustomer: CustomerSearchItemResponseDto) => {
    setFilterParam((prev) => ({
      ...prev,
      memberId: selectedCustomer?.memberId ? selectedCustomer?.memberId : null,
    }));
    setMemberName(`${selectedCustomer.firstName} ${selectedCustomer.lastName}`);
    setCustomerOpen(false);
  };


  const headerTooltipTemplate = (props: any) => {
    const { productTypes } = props.resourceData.data
    const productConfig = productTypes && productTypes?.map((type: any) => {
      return {
        config: pontProductsConfig.find(
          (x) => x.productTypeName === type.productTypeName
        ),
        ...type
      }
    })
    return (
      <Box className="template-wrap">
        <Box sx={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", px: 1, fontSize: '10px', color: colors.white }}>
          <Box>
            {productConfig?.map((product: any) => {
              return (
                <Box>
                  {product?.config?.requiresQuantity && <Box>
                    {product?.quantity} {i18n.t("COMMON_LABEL_PERSON")} {i18n.t(product?.config?.productLabel!).toString()}
                  </Box>
                  }
                </Box>
              )
            })}
          </Box>
        </Box>
      </Box>
    );
  }


  return (
    <>
      <div className='schedule-control-section'>
        <div className='col-lg-12 control-section'>
          <div className='control-wrapper'>
            <Box sx={{ mb: 1 }}>
              <Grid container spacing={1} direction="row" wrap="wrap">
                <Grid item xs={12} sm={6} md={2}>
                  <CustomSelect
                    options={inventoryOptions}
                    getOptionLabel={(option: any) => option.sellableItemName}
                    getOptionValue={(option: any) => option.sellableItemId}
                    value={
                      calendarData?.find((x) => x.sellableItemId === selectedInventoryId) ||
                      inventoryOptions[0]
                    }
                    onChange={(e: any) => handleInventoryChange(e.sellableItemId)}
                    placeholder="Select Inventory"
                    labelProps={{
                      message: 'Select Inventory',
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <CustomSelect
                    labelProps={{
                      message: "LABEL_PRODUCT_TYPE",
                    }}
                    isClearable
                    isMulti
                    isLoading={fetchingProductTypes}
                    //@ts-ignore
                    value={productTypes?.filter((item: any) => filterParam?.productTypeNames.includes(item.productTypeName))}
                    options={productTypes}
                    placeholder={i18n.t("LABEL_PRODUCT_TYPE")}
                    onChange={(type: any) => {
                      setFilterParam((prev) => ({
                        ...prev,
                        productTypeNames: type?.map((item: any) => item.productTypeName),
                      }));
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2} >
                  <Label message="COMMON_SUMMARY_INVENTORY" />
                  <SearchInput
                    searchQuery={filterParam.inventoryName || ""}
                    onSearchChange={(query) => {
                      setFilterParam((prev) => ({
                        ...prev,
                        inventoryName: query,
                      }));
                    }
                    } placeholder={'MEMBER_LABEL_NAME'} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Label message="INVOICE_LABEL_ORDER" />
                  <SearchInput
                    searchQuery={filterParam.orderExternalReference || ""}
                    onSearchChange={(query) => {
                      setFilterParam((prev) => ({
                        ...prev,
                        orderExternalReference: query,
                      }));
                    }
                    } placeholder={'LABEL_ORDER_REFERENCE'} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <DialogMemberSelectorForOrder
                    userLogged={userLogged}
                    open={customerOpen}
                    handleConfirm={handleCustomerConfirm}
                    handleCancel={() => setCustomerOpen(false)}
                    memberTypeName={"CompanyContact"}
                  ></DialogMemberSelectorForOrder>
                  <Input
                    labelProps={{
                      message: i18n.t("INVOICE_LABEL_BILL_TO").toString(),
                    }}
                    placeholder={i18n.t("SEARCH_BY_NAME_OR_REFERENCE").toString()}
                    sx={{ input: { paddingRight: "3rem !important" } }}
                    readOnly
                    onClick={() => setCustomerOpen(true)}
                    value={memberName}
                    rightIcon={
                      <IconButton
                        type="button"
                        onClick={() => setCustomerOpen(true)}
                      >
                        <GridSearchIcon />
                      </IconButton>
                    }
                  ></Input>
                </Grid>
              </Grid>
            </Box>
            {
              loading ? <Loader /> :
                <ScheduleComponent
                  cssClass='timeline-resource'
                  ref={scheduleObj}
                  key={calendarKey}
                  width='100%'
                  height='750px'
                  selectedDate={selectedDateFrom}
                  navigating={handleNavigating}
                  timezone={"Europe/London"}
                  eventRendered={onEventRendered}
                  actionComplete={onActionComplete}
                  resourceHeaderTemplate={resourceHeaderTemplate}
                  eventSettings={events}
                  quickInfoTemplates={quickInfoTemplates} // Replacing all templates with custom one
                  actionBegin={onActionBegin}
                  eventDoubleClick={onPopupOpen}
                  rowAutoHeight={true}
                  group={{
                    enableCompactView: false, resources: ['MeetingRoom'],
                  }}
                  select={onSelect}
                  currentView={selectedCalendarView ? selectedCalendarView : 'TimelineDay'}

                >
                  <ViewsDirective>
                    <ViewDirective option='TimelineDay' startHour='07:00' endHour='20:00' />
                    <ViewDirective option='TimelineWeek' startHour='07:00' endHour='20:00' firstDayOfWeek={1} />
                    <ViewDirective option='TimelineMonth' startHour='07:00' endHour='20:00' />
                  </ViewsDirective>
                  <ResourcesDirective>
                    <ResourceDirective
                      field='RoomId'
                      title='Room Type'
                      name='MeetingRoom'
                      allowMultiple={true}
                      dataSource={ownerData}
                      textField='text'
                      idField='id'
                      colorField='color'
                    />
                  </ResourcesDirective>

                  <Inject services={[TimelineViews, TimelineMonth]} />
                </ScheduleComponent>
            }
          </div>
        </div>
      </div>

      <CustomConfirmDialogWithContent
        isOpen={openOrderBookingDialog}
        confirmButtonColor='success'
        confirmButtonTitle='Yes'
        handleConfirm={confirmOrderBooking}
        onClose={cancelOrderBooking}
        title={i18n.t('ACTIVITY_LABEL_NEWORDER')}
      >
        <Box sx={{ width: '100%' }}>
          {
            orderBookingData &&
            <Box sx={{ minWidth: 500 }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell><LR localResource="COMMON_LABEL_FROM"></LR></TableCell>
                    <TableCell>{orderBookingData.startDate} {orderBookingData.startTime}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><LR localResource="COMMON_LABEL_TO"></LR></TableCell>
                    <TableCell>
                      <Box sx={{ position: 'relative', pr: 6 }}>
                        {orderBookingData.endDate} {orderBookingData.endTime}
                        <Box sx={{
                          height: 0,
                          width: 0,
                          opacity: 0,
                          visibility: 'hidden',
                          overflow: 'hidden',
                        }}>
                          <CustomDatePicker
                            open={isDatePickerOpen}
                            minDate={dayjs(orderBookingData.startDate, 'DD/MM/YYYY')}
                            onClose={() => setIsDatePickerOpen(false)}
                            value={dayjs(orderBookingData.endDate, 'DD/MM/YYYY')}
                            onChange={(e) => {
                              setOrderBookingData({ ...orderBookingData, endDate: e.format('DD/MM/YYYY') })
                              setIsDatePickerOpen(false)
                            }}
                          ></CustomDatePicker>
                        </Box>
                        <IconButton
                          size='small'
                          color='secondary'
                          onClick={() => setIsDatePickerOpen(true)}
                          sx={{
                            position: 'absolute',
                            right: 0,
                            top: '50%',
                            transform: 'translateY(-50%)'
                          }}>
                          <EditIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><LR localResource="COMMON_LABEL_FOR"></LR></TableCell>
                    <TableCell>{orderBookingData.sellableItemName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><LR localResource='COMMON_LABEL_CLIENT_NAME'></LR></TableCell>
                    <TableCell>
                      <Box sx={{ position: 'relative', pr: 6 }}>
                        {customerData ? `${customerData.firstName} ${customerData.lastName}` : "-"}
                        <IconButton
                          size='small'
                          color='secondary'
                          onClick={() => setOpenCustomerSelectorDialog(true)}
                          sx={{
                            position: 'absolute',
                            right: 0,
                            top: '50%',
                            transform: 'translateY(-50%)'
                          }}>
                          <EditIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><LR localResource='MEMBER_LABEL_COMPANYNAME'></LR></TableCell>
                    <TableCell>{customerData?.companyName || "-"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell><LR localResource='COMMON_LABEL_TYPE'></LR></TableCell>
                    <TableCell>{customerData?.memberTypeName || "-"}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          }
        </Box>
      </CustomConfirmDialogWithContent>
      <DialogMemberSelectorForOrder
        userLogged={userLogged}
        open={openCustomerSelectorDialog}
        handleConfirm={handleSelectCustomer}
        handleCancel={() => setOpenCustomerSelectorDialog(false)}
        memberTypeName={"CompanyContact"}
      ></DialogMemberSelectorForOrder>
    </>
  );
};