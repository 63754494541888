
import { yupResolver } from "@hookform/resolvers/yup";
import SearchIcon from '@mui/icons-material/Search';
import { Box, DialogContent, DialogTitle, Grid, IconButton } from "@mui/material";
import dayjs from "dayjs";
import { isNil } from "lodash";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { pontInitiatedBy } from "../../../../data/pont";
import { TerminationLetterResponseDto, useOrderEndingReason, useOrderTermination, useTerminationLetter } from "../../../../hooks/Order/useOrder";
import i18n from "../../../../i18n";
import { CustomerSearchItemResponseDto } from "../../../../types/Customer/CustomerSearchItemResponseDto";
import { CustomDatePicker } from "../../../Common/formComponents/CustomDatepicker";
import { CustomSelect } from "../../../Common/formComponents/CustomSelect";
import { Input } from "../../../Common/formComponents/Input";
import { LR } from "../../../Common/Help/LR";
import { CustomButton } from "../../../Common/UI/CustomButton";
import { CustomDialog } from "../../../Common/UI/CustomDialog";
import { showSnackbar } from "../../../Common/UI/CustomSnackbar";
import { DialogMemberSelectorForOrder } from "../DialogMemberSelectorForOrder";
import '../OrderForm.css';
import { useOrderContext } from "../OrderProvider";
import { useSingleFileUpload } from "../../../../hooks/Common/fileUpload";
import { ButtonLoader } from "../../../Common/UI/Loader";
import { colors } from "../../../../main/Theme";
import { Label } from "../../../Common/formComponents/Label";
import { ValidationError } from "../../../Common/formComponents/ValidationError";

type RequestTerminationArgs = {
  open: boolean
  onClose: () => void

}

export const DialogRequestTermination = ({ open, onClose, }: RequestTerminationArgs) => {
  const navigate = useNavigate()
  const { currentBasket, userLogged, basketId } = useOrderContext()
  const { mutate: orderTermination, isLoading: isLoadingOrderTermination } = useOrderTermination()
  const [showMemberSelectorModal, setShowMemberSelectorModal] = useState(false)

  const { data: _terminationLetterData } = useTerminationLetter({
    basketId: currentBasket ? currentBasket?.id : "",
    orderId: currentBasket ? currentBasket?.orderId! : ""
  })
  const { mutate: fileUploadMutation, isLoading: isLoadingFileUpload } = useSingleFileUpload()

  const terminationLetterData = _terminationLetterData as TerminationLetterResponseDto

  const formattedInitiatedBy = pontInitiatedBy.map(i => ({
    ...i,
    label: i18n.t(i.label).toString()
  }))

  const formSchema = yup.object().shape({
    initiatedByName: yup.string().required(i18n.t("VALIDATION_THIS_REQUIRED").toString()),
    requestComments: yup.string(),
    memberId: yup.object().nullable().when("initiatedByName", {
      is: (initiatedByName: string) => initiatedByName === "customer",
      then: (schema) =>
        schema
          .shape({
            label: yup.string().required(i18n.t("VALIDATION_THIS_REQUIRED").toString()),
            value: yup.string().required(i18n.t("VALIDATION_THIS_REQUIRED").toString()),
          })
          .required(i18n.t("VALIDATION_THIS_REQUIRED").toString()),
      otherwise: (schema) => schema.nullable(),
    }),
    orderEndReasonId: yup
      .object()
      .shape({
        label: yup.string().required(i18n.t("VALIDATION_THIS_REQUIRED").toString()),
        id: yup.string().required(i18n.t("VALIDATION_THIS_REQUIRED").toString()),
        orderEndTypeName: yup.string().required(i18n.t("VALIDATION_THIS_REQUIRED").toString()),
        isWrittenNoticeRequired: yup.boolean().required(i18n.t("VALIDATION_THIS_REQUIRED").toString()),
      })
      .required(i18n.t("VALIDATION_THIS_REQUIRED").toString()),
    requestedEndDate: yup
      .string()
      .required(i18n.t("VALIDATION_THIS_REQUIRED").toString())
      .test(
        "is-valid-to-date",
        i18n.t("VALIDATION_TO_DATE_MUST_BE_A_VALID_DATE") as string,
        (value) => {
          return value ? dayjs(value).isValid() : true;
        }
      ),
    writtenTermination: yup.mixed(),
    digitalAssetId: yup.string().when("orderEndReasonId", {
      is: (orderEndReasonId: any) => orderEndReasonId.isWrittenNoticeRequired === true,
      then: (schema) => schema.required(i18n.t("VALIDATION_THIS_REQUIRED").toString()),
      otherwise: (schema) => schema.nullable(),
    }),
  });

  type formType = yup.InferType<typeof formSchema>;
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    getValues,

  } = useForm<formType>({
    resolver: yupResolver(formSchema),
    mode: 'onChange'
  });


  const initiatedBy = watch("initiatedByName")
  const writtenNoticeRequired = watch("orderEndReasonId")
  const isWrittenNoticeRequired = writtenNoticeRequired?.isWrittenNoticeRequired ? writtenNoticeRequired?.isWrittenNoticeRequired : false

  const { data: orderEndingReasonList, isLoading: isOrderEndingReasonLoading } = useOrderEndingReason({
    initiatedByName: initiatedBy,
    orderEndTypeName: "terminated"
  })

  const onSubmit = (data: formType) => {
    const memberData: any = data.memberId
    orderTermination({
      basketId: basketId!,
      initiatedByName: data.initiatedByName,
      ...(data.initiatedByName === "customer" && {
        memberId: memberData?.value || null,
        memberName: memberData?.label || null,
      }),
      orderEndReasonId: data.orderEndReasonId?.id,
      requestComments: data.requestComments || "",
      orderEndTypeName: data.orderEndReasonId?.orderEndTypeName,
      requestedEndDate: data.requestedEndDate ? dayjs(data.requestedEndDate).format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]") : null,
      digitalAssetId: data.digitalAssetId
    }, {
      onSuccess: () => {
        onClose()
        navigate(`/orders`)
        showSnackbar(i18n.t("SUCCESS_REQUEST_TERMINATION_ORDER"))
      }, onError: (error: any) => {
        showSnackbar(error.response?.data, {
          persist: true,
          variant: 'error'
        })
      }
    })
  }

  const handleConfirmSelection = (selectedCustomer: CustomerSearchItemResponseDto) => {
    setValue("memberId", { label: `${selectedCustomer.firstName} ${selectedCustomer.lastName}`, value: selectedCustomer.memberId, })
    setShowMemberSelectorModal(false)
  }



  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      fileUploadMutation({
        version: 1,
        FileName: selectedFile?.name,
        GroupName: "import",
        SourceKey: terminationLetterData.sourceKey,
        SourceName: terminationLetterData.sourceName,
        EntityName: terminationLetterData.entityName,
        UniqueFile: (terminationLetterData.maximumFileCount === 1 ? "true" : "false"),
        Overwrite: "true",
        EntityKey: terminationLetterData.entityKey,
        File: selectedFile,
        ServiceName: terminationLetterData.serviceName,
      }, {
        onSuccess: (data) => {
          setValue("digitalAssetId", data.fileId)
        }, onError: (error: any) => {
          showSnackbar(error.response?.data, {
            persist: true,
            variant: 'error'
          })
        }
      })

    }

  };

  useEffect(() => {
    if (currentBasket) {
      reset({
        initiatedByName: getValues("initiatedByName"),
        memberId: currentBasket?.memberTypeName === "Individual"
          ? {
            label: currentBasket?.memberFullName,
            value: currentBasket?.memberId,
          }
          : null,
      })
      setValue("requestedEndDate", currentBasket?.maximumEndDate!)
    }
  }, [currentBasket, initiatedBy])




  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      scroll="body"
    >
      <DialogTitle>
        <LR localResource={i18n.t("COMMON_LABEL_REQUEST_TERMINATION")}></LR>
      </DialogTitle>
      <DialogContent>
        <Box sx={{
          minHeight: '550px',
        }}>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} mb={2}>

              {/* Who has requested the termination */}
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="initiatedByName"
                  render={({ field, fieldState }) => {
                    return (
                      <CustomSelect
                        labelProps={{
                          message: "LABEL_ORDER_TERMINATION",
                          mandatory: true,
                        }}
                        value={formattedInitiatedBy.find((x) => x.value === field.value)}
                        options={formattedInitiatedBy}
                        placeholder={i18n.t("COMMON_LABEL_SELECT")}
                        field={field}
                        onChange={(e) => {
                          field.onChange(e?.value);
                        }}
                        validationError={fieldState.error}
                      />
                    );
                  }}
                />
              </Grid>

              {
                initiatedBy &&
                <>
                  {
                    initiatedBy === "customer" &&
                    <>
                      {/* Which member has requested the termination */}
                      <DialogMemberSelectorForOrder
                        userLogged={userLogged}
                        open={showMemberSelectorModal}
                        handleConfirm={handleConfirmSelection}
                        handleCancel={() => setShowMemberSelectorModal(false)}
                        memberTypeName={"Individual"}
                      ></DialogMemberSelectorForOrder>
                      <Grid item xs={12}>
                        <Controller
                          name="memberId"
                          control={control}
                          render={({ field, fieldState }) => {
                            const value = field?.value && typeof field?.value === "object" && "label" in field?.value
                              ? field?.value.label
                              : "";
                            return (
                              <Box position="relative">
                                <Input
                                  labelProps={{
                                    message: i18n.t("LABEL_MEMBER_REQUEST_BY").toString(),
                                    mandatory: true,
                                  }}
                                  placeholder={i18n.t("COMMON_LABEL_SELECT").toString()}
                                  sx={{ input: { paddingRight: "3rem !important" } }}
                                  validationError={fieldState.error}
                                  readOnly
                                  disabled={currentBasket?.memberTypeName === "Individual"}
                                  value={value}
                                  onClick={() => setShowMemberSelectorModal(true)}
                                  rightIcon={
                                    <IconButton
                                      type="button"
                                      disabled={currentBasket?.memberTypeName === "Individual"}
                                      onClick={() => setShowMemberSelectorModal(true)}
                                    >
                                      <SearchIcon />
                                    </IconButton>
                                  }
                                ></Input>
                              </Box>
                            )
                          }
                          }
                        />

                      </Grid>
                    </>
                  }

                  {/* Reason for termination  */}
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="orderEndReasonId"
                      render={({ field, fieldState }) => {
                        return (
                          <CustomSelect
                            labelProps={{
                              message: "LABEL_REASON_FOR_TERMINATION",
                              mandatory: true,
                            }}
                            getOptionValue={(option: any) => option.id}
                            getOptionLabel={(option: any) => i18n.t(option.label).toString()}
                            isLoading={isOrderEndingReasonLoading}
                            options={orderEndingReasonList ? orderEndingReasonList : []}
                            field={field}
                            value={field.value || null}
                            onChange={field.onChange}
                            placeholder={i18n.t("COMMON_LABEL_SELECT")}
                            validationError={fieldState.error}
                          />
                        );
                      }}
                    />
                  </Grid>

                  {/* requestComments */}

                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="requestComments"
                      render={({ field, fieldState }) => {
                        return (
                          <Input
                            labelProps={{
                              message: i18n.t("LABEL_ANY_ADDITIONAL_COMMENTS").toString(),
                              mandatory: false,
                            }}
                            multiline={true}
                            rows={3}
                            field={field}
                            onChange={(e) => {
                              field.onChange(e.target.value)
                            }}
                            validationError={fieldState.error}
                          />
                        );
                      }}
                    />
                  </Grid>

                  {/* When do they want the agreement to end?  ||  Request end date */}
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="requestedEndDate"
                      render={({ field, fieldState, ...rest }) => {
                        return (
                          <CustomDatePicker
                            value={field.value ? dayjs(field.value) : null}
                            onChange={(e) => {
                              if (!isNil(e) && e.isValid()) {
                                field.onChange(e.toDate())
                              }
                            }}
                            maxDate={dayjs(currentBasket?.maximumEndDate)}
                            minDate={dayjs(currentBasket?.minimumEndDate)}
                            labelProps={{
                              message: i18n.t(initiatedBy === "operator" ? "LABEL_REQUEST_END_DATE" : "LABEL_AGREEMENT_END").toString(),
                              mandatory: true
                            }}
                            validationError={fieldState.error}
                            {...rest}
                          />
                        );
                      }}
                    />
                  </Grid>

                  {/* writtenTermination */}

                  {isWrittenNoticeRequired &&
                    <Grid item xs={12} >
                      <Controller
                        control={control}
                        name="digitalAssetId"
                        render={({ field, fieldState }) => {
                          return (
                            <>
                              <Label message={i18n.t(terminationLetterData.label).toString()} />
                              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                <Box sx={{ width: "100%" }}>
                                  <input type="file"
                                    className='fileInput form-control fa-form'
                                    onChange={(e) => handleFileUpload(e)}
                                    disabled={isLoadingFileUpload}
                                    accept={terminationLetterData.fileTypeCsv!}
                                  />
                                </Box>
                                {
                                  isLoadingFileUpload &&
                                  <Box sx={{ maxWidth: "min-content" }}>
                                    <ButtonLoader color={colors.primary} />
                                  </Box>
                                }
                              </Box>
                              <ValidationError message={fieldState.error?.message} />
                            </>
                          );
                        }}
                      />
                    </Grid>
                  }
                </>
              }
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={1} md={4} mx={"auto"}>
                <CustomButton
                  variant="contained"
                  fullWidth
                  loading={isLoadingOrderTermination}
                  disabled={isLoadingFileUpload}
                  type="submit"
                >
                  {i18n.t("COMMON_LABEL_SAVE")}
                </CustomButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </DialogContent>
    </CustomDialog>
  );
};
