import { DateRangePickerComponent, RangeEventArgs } from '@syncfusion/ej2-react-calendars';
import React, { useState } from 'react';
import { getLocalDateFormat } from '../../../functions/utils/helper';
import i18n from '../../../i18n';
import './DateNavigation.css';
import { Box } from '@mui/material';
import { colors } from '../../../main/Theme';

interface DateNavigationProps {
  onDateSelect: (fromDate: Date, toDate: Date) => void;
  fromDate: Date;
  toDate: Date;
}

const DateNavigation: React.FC<DateNavigationProps> = ({ onDateSelect, fromDate, toDate }) => {
  const [selectedDates, setSelectedDates] = useState<Date[]>([fromDate, toDate]);

  const handleDateChange = (args: RangeEventArgs) => {
    if (args.startDate && args.endDate) {
      setSelectedDates([args.startDate, args.endDate]);

      onDateSelect(args.startDate, args.endDate);
    }
  };

  const getLocaleDateFormat = (): string => {

    const formatted = getLocalDateFormat();
    return formatted;
  };

  return (
    <Box sx={{ backgroundColor: colors.grey[100], padding: "3px 5px" }}>
      <DateRangePickerComponent
        format={getLocaleDateFormat()}
        value={selectedDates}
        change={handleDateChange}
        showClearButton={false}
        placeholder="Select a date range"
        cssClass="custom-datepicker-dropdown"
        locale={i18n.language}
      />
    </Box>
  );
};

export default DateNavigation;
