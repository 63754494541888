import { DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { CustomDialog } from "./CustomDialog";
import { CustomButton } from "./CustomButton";
import { LR } from "../Help/LR";

type CustomConfirmDialogType = {
  title: string,
  isOpen: boolean,
  onClose: () => void,
  content: string,
  confirmButtonTitle: string,
  confirmButtonColor: "error" | "success" | "info" | "warning" | "primary",
  handleConfirm: () => void,
}
export const CustomConfirmDialog = ({
  title,
  isOpen,
  onClose,
  content,
  confirmButtonColor,
  confirmButtonTitle,
  handleConfirm
}: CustomConfirmDialogType) => {
  return (
    <CustomDialog open={isOpen} onClose={onClose} hideCloseButton>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1" color="textSecondary">
            {content}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CustomButton variant="contained" type="button" color="info" onClick={onClose}>
          <LR localResource='COMMON_BUTTON_CANCEL' />
        </CustomButton>
        <CustomButton variant="contained" type="button" color={confirmButtonColor} onClick={handleConfirm} autoFocus>
          {confirmButtonTitle}
        </CustomButton>
      </DialogActions>
    </CustomDialog>
  )
}