import { useNavigate, useParams } from "react-router-dom";
import { useDirtyState } from "../../hooks/Common/dirty";
import { useFetchMemberDomains } from "../../hooks/Member/domains";
import { useFetchMemberById, } from "../../hooks/Member/member";
import i18n from "../../i18n";
import { useSource } from "../../providers/SourceProvider";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { SourceEnum } from "../../types/Common/CommonSummaryHeaderProps";
import ApiStatus from "../Common/Errors/ApiStatus";
import { SectionHead } from "../Common/Help/SectionHead";
import { DomainCollection } from "./DomainCollection";

type Args = {
    userLogged: ApplicationUser,
}

const HostDomains = ({ userLogged, }: Args) => {
    const nav = useNavigate();
    const queryStringParams = useParams();
    const { source } = useSource()
    const [isDirty, setDirty, unsetDirty] = useDirtyState();

    if (!queryStringParams.id) throw Error(`${source.toUpperCase()} id needed`);

    const handleDirty = () => {
        setDirty();
    };

    const { data: memberData, status: memberStatus, isSuccess: memberIsSuccess } = useFetchMemberById(queryStringParams.id!)
    const { data: domainList, status: domainListStatus, isSuccess: domainListSuccess, refetch: refetchDomainList } = useFetchMemberDomains(queryStringParams.id!);

    const afterSave = () => {
        refetchDomainList()
        nav(`/${source}/${queryStringParams.id}/summary`);
    }

    if ((!domainListSuccess || !memberIsSuccess) && (domainListStatus !== 'success') && (memberStatus !== 'success')) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-7 col-11 justify-content-center">
                    <ApiStatus status={domainListStatus} />
                </div>
            </div>
        </>
    )



    if (!domainList || !memberData) return <ApiStatus status={domainListStatus} />;

    const ctaText: any = {
        [SourceEnum.operators]: i18n.t('COMMON_SUMMARY_OPERATORCONTACT')!,
        [SourceEnum.companies]: i18n.t('COMMON_SUMMARY_COMPANYSUMMARY')!,
        [SourceEnum.landlords]: i18n.t('COMMON_SUMMARY_LANDLORDCONTACT')!,
    }

    const description: any = {
        [SourceEnum.operators]: i18n.t('COMMON_SUMMARY_OPERATORMANAGEMENT')!,
        [SourceEnum.companies]: i18n.t('COMMON_SUMMARY_COMPANYMANAGEMENT')!,
        [SourceEnum.landlords]: i18n.t('COMMON_SUMMARY_LANDLORDMANAGEMENT')!,
    }

    return (
        <>
            <SectionHead
                linkCallBack={() => nav(`/${source}/${queryStringParams.id}/summary`)}
                ctaText={ctaText[source]}
                name={`${memberData.fullName} (${memberData.externalReference})`}
                title={i18n.t('COMMON_SUMMARY_DOMAINS')!}
                description={description[source]}
            />

            <div className="row d-flex justify-content-center mt-1 mb-3">
                <div className="col-md-7 col-11">
                    <DomainCollection
                        handleDirty={handleDirty}
                        items={domainList}
                        entityName="Member"
                        entityKey={queryStringParams.id!}
                        afterSave={afterSave}
                    />
                </div>
            </div>
        </>
    );
};


export { HostDomains };

