import { Box, Grid, Tooltip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounceValue } from 'usehooks-ts';
import { pontRelationshipTypes } from '../../data/customer/pontRelationshipTypes';
import { useFetchCustomers } from '../../hooks/Customer/useCustomer';
import { useSaveCustomerLocalStorage } from '../../hooks/Customer/useSaveCustomerLocalStorage';
import i18n from '../../i18n';
import { colors } from '../../main/Theme';
import { ApplicationUser } from '../../types/Authentication/ApplicationUser';
import { CustomerSearchItemResponseDto } from '../../types/Customer/CustomerSearchItemResponseDto';
import { CustomerTypeName } from '../../types/Customer/CustomerSearchResponseDto';
import MessageAlert from '../Common/Errors/MessageAlert';
import { CustomSelect } from '../Common/formComponents/CustomSelect';
import { SearchInput } from '../Common/SearchInput';
import { CustomDataGrid } from '../Common/UI/CustomDataGrid';
import { Loader } from '../Common/UI/Loader';
import { MaterialIcon } from '../Common/UI/MaterialIcon';
import './Customers.css';


type CustomerFeedProps = {
    userLogged: ApplicationUser;
    headers?: string[];
    handleArrowClick?: (customer: CustomerSearchItemResponseDto) => void;
    compact?: boolean;
    memberTypeName?: CustomerTypeName;
    searchPlaceHolder?: string;
    excludeMember?: CustomerTypeName[],
    usePointSystem?: boolean,
    companyMemberId?: string,
    showIcons?: boolean
};

const customerTypes: { label: string, point: number, value: CustomerTypeName }[] = [{
    label: 'Individual',
    point: 64,
    value: 'Individual'
}, {
    label: 'Company',
    point: 128,
    value: 'Company'
}, {
    label: 'Company Contact',
    point: 256,
    value: 'CompanyContact'
}]

const CustomerFeed = ({ userLogged, compact, headers, handleArrowClick, memberTypeName, searchPlaceHolder, excludeMember, usePointSystem, companyMemberId, showIcons }: CustomerFeedProps) => {
    const navigate = useNavigate();
    const defaultPagination = { page: 0, pageSize: 10 }
    const [paginationModel, setPaginationModel] = useState(defaultPagination);

    let pointForAll = customerTypes.reduce((total, x) => excludeMember?.includes(x.value) ? total : total + x.point, 0);

    const customerTypeOptions = [{
        label: 'All',
        point: pointForAll,
        value: null
    }, ...customerTypes.filter(x => !excludeMember?.includes(x.value))]

    let shouldHideDropDown = customerTypeOptions.length <= 2;

    if (companyMemberId) {
        shouldHideDropDown = true;
    }


    const [customerType, setCustomerType] = useState<CustomerTypeName>(memberTypeName || null);
    const [customerTypePoint, setCustomerTypePoint] = useState(customerTypes.find(x => x.value === memberTypeName)?.point || pointForAll);

    const { setCustomerData: saveSelectedCustomer } = useSaveCustomerLocalStorage()

    const [debouncedValue, setDebouncedValue] = useState('')
    const disableSelectBox = Boolean(memberTypeName)


    const { data: customersData, isLoading } = useFetchCustomers({
        locationId: userLogged.location?.id!,
        searchRequirement: debouncedValue,
        pageNumber: paginationModel.page + 1,
        pageSize: paginationModel.pageSize,
        ...(
            usePointSystem
                ? { memberTypeFlags: customerTypePoint }
                : { memberTypeName: customerType }
        ),
        ...(
            companyMemberId ? {
                companyMemberId: companyMemberId
            } : {}
        )
    });




    const defaultHeaders = [
        'Account Reference',
        'First Name',
        'Last Name',
        'Company',
        'Relationship',
        'Type',
        'Orders',
        'Spend'
    ];

    const defaultHandleArrowClick = (customer: CustomerSearchItemResponseDto) => {
        const memberType = customer.memberTypeName as CustomerTypeName;
        if (memberType) {
            const path = {
                Individual: `/individuals/${customer.memberId}/summary`,
                CompanyContact: `/companies/${customer.companyId}/contacts/${customer.memberId}/summary`,
                Company: `/companies/${customer.memberId}/summary`,
            }
            navigate(path[memberType], { state: { customer, userLogged } });
        }
    };


    const columns: GridColDef[] = [

        {
            field: 'memberExternalReference',
            headerName: 'Account Reference',
            sortable: false,
            flex: 2,
        },
        {
            field: 'firstName',
            headerName: 'First Name',
            sortable: false,
            flex: 2,
        },
        {
            field: 'lastName',
            headerName: 'Last Name',
            sortable: false,
            flex: 2,
        },
        {
            field: 'companyName',
            headerName: 'Company',
            sortable: false,
            flex: 2,
        },
        {
            field: 'relationshipTypes',
            headerName: 'Relationship',
            sortable: false,
            flex: 2,
        },
        {
            field: 'memberTypeName',
            headerName: 'Type',
            sortable: false,
            flex: 2,
        },
        {
            field: 'orderCount',
            headerName: 'Orders',
            sortable: false,
            flex: 2,
        },
        {
            field: 'currencySymbol',
            headerName: 'Spend',
            sortable: false,
            flex: 2,
            renderCell: ({ row }) => {
                return row.currencySymbol && row.orderTotalAmountExTax ? `${row.currencySymbol}${row.orderTotalAmountExTax.toFixed(2)}` : ''
            }
        },
        {
            field: 'action',
            headerName: 'action',
            sortable: false,
            align: "right",
            headerAlign: "right",
            renderCell: ({ row }) => {
                const relationTitle = pontRelationshipTypes.find(x => x.flag === row?.relationshipTypeFlags)?.label || ""
                const Icon = pontRelationshipTypes.find(x => x.flag === row?.relationshipTypeFlags)?.icon as any
                const showIconsActually = Boolean(Icon) && Boolean(relationTitle)
                return (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {
                            (showIconsActually && showIcons) ? (
                                <Tooltip title={i18n.t(relationTitle)}>
                                    <Icon></Icon>
                                </Tooltip>
                            ) : (
                                <Box sx={{ width: 32 }}></Box>
                            )
                        }
                        <Box onClick={() => {
                            saveSelectedCustomer(row)
                            if (handleArrowClick) {
                                handleArrowClick(row)
                            } else {
                                defaultHandleArrowClick(row)
                            }
                        }}>
                            <MaterialIcon
                                sx={{
                                    color: colors.primary,
                                }} name="keyboard_arrow_right" size={40} />
                        </Box>
                    </Box>
                )
            }
        }
    ];

    const handelPaginationChange = (pagination: any) => {
        setPaginationModel((prev) => ({
            ...prev,
            page: pagination?.pageSize !== paginationModel.pageSize ? 0 : pagination?.page,
            pageSize: pagination?.pageSize,
        }));
    }
    const header = headers ?? defaultHeaders

    const _columns = columns?.filter((col) => header?.includes(col.headerName!) || col.headerName === "action")

    // const getDetailPanelHeight = useCallback(() => 400, []);

    // const getDetailPanelContent = (params: any) => {
    //     console.log(params);
    //     return <div>Detail Panel</div>
    // }

    return (
        <>

            {/*

            <DataGridPro   columns={_columns}
        rows={customersData?.records} /> */}

            <Grid container spacing={2}>
                <Grid item xs={12} md={shouldHideDropDown ? 12 : 8}>
                    <SearchInput onSearchChange={(query) => setDebouncedValue(query)
                    }
                        placeholder={'SEARCH_BY_ACTIVITY'} />

                    {/* <SearchInput onSearchChange={handleSearchChange} searchQuery={searchQuery} placeholder={searchPlaceHolder || 'SEARCH_BY_CUSTOMER'} /> */}

                </Grid>
                {!shouldHideDropDown &&
                    <Grid item xs={12} md={4}>
                        <CustomSelect
                            options={customerTypeOptions}
                            value={customerTypeOptions.find(x => x.value === customerType)}
                            onChange={(e) => {
                                setCustomerType((e?.value || null))
                                setCustomerTypePoint(e?.point || pointForAll)
                            }}
                            isDisabled={disableSelectBox}
                        ></CustomSelect>
                    </Grid>
                }
            </Grid>
            {
                isLoading ? (
                    <Box sx={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Loader />
                    </Box>
                ) : (
                    <div className="customer-feed">
                        {
                            !customersData?.records || customersData?.records?.length === 0 ? <MessageAlert message={i18n.t("COMMON_MESSAGE_NO_ACTIVITIES_FOUND")} /> :
                                <CustomDataGrid
                                    // getDetailPanelContent={getDetailPanelContent}
                                    columns={_columns}
                                    rows={customersData?.records}
                                    initialState={{
                                        pagination: {
                                            paginationModel: defaultPagination,
                                        },
                                    }}
                                    loading={isLoading}
                                    getRowId={(row) => row.memberId}
                                    // onRowClick={(row) => navigateActivity(row?.row)}
                                    rowCount={customersData?.totalRecords}
                                    paginationModel={paginationModel}
                                    onPaginationModelChange={handelPaginationChange}
                                    sx={{
                                        '& .MuiDataGrid-row': {
                                            transition: 'background-color 0.3s ease', // Optional smooth transition effect
                                        }
                                    }}
                                />
                        }
                        {/* <HeaderDisplay headers={headers ?? defaultHeaders} />
                        <CustomerTable
                            customers={paginatedCustomers}
                            headers={headers ?? defaultHeaders}
                            onActionClick={(customer) => {
                                saveSelectedCustomer(customer)
                                if (handleArrowClick) {
                                    handleArrowClick(customer)
                                } else {
                                    defaultHandleArrowClick(customer)
                                }
                            }}
                            userLogged={userLogged}
                            compact={compact}
                            showIcons={showIcons}
                        />

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={10}>
                                <Pagination
                                    count={Math.ceil(allCustomers.length / itemsPerPage)}
                                    page={page}
                                    onChange={handleChangePage}
                                    color="primary"
                                    className="pagination-bar-order"
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <CustomSelect
                                    options={itemPerPageList}
                                    value={itemPerPageList.find(x => x.value === itemsPerPage)}
                                    onChange={(e) => setItemsPerPage(e?.value || defaultItemPerPage)}
                                ></CustomSelect>
                            </Grid>
                        </Grid> */}
                    </div>
                )
            }
        </>
    );
};

export default CustomerFeed;
