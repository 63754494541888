import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { useEffect, useState } from "react";
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { colors } from "../../../main/Theme";
import { FeaturesDto } from "../../../types/Common/FeaturesDto";

type Args = {
  items: any
  callback: (features: FeaturesDto[], targetNode: any) => void
  handleDirty: () => void
}

export const FeaturesTreeview = ({ items, callback, handleDirty }: Args) => {
  useEffect(() => {
    const link = document.createElement('link');
    link.href = 'https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css';
    link.rel = 'stylesheet';
    document.head.appendChild(link);
    return () => {
      document.head.removeChild(link);
    };
  }, []);

  function extractCheckedValues(items: any[]): string[] {
    return items?.reduce((acc: string[], item: any) => {
      if (item.isChecked) {
        acc.push(item.value);
      }

      if (item.children && item.children.length > 0) {
        const nestedCheckedValues = extractCheckedValues(item.children);
        acc.push(...nestedCheckedValues);
      }

      return acc;
    }, []);
  }

  const [treeData, setTreeData] = useState(items.children || []);
  const [targetNode, setTargetNode] = useState<any>();
  const savedValue = extractCheckedValues(items.children);

  const [checked, setChecked] = useState<any[]>(savedValue); // Map each item to its value property);
  const [expanded, setExpanded] = useState<any[]>(savedValue);

  function findNodeParents(root: any[], targetLabel: string): any[] | null {
    for (const node of root) {
      if (node.value === targetLabel) {
        return [node]; // Found the target node, return it as an array
      }
      if (node.children) {
        const parents = findNodeParents(node.children, targetLabel);
        if (parents) {
          return [node, ...parents]; // Found the target node in children, prepend current node and return
        }
      }
    }
    return null;
  }

  const onCheck = (newChecked: any[], targetNode: any) => {
    handleDirty();
    const clickedValue = targetNode.value;
    setTargetNode(targetNode);
    if (targetNode.checked) {
      // we build all the hierarchy up to the main parent.
      const newCheckedArray: string[] = newChecked;
      const parents = findNodeParents(treeData, clickedValue);
      if (parents) {
        parents.forEach((node, index) => {

          newCheckedArray.push(node.value);
        });
        setChecked(Array.from(new Set(newCheckedArray)));
      }
    } else {
      // the node is unckecked.
      const nodestoremove = findNodeParents(treeData, clickedValue);
      nodestoremove!.forEach((node, index) => {
        newChecked = newChecked.filter((element) => element !== node.value);
      });

      if (targetNode.children) //removing the children of what has been removed
      {
        targetNode.children.forEach((subnode: any, kindex: number) => {
          newChecked = newChecked.filter((element) => element !== subnode.value);
        });
      }
      const newCheckedArray: string[] = newChecked; //this is the new elements. We now need to rebuild the hierarchy of its parents.
      newChecked.forEach((remainingNode, kindex) => {
        const parents = findNodeParents(treeData, remainingNode);
        if (parents) {
          parents.forEach((node, index) => {
            newCheckedArray.push(node.value);
          });
        }
      });

      setChecked(Array.from(new Set(newCheckedArray)));
    }
  };

  useEffect(() => {
    if (checked) {
      const selectedArray: FeaturesDto[] = [];
      for (var i = 0; i < checked.length; i++) {
        selectedArray.push({ value: checked[i], description: '' });
      }

      callback(selectedArray, targetNode);
    }

  }, [checked, targetNode]);

  useEffect(() => {
    setTreeData(items.children || []);
  }, [items])

  const onExpand = (newExpanded: any[]) => {
    setExpanded(newExpanded);
  };


  return (
    <CheckboxTree
      noCascade
      nodes={treeData}
      checked={checked}
      expanded={expanded}
      onCheck={onCheck}
      onExpand={onExpand}
      icons={{
        check: <CheckBoxIcon color="secondary" />,
        uncheck: <CheckBoxOutlineBlankIcon sx={{ color: colors.grey[200] }} />,
        halfCheck: <IndeterminateCheckBoxIcon />,
      }}
    />
  );
};
