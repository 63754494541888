import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { Collapse, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useDeleteAllocation } from "../../hooks/Inventory/inventory";
import i18n from "../../i18n";
import { InventoryAllocationDto } from "../../types/Inventory/InventoryAllocationDto";
import ".././Common/SummaryItems/SummaryItem.css";
import MessageAlert, { AlertStyle } from "../Common/Errors/MessageAlert";
import { LabelAndHelp } from "../Common/Help/LabelAndHelp";
import { LR } from "../Common/Help/LR";
import { CustomConfirmDialog } from "../Common/UI/CustomConfirmDialog";
import { colors } from '../../main/Theme';

type Args = {
  items: InventoryAllocationDto[],
  handleDirtyAction: (cb: () => void) => void
};

const InventoryAllocation = ({ items, handleDirtyAction }: Args) => {
  const nav = useNavigate();
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const deleteallocation = useDeleteAllocation();

  const [allocationItems, setAllocationItems] = useState<InventoryAllocationDto[]>(items);
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
  const [deleteName, setDeleteName] = useState<string>('');
  const [deleteId, setDeleteId] = useState<string>('');
  const [deleteInventoryId, setDeleteInventoryId] = useState<string>('');
  const [error, setError] = useState<string>('');


  const handleDelete = (name: string, id: string, inventoryId: string) => {
    setError('');
    setDeleteName(name);
    setDeleteId(id);
    setDeleteInventoryId(inventoryId);
    setDeleteConfirmation(true);
  }

  const confirmDeleteItem = async (id: string, inventoryId: string) => {
    const resultDelete = await deleteallocation(id, inventoryId);
    if (resultDelete.success) {
      setAllocationItems(allocationItems.filter(x => x.id !== id))
    } else {
      setError("INVENTORY_ERROR_ALLOCATIONDELETION");
    }
    setDeleteConfirmation(false);
  }

  const addItem = () => {
    handleDirtyAction(() => nav('allocations/add'))
  }

  const editItem = (id: string) => {
    handleDirtyAction(() => nav(`allocations/${id}`))
  }

  return (
    <>
      <LabelAndHelp mandatory={false} fullscreen={true} message='INVENTORY_LABEL_ALLOCATIONTEXT' ></LabelAndHelp>
      <Paper elevation={3} className="fa-paper-light">
        <div className="d-flex align-items-center">
          <div className="fa-name flex-grow-1">
            <Tooltip title={i18n.t('INVENTORY_LABEL_NEWALLOCATION')}>
              <IconButton onClick={addItem} className="btn plusButton">
                <AddCircleRoundedIcon fontSize="medium" sx={{ color: colors.primary }} />
              </IconButton>
            </Tooltip>
            <Typography variant="h6" className="linkbutton">
              <LR localResource="INVENTORY_LABEL_ALLOCATIONS" /> ({allocationItems.length})
            </Typography>
          </div>
          <div className="fa-icons">
            <IconButton className="fa-arrow" onClick={() => setIsCollapseOpen(!isCollapseOpen)}>
              {!isCollapseOpen && <ArrowForwardIosRoundedIcon />}
              {isCollapseOpen && <ArrowForwardIosRoundedIcon className='fa-arrow-down' />}
            </IconButton>
          </div>
        </div>
      </Paper>

      <Collapse in={isCollapseOpen}>
        <div>
          <div className="row d-flex justify-content-center">
            <div className="justify-content-center">
              {allocationItems.map((item, index) => (
                <React.Fragment key={index}>
                  <Paper key={item.id} elevation={24} className="fa-paper-grid ">
                    <div className="d-flex align-items-center">
                      <div className="fa-name flex-grow-1">
                        {item.inventoryTypeName} - {item.inventoryVariantValue}
                      </div>
                      <div className="col-1 d-flex justify-content-end" style={{ flex: 1 }}>
                        <Tooltip title={i18n.t('COMMON_TOOLTIP_DETAILS')}>
                          <IconButton className="fa-info" onClick={() => editItem(item.id!)}>
                            <EditRoundedIcon sx={{ color: colors.primary }} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={i18n.t('COMMON_TOOLTIP_DELETE', { param0: item.inventoryVariantValue })}>
                          <IconButton onClick={(e) => handleDelete(`${item.inventoryTypeName!}- ${item.inventoryVariantValue!}`, item.id!.toString(), item.inventoryId!.toString())}>
                            <DeleteRoundedIcon color={'error'} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  </Paper>
                </React.Fragment>
              ))}
            </div>
          </div>
          {
            error && <div className="row d-flex justify-content-center">
              <div className="justify-content-center">
                <MessageAlert message={error} variant="danger" style={AlertStyle.Tiny} additionalClassName="fa-alert-tiny"></MessageAlert>
              </div>
            </div>
          }
        </div>
      </Collapse>

      <CustomConfirmDialog
        isOpen={deleteConfirmation}
        onClose={() => setDeleteConfirmation(false)}
        title={i18n.t(`INVENTORY_LABEL_DELETEALLOCATION`)}
        content={i18n.t(`COMMON_BUTTON_DELETETEXT`, { name: deleteName })}
        confirmButtonColor='error'
        confirmButtonTitle={i18n.t(`COMMON_BUTTON_DELETE`)}
        handleConfirm={() => { confirmDeleteItem(deleteId, deleteInventoryId) }}
      ></CustomConfirmDialog>
    </>
  );
}

export {
  InventoryAllocation
};
