import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { Tooltip } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from 'usehooks-ts';
import { ApplicationConfig } from "../../config/ApplicationConfig";
import { getDefaultLocationByOperator } from "../../hooks/Authentication/userinfoV2";
import { useFetchLocations } from '../../hooks/Location/location';
import { useFetchMembers } from "../../hooks/Member/member";
import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { LocationResponseDto } from '../../types/Location/LocationResponseDto';
import { MemberResponseDto } from "../../types/Member/MemberResponseDto";
import ApiStatus from "../Common/Errors/ApiStatus";
import MessageAlert from "../Common/Errors/MessageAlert";
import { GlobalStateContext } from "../Common/GlobalStateProvider";
import { DialogLocationSelector } from '../Location/DialogLocationSelector';
import { DialogOperatorSelector } from "./DialogOperatorSelector";

type Args = {
  userInfo: ApplicationUser
};

const OperatorContext = ({ userInfo }: Args) => {
  const nav = useNavigate();

  const locationKey = userInfo.id + ApplicationConfig.currentLocationStorageKey;
  const currentUserKey = ApplicationConfig.currentUserStorageKey;
  const operatorKey = userInfo.id + ApplicationConfig.currentOperatorStorageKey;

  const [operatorData, saveOperatorData, removeOperatorData] = useLocalStorage<MemberResponseDto | null>(operatorKey, null)
  const [locationData, saveLocationData, removeLocationData] = useLocalStorage<LocationResponseDto | null>(locationKey, null)
  const [userData, saveUserData, removeUserData] = useLocalStorage<ApplicationUser | null>(currentUserKey, null)

  const { data: operators, status, isSuccess } = useFetchMembers(4, "", 2);
  const { data: locations } = useFetchLocations(operatorData?.id || "", false, {
    enabled: Boolean(operatorData?.id)
  });

  const queryClient = useQueryClient();

  const [openOperator, setOpenOperator] = useState(false);
  const [openLocation, setOpenLocation] = useState(false);
  const [operatorName, setOperatorName] = useState('');

  const globalState = useContext(GlobalStateContext);

  if (!globalState) {
    throw new Error('Used GlobalStateContext outside of GlobalStateProvider');
  }

  const { setGlobalLoggedUser } = globalState;

  const handleOperatorSelection = async (operator: MemberResponseDto) => {
    saveOperatorData(operator);

    const defaultLocation = await getDefaultLocationByOperator(operator);

    let updatedUserInfo = userInfo;
    updatedUserInfo.operator = operator;

    if (!defaultLocation) {
      updatedUserInfo.doesNeedSetup = true;
    } else {
      updatedUserInfo.location = defaultLocation;
      saveLocationData(defaultLocation);
    }

    setGlobalLoggedUser(updatedUserInfo);
    saveUserData(updatedUserInfo);

    queryClient.invalidateQueries("locations");
    setOperatorName(operator.fullName)
    setOpenOperator(false)
    nav('/')
  };

  const handleLocationSelection = (location: LocationResponseDto) => {
    saveLocationData(location);
    setGlobalLoggedUser({
      ...userInfo,
      location: location
    });
    setOpenLocation(false)
    nav('/')
  }

  useEffect(() => {
    if (operators) {
      const cachedOperator = operatorData;
      if (cachedOperator) {
        setOperatorName(cachedOperator.fullName!)
      } else {
        const selectedOperator = operators[0];
        saveOperatorData(selectedOperator);
      }
    }
  }, [operators]);

  if (!isSuccess) return (
    <>
      <div className="mt-5 row d-flex justify-content-center">
        <div className="col-md-7 col-11 justify-content-center">
          <ApiStatus status={status} />
        </div>
      </div>
    </>
  )

  if (!operators) return <MessageAlert message="MEMBER_LABEL_MEMBERSNOTFOUND" />;

  return (
    <div className="operatorContext center-vertically">
      <DialogOperatorSelector
        type="Operator"
        operators={operators}
        open={openOperator}
        handleCancel={() => setOpenOperator(false)}
        handleConfirm={handleOperatorSelection}
      />
      <DialogLocationSelector
        open={openLocation}
        locations={locations || []}
        handleConfirm={handleLocationSelection}
        handleCancel={() => setOpenLocation(false)}
      ></DialogLocationSelector>
      <div className="operatorContext-names">
        <h3>{userInfo.location?.name} {locations && locations.length > 1 &&
          <Tooltip title={i18n.t('MEMBER_TITLE_SWAPLOCATION')}>
            <SwapHorizIcon onClick={() => setOpenLocation(true)} sx={{ cursor: 'pointer', ml: 1 }}></SwapHorizIcon>
          </Tooltip>
        } </h3>
        <h6>{operatorName} {operators.length > 1 &&
          <Tooltip title={i18n.t('MEMBER_TITLE_SWAPOPERATOR')}>
            <SwapHorizIcon onClick={() => setOpenOperator(true)} sx={{ cursor: 'pointer', ml: 1 }}></SwapHorizIcon>
          </Tooltip>
        }</h6>
      </div>
    </div>
  );
};


export { OperatorContext };

